/* Welcome section Css Started */

.Welcome-Section {
    height: 100vh;
    position: relative;
    width: 100%;
    overflow-anchor: none;
}
.Welcome-Section .black-overlay {
  background-color: #3916037a;
  height: 100vh;
  position: absolute;
  top: 0;
  width: 100%;
  /* backdrop-filter: blur(5px); */
  /* -webkit-backdrop-filter: blur(6px); */
}
.Welcome-Section .content-welcome-section {
    position: absolute;
    top: 0px;
    height: 90%;
    position: absolute;
    text-align: center;
    top: 10%;
    width: 100%;
    z-index: 99;
}
.Welcome-Section .content-welcome-section .netpurti-logo img.logo-img {
    width: 261px;
    height: auto;
}
.Welcome-Section .content-welcome-section .welcome-banner-content h1.heading {
    font-size: 48px;
    font-weight: bold;
    color: #ffffff;
    text-align: -webkit-center;
}
.Welcome-Section .content-welcome-section .welcome-banner-content .content-heading {
    display: flex;
    justify-content: center;
}
.Welcome-Section .content-welcome-section .welcome-banner-content .content-heading .main h4 {
    margin: 14px;
    color: #f88d28;
    font-size: 30px;
}

.Welcome-Section .content-welcome-section .welcome-banner-content .content-heading .main .text-border {
    border-right: 3px solid #f88d28;
    height: 25px;
    margin: 24px 0px;
}
.Welcome-Section .content-welcome-section .welcome-banner-content {
    margin-top: 4%;
}
.Welcome-Section .content-welcome-section .welcome-banner-content .Panels .seller {
    border: 2px solid #f88d28;
    border-radius: 100px;
    width: 108px;
    height: 108px;
    background: #ffffff 0% 0% no-repeat padding-box;
}
.Welcome-Section .content-welcome-section .welcome-banner-content .Panels .seller img.seller-logo-img {
    margin: 21px 0px;
    width: 58px;
    height: 58px;
}
.Welcome-Section .content-welcome-section .welcome-banner-content .Panels .panel-text {
    text-align: center;
    color: #ffffff;
    font-size: 24px;
}
.Welcome-Section .content-welcome-section .welcome-banner-content .Panels .Seller-main {
    text-align: -webkit-center;
}
.Welcome-Section .content-welcome-section .welcome-banner-content .Panels .seller img.B2B-logo-img {
    margin: 25px 0px;
    width: 44px;
    height: 55px;
}
.Welcome-Section .content-welcome-section .welcome-banner-content .Panels .seller img.logistic-logo-img {
    margin: 21px 0px;
    width: 61px;
    height: 63px;
}
.Welcome-Section .content-welcome-section .welcome-banner-content .Panels .seller img.farmers-logo-img {
    margin: 21px 0px;
    width: 66px;
    height: 68px;
}
.Welcome-Section .content-welcome-section .welcome-banner-content .Panels .seller img.website-logo-img {
    margin: 29px 0px;
    width: 59px;
    height: 49px;
}
.Welcome-Section .content-welcome-section .welcome-banner-content .Panels {
    margin-top: 5%;
}

.Welcome-Section .welcome-banner-img img.welcome-img {
    height: 100vh;
    margin-bottom: -7px;
    min-height: 100vh;
    object-fit: cover;
    width: 100%;
}
.Welcome-Section .content-welcome-section .netpurti-link {
  bottom: 20px;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0px);
}

.Welcome-Section .content-welcome-section .welcome-banner-content .netpurti-link p.link {
    color: #ffffff;
    font-size: 16px;
}

/* Welcome section Css End */



/* Media Query Welcome Section Started */

@media (min-width: 0px) and (max-width: 485px) {
  .Welcome-Section .content-welcome-section .welcome-banner-content h1.heading {
    font-size: 26px;
  }
  .Welcome-Section
    .content-welcome-section
    .welcome-banner-content
    .content-heading
    .main
    h4 {
    font-size: 18px;
  }
  .Welcome-Section
    .content-welcome-section
    .welcome-banner-content
    .content-heading
    .main
    .text-border {
    height: 22px;
    margin: 13px 0px;
  }
  .Welcome-Section
    .content-welcome-section
    .welcome-banner-content
    .Panels
    .panel-text {
    font-size: 18px;
  }
 
  .Welcome-Section
    .content-welcome-section
    .welcome-banner-content
    .Panels
    .seller {
    width: 95px;
    height: 95px;
  }
  .Welcome-Section
    .content-welcome-section
    .welcome-banner-content
    .Panels
    .seller
    img.seller-logo-img {
    margin: 17px 0px;
    width: 50px;
    height: 50px;
  }
  .Welcome-Section
    .content-welcome-section
    .welcome-banner-content
    .Panels
    .seller
    img.logistic-logo-img {
    margin: 15px 0px;
  }
  .Welcome-Section
    .content-welcome-section
    .welcome-banner-content
    .Panels
    .seller
    img.B2B-logo-img {
    margin: 20px 0px;
  }
  .Welcome-Section
    .content-welcome-section
    .welcome-banner-content
    .Panels
    .seller
    img.farmers-logo-img {
    margin: 15px 0px;
    width: 59px;
    height: 62px;
  }
  .Welcome-Section
    .content-welcome-section
    .welcome-banner-content
    .Panels
    .seller
    img.website-logo-img {
    margin: 24px 0px;
    width: 55px;
    height: 43px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  
}

@media (min-width: 486px) and (max-width: 575px) {
  .Welcome-Section .content-welcome-section .welcome-banner-content h1.heading {
    font-size: 28px;
  }
  .Welcome-Section .content-welcome-section {
    top: 5%;
  }
  .Welcome-Section
    .content-welcome-section
    .welcome-banner-content
    .content-heading
    .main
    h4 {
    font-size: 22px;
  }
  .Welcome-Section
    .content-welcome-section
    .welcome-banner-content
    .content-heading
    .main
    .text-border {
    margin: 17px 0px;
  }

}

@media (min-width: 576px) and (max-width: 767px) {
  .Welcome-Section .content-welcome-section .welcome-banner-content h1.heading {
    font-size: 32px;
  }
  .Welcome-Section
    .content-welcome-section
    .welcome-banner-content
    .content-heading
    .main
    h4 {
    font-size: 26px;
  }
  .Welcome-Section
    .content-welcome-section
    .welcome-banner-content
    .Panels
    .panel-text {
    font-size: 22px;
  }
  .Welcome-Section .content-welcome-section {
    top: 6%;
  }
 
  .Welcome-Section
    .content-welcome-section
    .welcome-banner-content
    .content-heading
    .main
    .text-border {
    margin: 18px 0px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .Welcome-Section .content-welcome-section .welcome-banner-content h1.heading {
    font-size: 34px;
  }
  .Welcome-Section
    .content-welcome-section
    .welcome-banner-content
    .content-heading
    .main
    h4 {
    font-size: 28px;
  }
  .Welcome-Section
    .content-welcome-section
    .welcome-banner-content
    .content-heading
    .main
    .text-border {
    margin: 20px 0px;
  }
  .Welcome-Section
    .content-welcome-section
    .welcome-banner-content
    .Panels
    .panel-text {
    font-size: 22px;
  }
  .Welcome-Section
    .content-welcome-section
    .welcome-banner-content
    .Panels
    .Seller-main {
    margin-top: 16%;
  }
  
}
/* Media Query Welcome Section End */
