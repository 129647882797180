.slider-home {
  margin: 6% 0;
}

.slider-home .main-img-class {
  position: relative;
}

.slider-home .main-img-class .achive-img {
  width: 100%;
  height: 328px;
  object-fit: cover;
  background-position: top;
  border-radius: 10px;
}

.slider-home .main-img-class .overlay-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #1d1d1d 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 0.69;
}

.slider-home .name-holder {
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-39%, -28%);
}

.slider-home .name-holder h4 {
  font-size: 16px;
  color: #fff;
  letter-spacing: 0.16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 5;
  line-clamp: 5;
}

.slider-home .swiper-pagination-bullet {
  width: 8px;
  height: 6px;
  border-radius: 5px;
  opacity: 1;
  background-color: #cecece;
}

.slider-home .swiper-pagination-bullet-active {
  width: 18px;
  background: #000;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .slider-home .main-img-class .achive-img {
    height: 278px;
  }

  .slider-home .name-holder {
    left: 22%;
    transform: translate(-13%, -32%);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .slider-home .main-img-class .achive-img {
    height: 278px;
  }

  .slider-home .name-holder {
    left: 22%;
    transform: translate(-13%, -32%);
  }
}

@media (min-width: 0px) and (max-width: 578px) {
  .slider-home .main-img-class {
    margin: 0px 10px;
  }
}
