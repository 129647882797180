.help-center-tab-banner {
    position: relative;
    top: -155px;
}

.help-center-tab-banner .banner-img {
    width: 100%;
    height: 367px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.help-center-tab-banner .banner-txttt {
    position: absolute;
    top: 38%;
    left: 17%;
}

.help-center-tab-banner .banner-txttt .hi-txt {
    font-size: 50px;
    color: #FFF;
    font-weight: 600;
}

.help-center-tab-banner .banner-txttt .how-can-txtt {
    font-size: 28px;
    color: #FFF;
    font-weight: 600;
}

@media (min-width:0px) and (max-width:575px){
    .help-center-tab-banner .banner-img {
      height: 263px;
    }
    
}