/* common details css */

.Common-details-step .main-card h5.text-heading {
  color: #262626;
  font-size: 14px;
  font-weight: 600;
}

.Common-details-step p.minitext {
  color: #262626;
  font-size: 14px;
  font-weight: 500;
}

.Common-details-step p.grey-text {
  color: #5e5e5e;
  font-size: 14px;
  font-weight: 500;
}

.Common-details-step .fa-xmark {
  font-size: 30px;
  color: grey;
}

.Common-details-step .main-card .form-label {
  font-size: 14px;
  font-weight: 500;
}

.Common-details-step .span-text {
  font-size: 10px;
  font-weight: 500;
}

.Common-details-step label {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.Common-details-step .main-card .css-1jqq78o-placeholder {
  font-size: 14px;
}

.Common-details-step .main-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000012;
  border-radius: 10px;
  opacity: 1;
  padding: 30px 10px;
  font-size: 12px;
  margin: 20px 5px;
  font-weight: 500;
}

.css-1dimb5e-singleValue {
  font-size: 12px;
  font-weight: 500;
}

.css-b62m3t-container {
  width: 30%;
}

.preview-box {
  width: 40px;
  text-align: center;
  height: 40px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  margin: 0px 5px;
  border-radius: 50px;
}

.Common-details-step .main-card .form-control {
  font-size: 14px;
  font-weight: 500;
}

.Common-details-step .main-card .star {
  color: red;
}

.Common-details-step .main-card h4.cust-text {
  font-size: 16px;
  font-weight: 500;
}

.Common-details-step .tab-section .nav-pills .nav-link.active {
  background-color: #ffff;
  color: black;
  font-weight: 600;
  border-bottom: 2px solid #1c42a9;
  border-radius: 0px;
  padding: 8px 22px;
  font-size: 14px;
}

.Common-details-step .tab-section .nav-pills .nav-link {
  background-color: #ffff;
  font-weight: 400;
  border-radius: 0px;
  padding: 8px 22px;
  font-size: 14px;
  color: #656565;
  font-weight: 400;
  margin: 0px 25px;
}

.Common-details-step .tab-section .nav {
  border-bottom: 1px solid grey;
  margin-bottom: 20px;
}

.Common-details-step .form-check-input:focus {
  box-shadow: none;
}

.Common-details-step .delet-row {
  width: 18px;
  height: 18px;
  border: 1px solid red;
  border-radius: 50%;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  background-color: #fff;
}

.Common-details-step .delet-row .fa-minus {
  color: red;
  font-size: 10px;
}

.Common-details-step .video-sec h6.video-title {
  font-size: 12px;
  font-weight: 500;
  color: #262626;
}

.Common-details-step .video-sec p.itlic-text {
  font-size: 12px;
  font-weight: 500;
  color: #262626;
  font-style: italic;
}

.Common-details-step .video-sec .uplod-btn {
  background: #dfdfdf 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  border: none;
  font-size: 14px;
  font-weight: 400;
  padding: 4px 10px;
}

.Common-details-step .video-sec p.sub-title {
  font-size: 10px;
  font-weight: 400;
  color: #262626;
}

.Common-details-step .video-sec .video-div .online-video {
  width: 100%;
  height: 180px;
  margin-top: 1%;
}

.jodit-toolbar-button {
  height: 21px;
  min-height: 21px;
}

span.jodit-placeholder {
  font-size: 14px !important;
}

.jodit-toolbar-button .jodit-icon {
  height: 10px;
  width: 10px;
}

.jodit-ui-spacer {
  display: none;
}

.Common-details-step .addrowiccc {
  border: 0px;
  padding: 0px;
  background-color: transparent;
}
.Common-details-step .addrowiccc img {
  width: 20px;
  margin-right: 5px;
}

.Common-details-step .react-autosuggest__input {
  width: 100% !important;
  border-color: hsl(0, 0%, 80%);
  border-radius: 5px;
  padding: 5px;
}

.Common-details-step .react-autosuggest__suggestions-list {
  list-style-type: none;
  padding-left: 0;
  overflow-y: auto;
  height: 162px;
}

.Common-details-step
  .react-autosuggest__suggestions-list
  .react-autosuggest__suggestion {
  background-color: hsl(0, 0%, 90%);
  margin: 5px;
  padding: 5px;
  font-size: 14px;
}
.Common-details-step .custooo-overflow {
  height: auto;
  overflow-y: scroll;
  overflow-x: hidden;
}
.Common-details-step ::-webkit-scrollbar-track {
  background: #f88d28;
  border-radius: 20px;
}

.Common-details-step ::-webkit-scrollbar-thumb:hover {
  background: #f88d28;
  border-radius: 20px;
}
.Common-details-step ::-webkit-scrollbar-track {
  background: #f88d28;
  border-radius: 20px;
}
