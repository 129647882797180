.my-inquiry-section .item-date {
    font-size: 12px;
    color: #585858;
}

.my-inquiry-section .item-id {
    font-size: 12px;
    color: #585858;
}

.my-inquiry-section .rfq-card p {
    line-height: 5px;
}

.my-inquiry-section .rfq-card .brown-bg .comment-img {
    background: #ebebeb;
    padding: 10px;
    border-radius: 50px;
    color: #585858;
}

.my-inquiry-section .item-title {
    font-size: 14px;
    text-align: justify;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    margin-bottom: 12px;
}

.my-inquiry-section .rfq-card {
    margin-bottom: 2%;
    position: relative;
    background: #ffffff;
    box-shadow: 0px 3px 14px #00000012;
    border-radius: 10px;
    border: none;
    /* padding: 18px 15px; */
    padding: 21px 11px 1px 16px;
}

.my-inquiry-section .item-price {
    font-size: 16px;
    font-weight: 700;
    color: #000;
}

.my-inquiry-section .min-order {
    font-size: 12px;
    color: #000;
    font-weight: 400;
}

.my-inquiry-section .qty-item {
    font-size: 12px;
    font-weight: 600;
    color: #000;
}

.my-inquiry-section .detail-req {
    font-size: 10px;
    color: #404040;
}

.my-inquiry-section .process-btn {
    background: #F88D28 0% 0% no-repeat padding-box;
    border-radius: 24px;
    color: #FFF;
    font-size: 12px;
    border: none;
    margin-top: 97px;
}

.my-inquiry-section .process-btn:focus {
    background: #F88D28 0% 0% no-repeat padding-box;
    border-radius: 24px;
    color: #FFF;
    font-size: 12px;
    border: none;
    margin-top: 97px;
}

@media screen and (min-width: 0px) and (max-width: 767.97px) {
    /* .my-inquiry-section .rfq-card .brown-bg {
        left: 0%;
    } */

    .my-inquiry-section .product-img {
        margin-bottom: 15px;
    }
}

/* New css Started */
.my-inquiry-section .rfq-card .product-img {
    width: 58px;
    height: 58px;
}

.my-inquiry-section .rfq-card .card-content {
    padding: 0px 7px;
}

.my-inquiry-section .rfq-card .border-id {
    margin: 2px 7px;
    height: 12px;
    border-right: 1px solid #a39f9f;
}

.my-inquiry-section .rfq-card .chair-content {
    display: flex;
    margin: 11px 0px;
}

.my-inquiry-section .rfq-card p.status {
    color: #000000;
    font-size: 10px;
    text-align: center;
}

.my-inquiry-section .rfq-card p.approved {
    text-align: center;
    font-size: 12px;
    color: #28a403;
}

.my-inquiry-section .rfq-card p.action {
    color: #000000;
    font-size: 10px;
}

.my-inquiry-section .rfq-card svg.svg-inline--fa.fa-eye {
    width: 19px;
    height: 12px;
    color: #858585;
}

.my-inquiry-section .rfq-card p.view {
    color: #000000;
    font-size: 12px;
    font-weight: 500;
    margin: 6px 2px;
    cursor: pointer;
}

.my-inquiry-section .rfq-card .action-section {
    display: flex;
    margin-bottom: 10px;
}

.brown-bg {
    position: relative;
    text-align: end;
    top: 0px;
    /* right: -103px; */
}

.my-inquiry-section .rfq-card .red-dot {
    height: 7px;
    width: 7px;
    background: #ff0000;
    border-radius: 50px;
    position: absolute;
    top: 3px;
    right: 3px;
}


/* Media Query Started */

@media (min-width:0px) and (max-width:991px) {
    .my-inquiry-section .rfq-card p.status {
        text-align: left;
    }

    .my-inquiry-section .rfq-card p.approved {
        text-align: left;
    }

    .my-inquiry-section .rfq-card .content-one {
        margin-left: 67px;
    }

    .my-inquiry-section .rfq-card .content-three {
        margin-left: 67px;
    }

    .my-inquiry-section .item-title {
        -webkit-line-clamp: 2;
    }

    .brown-bg {
        text-align: end;
    }

    .my-inquiry-section .process-btn {
        margin-top: 0px;
    }

    .my-inquiry-section .process-btn:focus {
        margin-top: 0px;
    }
}

@media (min-width:577px) and (max-width:992px) {
    .my-inquiry-section .rfq-card .content-three {
        margin-left: 30px !important;
    }
}

@media (min-width:992px) and (max-width:1399px) {
    .my-inquiry-section .rfq-card p.status {
        text-align: left;
    }

    .my-inquiry-section .rfq-card p.approved {
        text-align: left;
    }

    .content-one {
        margin-left: 67px;
    }

    .my-inquiry-section .process-btn {
        margin-top: 0px;
    }

    .my-inquiry-section .process-btn:focus {
        margin-top: 0px;
    }
}