.blog-card {
    margin-top: 3%;
}

.blog-card .card {
    border-radius: 5px;
    overflow: hidden;
    border: none;
    box-shadow: 0px 3px 6px #efeded;
    margin-top: 15%;

}

.blog-card .card:hover {
    box-shadow: 0px 3px 6px #d5d3d3;
}

.blog-card .continue-btn {
    color: #FFF;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    background: transparent;
    font-weight: 500;
}

.blog-card .buy-now {
    color: #f88d28;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    background: transparent;
    border: 1px solid #f88d28;
    font-weight: 500;
    padding: 5px 30px;
}

.blog-card .buy-now:hover {
    color: #FFF;
    background: #f88d28;
}

.blog-card .card-tit {
    font-size: 18px;
    font-weight: 600;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

.blog-card .blog-main-img {
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    display: block;
    position: relative;
}

.blog-card .blog-main-img .blog-card-img {
    position: relative;
    background: no-repeat;
    width: 100%;
    display: block;
    transition: 1.4s;
    height: 170px;
}

.blog-card .blog-main-img:hover .blog-card-img {
    transform: scale(1.1);
}


.blog-card .blog-main-img .theme-card-img {
    position: relative;
    background: no-repeat;
    width: 100%;
    display: block;
    transition: 1.4s;
    /* border-radius: 5px 5px 0px 0px; */

}

/* .blog-card .blog-main-img:hover .theme-card-img {
    transform: scale(1.1);
} */

.blog-card .date {
    font-size: 20px;
    color: #000;
    background: #FFF;
    border-radius: 5px;
    padding: 10px;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 999;
}

.blog-card .month {
    font-size: 15px;
}

.blog-card .blog-txtt {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    font-size: 12px;
    color: #928e8e;
}

.blog-card .blog-price {
    font-size: 18px;
    font-weight: 600;
    color: #000;
}

.blog-card .blog-main-img .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background: rgba(0, 0, 0, 0.5);
}

.blog-card .blog-main-img:hover .overlay {
    opacity: 1;
}

.blog-card .blog-main-img .dot-lotti {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.blog-card a {
    text-decoration: none;
}