.ShoppingCart-sec .slider-img {
    width: 100%;
    height: 100%;
}

.ShoppingCart-sec .btn-continue {
    background: #F88D28 0% 0% no-repeat padding-box;
    border-radius: 24px;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    border: none;
   padding: 7px 40px;
}


.ShoppingCart-sec .btn-continue:focus {
    background: #F88D28 0% 0% no-repeat padding-box;
    border-radius: 24px;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    border: none;
    padding: 7px 40px;
}