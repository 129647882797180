.Variants_section_main {
  margin-top: 20px;
}

.Variants_section_main .sec-div {
  padding: 15px;
  border-radius: 10px;
  background: #fff;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.07));
  margin-bottom: 15px;
}

.Variants_section_main .sec-div .varient-checkss .form-check {
  margin-right: 20px;
}

.Variants_section_main .sec-div .form-label {
  font-weight: normal;
  font-size: 14px;
  color: #000;
}

.Variants_section_main .sec-div .form-check-label {
  font-weight: normal;
  font-size: 14px;
  color: #000;
}

.mandatory-star {
  color: #ef0000;
}

.Variants_section_main .sec-div .varient-checkss .form-check-input:checked {
  background-color: #656565;
  border-color: #818181;
}

.Variants_section_main .sec-div .form-control::placeholder {
  font-size: 14px;
}

.Variants_section_main .sec-div .form-control {
  font-size: 14px;
  width: 100%;
}

.Variants_section_main .sec-div .form-select {
  font-size: 14px;
}

.sec-div .uploadbtn {
  background: #dfdfdf;
  border-radius: 5px;
  border: 0px;
  color: #000;
  padding: 5px 15px;
  font-size: 14px;
}

.uploadBtn {
  background: #dfdfdf 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: 0;
  font-size: 14px;
  padding: 5px 10px;
}

.Variants_section_main .sec-div .uplodicc {
  height: 14px;
  width: auto;
}

.Variants_section_main .sec-div .uplod-imm-div {
  position: relative;
  width: max-content;
}

.Variants_section_main .sec-div .uplod-imm-div .up-imgdg {
  width: 100px;
  height: auto;
}

.Variants_section_main .sec-div .uplod-imm-div .dlttt {
  position: absolute;
  right: -5px;
  top: -6px;
  width: 14px;
}

.Variants_section_main .sec-div .jpgimg {
  font-size: 10px;
}

.Variants_section_main .sec-div .single-variant-tabsshd .nav-link.active {
  background-color: #fff;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 2px solid #1c42a9;
  border-radius: 0px;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #000 !important;
}

.Variants_section_main .sec-div .single-variant-tabsshd .nav-tabs .nav-link:hover {
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
  border-bottom: 0 !important;
}

.Variants_section_main .sec-div .single-variant-tabsshd .nav-link {
  font-weight: normal;
  font-size: 14px;
  color: #656565;
  /* margin-bottom: 10px; */
  width: max-content;
}

.Variants_section_main .sec-div .single-variant-tabsshd .nav-pills {
  border-bottom: 1px solid #ccc;
}

.Variants_section_main .sec-div .single-variant-tabsshd .proppdp {
  font-weight: 500;
  font-size: 14px;
  color: #000;
}

.Variants_section_main .sec-div .single-variant-tabsshd .proppdp span {
  font-weight: 500;
  font-size: 10px;
}

.Variants_section_main .sec-div .single-variant-tabsshd .tab-content-data .uploadbox {
  background: #f5f5f5;
  border-radius: 10px;
  width: 100%;
  padding: 15px;
}

.Variants_section_main .sec-div .single-variant-tabsshd .tab-content-data .uploadbox .upd-card {
  position: relative;
  width: 125px;
  height: 125px;
}

.Variants_section_main .sec-div .single-variant-tabsshd .tab-content-data .uploadbox .upd-card .slide-img {
  width: 125px;
  height: 125px;
  object-fit: cover;
  border-radius: 5px;
}

.Variants_section_main .sec-div .multi-scroll-box .tab-content-data .uploadbox .upd-card .slide-img {
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 5px;
  position: absolute;
}

.Variants_section_main .sec-div .single-variant-tabsshd .tab-content-data .uploadbox .upd-card .dltt-slide {
  position: relative;
  top: -9px;
  left: 54px;
  width: 20px;
}

.Variants_section_main .sec-div .single-variant-tabsshd .tab-content-data .uploadbox .upd-card .dltt-slide-img {
  width: 15px;
  position: absolute;
  top: -4px;
  left: 53px;
}

.Variants_section_main .sec-div .single-variant-tabsshd .thumbnail-txt {
  font-size: 14px;
}

.Variants_section_main .sec-div .single-variant-tabsshd .button-uploadd {
  border: none;
  border-radius: 5px;
  background: #dfdfdf;
  font-size: 14px;
  padding: 6px 16px;
}

.Variants_section_main .sec-div .multi-scroll-box .nav-pills {
  display: inline-flex;
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-bottom: 16px;
}

.Variants_section_main .sec-div .multi-scroll-box .nav-link.active {
  background-color: #fff;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 2px solid #1c42a9;
  border-radius: 0px;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #000 !important;
}

.Variants_section_main .sec-div .multi-scroll-box .nav-tabs .nav-link {
  text-wrap: nowrap !important;
}

.Variants_section_main .sec-div .multi-scroll-box .nav-tabs::-webkit-scrollbar {
  height: 5px;
}

.Variants_section_main .sec-div .multi-scroll-box .nav-tabs::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.Variants_section_main .sec-div .single-variant-tabsshd .multiinner-table td {
  font-size: 14px;
}

.Variants_section_main .sec-div .green-add {
  font-size: 14px;
  color: green;
  cursor: pointer;
}

.Variants_section_main .sec-div .red-add {
  font-size: 14px;
  color: #ef0000;
  cursor: pointer;
}

.Variants_section_main .sec-div .single-variant-tabsshd .tab-content-data .uploadbox .swiper {
  padding: 6px 0px !important;
}

.Variants_section_main .sec-div .single-variant-tabsshd .tab-content-data .uploadbox .swiper-pagination {
  display: none;
}

.Variants_section_main .sec-div .single-variant-tabsshd .tab-content-data .produuyct-discri .tttt {
  font-weight: 500;
  font-size: 12px;
  color: #262626;
  margin-bottom: 5px;
}

.Variants_section_main .sec-div .single-variant-tabsshd .tab-content-data .produuyct-discri .testtt {
  font-weight: normal;
  font-size: 10px;
  color: #262626;
  margin-bottom: 5px;
}

.Variants_section_main .single-varient-sec .sec-div .add-new-attribute-class {
  font-size: 14px;
  color: #1c42a9;
  font-weight: 500;
  margin-right: 27px;
  cursor: pointer;
  text-align: end;
  border: none;
  background: transparent;
}

.Variants_section_main .single-varient-sec .sec-div .remove-btn-class {
  font-size: 14px;
  color: #ef0000;
  font-weight: 500;
  margin-right: 27px;
  cursor: pointer;
  text-align: end;
  border: none;
  background: transparent;
}

.Variants_section_main .single-varient-sec .varient-firsttt {
  border-right: 1px solid #ced4da;
  padding-right: 15px;
}