.select-brand-step-2 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000012;
  border-radius: 10px;
  opacity: 1;
  padding: 20px;
}
.select-brand-step-2 .brand.form-label {
  font-size: 16px;
  font-weight: 500;
}
.select-brand-step-2 .form-text.form-label {
  font-size: 14px;
  font-weight: bold;
  color: #000000;
}
.select-brand-step-2 .form-control {
  font-size: 12px;
  color: #c8c8c8;
  padding: 12px 10px;
  border-radius: 7px;
}
.select-brand-step-2 .fotm-grp {
  position: relative;
}
.select-brand-step-2 .check-brand-btn {
  position: absolute;
  top: 38.5px;
  right: 9px;
  background-color: #f88d28;
  color: #fff;
  border: none;
  padding: 7px 16px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
}
.select-brand-step-2 label.form-check-label {
  margin-left: 17px;
  font-size: 15px;
  font-weight: 500;
}
.select-brand-step-2 .mySwiper .brand-round {
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border-radius: 19px;
  opacity: 1;
  padding: 10px 20px;
  text-align: center;
}
.select-brand-step-2 .mySwiper .brand-round p {
  font-size: 14px;
  color: #1c1c1c;
  font-weight: 400;
  margin: 0px;
}
.select-brand-step-2 .swiper {
  padding: 30px 9px;
}
.select-brand-step-2 .swiper-pagination-bullet {
  background-color: grey;
}
.select-brand-step-2 .brand-swiper h5 {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-top: 40px;
}

.select-brand-step-2 .right-div {
  border-left: 1px solid grey;
  height: 100%;
}
.select-brand-step-2 .right-div .lotty-div {
  height: 85px;
}
.select-brand-step-2 .right-div .continu-btn-div {
  text-align: center;
}
.select-brand-step-2 .right-div .continu-btn-div p {
  font-size: 14px;
  font-weight: 500;
  color: #1c1c1c;
}
.select-brand-step-2 .right-div .continu-btn-div .aprov-btn {
  background-color: #1c42a9;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  padding: 7px 18px;
  border-radius: 20px;
  border: none;
}
.select-brand-step-2 .right-div .bottom-div {
  text-align: center;
  margin-top: 60px;
}
.select-brand-step-2 .right-div .bottom-div h6 {
  color: #272727;
  font-size: 16px;
  font-weight: 500;
}
.select-brand-step-2 .right-div .bottom-div p {
  color: #272727;
  font-size: 13px;
  font-weight: 300;
}
.select-brand-step-2 .right-div .bottom-div .grey-card {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 10px;
  text-align: start;
  margin: 0px 20px;
}
.select-brand-step-2 .right-div .bottom-div .wrong {
  color: #f44336;
}
.select-brand-step-2 .right-div .bottom-div .right {
  color: #26b80a;
}
.select-brand-step-2 .right-div .bottom-div th {
  margin-left: 10px;
  border: none;
}
.select-brand-step-2 .right-div .bottom-div td {
  border: none;
  font-size: 14px;
}
.select-brand-step-2 .form-check-input:checked {
  background-image: radial-gradient(
    circle at 50% 50%,
    grey 50%,
    #fff 0
  ) !important;
  border: 1px solid gray !important;
}
.select-brand-step-2 .form-check-input:focus {
  box-shadow: none;
}

.select-brand-step-2 .form-control:focus {
  border-color: grey;
  box-shadow: none;
}

.select-brand-step-2 .react-autosuggest__input {
  width: 100% !important;
  border-color: hsl(0, 0%, 80%);
  border-radius: 5px;
  padding: 5px;
}

.select-brand-step-2 .react-autosuggest__suggestions-list {
  list-style-type: none;
  padding-left: 0;
  overflow-y: auto;
  height: 162px;
}

.select-brand-step-2
  .react-autosuggest__suggestions-list
  .react-autosuggest__suggestion {
  background-color: hsl(0, 0%, 90%);
  margin: 5px;
  padding: 5px;
  font-size: 14px;
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .select-brand-step-2 .right-div {
    border-left: 0px;
  }
}
