/* RFQ Main */

.rfq-main .nav-pills .nav-link.active {
    color: #000;
    background-color: transparent;
    font-weight: 600;
    font-size: 14px;
    border-bottom: 2px solid #000000;
    border-radius: 0;
}

.rfq-main .breadcrumb-item + .breadcrumb-item::before {
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, ">");
}

.rfq-main .breadcrumb-item a {
    color: #000;
    font-size: 14px;
}

.rfq-main .breadcrumb-item.active {
    font-size: 14px;
}

.rfq-main .nav-pills .nav-link {
    color: #474747;
    background-color: none;
    font-size: 14px;
}
.rfq-main {
    background-image: url("../../../../../public/assest/images/B2Bbuyer/BestSeller/bestSellerBackImg.png");
    /* height: 491px; */
    background-size: cover;
    margin-bottom: 5%;
    padding-top: 1%;
}
/* My-Rfq  */

.my-rfq .card-myrfq {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 14px #00000012;
    border-radius: 10px;
    padding: 10px 20px 0px 20px;
}

.my-rfq .card-myrfq .card-myrfq-title {
    font-size: 10px;
    color: #000000;
}

.my-rfq .card-myrfq .status-approved {
    color: #28a403;
    font-size: 12px;
}

.my-rfq .card-myrfq .status-pending {
    color: #d80707;
    font-size: 12px;
}

.my-rfq .card-myrfq .pieces {
    font-size: 12px;
    color: #000;
    font-weight: 700;
}

.my-rfq .card-text {
    font-size: 12px;
    color: #000;
    cursor: pointer;
}

.my-rfq .rfq-title {
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
}

.my-rfq .eye-icon {
    color: #858585;
}

.my-rfq .card-myrfq .item-date {
    font-size: 10px;
    color: #585858;
    border-right: 2px solid #585858;
}

.my-rfq .card-myrfq .item-id {
    font-size: 10px;
    color: #585858;
}


/* Media Query Started */

@media (min-width:0px) and (max-width:991px) {
.rfq-main {
    height: auto;
}
}