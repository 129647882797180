.ShipmentTracking-sec {
    margin-bottom: 50px;
}

.ShipmentTracking-sec .heading h1 {
    font-size: 24px;
    font-weight: bold;
    color: #000;
}

.ShipmentTracking-sec .slider-img {
    width: 100%;
    height: 100%;
}

.ShipmentTracking-sec .bg-sec {
    background: #F3F3F3 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    padding-bottom: 10px;
    margin-bottom: 10%;
}

.ShipmentTracking-sec .heading {
    border-bottom: 1px solid #B2B2B2;
    margin: 15px 0px;
}

.TrackingCard-sec .card-sec p.order-text {
    font-size: 12px;
    font-weight: 400;
    color: #000;
}

.TrackingCard-sec .card-sec .order-btn {
    background-color: transparent;
    border: none;
    font-size: 12px;
    text-decoration: underline;
    color: #000;
    font-weight: 500;
}

.TrackingCard-sec .card-sec {
    opacity: 1;
    padding: 10px 10px;
    margin: 2px 0px;
    border-bottom: 1px solid #B2B2B2;
}

.TrackingCard-sec .card-sec p.invoice-text {
    font-size: 10px;
    font-weight: 400;
    color: #000;
    margin-bottom: 20px;
}

.TrackingCard-sec p {
    margin: 0px;
}

.TrackingCard-sec .card-sec .prduct-img {
    width: 80px;
    height: 80px;
    border-radius: 10px;
}

.TrackingCard-sec .card-sec .left-sec {
    display: flex;
}

.TrackingCard-sec .card-sec .right-sec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 10px 10px;
}

.TrackingCard-sec .card-sec .left-sec .details h6 {
    font-size: 14px;
    font-weight: 500;
    color: #000;
}

.TrackingCard-sec .card-sec .left-sec .details p {
    font-size: 12px;
    font-weight: 400;
    color: #707070;
    margin: 0px;
}

.TrackingCard-sec .card-sec .right-sec p.price-text {
    font-size: 16px;
    font-weight: bold;
    color: #000000;
}

@media (min-width: 0px) and (max-width: 576px) {
    .TrackingCard-sec .card-sec .left-sec {
        display: inline;
    }
}

@media (min-width: 0px) and (max-width: 768px) {


    .TrackingCard-sec .card-sec .left-sec .details h6 {
        font-size: 12px;
    }

    .TrackingCard-sec .card-sec .left-sec .details p {
        font-size: 11px;

    }

    .TrackingCard-sec .card-sec .right-sec p.price-text {
        font-size: 14px;

    }

    .TrackingCard-sec .card-sec .prduct-img {
        width: 65px;
        height: 65px;
    }

    .ShipmentTracking-sec .heading h1 {
        font-size: 20px;
        font-weight: bold;
        color: #000;
    }

    .ShipmentTracking-sec .slider-img {
        margin-top: 10px;
    }
}
@media (min-width: 769px) and (max-width: 991px)
{
    .ShipmentTracking-sec .slider-img {
        margin-top: 10px;
    }
}