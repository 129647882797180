.Furniture_Slider .Furniture_Swiper {
  padding-bottom: 4%;
  padding-left: 19px;
}

.Furniture_Slider .Furniture_Swiper .swiper-pagination-bullet-active {
  background: #000;
}

.Furniture_Slider .Furniture_Swiper .Furniture-img-holder {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 14px #00000012;
  height: 195px;
  padding: 10px 20px;
  border-radius: 10px;
}

.Furniture_Slider .Furniture_Swiper .Furniture-img-holder:hover .Furniture-img {
  transition: transform 0.3s ease-in-out; /* Animation */
  transform: scale(1.1);
}

.Furniture_Slider .Furniture_Swiper .Furniture-img-holder:hover {
  background: #fff7ef 0% 0% no-repeat padding-box;
}

.Furniture_Slider
  .Furniture_Swiper
  .half-width-button-container
  .view-more-button {
  background: #f88d28 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 14px #00000012;
  border-radius: 10px;
  height: 195px;
  border: none;
  width: 50%;
  color: #fff;
}

.Furniture_Slider .Furniture_Swiper .half-width-button-container {
  display: flex;
  justify-content: end;
  align-items: center;
}

.Furniture_Slider .Furniture_Swiper .swiper-pagination-bullet-active {
  background: #000;
  width: 10px !important;
  height: 2px;
  border-radius: 0px;
}

.Furniture_Slider .Furniture_Swiper .swiper-pagination-bullet {
  width: 15px;
  height: 2px;
  border-radius: 0px;
  margin: 0px 1px;
}

.Furniture_Slider
  .Furniture_Swiper
  .Furniture-img-holder
  .Furniture-img-img
  .Furniture-img {
  width: 100%;
  height: 110px;
  object-fit: contain;
}

.Furniture_Slider .Furniture_Swiper .Furniture-img-holder h4 {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  text-align: center;
  margin-bottom: 20%;
}

.Furniture_Slider .Furniture_Swiper .Furniture-img-holder .button-holder {
  text-align: center;
  margin: 15px 0%;
}

.Furniture_Slider
  .Furniture_Swiper
  .Furniture-img-holder
  .button-holder
  .sell-btn {
  border-radius: 19px;
  color: #000;
  font-size: 11px;
  font-weight: 500;
  background: #68d1ff 0% 0% no-repeat padding-box;
  border-radius: 17px;
  padding: 3px 10px;
}

.Furniture_Slider
  .Furniture_Swiper
  .Furniture-img-holder
  .button-holder
  .sell-btn:focus {
  box-shadow: none;
}

.Furniture_Slider
  .Furniture_Swiper
  .Furniture-img-holder
  .button-holder
  .sell-btn:hover {
  background-color: #68d1ff;
}

@media (min-width: 0px) and (max-width: 320px) {
  .Furniture_Slider .Furniture_Swiper .Furniture-img-holder h4 {
    font-size: 11px;
  }

  .Furniture_Slider .Furniture_Swiper .Furniture-img-holder {
    height: 150px;
    margin-bottom: 25px;
}

  .Furniture_Slider
    .Furniture_Swiper
    .Furniture-img-holder
    .Furniture-img-img
    .Furniture-img {
    height: 100px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Furniture_Slider .Furniture_Swiper .Furniture-img-holder h4 {
    font-size: 15px;
    margin-bottom: 10%;
  }

  .Furniture_Slider .Furniture_Swiper .Furniture-img-holder {
    height: 150px;
  }

  .Furniture_Slider
    .Furniture_Swiper
    .Furniture-img-holder
    .Furniture-img-img
    .Furniture-img {
    height: 80px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Furniture_Slider .Furniture_Swiper .Furniture-img-holder h4 {
    font-size: 15px;
    margin-bottom: 10%;
  }

  .Furniture_Slider .Furniture_Swiper .Furniture-img-holder {
    height: 150px;
  }

  .Furniture_Slider
    .Furniture_Swiper
    .Furniture-img-holder
    .Furniture-img-img
    .Furniture-img {
    height: 80px;
  }
}

@media (min-width: 576px) and (max-width: 675px) {
  .Furniture_Slider .Furniture_Swiper .Furniture-img-holder h4 {
    font-size: 15px;
    margin-bottom: 10%;
  }

  .Furniture_Slider .Furniture_Swiper .Furniture-img-holder {
    height: 150px;
  }

  .Furniture_Slider
    .Furniture_Swiper
    .Furniture-img-holder
    .Furniture-img-img
    .Furniture-img {
    height: 80px;
  }
}

@media (min-width: 676px) and (max-width: 767px) {
  .Furniture_Slider .Furniture_Swiper .Furniture-img-holder h4 {
    font-size: 15px;
    margin-bottom: 10%;
  }

  .Furniture_Slider .Furniture_Swiper .Furniture-img-holder {
    height: 150px;
  }

  .Furniture_Slider
    .Furniture_Swiper
    .Furniture-img-holder
    .Furniture-img-img
    .Furniture-img {
    height: 80px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
.Furniture_Slider .Furniture_Swiper {
  padding-bottom: 10%;
}
}