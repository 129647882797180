.main-help-centered {
    background-image: url('../../../../../public/assest/images/B2Bbuyer/HelpCenter/help-bg.png');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

.help-slider .add-img {
    height: 360px;
    width: 100%;
}

.help-slider .swiper-pagination-bullet {
    width: 10px;
    height: 4px;
    border-radius: 5px;
    opacity: 1;
    background-color: #CECECE;
}

.help-slider .swiper-pagination-bullet-active {
    width: 10px;
    height: 4px;
    background: #000;
}


@media(min-width:0px) and (max-width:992px) {
    /* .help-slider {
        margin-top: 10%;
    } */
}