.adresss-modals .flex-div {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.adresss-modals .add-adress-btn {
    font-size: 14px;
    font-weight: 500;
    border: 1px solid black;
    background-color: #Fff;
    color: #000;
    border-radius: 20px;
    margin: 0px 43px 0px 10px;
}

.adresss-modals .close-icon {
    font-size: 25px;
    cursor: pointer;
}

.adresss-modals h1 {
    font-size: 18px;
    margin: 0px;
}


.adresss-modals .existing-user-div .res-btn {
    background-color: #FFE2C8;
    border: none;
    font-size: 10px;
    color: #000;
    border-radius: 10px;
    margin: 6px 0px;
}

.adresss-modals .existing-user-div h6 {
    font-size: 16px;
    color: #000000;
    font-weight: bold;
}

.adresss-modals .existing-user-div p {
    font-size: 14px;
    color: #000000;
    font-weight: 400;
    margin: 0px;
}

.adresss-modals .existing-user-div .change-btn {
    font-size: 12px;
    border: navajowhite;
    color: #000;
    background-color: transparent;
    padding: 0px;
    margin: 15px 0px;
    font-weight: 500;
}

.adresss-modals .existing-user-div .icons {
    font-size: 12px;
    margin: 0px 10px;
}

.adresss-modals .conform-btn {
    background: #F88D28 0% 0% no-repeat padding-box;
    border-radius: 24px;
    opacity: 1;
    color: #fff;
    border: none;
    margin: auto;
    font-size: 14px;
    padding: 5px 22px;
    margin: 5px;
}

.adresss-modals .btn-cancle {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #7A7A7A;
    border-radius: 24px;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    padding: 5px 22px;
    margin: 5px;
}

.adresss-modals .mobile-view {
    display: none;
}


.adresss-modals .form-label {

    font-size: 12px;
    font-weight: 500;
    color: #000;
}

.adresss-modals .form-control {
    font-size: 12px;
    font-weight: 500;
    color: #A4A2A2;
}

.adresss-modals p {
    font-size: 12px;
    font-weight: 400;
    color: #000;
}

.adresss-modals .wevvcwec {
    font-weight: 600;
    font-size: 14px;
    color: #000;
    margin-bottom: 0px;
}

.adresss-modals .nam-add-tab .nav-pills .nav-link {
    text-decoration: none;
}

.adresss-modals .nam-add-tab .nav-pills .nav-link {
    color: #000;
    background-color: #fff;
    border-radius: 15px;
    background: #fff;
    border: 1px solid #000;
    font-size: 12px;
    padding: 5px 20px;
    margin: 5px 5px;
}

.adresss-modals .nam-add-tab .nav-pills .nav-link.active {
    border: 1px solid #f88d28;
}

.adresss-modals .icons {
    cursor: pointer;
}

@media screen and (min-width: 0px) and (max-width: 485px) {
    .adresss-modals h1 {
        font-size: 14px;

    }

    .adresss-modals .add-adress-btn {
        font-size: 12px;
        margin: 8px 0px 0px 0px;
    }

    .adresss-modals .desktop-view {
        display: none;
    }

    .adresss-modals .mobile-view {
        display: block;
    }

    .adresss-modals .existing-user-div h6 {
        font-size: 15px;

    }

    .adresss-modals .existing-user-div p {
        font-size: 13px;

    }

    .adresss-modals .conform-btn {
        font-size: 12px;
    }

    .adresss-modals .btn-cancle {


        font-size: 12px;

    }
}

@media screen and (min-width: 486px) and (max-width: 768px) {
    .adresss-modals h1 {
        font-size: 14px;

    }

    .adresss-modals .add-adress-btn {
        font-size: 12px;

    }


}