.B2b_all_categories_sec{
    margin: 2% 0% 10% 0%;
}
.B2b_all_categories_sec .cat-div{
    box-shadow: 0px 0px 10px 0px #ccc;
    border-radius: 8px;
    background-color: #fff;
    padding: 15px;
    height: 100px;
}
.B2b_all_categories_sec .cat-div .d-flex{
    height: 100%;
}
.B2b_all_categories_sec .cat-div .icon-div{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 70px;    
}
.B2b_all_categories_sec .cat-div .icon-div .fafaicc{
    font-size: 25px;
}
.B2b_all_categories_sec .cat-div .icon-div{
    color: #000;
}
.B2b_all_categories_sec .cat-div  .name-div{
    width: 100%;
}
.B2b_all_categories_sec .cat-div  .name-div .name{
    font-size: 15px;
    margin-bottom: 0px;
}
