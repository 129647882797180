.privacy-policy{
    margin-top: 3%;
    margin-bottom: 5%;
}

.privacy-policy .heading-holder h1{
    font-size: 18px;
    font-weight: 600;
}
.privacy-policy .heading-holder h2{
    font-size: 14px;
    font-weight: 600;
}
.privacy-policy .heading-holder span{
    font-size: 13px;
    font-weight: 600;  
}
.privacy-policy .heading-holder  p{
    font-size: 14px;
}