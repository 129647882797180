.BestSellersCategories {
  margin-bottom: 5%;
  margin-top: 3%;
}

.BestSellersCategories .BestSellersCategories-slider-sec {
  margin: 2% 0px 4% 0%;
}

.BestSellersCategories .heading-holder .subtitle-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BestSellersCategories .heading-holder .subtitle-holder p {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  cursor: pointer;
}

.BestSellersCategories .BestSellersCategories {
  margin-top: 1%;
}

@media (min-width: 0px) and (max-width: 320px) {
  .BestSellersCategories .heading-holder .subtitle-holder p {
    font-size: 10px;
  }

  .BestSellersCategories .heading-holder h4 {
    text-align: center;
}
}
