/* .MyprofileBanner {
    position: fixed;
    z-index: 9999;
} */
.MyprofileBanner .Bannermain {
    position: relative;
}

.MyprofileBanner .Bannermain .profileBanner {
    width: 100%;

}

/* .MyprofileBanner .Bannermain {
    position: fixed;
    top: 0;
    z-index: 9999;
} */
.MyprofileBanner .breadcrum-sec {
    position: absolute;
    top: 9px;
    left: 30px;
}

.MyprofileBanner .Bannermain .secMainprofile {
    position: absolute;
    bottom: -64px;
    width: 100%;
}

.MyprofileBanner .Bannermain .profilebannercard {
    border: 0;
    box-shadow: 0px 3px 14px #00000012;
    border-radius: 10px;

}

.MyprofileBanner .Bannermain .userImg {
    width: 60px;
    height: 60px;
}

.MyprofileBanner .Bannermain .profileContent h3 {
    font-size: 12px;
    font-weight: bold;
}

.MyprofileBanner .Bannermain .profileContent p {
    font-size: 12px;
    margin-left: 12px;
    margin-bottom: 12px;
    margin-top: -2px;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
    .MyprofileBanner .Bannermain .profileBanner {

        height: 100px;
    }
}

@media screen and (min-width: 0px) and (max-width: 576px) {
    .MyprofileBanner .Bannermain .userImg {
        width: 50px;
        height: 50px;
    }

    .MyprofileBanner .Bannermain .profileContent h3 {
        font-size: 11px;

    }

    .MyprofileBanner .Bannermain .profileContent p {
        font-size: 11px;

    }
}