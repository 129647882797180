.Api_response-section .responsedata .title{
    font-size: 25px;
    color: #000;
}
.Api_response-section .responsedata .subtitle{
    font-size: 20px;
    color: #000;
}
.Api_response-section .responsedata .cont{
    font-size: 14px;
    color: #000;
}
.Api_response-section .responsedata {
    padding: 0px 20px;
}
.Api_response-section .responsedata .table-data tbody td{
    font-size: 14px;
}
.Api_response-section .responsedata .responsive-code{
    border: 1px solid #000;
    padding: 15px;
    border-radius: 5px;
}
.Api_response-section .code-response-section .alignntitle {
    justify-content: space-between;
    background: #ccc;
    padding: 10px;
}
.Api_response-section .code-response-section .alignntitle p{
    margin-bottom: 0px;
    font-size: 14px;
}
.Api_response-section .code-response-section .alignntitle .copyico{

}
.Api_response-section .code-response-section .resp-data{
    padding: 10px;
    background-color: #e1e1e1;
}   
.Api_response-section .code-response-section  .resuytvwcvP{
    margin-bottom: 0px;
    font-size: 15px;
}
/* .Api_response-section .code-response-section .resp-data code{
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    white-space: pre;
    word-spacing: normal;
    word-break: break-word;
    line-height: 1.5;
} */