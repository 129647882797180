.subscription-class {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 91.34%), linear-gradient(270deg, rgba(0, 102, 255, 0.25) 0%, rgba(0, 197, 149, 0.25) 100%);
    padding: 50px;
}

.subscription-class .white-bg-subscribe .most-pop {
    color: #FFF;
    text-align: center;
    background: #FFF;
    font-size: 14px;
    font-weight: 700;
    padding: 5px;
    border-radius: 12px 12px 0px 0px;
}

.subscription-class .white-bg-subscribe:hover .most-pop {
    text-align: center;
    background: linear-gradient(46.21deg, #6297FF 47.49%, #29D7F4 92.35%);
    font-size: 14px;
    font-weight: 700;
    padding: 5px;
    display: block;

}

.subscription-class .white-bg-subscribe .sub-white {
    padding: 0px 25px 35px 35px;
}

.subscription-class .white-bg-subscribe {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 12px 25px 0px rgba(0, 0, 0, 0.05);
    border-right: 2px solid #FFF;
    border-left: 2px solid #FFF;
    border-bottom: 2px solid #FFF;
}

.subscription-class .white-bg-subscribe:hover {
    border-right: 2px solid #007BFF;
    border-left: 2px solid #007BFF;
    border-bottom: 2px solid #007BFF;
}

.subscription-class .nav-pills .nav-link {
    color: rgba(0, 0, 0, 0.48);
    font-size: 14px;
    font-weight: 400;
    background: #cac9c9;
    border-radius: 5px;
    margin: 0px 5px 0px 0px;
}

.subscription-class .nav-pills .nav-link.active {
    font-weight: 600;
    background: #FFF;
}

.subscription-class .nav-pills {
    background: #cac9c9;
    padding: 3px;
}

.subscription-class .plan-img {
    width: 40px;
    height: 40px;
}

.subscription-class .available-img {
    width: 20px;
    height: 20px;
    margin: 0px 7px;
}

.subscription-class .white-bg-subscribe .subscription-title {
    font-size: 18px;
    color: #000;
    font-weight: 600;
    margin: 6% 0;
}

.subscription-class .white-bg-subscribe .doller-price {
    font-size: 34px;
    font-weight: 600;
}

.subscription-class .white-bg-subscribe .cont {
    font-size: 20px;
    font-weight: 500;
    margin: 7% 0 3% 0;
}

.subscription-class .white-bg-subscribe .available-txt {
    font-size: 14px;
}

.subscription-class .white-bg-subscribe .buy-now-btn {
    background: transparent;
    color: #007BFF;
    border: 2px solid #007BFF;
    font-weight: 600;
    width: 100%;
    padding: 9px;
    cursor: pointer;
}

.subscription-class .white-bg-subscribe:hover .buy-now-btn {
    background: transparent linear-gradient(180deg, #1D44AD 0%, #1553F9 100%);
    color: #FFF;
    border: 2px solid #1D44AD;
    font-weight: 600;
    width: 100%;
    padding: 9px;
    cursor: pointer;
}

.subscription-class .white-bg-subscribe .paypal-btn {
    background: #ffc439;
    border: 2px solid #ffc439;
    cursor: pointer;
    width: 100%;
    padding: 9px;
}

.subscription-class .white-bg-subscribe .italic-txt {
    font-size: 12px;
    font-style: italic;
}

.subscription-class .white-bg-subscribe .paypal-img {
    height: 20px;
}

.subscription-class .white-bg-subscribe ul li {
    font-size: 14px;
    padding-left: 0px;
}

.subscription-class .white-bg-subscribe .brown-bg-subscribe {
    border-bottom: 1px solid #e2dfdf;
    margin-top: 15%;
}

.subscription-class .white-bg-subscribe .last-line-subscribe {
    font-size: 12px;
}

.subscription-class .white-bg-subscribe .days-free-txtt {
    font-size: 13px;
    font-weight: 700px;
    color: #000;
}

.subscription-class .white-bg-subscribe .edraw-txtt {
    font-size: 14px;
    color: #000;
    text-decoration: underline;
}

.subscription-class .white-bg-subscribe .edraw-txtt:hover {
    font-size: 14px;
    color: #007BFF;
}

.subscription-class .white-bg-subscribe .trial {
    font-size: 12px;
    border: 1px solid;
    padding: 2px;
    margin: 0;
    height: 23px;
}
.subscription-class .swiper {
    padding-bottom: 5%;
}
/* Media */
@media(min-width:0px) and (max-width:576px) {
    .subscription-class {
        padding: 30px 0px;
    }

    .subscription-class .white-bg-subscribe .subscription-title {
        font-size: 16px;
    }

    .subscription-class .white-bg-subscribe .doller-price {
        font-size: 28px;
    }

    .subscription-class .white-bg-subscribe .cont {
        font-size: 17px;
    }

    .subscription-class .available-img {
        margin: 0px 4px;
    }

    .subscription-class .white-bg-subscribe ul li {
        font-size: 13px;
    }
    .subscription-class .swiper {
        padding-bottom: 11%;
    }
}



@media(min-width:486px) and (max-width:576px) {}

@media(min-width:577px) and (max-width:768px) {
    .subscription-class .swiper {
        padding-bottom: 11%;
    }
}

@media(min-width:769px) and (max-width:992px) {}

@media(min-width:992px) and (max-width:1200px) {}