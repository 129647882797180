.deshboard-start .heading-holder h2 {
    font-size: 18px;
    font-weight: 500;
}

.deshboard-start .heading-holder p {
    font-size: 12px;
}

.deshboard-start .total-order {
    display: flex;
}

.deshboard-start .circle-holder {
    background-color: #E5F6FD;
    width: 63px;
    height: 64px;
    border-radius: 50px;
    text-align: center;
}

.deshboard-start .circle-holder1 {
    background-color: #E8FFEE;
    width: 63px;
    height: 64px;
    border-radius: 50px;
    text-align: center;
}

.deshboard-start .circle-holder2 {
    background-color: #FDEBEB;
    width: 63px;
    height: 64px;
    border-radius: 50px;
    text-align: center;
}

.deshboard-start .circle-holder3 {
    background-color: #FEF2E9;
    width: 63px;
    height: 64px;
    border-radius: 50px;
    text-align: center;
}

.deshboard-start .circle-holder4 {
    background-color: #E8EEF7;
    width: 63px;
    height: 64px;
    border-radius: 50px;
    text-align: center;
}

.deshboard-start .circle-logo {
    width: 30px;
    height: 30px;
    margin-top: 16px;

}

.deshboard-start .text-holder {
    margin-top: 10px;
    margin-left: 12px;
}

.deshboard-start .text-holder h3 {
    font-size: 13px;
    margin-bottom: 0%;
}

.deshboard-start .text-holder p {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0%;
}