.chartt-main-div {
    margin: 20px;
}


.chartt-main-div .innerbox {
    width: 100%;
    height: 368px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 5px 0px #d1d1d1;
    padding: 20px;
}

.chartt-main-div .innerbox .box-name {
    font-weight: 500;
    font-size: 18px;
    color: #303030;

}

.chartt-main-div .innerbox .message-overflow .user-imgg {
    width: 36px;
    height: 36px;
    border-radius: 100px;
    object-fit: cover;
}

.chartt-main-div .innerbox .message-overflow .name {
    font-weight: normal;
    font-size: 14px;
    color: #303030;
    margin-bottom: 0px;
    margin-left: 10px;
}

.chartt-main-div .innerbox .message-overflow .time {
    font-weight: normal;
    font-size: 10px;
    color: #303030;
}

.chartt-main-div .innerbox .message-overflow {
    height: 280px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.chartt-main-div .innerbox .css-10vg0a6-MuiResponsiveChart-container {
    width: 100% !important;
}

.chartt-main-div .innerbox .css-66gjpw-MuiResponsiveChart-container {
    width: 100% !important;
}


@media(min-width:577px) and (max-width:768px) {
    .chartt-main-div .innerbox .box-name {
        font-size: 15px;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .chartt-main-div .innerbox .box-name {
        font-size: 16px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .chartt-main-div .innerbox .box-name {
        font-size: 17px;
    }
}




/* affilate Maindashboard css start */

.Maindashboard .sec_col {
    background: #fff;
    height: 100vh;
   
}

.Maindashboard .Header_affilate_dashboard .space-bd {
    padding: 10px 12px 0px 12px !important;
    border: none !important;
}

.Maindashboard .Header_affilate_dashboard .space-bd p {
    font-size: 12px;
    font-weight: 500;
    color: #000000;
}

.Maindashboard .Header_affilate_dashboard .space-bd span {
    font-size: 12px;
    font-weight: 300;
    color: #000000;
}

.Maindashboard .Header_affilate_dashboard .space-bd2 {
    padding: 10px 12px 0px 12px !important;
    border: none !important;
}

.dashboard-box-heading-holder h4{
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    color: #303030;
}

/* affilate Maindashboard css end */