.SelectedTrendingProducts_NewArrivals {
  background: #f3f3f3 0% 0% no-repeat padding-box;
  padding: 3% 0%;
}

.SelectedTrendingProducts_NewArrivals .heading-holder h4 {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 600;
}

.SelectedTrendingProducts_NewArrivals .arrivals-heading {
  position: relative;
}


@media (min-width: 0px) and (max-width: 320px) {
    .SelectedTrendingProducts_NewArrivals .heading-holder h4 {
      text-align: center;
    }
  }

@media (min-width: 321px) and (max-width: 485px) {
  .SelectedTrendingProducts_NewArrivals .heading-holder h4 {
    text-align: center;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .SelectedTrendingProducts_NewArrivals .heading-holder h4 {
    text-align: center;
  }
}
