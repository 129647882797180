.explore-package {
    margin-bottom: 3%;
}

.explore-package .title-explore h4 {
    text-align: center;
    font-size: 30px;
}

.explore-package .title-explore p {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
}

.explore-package .jp-package-slider {
    margin-top: 7%;
}

.explore-package .jp-package-slider {
    margin-top: 7%;
}

.explore-package .jp-package-slider .main-card .card {
    border: none;
    box-shadow: 0px 3px 6px #e7e3e3;
    border-radius: 5px;
}

.explore-package .jp-package-slider .main-card .card .card-title {
    background: rgb(208, 206, 206);
    text-align: start;
    padding: 10px;
}

.explore-package .main-card .mark-check {
    color: orange;
}

.explore-package .main-card .table td {
    font-size: 14px;
    border: none;
    padding: 5px;
}

.explore-package .main-card .table .number-td {
    font-size: 14px;
    font-weight: 700;
}

.explore-package .main-slider .main-arrow-back {
    position: absolute;
    left: 49%;
    z-index: 2;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    transform: translate(-50%, 0px);
    box-shadow: 0px 0px 13px #0000001c;
}

.explore-package .main-slider .main-arrow-next {
    position: absolute;
    right: 47%;
    z-index: 1;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    transform: translate(-50%, 0%);
    box-shadow: 0px 0px 13px #0000001c;
}

.explore-package .swiper-slide.swiper-slide-visible.swiper-slide-fully-visible.swiper-slide-prev {
    filter: blur(4px);
}

.explore-package .swiper-slide.swiper-slide-visible.swiper-slide-fully-visible.swiper-slide-next {
    filter: blur(4px);
}


@media(min-width:577px) and (max-width:768px) {
    .explore-package .main-slider .main-arrow-back {
        left: 44%;
    }

    .explore-package .main-slider .main-arrow-next {
        right: 45%;
    }

    .explore-package .title-explore h4 {
        font-size: 24px;
    }

    .explore-package {
        margin-bottom: 16%;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .explore-package .main-slider .main-arrow-back {
        left: 44%;
    }

    .explore-package .main-slider .main-arrow-next {
        right: 45%;
    }

    .explore-package .title-explore h4 {
        font-size: 25px;
    }

    .explore-package {
        margin-bottom: 16%;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .explore-package .main-slider .main-arrow-back {
        left: 44%;
    }

    .explore-package .main-slider .main-arrow-next {
        right: 45%;
    }

    .explore-package .title-explore h4 {
        font-size: 26px;
    }

    .explore-package {
        margin-bottom: 16%;
    }
}

@media(min-width:1201px) and (max-width:1399px) {
    .explore-package .main-slider .main-arrow-back {
        left: 46%;
    }

    .explore-package .main-slider .main-arrow-next {
        right: 46%;
    }

    .explore-package .title-explore h4 {
        font-size: 28px;
    }

    .explore-package {
        margin-bottom: 16%;
    }
}