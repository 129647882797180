.StepForm-Employer .progress-bar-container .step-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 175px;
}

.StepForm-Employer .progress-bar-container .step-container {
  position: relative;
}

.StepForm-Employer .line-right {
  width: 60px;
  height: 5px;
  background: #dfdfdf;
  position: absolute;
  top: 50%;
  left: 100%;
}

.StepForm-Employer .progress-bar-container .line-left {
  width: 50px;
  height: 5px;
  background: #dfdfdf;
  position: absolute;
  top: 50%;
  /* left: 100%; */
  right: 101%;
}

.StepForm-Employer .line-left.completed {
  background-color: #1967d3;
}

.StepForm-Employer .line-right.completed {
  background: #ee9723 0% 0% no-repeat padding-box;
  opacity: 1;
}

.StepForm-Employer .progress-bar-container .step-text {
  position: absolute;
  font-size: 14px;
  color: #000000;
  font-weight: 500;
  width: max-content;
  text-align: center;
  left: 71px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.StepForm-Employer .signup-form {
  margin-bottom: 30px;
}

.StepForm-Employer .progress-bar-container .circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  font-size: 20px;
  color: black;
  border: 1px solid #ffffff;
}

.StepForm-Employer .progress-bar-container .circle.completed {
  background: #dfdfdf 0% 0% no-repeat padding-box;
  opacity: 1;
  opacity: 1;
  color: black;
  border: 1px solid #dfdfdf;
}

.StepForm-Employer .step-container.active .circle {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border: 1px solid #ababab;
  opacity: 1;
  color: black;
}

.StepForm-Employer .ProgressBar {
  margin: 20px;
}
