.add-note-modal .modal-content {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px #0000001C;
    border-radius: 10px;
    border: none;
}

.add-note-modal .modal-header {
    border-bottom: none;
}

.add-note-modal .modal-title {
    font-size: 16px;
    font-weight: 500;
}

.add-note-modal .form-control {
    background: #EEEEEE;
    border-radius: 10px;
}

.add-note-modal .modal-footer {
    border-top: none;
}

.add-note-modal .btn-cancle {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #7A7A7A;
    border-radius: 24px;
    font-size: 12px;
    font-weight: 500;
    color: #000;
    padding: 7px 20px;
}

.add-note-modal .btn-cancle:focus {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #7A7A7A;
    border-radius: 24px;
    font-size: 12px;
    font-weight: 500;
    color: #000;
    padding: 7px 20px;
}

.add-note-modal .btn-confirm {
    background: #F88D28 0% 0% no-repeat padding-box;
    border-radius: 24px;
    font-size: 12px;
    font-weight: 500;
    color: #FFF;
    border: none;
    padding: 7px 20px;
}

.add-note-modal .btn-confirm:focus {
    background: #F88D28 0% 0% no-repeat padding-box;
    border-radius: 24px;
    font-size: 12px;
    font-weight: 500;
    color: #FFF;
    border: none;
    padding: 7px 20px;
}