.builder-add .add-ons-title p {
    font-size: 14px;
    color: #3E3E3E;
    text-align: center;
}

.builder-add .cream-bg {
    background-image: url('../../../../../public/assest/images/MyWebsite/Pricing/plan/add.png');
    border-radius: 25px 38px;
    padding: 20px 25px 50px 25px;
    margin-top: 7%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.builder-add .contact-me {
    border: none;
    color: #FFF;
    font-size: 14px;
    background: #F88D28;
    border-radius: 50px;
    padding: 11px 74px;
}

.builder-add .cream-bg h3 {
    font-size: 24px;
    color: #000;
    text-align: center;
    margin: 5% 0;
}

.builder-add .cream-bg .add-on-txt {
    font-size: 14px;
    text-align: justify;
    color: #3E3E3E;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 6;
}

.builder-add .add-ons-title p {
    font-size: 14px;
    color: #3E3E3E;
    width: 50%;
    margin: auto;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
}

.builder-add .plan-txt-number {
    font-size: 20px;
    color: #000;
    text-align: center;
    font-weight: 600;
}

.builder-add .plan-txt-month {
    font-size: 18px;
    color: #000;
    text-align: center;
    font-weight: 600;
}

/* Media */

@media(min-width:0px) and (max-width:576px) {
    .builder-add .add-ons-title p {
        font-size: 12px;
        width: 100%;
    }

    .builder-add .cream-bg h3 {
        font-size: 18px;
    }

    .builder-add .cream-bg .add-on-txt {
        font-size: 12px;
    }

    .builder-add .contact-me {
        font-size: 12px;
        padding: 7px 28px;
    }
}

@media(min-width:321px) and (max-width:485px) {}

@media(min-width:486px) and (max-width:576px) {}

@media(min-width:577px) and (max-width:768px) {}

@media(min-width:769px) and (max-width:992px) {}