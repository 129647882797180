.rightCradmainSec
  .rightMaincardRow
  .main-slider-section
  .mySwiper
  .navigation-arrow-holder
  .navigation-arrow {
  width: 14px;
  height: 30px;
  color: #000;
  background: #ccc;
  padding: 0px 8px;
  border-radius: 50px;
}


.rightCradmainSec
  .rightMaincardRow
  .main-slider-section
  .mySwiper .swiper-pagination-bullets{
    bottom: 15px;
}

.rightCradmainSec
  .rightMaincardRow
  .main-slider-section
  .mySwiper .swiper-pagination-bullet {
    width: 5px;
    height: 5px;
    background: #ffffff;
    opacity: 1;
}

.rightCradmainSec
  .rightMaincardRow
  .main-slider-section
  .mySwiper
  .swiper-button-next:after,
.rightCradmainSec
  .rightMaincardRow
  .main-slider-section
  .mySwiper
  .swiper-button-prev:after {
  display: none;
}

.rightCradmainSec
  .rightMaincardRow
  .main-slider-section
  .mySwiper
  .swiper-button-next {
  right: 4px;
  display: none;
}

.rightCradmainSec
  .rightMaincardRow
  .main-slider-section
  .mySwiper
  .swiper-button-prev {
  left: 4px;
  display: none;
}

.rightCradmainSec .rightMaincardRow .main-slider-section .swiper-horizontal {
  padding: 15px;
}

.rightCradmainSec
  .rightMaincardRow
  .main-slider-section:hover
  .swiper-button-prev {
  display: block;
}

.rightCradmainSec
  .rightMaincardRow
  .main-slider-section:hover
  .swiper-button-next {
  display: block;
}

