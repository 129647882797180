.Logout_modal .modal-header {
  border-bottom: 0px solid #dee2e6;
}

.Logout_modal .modal-content {
  border: 0px;
  border-radius: 9px;
  box-shadow: 0px 0px 5px 0px #ccc;
}
.Logout_modal .logoutdbtn {
  background: #f88d28;
  border: 0px;
  padding: 10px 20px;
  border-radius: 25px;
  width: 100%;
  color: #fff;
}
.Logout_modal .Cancelbtn {
  background: #e2e2e2;
  border: 0px;
  padding: 10px 20px;
  border-radius: 25px;
  width: 100%;
}
.Logout_modal .modal-title {
  font-weight: 500;
  font-size: 18px;
  color: #000;
}
