.Mywebsite .mywebsiteMain .websitestab {
  height: 100vh;
  background: #ffffff;
  box-shadow: 0px 3px 29px #00000029;
  border-radius: 10px 10px 10px 10px;
  margin: 10px 0px;
  padding: 20px 0px;
}

.Mywebsite .mywebsiteMain {
  margin: 0px 0px;
}

.Mywebsite .mywebsiteMain .nav-pills .nav-link {
  color: #f88d28;
  background: transparent;
  border: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 10px;
  border-radius: 0px;
}

.Mywebsite .mywebsiteMain .websitestab .nav-pills .nav-link {
  padding: 7px 0px;
}

.Mywebsite
  .mywebsiteMain
  .websitestab
  .nav-pills
  .nav-link.active:not(.exclude-active-styling) {
  padding: 7px 0px;
}

.Mywebsite .mywebsiteMain .nav-pills .nav-link svg {
  color: #f88d28;
  background: #fff;
  padding: 7px 6px;
  font-size: 12px;
  width: 15px;
  border-radius: 50px;
}

/* .Mywebsite .mywebsiteMain .nav-pills .nav-link.active {
    color: #fff;
    background-color: #f88d28;
} */

.Mywebsite
  .mywebsiteMain
  .nav-pills
  .nav-link.active:not(.exclude-active-styling) {
  color: #fff;
  background-color: #f88d28;
  padding: 7px 10px;
}

.Mywebsite .mywebsiteMain .nav-pills .nav-link:hover svg {
  color: #fff;
  background: #000;
  padding: 7px 6px;
  font-size: 12px;
  width: 15px;
  border-radius: 50px;
}

.Mywebsite .mywebsiteMain .nav-pills .nav-link.active svg {
  color: #000;
  background: #fff;
  padding: 7px 6px;
  font-size: 12px;
  width: 15px;
  border-radius: 50px;
}
