.faq-questions .accordion-item {
  margin: 15px 0px;
  border-radius: 11px;
}

.faq-questions {
  margin-bottom: 15px;
}

.faq-questions .accordion-button::after {
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  width: 9px;
  height: 14px;
  padding: 12px;
  justify-content: flex-start;
}

.faq-questions .accordion-button:focus {
  border-color: black;
  box-shadow: none;
}

.faq-questions .accordion-button:not(.collapsed) {
  color: black;
  background-color: #ffff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
  font-size: 14px;
  font-weight: 400;
  border-radius: 10px;
}

.faq-questions .accordion-item:last-of-type .accordion-button.collapsed {
  font-size: 14px;
  font-weight: 400;
  border-radius: 10px;
}

.faq-questions .accordion-body {
  font-size: 14px;
  font-weight: 400;
}

.faq-questions .CardsMain .faqCards {
  border: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 13px;
  height: 115px;
}

.faq-questions .CardsMain .faqCards .card-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.faq-questions .CardsMain .faqCards .card-body p {
  font: normal normal 600 20px/24px Raleway;
  text-align: center;
}

.faq-questions .main-card-class {
  position: relative;
}


.faq-questions .main-card-class:hover .faqCards {
  background: transparent linear-gradient(180deg, #FFF7EF 0%, #F2F0FD 100%) 0% 0% no-repeat padding-box;
}

.faq-questions .main-card-class .image-div {
  position: absolute;
  top: -28px;
  z-index: 9;
  left: 63px;
}

.faq-questions .main-card-class .image-div .feature-img {
  width: 60px;
  height: 60px;
  background: #FFF;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 50%;
  padding: 10px;
}

.faq-questions .main-card-class:hover .image-div .feature-img {
  background: transparent linear-gradient(180deg, #FFF7EF 0%, #F2F0FD 100%) 0% 0% no-repeat padding-box;
}


@media(min-width:0px) and (max-width:320px) {
  .FAQ-banner .heading h1 {
    font-size: 16px;
  }

  .faq-questions .main-card-class {
    margin-top: 13%;
  }

  .faq-questions .main-card-class .image-div {
    left: 119px;
  }

  .faq-questions .CardsMain .faqCards .card-body p {
    font-size: 16px;
  }

  .faq-questions .accordion-item:last-of-type .accordion-button.collapsed {
    font-size: 12px;
  }

}

@media(min-width:321px) and (max-width:375px) {
  .FAQ-banner .heading h1 {
    font-size: 18px;
  }

  .faq-questions .main-card-class {
    margin-top: 13%;
  }

  .faq-questions .main-card-class .image-div {
    left: 142px;
  }

  .faq-questions .CardsMain .faqCards .card-body p {
    font-size: 18px;
  }

}

@media(min-width:376px) and (max-width:485px) {
  .FAQ-banner .heading h1 {
    font-size: 18px;
  }

  .faq-questions .main-card-class {
    margin-top: 13%;
  }

  .faq-questions .main-card-class .image-div {
    left: 171px;
  }

  .faq-questions .CardsMain .faqCards .card-body p {
    font-size: 18px;
  }

}

@media(min-width:486px) and (max-width:576px) {
  .FAQ-banner .heading h1 {
    font-size: 18px;
  }

  .faq-questions .main-card-class {
    margin-top: 13%;
  }

  .faq-questions .main-card-class .image-div {
    left: 171px;
  }

  .faq-questions .CardsMain .faqCards .card-body p {
    font-size: 18px;
  }

}

@media(min-width:577px) and (max-width:768px) {
  .faq-questions .main-card-class {
    margin-top: 21%;
  }
}

@media(min-width:769px) and (max-width:992px) {}

@media(min-width:992px) and (max-width:1200px) {
  .faq-questions .main-card-class .image-div {
    left: 79px;
  }

}

@media(min-width:1201px) and (max-width:1440px) {
  .faq-questions .main-card-class .image-div {
    left: 79px;
  }

}