.frequently-bought-together {
    margin: 5% 0;
}
.frequently-bought-together .hediang-holder h2{
    font-size: 20px;
    font-weight: 600;
}
.frequently-bought-together .main-section .seller-img {
    border-radius: 10px;
    width: 100%;
    height: 195px;
}

.frequently-bought-together .main-section h4 {
    font-size: 13px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4;
}

.frequently-bought-together .main-section p {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0%;
}

.frequently-bought-together .main-section span {
    color: #484848;
    font-size: 12px;
}

.frequently-bought-together .silder-btn {
    position: relative;
}

.frequently-bought-together .silder-btn .back-btn {
    content: url('../../../../../public/assest/images/B2Bbuyer/B2BSellerDetail/left.png');
    width: 24px;
    height: 24px;
    /* margin: 10px; */
    cursor: pointer;
    position: absolute;
    bottom: 205px;
    z-index: 999;
    left: 0px;

}

.frequently-bought-together .silder-btn .next-btn {
    content: url('../../../../../public/assest/images/B2Bbuyer/B2BSellerDetail/right.png');
    width: 24px;
    height: 24px;
    /* margin: 10px; */
    cursor: pointer;
    position: absolute;
    bottom: 205px;
    z-index: 999;
    right: 0px;
}

.frequently-bought-together .swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
    display: none;
}

@media(min-width:0px) and (max-width:486px) {}