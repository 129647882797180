.B2b-ProductCard-sec {

    margin: 10px 0px;
}

.B2b-ProductCard-sec .main-product-border {
    border-bottom: 1px solid #00000012;
    margin: 10px 0px;
    padding: 10px 0px;
}

.B2b-ProductCard-sec .main-slider-section .mySwiper .navigation-arrow-holder .navigation-arrow {
    width: 14px;
    height: 30px;
    color: #000;
    background: #ccc;
    padding: 0px 8px;
    border-radius: 50px;
}


.B2b-ProductCard-sec .main-slider-section .mySwiper .swiper-pagination-bullets {
    bottom: 15px;
}

.B2b-ProductCard-sec .main-slider-section .mySwiper .swiper-pagination-bullet {
    width: 5px;
    height: 5px;
    background: #ffffff;
    opacity: 1;
}

.B2b-ProductCard-sec .main-slider-section .mySwiper .swiper-button-next:after,
.B2b-ProductCard-sec .main-slider-section .mySwiper .swiper-button-prev:after {
    display: none;
}

.B2b-ProductCard-sec .main-slider-section .mySwiper .swiper-button-next {
    right: 4px;
    display: none;
}

.B2b-ProductCard-sec .main-slider-section .mySwiper .swiper-button-prev {
    left: 4px;
    display: none;
}

.B2b-ProductCard-sec .main-slider-section .swiper-horizontal {
    padding: 15px;
}

.B2b-ProductCard-sec .main-slider-section:hover .swiper-button-prev {
    display: block;
}

.B2b-ProductCard-sec .main-slider-section:hover .swiper-button-next {
    display: block;
}

.B2b-ProductCard-sec img {
    width: 100%;
    border-radius: 10px;
}

.B2b-ProductCard-sec .main-card h6.product-name {
    font-size: 15px;
    font-weight: 500;
    color: #000;
}

.B2b-ProductCard-sec .main-card .price-div .price {
    font-size: 18px;
    font-weight: bold;
    color: #000;
    margin-right: 10px;
}

.B2b-ProductCard-sec .main-card .price-div .amp {
    font-size: 10px;
    font-weight: 500;
    color: #555555;
    margin-right: 10px;
}

.B2b-ProductCard-sec .main-card .price-div .excla-icon {
    color: #1D44AD;
}

.B2b-ProductCard-sec .main-card .flex-div {
    display: flex;
    align-items: center;
}

.B2b-ProductCard-sec .main-card .order-div p.order-item {
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    margin: 0px;
    margin-right: 5px;
}

.B2b-ProductCard-sec .main-card .order-div .custum-btn {
    font-size: 10px;
    font-weight: 400;
    color: #000000;
    background-color: #FFD500;
    padding: 3px;
    border: none;
    border-radius: 10px;
    margin-left: 5px;
}

.B2b-ProductCard-sec .main-card .flex-div .border-div {

    background-color: grey;
    height: 15px;
    width: 2px;
    color: grey;
}

.B2b-ProductCard-sec .main-card .rating .order-text {
    font-size: 12px;
    font-weight: 600;
    color: #000000;
    margin-right: 5px;

}

.B2b-ProductCard-sec .main-card .rating .rating-no {
    font-size: 10px;
    font-weight: 500;
    color: #000000;
    margin-right: 5px;
    margin-left: 5px;
}

.B2b-ProductCard-sec .main-card .rating .manu-fac {
    color: #228B03;
    font-size: 10px;
    font-weight: 500;
    margin-left: 5px;
}

.B2b-ProductCard-sec .main-card .feture-div .checkSymbol {
    width: 10px;
    height: 10px;
}

.B2b-ProductCard-sec .main-card .feture-div p.feture-text {
    font-size: 10px;
    font-weight: 500;
    color: #000000;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 0px;
}

.B2b-ProductCard-sec .main-card .verified-div p.feture-text {
    color: #31BF07;
    font-size: 12px;
    font-weight: 500;
    margin: 0px;
}

.B2b-ProductCard-sec .main-card .verified-div .checkSymbol {
    width: 14px;
    height: 14px;
    margin: 5px 5px;
}

.B2b-ProductCard-sec .buttons .contactSupplbtn {
    background: #f88d28 0% 0% no-repeat padding-box;
    border: 0;
    margin-top: 15px;
    color: #fff;
    font-size: 14px;
}

.B2b-ProductCard-sec .buttons .rightcardbtn {
    padding: 8px;
    border-radius: 24px;
    text-align: center;
    width: 100%;
    font-size: 14px;
}

.B2b-ProductCard-sec .buttons .chatnowbtn {
    margin-top: 15px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    color: #000;
}

.B2b-ProductCard-sec .rating-star {
    width: 20px;
}

.B2b-ProductCard-sec svg.star-svg {

    width: 18px;
}

@media screen and (min-width: 0px) and (max-width: 575px) {
    .B2b-ProductCard-sec .buttons {
        display: flex;
        justify-content: space-between;
    }

    .B2b-ProductCard-sec .buttons .rightcardbtn {
        font-size: 12px;
        padding: 5px 10px;
    }

    .B2b-ProductCard-sec .main-card h6.product-name {
        font-size: 12px;

    }

    .B2b-ProductCard-sec .main-card .price-div .price {
        font-size: 16px;

    }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
    .B2b-ProductCard-sec .buttons .rightcardbtn {
        font-size: 12px;

    }

    .B2b-ProductCard-sec .main-card h6.product-name {
        font-size: 12px;

    }

    .B2b-ProductCard-sec .main-card .price-div .price {
        font-size: 16px;

    }
}