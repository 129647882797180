.sell-smarter {
    margin: 6% 0;
}

.sell-smarter .heading-holder h4 {
    font-size: 30px;
    font-weight: 600;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
}

.sell-smarter .heading-holder p {
    font-size: 16px;
    letter-spacing: 0.16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
}

.sell-smarter .girls-image .girl-img {
    width: 364px;
    height: 403px;
}

.sell-smarter .white-card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px #0000001C;
    border-radius: 10px;
    padding: 27px;
    min-height: 147px;
    height: 90%;
    display: flex;
    align-items: center;
}

.sell-smarter .white-card:hover {
    background: #FFD3AA 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px #0000001C;
    border-radius: 10px;
}

.sell-smarter .white-card p {
    font-size: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 5;
}

@media(min-width:992px) and (max-width:1200px) {
    .sell-smarter .white-card p {
        font-size: 15px;
    }
}

@media(min-width:768px) and (max-width:991px) {
    .sell-smarter .white-card p {
        font-size: 14px;
    }

    .sell-smarter .girls-image .girl-img {
        width: 277px;
        height: 337px;
    }

    .sell-smarter .heading-holder h4 {
        font-size: 25px;
        text-align: center;
    }

    .sell-smarter .heading-holder p {
        text-align: center;
    }
}


@media(min-width:0px) and (max-width:767px) {
    .sell-smarter .white-card p {
        font-size: 14px;
    }

    .sell-smarter .girls-image {
        text-align: center;
        margin-bottom: 5%;
    }

    .sell-smarter .girls-image .girl-img {
        width: 277px;
        height: 337px;
    }

    .sell-smarter .heading-holder h4 {
        font-size: 20px;
        text-align: center;
    }

    .sell-smarter .heading-holder p {
        text-align: center;
        font-size: 14px;
    }
}

@media(min-width:0px) and (max-width:486px) {
    .sell-smarter .girls-image .girl-img {
        width: 271px !important;
        height: 311px !important;
    }
}