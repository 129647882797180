.help-center-tab {
    margin-bottom: 8%;
    position: relative;
    top: -150px;
}

.help-center-tab .swiper-wrapper {
    padding: 2% 0px;
}

.help-center-tab .hghhhg {
    margin-right: 25px;
}

.help-center-tab .nvsdskd {
    overflow-x: scroll;
    padding-bottom: 9px;
}

.help-center-tab .nav-link {
    background: #ffffff;
    box-shadow: 0px 0px 3px #00000029;
    border-radius: 11px;

    color: #000;
    font-weight: 600;

    font-size: 18px;
    width: 100%;
    color: black !important;
    height: 90px;
}

.help-center-tab .nav-link.active {
    background: transparent linear-gradient(180deg, #001a60 0%, #1d44ad 100%);
    box-shadow: 0px 0px 3px #00000029;
    border-radius: 11px;
    width: 100%;
    /* padding: 20px 40px; */
    color: #ffffff !important;
}
.help-center-tab .swiper {
    padding-bottom: 2%;
    padding-right: 3%;
    padding-left: 3%;
}
.help-center-tab p {
    margin-top: 10px;
}

.help-center-tab .icon-img {
    height: 23px;
    width: 23px;
    margin-right: 15%;
    margin-top: 13px;
}

.help-center-tab .nav-link.active .icon-img {
    filter: invert(99%) sepia(90%) saturate(2%) hue-rotate(191deg) brightness(118%) contrast(100%);
}

.help-center-tab .accordion .accordion-button:not(.collapsed) {
    background-color: #f5f5f5 !important;
    border-radius: 5px;
    font-size: 14px;
    color: #000;
    font-weight: 500;
}

.help-center-tab .accordion button.accordion-button.collapsed {
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border-radius: 5px;
    font-size: 14px;
}

.help-center-tab .accordion .accordion-button {
    background-color: #f5f5f5 0% 0% no-repeat padding-box !important;
    border-radius: 5px;
}

.help-center-tab .accordion .accordion-body {
    background-color: #f5f5f5;
    font-size: 14px;
    font-weight: 500;
}

.help-center-tab .accordion-item {
    border: none;
}

.help-center-tab .sub-title-medium {
}

.scale-up-top {
    -webkit-animation: scale-up-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.help-center-tab .accordion .accordion-body {
    animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/* .help-center-tab .accordion  .accordion-button:not(.collapsed)::after {
   
    background-image:url('../../../../../../public/assest/images/MyWebsite/Help-Center/down.png') !important;
   
}
.help-center-tab .accordion   .accordion-button::after {

   background-image:url('../../../../../../public/assest/images/MyWebsite/Help-Center/up.png') !important; ;

} */
/* media */

@media (min-width: 0px) and (max-width: 320px) {
    .help-center-tab .nav-link {
        padding: 10px;
        font-size: 15px;
        height: auto;
        width: 100%;
    }

    .help-center-tab .nav-link.active {
        padding: 10px;
    }

    .help-center-tab .accordion .accordion-button:not(.collapsed) {
        font-size: 12px;
    }

    .help-center-tab .accordion .accordion-body {
        font-size: 12px;
    }

    .help-center-tab .accordion button.accordion-button.collapsed {
        font-size: 12px;
    }
    .help-center-tab .swiper {
        padding-right: 2%;
        padding-left: 2%;
        padding-bottom: 10%;
        /* padding: 5px 0px; */
    }
    .help-center-tab {
        top: -141px;
    }
}

@media (min-width: 321px) and (max-width: 485px) {
    .help-center-tab .nav-link {
        padding: 10px;
        font-size: 15px;
        height: auto;
        width: 100%;
    }

    .help-center-tab .nav-link.active {
        padding: 10px;
    }

    .help-center-tab .accordion .accordion-button:not(.collapsed) {
        font-size: 12px;
    }

    .help-center-tab .accordion .accordion-body {
        font-size: 12px;
    }

    .help-center-tab .accordion button.accordion-button.collapsed {
        font-size: 12px;
    }
    .help-center-tab .swiper {
        padding-right: 2%;
        padding-left: 2%;
        padding-bottom: 5%;
    }
    .help-center-tab {
        top: -141px;
    }
}

@media (min-width: 486px) and (max-width: 576px) {
    .help-center-tab-banner .banner-img {
        height: 300px;
    }
    .help-center-tab {
        top: -138px;
    }
    .help-center-tab .swiper {
        padding-right: 2%;
        padding-left: 2%;
        padding-bottom: 5%;
    }
    .help-center-tab p {
        font-size: 14px;
    }
}

@media (min-width: 577px) and (max-width: 768px) {
    .help-center-tab-banner .banner-img {
        height: 300px;
    }
    .help-center-tab {
        top: -138px;
    }
    .help-center-tab .swiper {
        padding-right: 2%;
        padding-left: 2%;
        padding-bottom: 5%;
    }
    .help-center-tab p {
        font-size: 14px;
    }
}

@media (min-width: 769px) and (max-width: 992px) {
    .help-center-tab-banner .banner-img {
        height: 300px;
    }
    .help-center-tab {
        top: -138px;
    }
    .help-center-tab .swiper {
        padding-right: 2%;
        padding-left: 2%;
        padding-bottom: 5%;
    }
    .help-center-tab p {
        font-size: 16px;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
}
