.OffcanvasChatnow-new {
  width: 45%;
  height: auto;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 17px #00000030;
  border-radius: 10px 10px 0px 0px;
  left: 50% !important;
  height: 66% !important;
}

.OffcanvasChatnow-new .userImg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 12px;
}

.OffcanvasChatnow-new .userImg-big {
  width: 35px;
  height: 35px;
  margin-right: 12px;
}

.OffcanvasChatnow-new .userImg-two {
  width: 30px;
  height: 30px;
  margin-right: 12px;
}

.OffcanvasChatnow-new h1 {
  font-size: 16px;
  font-weight: bold;
  margin: 0px;
}

.OffcanvasChatnow-new .message-div {
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid #C9C9C9; */
  padding-bottom: 14px;
  align-items: center;
}

.OffcanvasChatnow-new .cls-btn {
  background-color: transparent;
  border: none;
}

.OffcanvasChatnow-new .title {
  border-bottom: 1px solid #C9C9C9;
  display: flex;
  justify-content: space-between;
}

.OffcanvasChatnow-new .title .cls-btn {
  display: none;
}

.OffcanvasChatnow-new .left-border {
  border-left: 1px solid #C9C9C9;
}

.OffcanvasChatnow-new .bottom-border {
  border-bottom: 1px solid #C9C9C9;
}

.OffcanvasChatnow-new .d-flex-div {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.OffcanvasChatnow-new .nav-pills .nav-link.active {
  background-color: #E9EFFF;
  border-radius: 0%;
}

.OffcanvasChatnow-new .left-side-content .bg-div {
  background: #E2BD03 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.OffcanvasChatnow-new .left-side-content .bg-div-two {
  background: #03E2A5 0% 0% no-repeat padding-box;
  opacity: 1;
}

.OffcanvasChatnow-new .left-side-content .left-flex p {
  font-size: 10px;
  margin: 0px;
  color: #585858;
}

.OffcanvasChatnow-new .left-side-content .left-flex {
  display: flex;
  justify-content: end;
  gap: 7px;
  align-items: center;
  margin: 10px 0px;
}

.OffcanvasChatnow-new .info-div p {
  font-size: 10px;
  font-weight: 400;
  color: #000;
  margin: 0px;
}

.OffcanvasChatnow-new .price-div h6 {
  font-size: 18px;
  font-weight: bold;
  margin: 0px;
}

.OffcanvasChatnow-new .price-div p {
  font-size: 10px;
  font-weight: 500;
  color: #000;
}

.OffcanvasChatnow-new .quantity-div p {
  font-size: 12px;
  font-weight: 500;
  color: #000;
}

.OffcanvasChatnow-new .info-div {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 10px;

}

.OffcanvasChatnow-new .offcanvas-body {
  background: #F9F9F9 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 17px #00000030;
  border-radius: 10px 10px 0px 0px;
  opacity: 1;
}

.OffcanvasChatnow-new .form-control {
  font-size: 14px;
  font-weight: 400;
}

.OffcanvasChatnow-new .form-div {
  position: relative;
}

.OffcanvasChatnow-new .form-div .textAreaMain {
  position: absolute;
  width: 100%;
  bottom: -6px;
}

.OffcanvasChatnow-new .form-div .textAreaMain .sendbtn {
  background: #C5C4C4 0% 0% no-repeat padding-box;
  border-radius: 24px;
  opacity: 1;
  font-size: 12px;
  border: none;
  color: #fff;
  padding: 4px 13px;
  margin: 0px 10px;
}

.OffcanvasChatnow-new .form-div .textAreaMain .Icon {
  color: #727272;
}

.OffcanvasChatnow-new .message-container h6 {
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  color: #000;
}

.OffcanvasChatnow-new .message-container p {
  font-size: 10px;
  font-weight: 500;
  margin: 0px;
  color: #858585;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .OffcanvasChatnow-new {
    width: 100%;
    left: 1% !important;
    height: 100% !important;
  }

  /* .OffcanvasChatnow-new .title .cls-btn {
    display: block;
  } */

  /* .OffcanvasChatnow-new .message-div .cls-btn {
    display: none;
  } */

  .OffcanvasChatnow-new h1 {
    font-size: 15px;
  }

}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .OffcanvasChatnow-new {
    width: 95%;
    left: 2% !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .OffcanvasChatnow-new {
    width: 72%;
    left: 25% !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .OffcanvasChatnow-new {
    width: 72%;
    left: 25% !important;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .OffcanvasChatnow-new {
    width: 55%;
    left: 43% !important;
  }
}