.women-seller {
  margin-bottom: 8%;
}

.women-seller .main-img-class {
  position: relative;
}

.women-seller .section-title h4 {
  margin-bottom: 4%;
  font-size: 20px;
  color: #000000;
  font-weight: 700;
  text-align: center;
  margin-top: 5%;
  position: relative;
}

.women-seller h4::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    width: 125px;
    height: 2px;
    background-color: #001B7C;
    left: 28%;
}

.women-seller h4::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    width: 125px;
    height: 2px;
    background-color: #001B7C;
    right: 28%;
}

.women-seller .main-img-class .women-imgg {
  width: 100%;
}

.women-seller .main-img-class .person-name-class {
  position: absolute;
  top: 52%;
  left: 7%;
  z-index: 9;
}

.women-seller .main-img-class .person-name-class p {
    font-size: 16px;
    color: #FFF;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 5;
}

.women-seller .main-img-class .black-overlay {
  background: transparent
    linear-gradient(180deg, #00000000 0%, #f88d281f 35%, #000000 100%) 0% 0%
    no-repeat padding-box;
  position: absolute;
  transition: 0.2s ease;
  width: 100%;
  height: 60%;
  bottom: 0;
  right: 0;
  left: 0;
}

.women-seller .main-img-class:hover .black-overlay {
  background: transparent
    linear-gradient(180deg, #00000000 0%, #f88d281f 35%, #000000 100%) 0% 0%
    no-repeat padding-box;
  position: absolute;
  transition: 0.2s ease;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  left: 0;
}

.women-seller .main-slider {
  position: relative;
}

/* .women-seller .main-slider .arrow-back,
.women-seller .main-slider .arrow-next {
    cursor: pointer;
} */

.women-seller .main-slider {
    position: relative;
}

.women-seller .main-slider .arrow-back {
  content: url("../../../../../public/assest/images/Seller_panel/sellersuccessstory/whitepre.png");
  position: absolute;
  top: 43%;
  left: 45%;
  z-index: 2;
  cursor: pointer;
}

.women-seller .main-slider .arrow-next {
  content: url("../../../../../public/assest/images/Seller_panel/sellersuccessstory/whiteback.png");
  position: absolute;
  top: 43%;
  right: 45%;
  z-index: 2;
  cursor: pointer;
}


.women-seller .main-slider .arrow-next::before {
    content: url("../../../../../public/assest/images/Seller_panel/sellersuccessstory/whitepre.png");
    position: absolute;
    top: 43%;
    right: 45%;
    z-index: 2;
    cursor: pointer;
}

@media(min-width:0px) and (max-width:320px) {
    .women-seller h4::before {
        display: none;
    }

    .women-seller h4::after {
        display: none;
    }

    .women-seller .section-title h4 {
        margin-top: 21%;
    }
    .women-seller .main-img-class .person-name-class p {
        font-size: 13px;
    }
    .women-seller .main-slider .arrow-next {
        top: -45px;
        right: 48%;
    }
    .women-seller .main-slider .arrow-back {
        top: -45px;
        left: 48%;
    }
    .women-seller h4::before {
        display: none;
    }
    .women-seller h4::after {
        display: none;
    }
}

@media(min-width:321px) and (max-width:485px) {
    .women-seller h4::before {
        display: none;
    }

    .women-seller h4::after {
        display: none;
    }
    .women-seller .section-title h4 {
        margin-top: 21%;
    }
    .women-seller .main-img-class .person-name-class p {
        font-size: 13px;
    }
    .women-seller .main-slider .arrow-next {
        top: -45px;
        right: 48%;
    }
    .women-seller .main-slider .arrow-back {
        top: -45px;
        left: 48%;
    }
}

@media(min-width:486px) and (max-width:576px) {
    .women-seller h4::before {
        display: none;
    }

    .women-seller h4::after {
        display: none;
    }
    .women-seller .section-title h4 {
        margin-top: 21%;
    }
    .women-seller .main-img-class .person-name-class p {
        font-size: 13px;
    }
    .women-seller .main-slider .arrow-next {
        top: -45px;
        right: 48%;
    }
    .women-seller .main-slider .arrow-back {
        top: -45px;
        left: 48%;
    }
}

@media(min-width:577px) and (max-width:768px) {
    .women-seller h4::before {
        display: none;
    }

    .women-seller h4::after {
        display: none;
    }
    .women-seller .section-title h4 {
        margin-top: 21%;
    }
    .women-seller .main-img-class .person-name-class p {
        font-size: 13px;
    }
    .women-seller .main-slider .arrow-next {
        top: -45px;
        right: 48%;
    }
    .women-seller .main-slider .arrow-back {
        top: -45px;
        left: 48%;
    }
    .women-seller h4::before {
        left: 4%;
    }
    .women-seller h4::after {
        right: 4%;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .women-seller .main-slider .arrow-next {
        top: -19px;
        right: 40%;
    }
    .women-seller .main-slider .arrow-back {
        top: -50px;
        left: 43%;
    }
    .women-seller .main-slider .arrow-next {
        top: -19px;
        right: 40%;
    }
}

@media(min-width:992px) and (max-width:1200px) {

}
