/* offcanvas css start */

.variants-offcanvas {
  z-index: 99999 !important;
  width: 100%;
  background-color: #ffffffd1 !important;
  height: 100vh !important;
}

.variants-offcanvas .variats-table-sec {
  background-color: #fff;
}


.variants-offcanvas tbody {
  background-color: #fff;
}

.variants-offcanvas thead tr td {
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

.variants-offcanvas .table-overflow {
  width: 100%;
  overflow-x: scroll;
}

.variants-offcanvas .search-main .form-group .form-label {
  font-weight: normal;
  font-size: 12px;
  color: #000;
}

.variants-offcanvas .fafdowncaret {
  color: #adadad;
  margin-left: 5px;
}

.variants-offcanvas .search-main .form-group .form-control {
  font-size: 14px;
  background: #f6f6f6;
  border-radius: 5px;
  border: 0px;
  box-shadow: unset;
}

.variants-offcanvas .search-main .form-group .form-select {
  font-size: 14px;
  border-radius: 5px;
  border: 0px;
  background-color: #f6f6f6;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
}

.B2b_table_section .product-main .search-main .form-group .form-control::placeholder {
  font-weight: normal;
  font-size: 12px;
  color: #c8c8c8;
}

.variants-offcanvas .search-main .form-group .serch-icoo {
  position: absolute;
  right: 12px;
  bottom: 8px;
  color: #c8c8c8;
}

.variants-offcanvas .search-main .product-status-div {
  width: 100%;
  justify-content: space-between;
}

.variants-offcanvas .search-main .product-status-div .sec1 {
  width: 38%;
}

.variants-offcanvas .search-main .product-status-div .sec2 {
  width: 28%;
}

.variants-offcanvas .search-main .excelimg {
  height: 25px;
  width: auto;
}

.variants-offcanvas .status-change-btn {
  background: #f2f2f2;
  border: 0px;
  border-radius: 20px;
  padding: 8px 20px;
  font-size: 13px;
}

.variants-offcanvas .listnewpro-btn {
  background: #f88d28;
  border: 0px;
  color: #fff;
  font-size: 14px;
  padding: 7px 20px;
  border-radius: 20px;
}

.variants-offcanvas .table thead td {
  text-align: center;
}

.variants-offcanvas .table tbody td {
  font-weight: normal;
  font-size: 14px;
  color: #4a4a4a;
  text-wrap: nowrap;
  text-align: center;
}

.variants-offcanvas .table .pcs-td {
  font-size: 10px;
}

.variants-offcanvas .table tbody td .skiddd {
  font-weight: 500;
  font-size: 12px;
  color: #333;
}

.variants-offcanvas .table tbody td .keybord-img {
  width: 50px;
  height: auto;
}

.variants-offcanvas .plus-icon {
  background-color: orange;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 0px 8px;
  color: #fff;
}

.variants-offcanvas .plus-icon .fa-plus {
  font-size: 12px;
}

.variants-offcanvas .plus-icon .svg-inline--fa {
  font-size: 9px;
  color: #fff;
}

.variants-offcanvas .active-btn {
  background-color: #39ae00;
  color: #ffff;
  border-radius: 20px;
  border: none;
  padding: 5px 18px;
  font-size: 14px;
  font-weight: 400;
}

.variants-offcanvas .inactive-btn {
  background-color: #ff2f2e;
  color: #ffff;
  border-radius: 20px;
  border: none;
  padding: 5px 18px;
  font-size: 14px;
  font-weight: 400;
}

.variants-offcanvas .action-btn {
  background-color: #f88d28;
  color: #ffff;
  border-radius: 20px;
  border: none;
  padding: 5px 18px;
  font-size: 14px;
  font-weight: 400;
}

.variants-offcanvas .form-switch .form-check-input:checked {
  background-color: #c7c7c7;
  border: grey;
}

.variants-offcanvas table thead {
  background-color: #ebebeb;
}

.variants-offcanvas .variats-table-sec {
  padding: 30px;
  box-shadow: 0px 0px 5px 0px #ccc;
  border-radius: 8px;
  overflow-y: auto;
  height: 100vh;
  margin-bottom: 30px;
}

/* offcanvas css end */