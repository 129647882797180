.edit-details-modal .modal-content {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px #0000001C;
    border-radius: 10px;
    border: none;
    padding-right: 10px;
    padding-left: 10px;
}

.edit-details-modal .modal-title {
    font-size: 18px;
    font-weight: 500;
}

.edit-details-modal .form-label {
    font-size: 12px;
    font-weight: 500;
}

.edit-details-modal .form-control {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.800000011920929px solid #7D7D7D;
    border-radius: 5px;
    height: 38px;
    font-size: 13px;

}

.edit-details-modal .text-area {
    height: auto;
}

.edit-details-modal .detail-txt {
    font-size: 16px;
    font-weight: 700;
}

.edit-details-modal .add-attachment {
    font-size: 10px;
}

/* .edit-details-modal .input-group {
    border: 0.800000011920929px solid #7D7D7D;
    border-radius: 5px;
} */
.edit-details-modal .email-text {
    border-right: none;
    font-size: 12px;
}

.edit-details-modal .modal-footer {
    border-top: none;
}

.edit-details-modal .btn-cancle {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #7A7A7A;
    border-radius: 24px;
    height: 38px;
    color: #000;
    font-size: 14px;
    padding: 0px 30px;
}

.edit-details-modal .btn-cancle:focus {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #7A7A7A;
    border-radius: 24px;
    height: 38px;
    color: #000;
    font-size: 14px;
    padding: 0px 30px;
}

.edit-details-modal .btn-update {
    height: 38px;
    background: #F88D28 0% 0% no-repeat padding-box;
    border-radius: 24px;
    color: #FFF;
    font-size: 14px;
    border: none;
    padding: 0px 30px;
}

.edit-details-modal .btn-update:focus {
    height: 38px;
    background: #F88D28 0% 0% no-repeat padding-box;
    border-radius: 24px;
    color: #FFF;
    font-size: 14px;
    border: none;
    padding: 0px 30px;
}

.edit-details-modal .fa-circle-check {
    color: #31BF07;
}

.edit-details-modal .input-group-text {
    background-color: transparent;
    border-left: none;
    border-right: 0.800000011920929px solid #7D7D7D;
    border-top: 0.800000011920929px solid #7D7D7D;
    border-bottom: 0.800000011920929px solid #7D7D7D;
    font-size: 10px;
    color: #31BF07;
}

.edit-details-modal .change {
    font-size: 10px;
    color: #1D44AD;
    margin-left: auto;
    margin-bottom: 0%;
    cursor: pointer;
}

.edit-details-modal h2 {
    font-size: 12px;
}

.edit-details-modal .send-otp {
    font-size: 10px;
    color: #1D44AD;
}