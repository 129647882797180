.Affilate_All_product_count_list {
  margin: 20px;
}

.Affilate_All_product_count_list .list-flex {
  display: block;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 6px 15px;
  margin-bottom: 20px;
  height: 140px;
}

.Affilate_All_product_count_list .list-flex .img-div .circle-ico {
  min-width: 50px;
  height: 50px;
  background: #ff9e4a 0% 0% no-repeat padding-box;
  border-radius: 7px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Affilate_All_product_count_list .name_lineup_holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.Affilate_All_product_count_list .line_up_down_icon {
  color: #179100;
}

.Affilate_All_product_count_list .line_up_down_icon_2 {
  color: #dc3545;
}

.Affilate_All_product_count_list .list-flex .img-div .circle-ico .icon {
  height: 30px;
  width: auto;
}

.Affilate_All_product_count_list .list-flex .cont-div .name {
  font-weight: normal;
  font-size: 13px;
  color: #303030;
  margin-bottom: 0px;
}

.Affilate_All_product_count_list .list-flex .cont-div .number {
  font-weight: 500;
  font-size: 20px;
  color: #303030;
  margin-bottom: 0px;
}

@media (min-width: 576px) and (max-width: 768px) {
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (min-width: 992px) and (max-width: 1023px) {
  .Affilate_All_product_count_list .list-flex .img-div .circle-ico .icon {
    height: 17px;
    width: auto;
  }

  .Affilate_All_product_count_list .list-flex {
    width: 100%;
    height: 140px;
  }

  .Affilate_All_product_count_list .list-flex .cont-div .number {
    font-size: 15px;
  }

  .Affilate_All_product_count_list .list-flex .img-div .circle-ico {
    min-width: 40px;
    height: 40px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .Affilate_All_product_count_list .list-flex .img-div .circle-ico .icon {
    height: 17px;
    width: auto;
  }

  .Affilate_All_product_count_list .list-flex {
    width: 100%;
    height: 140px;
  }

  .Affilate_All_product_count_list .list-flex .cont-div .number {
    font-size: 15px;
  }

  .Affilate_All_product_count_list .list-flex .img-div .circle-ico {
    min-width: 40px;
    height: 40px;
  }
}


@media (min-width: 1200px) and (max-width: 1399px) {
    .Affilate_All_product_count_list .list-flex .img-div .circle-ico .icon {
      height: 17px;
      width: auto;
    }
  
    .Affilate_All_product_count_list .list-flex {
      width: 100%;
      height: 140px;
    }
  
    .Affilate_All_product_count_list .list-flex .cont-div .number {
      font-size: 15px;
    }
  
    .Affilate_All_product_count_list .list-flex .img-div .circle-ico {
      min-width: 40px;
      height: 40px;
    }
  }