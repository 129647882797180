.Payment_stepform_sec {
  margin: 1% 0% 10% 0%;
}

.Payment_stepform_sec h4.stepform-heading {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
}

.Payment_stepform_sec .step-container.active .circle {
  color: #ffffff;
  border: 1px solid #ee9723;
  background-color: #ee9723;
}

.Payment_stepform_sec .progress-bar-container .step-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.Payment_stepform_sec .progress-bar-container .step-container {
  position: relative;
  display: flex;
}

.line-right {
  margin: 0px 5px;
  margin-bottom: 13px;
  width: 131px;
  border-bottom: 1px dashed black;
}

/* 
.Payment_stepform_sec .line-right {
  transform: rotate(-90deg);
  content: "";
  position: absolute;
  top: -45px;
  left: 229px;
  height: 123px;
  width: 1px;
  background-image: linear-gradient(to bottom, #000000 50%, white 50%);
  background-position: 0 0, 4px 0;
  background-size: 56px 10px;
  background-repeat: repeat-y;
} */

/* .Payment_stepform_sec .line-right {
    content: "";
    position: absolute;
    top: 55%;
    left: 75px;
    width: 330px;
    border-bottom: 1px dashed blue;
} */
.Payment_stepform_sec .line-right.completed {
  border-bottom: 1px dashed orange;
  /* background-image: linear-gradient(to bottom, orange 50%, transparent 50%); */
}

.Payment_stepform_sec .progress-bar-container .line-left {
  width: 50px;
  height: 5px;
  background: #dfdfdf;
  position: absolute;
  top: 50%;
  right: 101%;
}

.Payment_stepform_sec .progress-bar-container .step-text {
  font-size: 16px;
  color: #000000;
  font-weight: 500;
  margin: 0px 0px 0px 15px;
}

.Payment_stepform_sec .progress-bar-container .step-container.active .step-text {
  font-weight: 600;
}

.Payment_stepform_sec .signup-form {
  margin: 10px 60px 40px 15px;
}

.Payment_stepform_sec .progress-bar-container .circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  color: #000;
  font-size: 20px;
  border: 1px solid #ee9723;
  font-size: 14px;
}

.Payment_stepform_sec .progress-bar-container .circle.completed {
  color: #ffffff;
  border: 1px solid #ee9723;
  background-color: #ee9723;
}

.Payment_stepform_sec .progress-bar-container .circle-center {
  justify-content: center;
  text-align: center;
  display: flex;
}

/* progress bar css end */

.Payment_stepform_sec .step2 .react-datepicker__input-container .react-datepicker__calendar-icon {
  right: 0px;
}

.Payment_stepform_sec .step2 .react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 25px;
  width: 100%;
  border: 1px solid #707070;
  border-radius: 5px;
}

.Payment_stepform_sec label {
  font-size: 15px;
}

.Payment_stepform_sec .form-check-label {
  font-size: 11px;
}

.Payment_stepform_sec .radio-box {
  background: #F2F2F2 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 10px;
}

.Payment_stepform_sec .radio-box span {
  font-size: 8px;
}

.Payment_stepform_sec .charges-label .form-check-label {
  font-size: 12px !important;
  font-weight: 500;
}

.Payment_stepform_sec .step2 .react-datepicker-wrapper {
  width: 100%;
}

.Payment_stepform_sec .displaycon {
  display: none;
}

/* step1 modal css Start*/
.stepform-modal .modal-text {
  text-align: center;
  padding: 0px 20px;
}

.stepform-modal .modal-text h4 {
  font-size: 20px;
  color: #383838;
  font-weight: bold;
}

.stepform-modal .modal-text .form-container {
  display: flex;
  justify-content: center;
  text-align: center;
}

.stepform-modal .modal-text .form-container .form-control {
  border-left: none;
  border-right: none;
  border-top: navajowhite;
  border-radius: 0px;
  border-bottom: 1px solid grey;
  width: 50%;
}

.stepform-modal .modal-text p.otp-text {
  color: #000000b2;
  font-size: 14px;
  font-weight: 400;
}

.stepform-modal .modal-text .verify-btn {
  background-color: #ff860059;
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  width: 60%;
  border: none;
  padding: 5px;
}

.stepform-modal .modal-header {
  border: none;
}

.stepform-modal .modal-text .wp-icon {
  width: 20px;
  height: 20px;
}

.stepform-modal .modal-text .term-text {
  color: #00499e;
  font-size: 12px;
}

.Payment_stepform_sec .circle-icon-holder .circle-icon {
  font-size: 16px;
  font-weight: 500;
  color: #ff860059;
}

.Payment_stepform_sec .circle-icon-holder .circle-check {
  position: relative;
}

.Payment_stepform_sec .circle-icon-holder .check-icon {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 86%;
}

.Payment_stepform_sec .text-holder p {
  font-size: 15px;
  font-weight: 400;
}

.Payment_stepform_sec .stepp-inner-main {
  padding-left: 50px;
}

.Payment_stepform_sec .stepp-inner-main .upoopp {
  font-weight: normal;
  font-size: 12px;
  color: #000;
}

.Payment_stepform_sec .stepp-inner-main .form-label {
  font-weight: normal;
  font-size: 12px;
  color: #000;
}

.Payment_stepform_sec .stepp-inner-main .form-select::placeholder {
  font-weight: normal;
  font-size: 12px;
  color: #b1b1b1;
}

.Payment_stepform_sec .stepp-inner-main .form-control {
  font-weight: normal;
  font-size: 12px;
  text-align: left;
  color: #b1b1b1;
  padding: 10px;
}

.Payment_stepform_sec .stepp-inner-main .form-select {
  font-weight: normal;
  font-size: 12px;
  text-align: left;
  color: #b1b1b1;
  padding: 10px;
}

.Payment_stepform_sec .stepp-inner-main .wevvcwec {
  font-weight: 600;
  font-size: 14px;
  color: #000;
  margin-bottom: 0px;
}

.Payment_stepform_sec .stepp-inner-main .nam-add-tab .nav-pills .nav-link {
  text-decoration: none;
}

.Payment_stepform_sec .stepp-inner-main .nam-add-tab .nav-pills .nav-link {
  color: #000;
  background-color: #fff;
  border-radius: 15px;
  background: #fff;
  border: 1px solid #000;
  font-size: 12px;
  padding: 5px 20px;
  margin: 0px 5px;
}

.Payment_stepform_sec .stepp-inner-main .nam-add-tab .nav-pills .nav-link.active {
  border: 1px solid #f88d28;
}

.Payment_stepform_sec .stepp-inner-main .continue-btn {
  background: #f88d28;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  border-radius: 50px;
  padding: 8px 20px;
  border: 1px solid #f88d28;
}

.Payment_stepform_sec .stepp-inner-main .back-btn {
  background: #fff;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  border-radius: 50px;
  padding: 8px 20px;
  border: 1px solid #000;
  margin-right: 10px;
}

.Payment_stepform_sec .stepp-inner-main .delevidiv .cardflex {
  display: flex;
}

.Payment_stepform_sec .stepp-inner-main .delevidiv .img-div {
  min-width: 115px;
  height: auto;
}

.Payment_stepform_sec .stepp-inner-main .delevidiv .img-div img {
  width: 112px;
  height: auto;
}

.Payment_stepform_sec .stepp-inner-main .delevidiv .cont-div .nnnn {
  font-weight: 600;
  font-size: 14px;
  color: #000;
  margin-bottom: 10px;
}

.Payment_stepform_sec .stepp-inner-main .continue-btn .next-fafa {
  margin-left: 10px;
}

.Payment_stepform_sec .stepp-inner-main .delevidiv .cardflex .mimodd {
  font-weight: normal;
  font-size: 12px;
  color: #000;
  margin-bottom: 0px;
}

.Payment_stepform_sec .stepp-inner-main .delevidiv .esttdelby {
  font-weight: normal;
  font-size: 14px;
  color: #000;
  margin-bottom: 0px;
  cursor: pointer;
}

.Payment_stepform_sec .stepp-inner-main .delevidiv .addnott {
  font-weight: 600;
}

.Payment_stepform_sec .stepp-inner-main .delevidiv {
  margin-bottom: 30px;
}

.Payment_stepform_sec .stepp-inner-main .piecce-box {
  background: #f2f2f2;
  border-radius: 8px;
  padding: 10px;
}

.Payment_stepform_sec .stepp-inner-main .piecce-box .colll {
  font-weight: 500;
  font-size: 14px;
  color: #000;
  margin-bottom: 0px;
}

.Payment_stepform_sec .stepp-inner-main .piecce-box .minus-box {
  width: 28px;
  height: 28px;
  border: 1px solid #c7c7c7;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

.Payment_stepform_sec .stepp-inner-main .piecce-box .countnumbb {
  font-weight: 500;
  font-size: 14px;
  color: #000;
  margin: 0px 10px;
}

.Payment_stepform_sec .stepp-inner-main .piecce-box .countt {
  margin: 10px 0px;
}

.Payment_stepform_sec .stepp-inner-main .piecce-box .totatllamt {
  font-weight: bold;
  font-size: 16px;
  color: #000;
  margin-bottom: 0px;
}

.Payment_stepform_sec .stepp-inner-main .red-text {
  color: #ff4e4e;
  font-size: 12px;
  font-weight: 400;
}

/* .Payment_stepform_sec .stepp-inner-main .form-check-input:checked {
    background-color: #FF860059;
    border-color: #FF860059;
} */

.Payment_stepform_sec .stepp-inner-main a {
  color: #000;
  text-decoration: underline;
}

.Payment_stepform_sec .stepp-inner-main p.term-text {
  font-size: 12px;
  color: #707070;
}

.Payment_stepform_sec .stepp-inner-main .save-btn-div .save-btn {
  border: 1px solid #000000;
  background-color: #000000;
  border-radius: 5px;
  padding: 4px 30px;
  font-size: 14px;
  font-weight: 400;
  color: #ffff;
}

.Payment_stepform_sec .stepp-inner-main .save-btn-div .save-btn:hover {
  background-color: #ffff;
  color: #000;
}

.Payment_stepform_sec .stepp-inner-main .addcard-icci {
  margin-left: 20px;
}

.Payment_stepform_sec .stepp-inner-main .addcard-icci img {
  height: 15px;
  width: auto;
  margin: 15px 15px 15px 0px;
}

.Payment_stepform_sec .nam-add-tab .nav-item {
  margin-bottom: 7px;
}

/* .Payment_stepform_sec .stepp-inner-main .form-check-input:checked {
    background-color: #FF860059;
    border-color: #FF860059;
    border-radius: 50px;
}

.Payment_stepform_sec .stepp-inner-main  .form-check-input {
    background-color: #FF860059;
    border-color: #FF860059;
    border-radius: 50px;
} */
.Payment_stepform_sec .existing-user-div .res-btn {
  background-color: #FFD500;
  border: none;
  font-size: 10px;
  color: #000;
  border-radius: 10px;
  margin: 10px 0px;
}
.Payment_stepform_sec .existing-user-div h6
{
  font-size: 16px;
  color: #000000;
  font-weight: bold;
}
.Payment_stepform_sec .existing-user-div p
{
  font-size: 14px;
  color: #000000;
  font-weight: 400;
margin: 0px;
}
.Payment_stepform_sec .existing-user-div .change-btn {
  font-size: 12px;
  border: navajowhite;
  color: #000;
  background-color: transparent;
  padding: 0px;
  margin: 15px 0px;
  font-weight: 500;
}


@media screen and (min-width: 0px) and (max-width: 485px) {
  .Payment_stepform_sec .line-right {
    display: none;
  }

  .Payment_stepform_sec .progress-bar-container .step-text {
    font-size: 13px;
    color: #000000;
    font-weight: 500;
    margin: 0px 0px 0px 0px;
  }

  .Payment_stepform_sec .signup-form {
    margin: 0px;
  }

  .Payment_stepform_sec .progress-bar-container .circle-center {
    align-items: center;
    margin-right: 9px;
  }

  .Payment_stepform_sec .progress-bar-container .circle-center {
    align-items: center;
  }

  .Payment_stepform_sec .stepp-inner-main {
    padding-left: 22px;
  }

  .Product_total_seccc .proddd-nnnn .prood-nname {
    font-size: 13px;
  }

  .Product_total_seccc .protect-odd-div ul li {
    font-size: 13px;
  }

  .nam-add-tab .nav.nav-pills .nav-item {
    width: 116px;
    margin: 4px 0px;
  }

  .Payment_stepform_sec .stepp-inner-main .continue-btn {
    font-size: 13px;
    padding: 5px 11px;
  }
  .Payment_stepform_sec .existing-user-div h6 {
    font-size: 14px;
}
.Payment_stepform_sec .existing-user-div p {
  font-size: 13px;

}
}

@media screen and (min-width: 486px) and (max-width: 767px) {
  .Payment_stepform_sec .line-right {
    display: none;
  }

  .Payment_stepform_sec .progress-bar-container .circle-center {
    align-items: center;
    margin-right: 9px;
  }

  .Payment_stepform_sec .progress-bar-container .circle-center {
    align-items: center;
  }

  .Payment_stepform_sec .signup-form {
    margin: 0px;
  }

  .Payment_stepform_sec .progress-bar-container .circle {
    width: 24px;
    height: 24px;
  }

  .Payment_stepform_sec .progress-bar-container .step-text {
    font-size: 14px;
    margin: 0px 0px 0px 4px;
  }

  .nam-add-tab .nav.nav-pills .nav-item {
    width: 116px;
    margin: 4px 0px;
  }

  .Payment_stepform_sec .stepp-inner-main .nam-add-tab .nav-pills .nav-link {
    text-align: center;
  }

  .Payment_stepform_sec .stepp-inner-main .continue-btn {
    font-size: 14px;
    padding: 5px 11px;
  }
  .Payment_stepform_sec .existing-user-div h6 {
    font-size: 14px;
  
}
.Payment_stepform_sec .existing-user-div p {
  font-size: 13px;

}
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .Payment_stepform_sec .progress-bar-container .circle-center {
    align-items: center;
    margin-right: 9px;
  }

  .Payment_stepform_sec .progress-bar-container .circle-center {
    align-items: center;
  }

  .Payment_stepform_sec .signup-form {
    margin: 0px;
  }

  .Payment_stepform_sec .progress-bar-container .circle {
    width: 24px;
    height: 24px;
  }

  .line-right {
    margin: 0px 11px;
    margin-bottom: 11px;
    width: 109px;
  }

  .Payment_stepform_sec .progress-bar-container .step-text {
    font-size: 14px;
    margin: 0px 0px 0px 4px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .line-right {
    width: 64px;
  }

  .Payment_stepform_sec .signup-form {
    margin: 10px 0px 24px 15px;
  }

  .Payment_stepform_sec .progress-bar-container .step-text {
    font-size: 14px;
  }

  .Payment_stepform_sec .stepp-inner-main .nam-add-tab .nav-pills .nav-link {
    margin: 4px 5px;
  }

  .Product_total_seccc .proddd-nnnn .prood-nname {
    font-size: 13px;
    line-height: 17px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .line-right {
    width: 119px;
  }

  .Payment_stepform_sec .signup-form {
    margin: 10px 0px 24px 15px;
  }

  .Payment_stepform_sec .progress-bar-container .step-text {
    font-size: 14px;
  }

  .Payment_stepform_sec .stepp-inner-main .nam-add-tab .nav-pills .nav-link {
    margin: 4px 5px;
  }

  .Product_total_seccc .proddd-nnnn .prood-nname {
    font-size: 13px;
    line-height: 17px;
  }
}