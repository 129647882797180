.WebsiteDashboard .dashboardMain .searchCard {
  background-color: #fff;
  padding: 11px;
  margin-bottom: 25px;
  border: 0;
}

.WebsiteDashboard .dashboardMain .searchInput .form-control {
  border: 0;
}

.WebsiteDashboard .dashboardMain .searchInput .input-group-text {
  background: transparent;
  border: 0;
}

.WebsiteDashboard .dashboardMain .searchrow .profileImgMain {
  position: relative;
}

.WebsiteDashboard .dashboardMain .searchrow .bottomCircle {
  border-radius: 50%;
  background-color: red;
  width: 12px;
  height: 12px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.WebsiteDashboard .dashboardMain .searchrow .profileImg {
  width: 40px;
  border-radius: 50%;
  height: 40px;
}

.endCont {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.WebsiteDashboard .dashboardMain .starcont {
  display: flex;
  border: 1px solid #ccc;
  width: 58%;
  align-content: center;
  align-items: center;
  justify-content: space-around;
}

.WebsiteDashboard .dashboardMain .startxt {
  font-size: 14px;
  margin-right: 3px;
}

.WebsiteDashboard .dashboardMain .cardtwo {
  border: 0;
  height: 180px;
  margin-bottom: 25px;
}

.WebsiteDashboard .dashboardMain .avatar {
  position: relative;
  width: 45px;
  height: 45px;
  cursor: pointer;
  margin-right: 12px;
}

.WebsiteDashboard .dashboardMain .cardtwo .cardhead {
  font-size: 17px;
}

.WebsiteDashboard .dashboardMain .cardtwo .badgesbtn {
  background-color: transparent;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #f88d28;
}

.WebsiteDashboard .dashboardMain .carddrop {
  box-shadow: 0 0.25rem 1rem rgba(161, 172, 184, 0.45);
  border: 0;
}

.WebsiteDashboard .dashboardMain .dropdown-toggle::after {
  display: none;
}

.WebsiteDashboard .dashboardMain .CircularProgressbar {
  width: 100%;
  vertical-align: middle;
  padding: 15%;
}

.WebsiteDashboard .dashboardMain .dashboard {
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 25px;
}

.revenue-chart .css-1k2pbbn-MuiResponsiveChart-container {
  width: 100%;
}

.WebsiteDashboard .dashboardMain .revenue-chart,
.growth {
  padding: 20px;
  border-radius: 8px;
}

.revenue-chart h2 {
  margin-bottom: 20px;
  font-size: 20px;
  color: #000;
  font-weight: 600;
}

.WebsiteDashboard .dashboardMain .growth {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.WebsiteDashboard .dashboardMain .year-dropdown {
  align-self: flex-end;
  margin-bottom: 20px;
}

.WebsiteDashboard .dashboardMain .revenue-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.WebsiteDashboard .dashboardMain .revenue-info div {
  text-align: center;
}

.WebsiteDashboard .dashboardMain .revenue-info p {
  margin: 0;
}

.WebsiteDashboard .dashboardMain .yeartxt {
  background-color: #fff2d6;
  color: #ffab00;
  padding: 5px 10px;
  font-size: 13px;
  border-radius: 15px;
}

.WebsiteDashboard .numbertext-holder {
  font-size: 18px;
}

.WebsiteDashboard .dashboardMain .notxt {
  font-size: 18px;
  margin-top: 9px;
  color: #566a7f;
}

.WebsiteDashboard .dashboardMain .percentagetxt {
  margin-top: 22px;
  font-size: 14px;
}

.WebsiteDashboard .dashboardMain .cardthree .card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: transparent;
  border-bottom: 0;
}

.WebsiteDashboard .dashboardMain .cardthree {
  border: 0;
}

.WebsiteDashboard .dashboardMain .year-dropdown .yeardrop .dropdown-toggle {
  background: transparent;
  color: #696cff;
  padding: 2px 10px;
  font-size: 15px;
}

.WebsiteDashboard .dashboardMain .dollarinfo p:nth-of-type(1) {
  font-size: 14px;
  text-align: left;
}

.WebsiteDashboard .dashboardMain .dollarinfo p:nth-of-type(2) {
  font-size: 15px;
  color: #566a7f;
  font-weight: 600;
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .revenue-chart h2 {
    font-size: 18px;
  }
}
