.Notification_offcanvas {
    top: 0;
    right: 0;
    left: unset;
    width: 500px;
    border-left: 1px solid rgba(0,0,0,.2);
    transform: translateX(100%);
    height: 70%;
    margin: auto;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 8px 0px 0px 8px;
    border: 0px;
}
.Notification_offcanvas .maksk-ppp{
font-weight: 500;
font-size: 14px;
color: #1c42a9;
margin-bottom: 0px;
}
.Notification_offcanvas .notification-div .noti-pppp{
font-weight: normal;
font-size: 14px;
color: #000;
margin-bottom: 0px;
}
.Notification_offcanvas .notification-div .noti-pppp .not-pp-name{
    font-weight: 600;
}
.Notification_offcanvas .notification-div .nott-imgg-pp{
    width: 26px;
    height: 26px;
    border-radius: 100px;
    object-fit: cover;
    margin-right: 10px;
}
.Notification_offcanvas .notification-div {
    padding: 15px;
}
.Notification_offcanvas .bg-collll{
    background: #ecf9ff;
}
.Notification_offcanvas .notification-div .nott-p-timggg{
font-weight: normal;
font-size: 12px;
color: #7b7b7b;
margin-bottom: 0px;
}