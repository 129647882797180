/* Portfolio Css Started */

.Portfolio-Content .Filter-Content .sub-title-medium {
    color: black;
    font-weight: 500;
}
.Portfolio-Content .Filter-Content .price-range {
    height: 99px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3px #00000029;
    border-radius: 10px;
    padding: 10px 15px;
    margin-bottom: 14%;
}
.Portfolio-Content .Filter-Content .Range {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.Portfolio-Content .Filter-Content .form-control {
    width: 55px;
    height: 28px;
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
}
.Portfolio-Content .Filter-Content .maintenance-text {
    font-weight: 500;
    color: black;
}
.Portfolio-Content .Filter-Content .Rating-Star {
    padding: 3px 0px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3px #00000029;
    border-radius: 10px;
    margin-bottom: 14%;
}
.Portfolio-Content .Filter-Content .Rating-Star .star svg.star-svg {
    height: 12px;
    width: 12px;
}
.Portfolio-Content .Filter-Content .Rating-Star .star {
    display: flex;
    justify-content: space-between;
    padding: 12px 11px 0px 14px;
}
.Portfolio-Content .Filter-Content .Rating-Star label.form-check-label {
    font-size: 12px;
    margin: 7px 6px;
}
.Portfolio-Content .Filter-Content .Rating-Star .form-check-input:checked[type="checkbox"] {
    border-color: #d7d7d7;
    background-color: white;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%2373AF55' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.Portfolio-Content .Filter-Content .Rating-Star .star .form-check .form-check-input {
    width: 20px;
    height: 20px;
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border-radius: 4px;
}
.Portfolio-Content .Filter-Content .Rating-Star .Rating {
    padding: 0px 25px;
}
.Portfolio-Content .Filter-Content .text {
    padding: 10px 10px 0px 15px;
}
.Portfolio-Content .Filter-Content {
    margin-top: 3%;
}

section.Portfolio-Content {
    margin-bottom: -5%;
    top: -135px;
    position: relative;
}

.Portfolio-Content .nav-link {
    background: #f5f5f5 0% 0% no-repeat padding-box !important;
    border-radius: 19px !important;
    margin-right: 12px;
}

.Portfolio-Content .nav-link.active {
    background-color: #1d44ad !important;
    border-radius: 19px;
}

.Portfolio-Content .maintenance-text {
    color: black;
    font-weight: 500;
}
.Portfolio-Content .Search-Box .form-control {
    width: 100%;
    height: 38px;
    background: #e5ecff 0% 0% no-repeat padding-box;
    border-radius: 19px;
    border: none;
}
.Portfolio-Content .search-icon {
    position: absolute;
    top: 9px;
    right: 13px;
}
.Portfolio-Content .Search-Box {
    position: relative;
}
.Portfolio-Content .nav-tabs-scroll.nav.nav-pills {
    width: 100%;
}
.Portfolio-Content  .main-product {
    height: 50px;
    display: flex;
}
.Portfolio-Content  .Show-More {
    text-align: center;
}
.Portfolio-Content .Show-More .show{
   
        background: #1d44ad 0 0 no-repeat padding-box;
        border-radius: 19px;
        box-shadow: 0 3px 6px #00000029;
        color: #fff;
        padding: 3px 21px;
    }

.Portfolio-Content .Show-More .show:hover{
    background: #0000 linear-gradient(180deg, #1d44ad, #000d33) 0 0 no-repeat padding-box;
    border-radius: 19px;
    box-shadow: 0 3px 6px #00000029;
}
/* Portfolio Css End */

/************** Media Query started ***********/

@media (min-width: 0px) and (max-width: 1399px) {
    .Portfolio-Content .nav-tabs-scroll {
        gap: 11px;
        flex-wrap: unset;
        display: flex;
        overflow: scroll;
        justify-content: left !important;
        width: 100%;
        overflow-y: auto;
    }
    .Portfolio-Content .nav-link {
        width: 190px !important;
        margin-right: 5px !important;
        padding: 6px 0px !important;
        text-align: center;
    }
}

@media (min-width: 0px) and (max-width: 485px) {
    .Portfolio-Content .Filter-Content .price-range {
        margin-bottom: 5%;
    }
    .Portfolio-Content .Filter-Content .Rating-Star {
        margin-bottom: 5%;
    }
    .Portfolio-Cards .card-portfolio {
        margin-bottom: 7%;
    }
    section.Portfolio-Content {
        top: -6px;
        margin-bottom: 5%;
    }
    .Portfolio-Content .Filter-Content .form-control {
        width: 100%;
    }
    .Portfolio-Cards p.maintenance-text {
        margin: 5px 5px;
    }
    .Portfolio-Content .Filter-Content .maintenance-text {
        margin: 5px 5px;
    }
    .Portfolio-Content .Filter-Content {
        margin-top: 7%;
    }
    .Portfolio-Content  .main-product {
        display: block;
        height: auto;
    }
}
@media (min-width: 486px) and (max-width: 575px) {
    .Portfolio-Content .Filter-Content .price-range {
        margin-bottom: 5%;
    }
    .Portfolio-Content .Filter-Content .Rating-Star {
        margin-bottom: 5%;
    }
    .Portfolio-Cards .card-portfolio {
        margin-bottom: 7%;
    }
    section.Portfolio-Content {
        top: -5px;
        margin-bottom: 5%;
    }
    .Portfolio-Content .Filter-Content .form-control {
        width: 100%;
    }
    .Portfolio-Cards p.maintenance-text {
        margin: 5px 5px;
    }
    .Portfolio-Content .Filter-Content .maintenance-text {
        margin: 5px 5px;
    }
    .Portfolio-Content .Filter-Content {
        margin-top: 7%;
    }
   
}
@media (min-width: 577px) and (max-width: 767px) {
    .Portfolio-Content .Filter-Content .price-range {
        margin-bottom: 5%;
    }
    .Portfolio-Content .Filter-Content .Rating-Star {
        margin-bottom: 5%;
    }
    .Portfolio-Cards .card-portfolio {
        margin-bottom: 7%;
    }
    .Portfolio-Content .Filter-Content .form-control {
        width: 100%;
    }
    section.Portfolio-Content {
        top: -178px;
        margin-bottom: -19%;
    }
    .Portfolio-Cards p.maintenance-text {
        margin: 5px 5px;
    }
    .Portfolio-Content .Filter-Content .maintenance-text {
        margin: 5px 5px;
    }
    .Portfolio-Content .Filter-Content {
        margin-top: 7%;
    }
}
@media (min-width: 768px) and (max-width: 1200px) {
    .Portfolio-Cards .card-portfolio {
        margin-bottom: 7%;
    }
    .Portfolio-Content .Filter-Content .maintenance-text {
        margin: 5px 5px;
    }

    .Portfolio-Cards .card-portfolio .portfolio-image img.portfolio {
        width: 100%;
    }
    .Portfolio-Cards p.maintenance-text {
        margin: 5px 5px;
    }
    section.Portfolio-Content {
        margin-bottom: -11%;
      
    }
}

/************** Media Query End ***********/

  