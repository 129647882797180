.heading {
    font-size: 24px;
    font-weight: bold;
  
    color: #000000;
}

.asterisk {
    color: red;
    font-weight: bolder;
    margin-left: 3px;
}

@media (min-width: 0px) and (max-width: 320px) {
    .heading {
        font-size: 15px;
    }
}