/* Styles for .Maintenance-Card */

.Maintenance-slider .Maintenance-Card {
  position: relative;
  cursor: pointer;
}
.Maintenance-slider .Maintenance-Card .black-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 330px;
  background: transparent linear-gradient(180deg, #00000047 0%, #000000 100%) 0%
    0% no-repeat padding-box;
  border-radius: 21px;
}

.Maintenance-slider .Maintenance-Card .content {
  position: absolute;
  bottom: 20px;
  left: 17px;
  text-align: left;
  transition: transform 0.8s ease-in-out;
}
.Maintenance-slider .Maintenance-Card:hover .content {
  bottom: 35px;
}

.Maintenance-slider .Maintenance-Card:hover .content {
  z-index: 9;
  transition: 0.5s;
}
.Maintenance-slider .Maintenance-Card .content .maintenance-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 4;
}
.Maintenance-slider .Maintenance-Card .content .sub-title-medium {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  transition: 0.5s ease-in-out;
  transition: transform 0.8s ease-in-out;
}

.Maintenance-slider .Maintenance-Card img.card-img {
  height: 330px;
  background: transparent linear-gradient(180deg, #00000047 0%, #000000 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  width: 100%;
  transition: transform 0.5s ease-in-out;
}

.Maintenance-slider .Maintenance-Card:hover img.card-img {
  transform: scale(1.1);
}
.Maintenance-slider .Maintenance-Card .image-main {
  overflow: hidden;
  width: 100%;
  border-radius: 24px;
}
.Maintenance-slider .Maintenance-Card .text {
  display: none;
}
.Maintenance-slider .Maintenance-Card:hover .text {
  display: block;
}
.Maintenance-slider .Maintenance-Card  .title {
  padding: 0px 12px 0px 0px;
}
