/* Demo Card Css Started */

.Demo-Card .card-demo {
    position: relative;
}
.Demo-Card img.Demo {
    border-radius: 17px;
    height: 306px;
    width: 100%;
}
.Demo-Card .demo-Content {
    position: absolute;
    top: 50%;
    left: 31px;
}
.Demo-Card .demo-Content .maintenance-title {
    font-weight: 400;
    color: white;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}
.Demo-Card .demo-Content .sub-title {
    color: white;
    font-weight: 400;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}
.Demo-Card .demo-Content .website-btn button.web-btn.btn.btn-primary {
    background: #f88d28 0% 0% no-repeat padding-box;
    border-radius: 19px;
    border: none;
    font-size: 14px;
}
.Demo-Card .demo-Content .website-btn:hover button.web-btn.btn.btn-primary {
    background: transparent linear-gradient(180deg, #f88d28 0%, #d86a00 100%) 0% 0% no-repeat padding-box;
}
.Demo-Card {
    margin-top: 10%;
    margin-bottom: 10%;
}
.Demo-Card .overlay {
    
    background: transparent linear-gradient(90deg, #000000 0%, #FF7C0000 100%) 0% 0% no-repeat padding-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 17px;
}
/* Demo Card Css End */

/*********************** Media Query Started ************************/

@media (min-width: 0px) and (max-width: 485px) {
    .Demo-Card img.Demo {
        height: 194px;
    }
    .Demo-Card .card-demo {
        margin-bottom: 23px;
    }
    .Demo-Card .demo-Content .website-btn button.web-btn.btn.btn-primary {
        font-size: 12px;
    }
    .Demo-Card .demo-Content {
      top: 39%;
    }
}
@media (min-width: 486px) and (max-width: 991px) {
    .Demo-Card img.Demo {
        height: 224px;
    }
    .Demo-Card .card-demo {
        margin-bottom: 23px;
    }
    .Demo-Card .demo-Content .website-btn button.web-btn.btn.btn-primary {
        font-size: 13px;
    }
    .Demo-Card .demo-Content {
      top: 41%;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .Demo-Card img.Demo {
        height: 238px;
    }
    .Demo-Card .demo-Content {
        top: 42%;
    }
}
