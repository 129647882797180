.login-page {
    background-image: url("../../../../../public/assest/images/MyWebsite/LoginSignup/login-bg.png");
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    padding-bottom: 5%;
}

.login-page .login-white {
    background: #ffffff;
    box-shadow: 0px 0px 3px #00000029;
    border-radius: 21px;
    margin-top: 0%;
    padding: 30px 60px;
}

.login-page .log_in_form .password-field-holder {
    position: relative;
}

.login-page .log_in_form .eye-icon-holder {
    position: absolute;
    top: 14px;
    right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.login-page .submit_btn {
    border: none;
    border-radius: 25px;
    background: #f88d28;
    padding: 15px 60px;
    color: #ffffff;
    font-size: 14px;
    width: 100%;
}

.login-page .submit_btn:hover {
    background: transparent linear-gradient(180deg, #f88d28 0%, #d26700 100%) 0% 0% no-repeat padding-box;
}

.login-page .social_img {
    width: 40px;
    background: #f5f5f5;
    margin: 11px;
    padding: 7px;
    border-radius: 5px;
}

.login-page .form-label {
    font-size: 14px;
    font-weight: 500;
}

.login-page .forget-txtt {
    font-size: 14px;
    color: #f88d28;
    text-decoration: underline;
    cursor: pointer;
}

.login-page label {
    font-weight: 700;
    color: #000;
}

.login-page .emailformpos{
    position: relative;
}
.login-page .icoset{
    position: absolute;
    right: 5px;
    bottom: 0px;
}

.login-page .react-tel-input .form-control {
    width: 100%;
}

.login-page .form-control {
    border-radius: 5px;
    /* padding: 12px; */
    font-size: 14px;
    /* background: #f5f5f5; */
    /* border: none; */
}

.login-page .login-txtt {
    font-size: 14px;
}

.login-page .log_in_form .log_in_with p {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #b7b7b7;
    display: flex;
    margin-top: 4%;
}

.login-page .log_in_form .log_in_with p:before,
.login-page .log_in_form .log_in_with p:after {
    color: white;
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #b7b7b7;
    margin: auto 5px;
}

.login-page .log-video-class .video-class {
    margin-top: 50%;
}

.login-page .log-video-class .video-class {
    width: 100%;
    border-radius: 15px;
}

.login-page .form-title h3 {
    font-size: 20px;
    color: #000;
    font-weight: 700;
}

.login-page .form-title p {
    font-size: 14px;
    color: #000;
}
.login-page .back-home {
    padding-top: 4%;
    display: flex;
    padding: -1px 0;
}
.login-page .back-home p.back {
    margin-top: -2px;
    margin-left: 11px;

    padding: -3px 17px;
    font-size: 13px;
}
.login-page  a {
    color: black;
   
}

.login-page .StepsixPassword {
    position: relative;
  }
  
  .login-page .StepsixPassword .StepsixeditIcon {
    position: absolute;
    top: 9px;
    right: 7px;
    color: #a1a1a1;
    font-size: 15px;
  }
/* Media */

@media (min-width: 0px) and (max-width: 576px) {
    .login-page .login-white {
        padding: 30px 22px;
        margin-top: 39%;
    }

    .login-page .form-label {
        font-size: 13px;
    }

    .login-page .form-control {
        font-size: 12px;
    }

    .login-page .forget-txtt {
        font-size: 12px;
    }

    .login-page .submit_btn {
        padding: 8px 18px;
        font-size: 12px;
    }

    .login-page .log_in_form .log_in_with p {
        font-size: 12px;
    }

    .login-page .login-txtt {
        font-size: 12px;
    }

    .login-page .log-video-class .video-class {
        display: none;
    }

    .login-page label {
        font-size: 12px;
    }
}

@media (min-width: 577px) and (max-width: 768px) {
    .login-page .login-white {
        padding: 30px 22px;
        margin-top: 39%;
    }

    .login-page .form-label {
        font-size: 13px;
    }

    .login-page .form-control {
        font-size: 12px;
    }

    .login-page .forget-txtt {
        font-size: 12px;
    }

    .login-page .submit_btn {
        padding: 8px 18px;
        font-size: 12px;
    }

    .login-page .log_in_form .log_in_with p {
        font-size: 12px;
    }

    .login-page .login-txtt {
        font-size: 12px;
    }

    .login-page .log-video-class .video-class {
        margin-top: 85%;
    }

    .login-page label {
        font-size: 12px;
    }
}
