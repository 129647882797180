.OffcanvasChat {
  width: 26%;
  height: auto !important;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 17px #00000030;
  border-radius: 10px 10px 0px 0px;
  left: 54% !important;
}

.OffcanvasChat .offcanvasmain .contactSupplycard {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: 0;
  margin-bottom: 20px;
}

.OffcanvasChat .offcanvasmain .contactSupplycard .canvasImageTextsec {
  display: flex;
  border-right: 1px solid #ccc;
  padding-right: 5px;
}

.OffcanvasChat .offcanvasmain .contactSupplycard .canvasImagesec img {
  width: 48px;
  height: 48px;
}

.OffcanvasChat .offcanvasmain .contactSupplycard .canvasImageTextsec p {
  font-size: 12px;
  margin-left: 15px;
}

.OffcanvasChat .offcanvasmain .contactSupplycard .QuantityUnitsec label {
  font-size: 12px;
}

.OffcanvasChat .offcanvasmain .contactSupplycard .Unitselect__menu,
.Unitselect__placeholder {
  font-size: 12px;
}

.OffcanvasChat .offcanvasmain .contactSupplycard .QuantityUnitsec {
  border-left: 1px solid #000;
}

.OffcanvasChat .offcanvasmain .Quantityehad h3 {
  font-size: 18px;
  font-weight: bold;
}

.OffcanvasChat .offcanvasmain .Quantityehad p {
  font-size: 12px;
  font-weight: 600;
}

.OffcanvasChat .offcanvasmain textarea {
  width: 100%;
  padding: 10px;
}

.OffcanvasChat .userImg {
  width: 23px;
  height: 25px;
  border-radius: 50%;
  margin-right: 12px;
}

.OffcanvasChat .textAreaMain {
  position: relative;
}

.OffcanvasChat .textAreaMain .sendbtnSec {
  position: absolute;
  bottom: 18px;
  right: 9px;
}

.OffcanvasChat .textAreaMain .galleryIconsec .gallertIcon {
  font-size: 20px;
  color: #727272;
}

.OffcanvasChat .textAreaMain .galleryIconsec .ImageIcon {
  margin-left: 10px;
  font-size: 20px;
  color: #727272;
}

.OffcanvasChat .textAreaMain .galleryIconsec {
  position: absolute;
  bottom: 18px;
  left: 9px;
}

.OffcanvasChat .textAreaMain .sendbtnSec .btn {
  background: #c5c4c4 0% 0% no-repeat padding-box;
  border-radius: 24px;
  border: 0;
  padding: 5px 26px;
}

.OffcanvasChat .offcanvas-header {
  border-bottom: 1px solid #ccc;
}

@media screen and (min-width: 0px) and (max-width: 767.98px) {
  .OffcanvasChat {
    width: 100%;
    left: 0% !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .OffcanvasChat {
    width: 50%;
    left: 44% !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .OffcanvasChat {
    width: 50%;
    left: 40% !important;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .OffcanvasChat {
    width: 36%;
  }
}

@media screen and (min-width: 2200px) and (max-width: 2560px) {
  .OffcanvasChat {
    width: 35%;
    left: 0;
  }
}