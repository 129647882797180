.inquire-now {
    margin: 5%;
}

.inquire-now .enquire-first-section {
    overflow-y: scroll;
    min-height: 800px;
    height: 800px;
}

.inquire-now .white-bg-inquire {
    background: #FFFFFF;
    border-radius: 5px;
    padding: 10px 20px;
}

.inquire-now .white-bg-inquire .pro-img {
    width: 48px;
    height: 48px;
}

.inquire-now .label-date {
    font-size: 12px;
}

.inquire-now .white-bg-inquire .td-subtitle {
    font-size: 10px;
}

.inquire-now .title-inquire h2 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 3%;
}

.inquire-now .purple-bg {
    background: #F8F8F8;
    border: 1px solid #DCDCDC;
    border-radius: 10px;
    padding: 10px 20px;
}

.inquire-now .user-name {
    font-size: 14px;
    font-weight: 600;
}

.inquire-now .company-name {
    font-size: 12px;
    font-weight: 400;
}

.inquire-now .form-check-label {
    font-size: 14px;
}

.inquire-now .form-control {
    font-size: 12px;
    height: 27px;
    border-radius: 5px;
}

.inquire-now .react-datepicker__input-container {
    font-size: 12px;
    height: 27px;
    border-radius: 5px;
    /* border: 0.5px solid #ccc; */
}

.inquire-now .circle-exclamation {
    color: #1D44AD;
    font-size: 15px;
}

.inquire-now .pro-dec {
    font-size: 12px;
    font-weight: 600;
}

.inquire-now .send-inquiry {
    background: #F88D28;
    border-radius: 24px;
    font-size: 14px;
    color: #FFF;
    border: none;
    padding: 8px 15px;
    margin: 3% 0% 5% 0%;
}

.inquire-now td {
    font-size: 12px;
}

.inquire-now th {
    font-size: 14px;
}

.inquire-now th.custome {
    background-color: #F1F1F1;
}

.inquire-now .table-heading {
    font-size: 14px;
    font-weight: 600;
}

.inquire-now .doc-required {
    font-size: 10px;
}

.inquire-now .star-class {
    color: #FF0000;
}

.inquire-now .detail-txt {
    font-size: 16px;
    font-weight: 700;
}

.inquire-now .sub-details {
    font-size: 12px;
    color: #3B3B3B;
}

.inquire-now .add-attachment {
    font-size: 10px;
}

.inquire-now .form-check-input:checked {
    background-color: orange;
    background-image: radial-gradient(circle at 50% 50%, #505050 50%, #FFFFFF 50%) !important;
    border: 1px solid #000;
}

@media(min-width:0px) and (max-width:767px) {
    .inquire-now .detail-txt {
        font-size: 14px;
    }

    .inquire-now .send-inquiry {
        font-size: 13px;
    }
}