/* .slider-seller {
  margin: 6% 0;
} */
.slider-seller .blogs-headig h2 {
  margin: 33px 0px;
  text-align: center;
  font-size: 24px;
  font-weight: 560;
  line-height: 29px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.slider-seller .main-card-div {
  position: relative;
}

.slider-seller .main-card-div .img-divs {
  overflow: hidden;
}
.slider-seller .main-card-div .img-divs .achived {
  width: 100%;
  height: 478px;
  transition: 0.3s;
}
.slider-seller .main-card-div .img-divs .achived:hover {
  transform: scale(1.1);
}

.slider-seller .sellerblog-content {
  position: absolute;
  top: 70%;
  left: 5%;
  right: 5%;
  z-index: 1;
}
.slider-seller .sellerblog-content h6 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #f88d28;
  opacity: 1;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
  line-clamp: 1;
}

.slider-seller .sellerblog-content p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.slider-seller .read-button-div {
  position: absolute;
  top: 89%;
  left: 5%;
  z-index: 1;
}

.slider-seller .read-button-div .read-button {
  font-size: 15px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  width: 120px;
  height: 38px;
  background: #f88d28 0% 0% no-repeat padding-box;
  border-radius: 24px;
  border: none;
  opacity: 1;
}

.slider-seller .read-button-div .read-button:hover {
  background: #E87000 0% 0% no-repeat padding-box;
}

.slider-seller .overlay-bgrrs {
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0;
  left: 0;
  background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 0.69;
}

.slider-seller .swiper-wrapper {
  padding: 0px 0px 50px 0px;
}
.slider-seller .swiper-pagination-bullet-active {
  background-color: black !important;
  height: 4px;
  width: 19px !important;
  border-radius: 27px;
}
.slider-seller .swiper-pagination-bullet
{
  background-color: grey;
  height: 4px;
  width: 10px;
  border-radius: 27px;
}

@media (min-width: 0px) and (max-width: 320px) {
  .slider-seller {
    margin: 0px 6%;
  }
  .slider-seller .blogs-headig h2{
    margin: 11px 0px;
    font-size: 17px;
    line-height: 20px;
  }

  .slider-seller .main-card-div .img-divs .achived {
    height: 350px;
  }
  .slider-seller .sellerblog-content h6 {
    font-size: 13px;
    line-height: 16px;
  }
  .slider-seller .sellerblog-content p {
    font-size: 11px;
    line-height: 15px;
  }
  .slider-seller .read-button-div .read-button {
    font-size: 12px;
    line-height: 15px;
    width: 101px;
    height: 30px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .slider-seller {
    margin: 0px 6%;
  }
  .slider-seller .blogs-headig h2{
    margin: 11px 0px;
    font-size: 17px;
    line-height: 20px;
  }

  .slider-seller .main-card-div .img-divs .achived {
    height: 420px;
  }
  .slider-seller .sellerblog-content h6 {
    font-size: 14px;
    line-height: 16px;
  }
  .slider-seller .sellerblog-content p {
    font-size: 12px;
    line-height: 15px;
  }
  .slider-seller .read-button-div .read-button {
    font-size: 13px;
    line-height: 15px;
    width: 110px;
    height: 32px;
  }
}

@media (min-width: 486px) and (max-width: 767px) {
  .slider-seller {
    margin: 0px 6%;
  }
  .slider-seller .blogs-headig h2{
    margin: 15px 0px;
    font-size: 19px;
    line-height: 23px;
  }
  .slider-seller .main-card-div .img-divs .achived {
    height: 365px;
  }
  .slider-seller .sellerblog-content h6 {
    font-size: 13px;
    line-height: 16px;
  }
  .slider-seller .sellerblog-content p {
    font-size: 11px;
    line-height: 15px;
  }
  .slider-seller .read-button-div .read-button {
    font-size: 12px;
    line-height: 15px;
    width: 101px;
    height: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .slider-seller {
    margin: 0px 6%;
  }
  .slider-seller .blogs-headig h2{
    margin: 17px 0px;
    font-size: 21px;
    line-height: 25px;
  }

  .slider-seller .main-card-div .img-divs .achived {
    height: 420px;
  }
  .slider-seller .sellerblog-content h6 {
    font-size: 15px;
    line-height: 20px;
  }
  .slider-seller .sellerblog-content p {
    font-size: 13px;
    line-height: 19px;
  }
  .slider-seller .read-button-div .read-button {
    font-size: 14px;
    line-height: 17px;
    width: 120px;
    height: 35px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .slider-seller {
    margin: 0px 6%;
  }
  .slider-seller .blogs-headig h2{
    margin: 19px 0px;
    font-size: 22px;
    line-height: 27px;
  }

  .slider-seller .main-card-div .img-divs .achived {
    height: 420px;
  }
  .slider-seller .sellerblog-content h6 {
    font-size: 15px;
    line-height: 20px;
  }
  .slider-seller .sellerblog-content p {
    font-size: 13px;
    line-height: 19px;
  }
  .slider-seller .read-button-div .read-button {
    font-size: 14px;
    line-height: 17px;
    width: 120px;
    height: 35px;
  }
}

