/* B2bSellerDetails CSS Started */

.Live_preview .Background-image {
    background-repeat: no-repeat;
    position: relative;
    height: auto;
    background-size: cover;
    margin-top: 3%;
    margin-bottom: 3%;
}

.Live_preview .Background-image .product-information p.price {
    color: #000000;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
}

.Live_preview .Background-image .product-information p.quantity {
    color: #000000;
    font-size: 14px;
    margin-bottom: 11px;
}

.Live_preview .Background-image .product-information p.product {
    font-size: 14px;
    color: #1d44ad;
    font-weight: 600;
    margin-bottom: 5px;
}

.Live_preview .Background-image .product-information .border-between {
    border-left: 1px solid #b7b7b7;
    height: 18px;
    margin: 1px 13px;
}

.Live_preview .Background-image .product-information p.product-id {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 11px;
}

.Live_preview .Background-image .product-information .productHead h1 {
    font-size: 20px;
    color: #000000;
}

.Live_preview .Background-image .product-information .productHead p {
    font-size: 15px;
    color: #333333;
}

.Live_preview .Background-image .product-information .main-price {
    display: flex;
    justify-content: space-between;
}

.Live_preview .Background-image .product-information .main-price button.btn.btn-primary {
    height: 30px;
    background: #f88d28 0% 0% no-repeat padding-box;
    border-radius: 15px;
    padding: 1px 15px;
    border: none;
    font-size: 12px;
    font-weight: 400;
}

.Live_preview .Background-image .product-information .standard-section button.btn.btn-standard {
    background: #ececec 0% 0% no-repeat padding-box;
    border-radius: 12px;
    padding: 2px 11px;
    color: #535252;
    font-size: 12px;
    font-weight: 400;
}

.Live_preview .Background-image .product-information .standard-section .buttons-section .gotoCartbtn {
    background: #E5ECFF 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #000;
    border: 0;
    font-size: 15px;
    width: 160px;
    margin-right: 15px;
}

.Live_preview .Background-image .product-information .standard-section .buttons-section .gotoCartbtn:hover {
    background: #1D44AD 0% 0% no-repeat padding-box;
    color: #fff;
}

.Live_preview .Background-image .priceSection {
    display: flex;
    font-size: 15px;
}

.Live_preview .Background-image .priceSection p:nth-of-type(2) {
    text-decoration: line-through;
    color: #808080;
    font-size: 16px;
    margin-top: 5px;
}

.Live_preview .Background-image .priceSection p {
    margin-top: 10px;
}

.Live_preview .Background-image .product-information .standard-section .buttons-section button.btn.btn-chat {
    background: #fdfcfc 0% 0% no-repeat padding-box;
    border-radius: 24px;
    border: 1px solid black;
    color: #010000;
    font-size: 12px;
    font-weight: 400;
    width: 107px;
    margin: 0px 8px;
}

.Live_preview .Background-image .product-information .standard-section .buttons-section {
    display: flex;
    justify-content: left;
    margin-top: 3%;
    margin-bottom: 3%;
}

.Live_preview .Background-image .product-information .main-section-tabs {
    display: flex;
}

.Live_preview .Background-image .breadcrumb-item+.breadcrumb-item::before {
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, ">");
}

.Live_preview .manufacture-bar p {
    font-size: 10px;
    margin-bottom: 0%;
}

.Live_preview .Background-image .product-information .standard-section .buttons-section button.btn.btn-primary {
    padding: 7px 0px;
    background: #f88d28 0% 0% no-repeat padding-box;
    border-radius: 24px;
    border: none;
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    width: 107px;
    margin: 0px 8px;
}

/***************** Swiper CSS ***********/

.Live_preview .Background-image .product-information .main-section-tabs .background-color-sofa {
    width: 58px;
    height: 58px;
    background: #f8f8f8 0% 0% no-repeat padding-box;
    padding: 8px 7px;
    border-radius: 5px;
    margin: 0px 0px 0px 9px;
}

.Live_preview .Background-image .product-information .main-section-tabs img.product {
    height: 42px;
    width: 42px;
}

.Live_preview .Background-image .product-information .main-section-tabs .nav-item {
    margin-left: 12px;
}



.Live_preview .Background-image .background-product-detail {
    height: 100%;
    border-radius: 10px;
    margin-left: 8px;
    text-align: -webkit-center;
    width: 100%;
    margin-bottom: 5%;
}

.Live_preview .Background-image .sidebar-swiper .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Live_preview .Background-image .swipertwo {
    margin-bottom: 5%;
}

.Live_preview .Background-image .gallery-imgs img.inner-img {
    width: 52px;
    height: 52px;
}

.Live_preview .Background-image .main .gallery-imgs {
    text-align: center;
}

.Live_preview .Background-image .sidebar-swiper .swiper-slide {
    height: 76px !important;
    width: 76px;
    background: #f8f8f8 0% 0% no-repeat padding-box;
    border-radius: 10px;
    margin-bottom: 6px !important;
}

.Live_preview .Background-image .sidebar-swiper-mobile-view .swiper-slide.swiper-slide {
    height: 76px !important;
    width: 76px;
    background: #f8f8f8 0% 0% no-repeat padding-box;
    border-radius: 10px;
    margin-bottom: 6px !important;
}

/* .Live_preview .Background-image .product-content {
      padding-top: 20px;
  } */

.Live_preview .Background-image .main {
    display: flex;
}

.Live_preview .Background-image .main .silder-btn {
    position: relative;
}

.Live_preview .Background-image .main .sidebar-swiper .silder-btn .back-btn {
    /* content: url("../../../../../public/assest/images/B2Bbuyer/B2BSellerDetail/up.png"); */
    width: 20px;
    height: 20px;
    font-size: 15px;
    margin: 10px;
    cursor: pointer;
    position: absolute;
    background-color: #E9E9E9;
    color: #A2A2A2;
    bottom: 388px;
    z-index: 99;
    left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.Live_preview .Background-image .main .sidebar-swiper .silder-btn .next-btn {
    /* content: url("../../../../../public/assest/images/B2Bbuyer/B2BSellerDetail/down.png"); */
    width: 20px;
    height: 20px;
    margin: 10px;
    font-size: 15px;
    cursor: pointer;
    position: absolute;
    background-color: #E9E9E9;
    color: #A2A2A2;
    bottom: -11px;
    z-index: 99;
    right: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.Live_preview .Background-image .swiper-button-prev.swiper-button-disabled,
.Live_preview .Background-image .swiper-button-next.swiper-button-disabled {
    display: none;
}

.Live_preview .Background-image .swiper-button-prev,
.Live_preview .Background-image .swiper-button-next {
    display: none;
}

.Live_preview .Background-image .sidebar-swiper .mySwiper {
    height: 406px !important;
}

.Live_preview .Background-image .main .background-product-detail .product-detail img.product-img {
    width: 100%;
    height: 520px;
    /* position: absolute; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    /* object-fit: contain; */
}

.Live_preview .Background-image .background-product-detail .product-detail {
    position: relative;
}

.Live_preview .Background-image .background-product-detail .product-detail .silder-btn {
    position: absolute;
    display: flex;
    width: 100%;
    top: 47%;
    padding: 0px 15px;
    justify-content: space-between;
}

.Live_preview .Background-image .background-product-detail .product-detail .silder-btn .back-btn {
    /* content: url("../../../../../public/assest/images/B2Bbuyer/B2BSellerDetail/left.png"); */
    width: 30px;
    height: 30px;
    /* margin: 10px; */
    cursor: pointer;
    /* position: absolute; */
    /* bottom: 342px; */
    z-index: 99;
    /* right: 43px; */
    background-color: #E9E9E9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 23px;
    color: #A2A2A2;
}

.Live_preview .Background-image .background-product-detail .product-detail .silder-btn .next-btn {
    /* content: url("../../../../../public/assest/images/B2Bbuyer/B2BSellerDetail/right.png"); */
    width: 30px;
    height: 30px;
    /* margin: 10px; */
    background-color: #E9E9E9;
    display: flex;
    border-radius: 50%;
    font-size: 23px;
    color: #A2A2A2;
    cursor: pointer;
    /* position: absolute; */
    /* bottom: 342px; */
    z-index: 99;
    /* right: 4px; */
    justify-content: center;
    align-items: center;
}

.Live_preview .Background-image .colourSection .form-check-input:checked[type=radio] {
    background: red;
    border: 0;
}


.Live_preview .Background-image .colourSection {
    display: flex;
    margin-top: 2%;
}

.Live_preview .Background-image .colourSection .colorBox {
    margin-right: 15px;
    width: 20px;
    height: 20px;
    border-radius: 20px;

}



.red-box {
    background-color: red;
}

.blue-box {
    background-color: blue;
}

.green-box {
    background-color: green;
}

.yellow-box {
    background-color: yellow;
}


.Live_preview .Background-image .sidebar-swiper-mobile-view {
    display: none;
    position: relative;
}



.Live_preview .Background-image .swipertwo .silder-btn .back-btn {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    z-index: 102;
    background-color: #E9E9E9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 15px;
    color: #A2A2A2;
    top: 35%;
    left: 0%;
}

.Live_preview .Background-image .swipertwo .silder-btn .next-btn {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    z-index: 102;
    background-color: #E9E9E9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 15px;
    color: #A2A2A2;
    top: 35%;
    right: 0%;
}

/***************** Swiper CSS ***********/

.Live_preview .Background-image .onImageHeartsec {
    position: relative;
}

.Live_preview .Background-image .onImageHeartsec .productheartIcon {
    position: absolute;
    top: 15px;
    right: 15px;
}




/******************** Media Query Started *************************/

.Live_preview svg.star-svg {
    height: 11px;
    width: 11px;
}

.Live_preview .Background-image .product-content .product-information .customize button.btn.btn-customize-btn {
    padding: 3px 10px;

    background: #ffd500 0% 0% no-repeat padding-box;
    border-radius: 11px;
    color: #000000;
    font-size: 11px;
}

.Live_preview .Background-image .product-content .product-information .star p.rating {
    font-size: 10px;
}

.Live_preview .Background-image .product-content .product-information .customize svg.svg-inline--fa.fa-print {
    height: 11px;
    width: 11px;
    cursor: pointer;
}

.Live_preview .Background-image .product-content .product-information .customize svg.svg-inline--fa.fa-share-nodes {
    height: 11px;
    width: 11px;
    cursor: pointer;
}

.Live_preview .Background-image .product-content .product-information .standard-section .buttons-section .circle {
    padding: 5px 8px;
    width: 30px;
    font-size: 14px;
    height: 30px;
    background-color: #ebebeb;
    border-radius: 100px;
}

.Live_preview .Background-image .product-content .product-information .Warranty {
    margin: 0px 10px;
    width: 38px;
    height: 38px;
    background-color: #fff4e9;
    border-radius: 100px;
    padding: 6px 10px;
}

.Live_preview .Background-image .product-content .product-information .Warranty .year {
    text-align: center;
    width: 60px;
    font-size: 10px;
}

.Live_preview .Background-image .product-content .product-information .Warranty img.icon {
    width: 18px;
    height: 20px;
}

.Live_preview .Background-image .product-content .product-information .accordion-button:not(.collapsed) {
    color: #000000 !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

.Live_preview .Background-image .product-content .product-information button span {
    position: absolute;
    content: ('');
    width: 52%;
    height: 2px;
    background-color: #a7a7a7;
    right: 8%;
    margin: auto 20px;
}


.Live_preview .Background-image .product-content .product-information .product {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
}

.Live_preview .Background-image .product-content .product-information .product-text {
    color: #000000;
    font-size: 12px;
}

.Live_preview .Background-image .product-content .product-information .accordion-item {
    border-radius: 0px !important;
    border-top: 0;
    border-bottom: 0;
    border-left: none;
    border-right: none;
}

.Live_preview .Background-image .accordionHead h3 {
    font-size: 15px;
    color: #333333;
    margin-bottom: 2px;
}

.Live_preview .Background-image .accordionHead p {
    font-size: 13px;
    color: #333333;
}

.Live_preview .Background-image .product-content .product-information .star {
    margin: -4px 5px;
}

.Live_preview .Background-image .accordion-button:focus {
    z-index: 3;
    outline: 0;
    box-shadow: none;
}

.Live_preview .Background-image .product-content .Amount-Card {
    height: 428px;
    background: #f6f6f6 0% 0% no-repeat padding-box;
}

.Live_preview .Background-image .product-content .Amount-Card .add-cart-box {
    border: 1px solid #ccc;
    border-radius: 5px;
    width: fit-content;
}

.Live_preview .Background-image .product-content .Amount-Card .add-cart-box .minus {
    background-color: #f6f6f6;
    border-right: 1px solid #ccc;
    padding: 0px 9px;
    font-size: 17px;
    border-radius: 5px;
}

.Live_preview .Background-image .product-content .Amount-Card .add-cart-box .add {
    background-color: #f6f6f6;
    border-left: 1px solid #ccc;
    padding: 0px 9px;
    font-size: 17px;
    border-radius: 5px;
}

.Live_preview .Background-image .product-content .Amount-Card .add-cart-box .quantity {
    background-color: #fff;
    padding: 0px 15px;
    font-size: 17px;
}

.Live_preview .Background-image .product-content .Amount-Card p.amount-text {
    font-size: 11px;
}

.Live_preview .Background-image .product-content .Amount-Card .variants {
    height: 22px;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #c9c9c9;

}

.Live_preview .Background-image .product-content .Amount-Card .chair-main .chair {
    padding: 8px 8px;
    width: 46px;
    height: 46px;
    background: #e1e1e1 0% 0% no-repeat padding-box;
    border-radius: 5px;
    margin-top: 8px;
    margin-bottom: 26px;
}

.Live_preview .Background-image .product-content .Amount-Card .Select {
    display: flex;
    justify-content: space-around;
}

.Live_preview .Background-image .product-content .Amount-Card .chair-main .chair img.product-image {
    width: 33px;
    height: 34px;
    /* padding: 0px 1px; */
}

.Live_preview .Background-image .product-content .Amount-Card .Select .form-check-reverse {
    margin-top: 21px;
    margin-bottom: 47px;
}

.Live_preview .Background-image .product-content .Amount-Card .price-main p.price {
    font-size: 11px;
    margin-top: 29px;
    margin-bottom: 55px;
}

.Live_preview .Background-image .product-information .sizeSection {
    display: flex;
    flex-flow: wrap;
}

.Live_preview .Background-image .product-information .sizeSection .sizeNumber {
    border: 0.5px solid #707070;
    border-radius: 3px;
    padding: 3px 5px;
    margin-right: 19px;
    margin-bottom: 6%;
}


.button_slide {
    border: 2px solid #1D44AD;
    box-shadow: inset 0 0 0 0#1D44AD;
    -webkit-transition: ease-out 0.8s;
    -moz-transition: ease-out 0.8s;
    transition: ease-out 0.8s;
    font-size: 12px;
    font-weight: 500;
    color: #000;
}

.slide_down:hover {
    box-shadow: inset 0 -100px 0 0 #1D44AD;
    color: #fff;
}

.slide_down.active {
    color: #fff !important;
    background: #1D44AD;
}

.product-information .Inclusivetxt p {
    font-size: 14px;
}

/********** common media query started ***********/

.Live_preview .Background-image .product-content {
    margin-top: 0%;
    padding-top: 20px;
}

.Live_preview .Background-image .product-information .heading {
    font-size: 14px;
}

.Live_preview .Background-image .product-information .standard-section .buttons-section button.btn.btn-primary {
    padding: 6px 18px;
    font-size: 13px;
    width: 135px;
}

.Live_preview .Background-image .product-information .standard-section .buttons-section button.btn.btn-chat {
    padding: 6px 18px;
    width: 135px;
    font-size: 13px;
}

.Live_preview .Background-image .product-information .main-price button.btn.btn-primary {
    margin-right: 0%;
}

.Live_preview .Background-image .product-information p.product-id {
    font-size: 12px;
}

.Live_preview .Background-image .product-information p.quantity {
    font-size: 13px;
}

.Live_preview .Background-image .product-information p.product {
    font-size: 13px;
}

.Live_preview .Background-image .product-information p.price {
    font-size: 15px;
}

.Live_preview .Background-image .main .sidebar-swiper .silder-btn {
    display: none;
}

.Live_preview .Background-image .product-content .product-information .year {
    text-align: center;
    width: 60px;
    font-size: 10px;
}

.Live_preview .Background-image .main .background-product-detail .product-detail img.product-img {
    width: 100%;
    height: 300px;
}

.Live_preview .Background-image .main .sidebar-swiper-mobile-view .silder-btn {
    display: flex;
}

.Live_preview .Background-image .onImageHeartsec .productheartIcon {
    right: 35px;
    top: 15px;
}



.Live_preview .Background-image .product-information .heading {
    font-size: 15px;
}

.Live_preview .Background-image .product-information p.quantity {
    font-size: 13px;
}

.Live_preview .Background-image .product-information p.product {
    font-size: 13px;
}

.Live_preview .Background-image .product-information p.price {
    font-size: 16px;
}

.Live_preview .Background-image .product-information .standard-section .buttons-section button.btn.btn-primary {
    padding: 7px 18px;
    font-size: 13px;
}

.Live_preview .Background-image .product-information .standard-section .buttons-section button.btn.btn-chat {
    padding: 7px 18px;

    font-size: 13px;
}

.Live_preview .Background-image .product-information .main-price button.btn.btn-primary {
    margin-right: 0%;
}

.Live_preview .Background-image .main .sidebar-swiper .silder-btn {
    display: none;
}

.Live_preview .Background-image .main .sidebar-swiper-mobile-view .silder-btn {
    display: flex;
}

.Live_preview .Background-image .onImageHeartsec .productheartIcon {
    top: 15px;
    right: 15px;
}


@media (min-width: 768px) and (max-width: 991px) {
    .Live_preview .Background-image .product-information .heading {
        font-size: 16px;
    }

    .Live_preview .Background-image .swiper-slide {
        margin-left: 13px;
    }


    .Live_preview .Background-image .onImageHeartsec .productheartIcon {
        right: 50px;
    }

    .Live_preview .Background-image .main .sidebar-swiper-mobile-view .silder-btn {
        display: flex;
    }

    .Live_preview .Background-image .main .background-product-detail .product-detail img.product-img {
        height: 300px;
        object-fit: contain;
    }
}

@media (min-width: 992px) and (max-width: 1399px) {
    .Live_preview .Background-image .background-product-detail {
        min-width: 79%;
    }

    .Live_preview .Background-image .main .background-product-detail .product-detail img.product-img {
        width: 100%;
        height: 390px;
    }


}

/****************************** Media Query End ******************************/
.Live_preview svg.star-svg {
    height: 11px;
    width: 11px;
}

.Live_preview .Background-image .product-content .product-information .customize button.btn.btn-customize-btn {
    padding: 3px 10px;

    background: #ffd500 0% 0% no-repeat padding-box;
    border-radius: 11px;
    color: #000000;
    font-size: 11px;
}

.Live_preview .Background-image .product-content .product-information .star p.rating {
    font-size: 10px;
}

.Live_preview .Background-image .product-content .product-information .customize svg.svg-inline--fa.fa-print {
    height: 11px;
    width: 11px;
    cursor: pointer;
}

.Live_preview .Background-image .product-content .product-information .customize svg.svg-inline--fa.fa-share-nodes {
    height: 11px;
    width: 11px;
    cursor: pointer;
}

.Live_preview .Background-image .product-content .product-information .standard-section .buttons-section .circle {
    padding: 5px 8px;
    width: 30px;
    font-size: 14px;
    height: 30px;
    background-color: #ebebeb;
    border-radius: 100px;
}

.Live_preview .Background-image .product-content .product-information .Warranty {
    margin: 0px 10px;
    width: 38px;
    height: 38px;
    background-color: #fff4e9;
    border-radius: 100px;
    padding: 6px 10px;
}

.Live_preview .Background-image .product-content .product-information .Warranty .year {
    text-align: center;
    width: 60px;
    font-size: 10px;
}

.Live_preview .Background-image .product-content .product-information .Warranty img.icon {
    width: 18px;
    height: 20px;
}

.Live_preview .Background-image .product-content .product-information .accordion-button:not(.collapsed) {
    color: #000000 !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

.Live_preview .Background-image .product-content .product-information button.accordion-button {
    font-size: 14px;
    font-weight: 600;
    padding-left: 10px;
    position: relative;
}

.Live_preview .Background-image .product-content .product-information button span {
    position: absolute;
    content: ('');
    width: 54%;
    height: 2px;
    background-color: #a7a7a7;
    right: 3%;
}


.Live_preview .Background-image .product-content .product-information .product {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
}

.Live_preview .Background-image .product-content .product-information .product-text {
    color: #000000;
    font-size: 12px;
}

.Live_preview .Background-image .product-content .product-information .accordion-item {
    border-radius: 0px !important;
    border-top: 0;
    border-bottom: 0;
    border-left: none;
    border-right: none;
}


.Live_preview .Background-image .product-content .product-information .star {
    margin: -4px 5px;
}

.Live_preview .Background-image .accordion-button:focus {
    z-index: 3;
    outline: 0;
    box-shadow: none;
}

.Live_preview .Background-image .product-content .Amount-Card {
    height: 428px;
    background: #f6f6f6 0% 0% no-repeat padding-box;
}

.Live_preview .Background-image .product-content .Amount-Card .add-cart-box {
    border: 1px solid #ccc;
    border-radius: 5px;
    width: fit-content;
}

.Live_preview .Background-image .product-content .Amount-Card .add-cart-box .minus {
    background-color: #f6f6f6;
    border-right: 1px solid #ccc;
    padding: 0px 9px;
    font-size: 17px;
    border-radius: 5px;
}

.Live_preview .Background-image .product-content .Amount-Card .add-cart-box .add {
    background-color: #f6f6f6;
    border-left: 1px solid #ccc;
    padding: 0px 9px;
    font-size: 17px;
    border-radius: 5px;
}

.Live_preview .Background-image .product-content .Amount-Card .add-cart-box .quantity {
    background-color: #fff;
    padding: 0px 15px;
    font-size: 17px;
}

.Live_preview .Background-image .product-content .Amount-Card p.amount-text {
    font-size: 11px;
}

.Live_preview .Background-image .product-content .Amount-Card .variants {
    height: 22px;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #c9c9c9;

}

.Live_preview .Background-image .product-content .Amount-Card .chair-main .chair {
    padding: 8px 8px;
    width: 46px;
    height: 46px;
    background: #e1e1e1 0% 0% no-repeat padding-box;
    border-radius: 5px;
    margin-top: 8px;
    margin-bottom: 26px;
}

.Live_preview .Background-image .product-content .Amount-Card .Select {
    display: flex;
    justify-content: space-around;
}

.Live_preview .Background-image .product-content .Amount-Card .chair-main .chair img.product-image {
    width: 33px;
    height: 34px;
    /* padding: 0px 1px; */
}

.Live_preview .Background-image .product-content .Amount-Card .Select .form-check-reverse {
    margin-top: 21px;
    margin-bottom: 47px;
}

.Live_preview .Background-image .product-content .Amount-Card .price-main p.price {
    font-size: 11px;
    margin-top: 29px;
    margin-bottom: 55px;
}

.Live_preview .Background-image .product-information .sizeSection {
    display: flex;
    flex-flow: wrap;
}

.Live_preview .Background-image .product-information .sizeSection .sizeNumber {
    border: 0.5px solid #707070;
    border-radius: 3px;
    padding: 3px 5px;
    margin-right: 19px;
    margin-bottom: 6%;
}


.Live_preview .Background-image .button_slide {
    border: 2px solid #1D44AD;
    box-shadow: inset 0 0 0 0#1D44AD;
    -webkit-transition: ease-out 0.8s;
    -moz-transition: ease-out 0.8s;
    transition: ease-out 0.8s;
    font-size: 12px;
    font-weight: 500;
    color: #000;
}

.Live_preview .Background-image .slide_down:hover {
    box-shadow: inset 0 -100px 0 0 #1D44AD;
    color: #fff !important;
}

.Live_preview .Background-image .accordionContent .contentHead h3 {
    font-size: 15px;
    color: #333333;
}

.Live_preview .Background-image .accordionContent .contentHead {
    margin-bottom: 15px;
}

.Live_preview .Background-image .accordionContent .contentHead p {
    font-size: 15px;
    color: #333333;
}

.Live_preview .manufacture-bar .show-percentage:hover .used-capacity {
    display: block;
    position: absolute;

}

.Live_preview .manufacture-bar .show-percentage .used-capacity {
    display: none;

}

.Live_preview .manufacture-bar .show-percentage .used-capacity p {
    font-size: 10px;
    width: 143px;
}

.Live_preview .manufacture-bar .show-percentage .used-capacity .red-circle {
    width: 5px;
    height: 5px;
    background: #840304;
    border-radius: 50px;
    margin-top: 4px;
}

.Live_preview .manufacture-bar .show-percentage .used-capacity .green-circle {
    width: 5px;
    height: 5px;
    background: #8CD676;
    border-radius: 50px;
    margin-top: 4px;
}

.Live_preview .manufacture-bar .show-percentage .used-capacity .yellow-circle {
    width: 5px;
    height: 5px;
    background: #F88D28;
    border-radius: 50px;
    margin-top: 4px;
}

@media screen and (min-width: 0px) and (max-width: 485px) {

    .Live_preview .Background-image .product-content .product-information button span {
        display: none;
    }
}

@media screen and (min-width: 0px) and (max-width: 1200px) {
    .Live_preview .Background-image .product-information .sizeSection {
        overflow-x: scroll;
        margin-bottom: 15px;
    }

    .Live_preview .Background-image .product-information .productHead h1 {
        font-size: 17px;
    }

    .Live_preview .Background-image .product-information .productHead p {
        font-size: 14px;
        padding-bottom: 6px;
    }

    .Live_preview .Background-image .priceSection p:nth-of-type(2) {
        font-size: 14px;
    }

    .Live_preview .Background-image .priceSection {
        font-size: 18px;
    }

    .Live_preview .Background-image .accordionHead h3 {
        font-size: 15px;
    }

    .Live_preview .Background-image .accordionHead p {
        font-size: 13px;
    }

    .Live_preview .Background-image .product-content .product-information button.accordion-button {
        font-size: 13px;
    }

    .Live_preview .Background-image .gallery-imgs img.inner-img {
        width: 52px;
        height: 73px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .Live_preview .Background-image .priceSection {
        font-size: 20px;
    }

    .Live_preview .Background-image .product-information .productHead h1 {
        font-size: 17px;
    }

    .Live_preview .Background-image .product-information .productHead p {
        font-size: 14px;
        padding-bottom: 6px;
    }

    .Live_preview .Background-image .priceSection p:nth-of-type(2) {
        font-size: 14px;
    }

    .Live_preview .Background-image .priceSection {
        font-size: 18px;
    }

    .Live_preview .Background-image .accordionHead h3 {
        font-size: 15px;
    }

    .Live_preview .Background-image .accordionHead p {
        font-size: 13px;
    }

    .Live_preview .Background-image .product-content .product-information button.accordion-button {
        font-size: 13px;
    }
}