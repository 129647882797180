/* B2bSellerProduct CSS Started */

.DashboardReviews .Product-section .nav-link.active {
    height: auto;
    background: transparent linear-gradient(180deg, #fff3e8 0%, #ffa046 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    color: #000000;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    border-radius: 0px;
}

.DashboardReviews .reviewsNavbar {
    overflow-x: scroll;
    width: max-content;
    padding-bottom: 10px;
}

.DashboardReviews .Product-section .nav-link {
    color: #000000;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
}

.DashboardReviews .Product-section .main-nav {
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    padding: 14px 0px;
}

.DashboardReviews .Product-section.Content-First-Tab h4.key {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
}

.DashboardReviews .Product-section .Content-First-Tab p.text {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
}

/* B2bSellerProduct CSS End */

/* Eight Tab Css Started */

.DashboardReviews .Product-section .Content-Six-Tab .Reviews-Section .review-image img.review {
    width: 50px;
    height: 50px;
}

.DashboardReviews .Product-section .Content-Six-Tab .Reviews-Section {
    margin-top: 19px;
    display: flex;
    border-bottom: 1px solid #d5d4d4;
}

.DashboardReviews .Product-section .Content-Six-Tab .Reviews-Section .content-review .stars svg.star-svg {
    height: 11px;
    width: 11px;
}

.DashboardReviews .Product-section .Content-Six-Tab .Reviews-Section .content-review .stars {
    display: flex;
    margin-bottom: -9px;
}

.DashboardReviews .Product-section .Content-Six-Tab .Reviews-Section .content-review {
    margin-left: 27px;
}

.DashboardReviews .Product-section .Content-Six-Tab .Reviews-Section .content-review .stars .App {
    margin-top: -4px;
    margin-left: 25px;
}

.DashboardReviews .Product-section .Content-Six-Tab .Review-btn button.btn.btn-review {
    margin: -2px 9px;
    padding: 5px 14px;
    color: #000000;
    border-radius: 18px;
    border: 1px solid #919191;
}

.DashboardReviews .Product-section .Content-Six-Tab .Review-btn {
    /* margin-top: 2%; */
    text-align: end;
}

.DashboardReviews .Product-section .Content-Six-Tab {
    margin-top: 2%;
    margin-bottom: 2%;
    border-bottom: 1px solid #dbdbdb;
}

/* Eight Tab Css End */

/* Seven tab css started*/
.DashboardReviews .Product-section .Content-seven-Tab {
    margin-top: 2%;
    margin-bottom: 2%;
}

/* Seven tab css End */

/* Six tab css started */

.DashboardReviews .Product-section .Content-six-Tab ul {
    list-style-type: none;
}

.DashboardReviews .Product-section .Content-six-Tab li.name {
    margin-bottom: 2%;
    margin-top: 2%;
}

.DashboardReviews .Product-section .Content-six-Tab {
    margin-top: 2%;
}

.DashboardReviews .Product-section .Content-six-Tab .name {
    margin-top: 19px;
}

.DashboardReviews .Product-section .Content-Six-Tab p.key {
    font-size: 16px;
    font-weight: 600;
}

.DashboardReviews .Product-section .Content-Six-Tab .star svg.star-svg {
    width: 18px;
    height: 18px;
}

.DashboardReviews .Product-section .Content-Six-Tab .star {
    margin-top: -19px;
}

.DashboardReviews .Product-section .Content-Six-Tab .rating-progressbar {
    display: flex;
}

.DashboardReviews .Product-section .Content-Six-Tab progress {
    margin: 2px 7px;
    width: 210px;
}

.DashboardReviews .Product-section .Content-Six-Tab p.rating-text {
    font-size: 12px;
}

.DashboardReviews .Product-section .Content-Six-Tab p.rating-number {
    padding: 6px 8px;
    color: #000000;
    font-size: 13px;
}

/* Six tab css End */

/* Fifth css started */
.DashboardReviews .Product-section .Content-fifth-Tab li.title-tab {
    margin-bottom: 6px;
}

.DashboardReviews .Product-section .Content-fifth-Tab {
    margin-top: 2%;
}

.DashboardReviews .Product-section .Content-fifth-Tab .title-tab {
    font-weight: 500;
}

.DashboardReviews .Product-section .product-safety {
    list-style-type: none;
}

.DashboardReviews .Product-section .Content-fifth-Tab .title-tab {
    margin: 9px 8px;
    /* margin-bottom: 0px; */
    font-weight: 600;
    font-size: 14px;
}

.DashboardReviews .Product-section .Content-fifth-Tab .product {
    font-size: 16px;
    font-weight: 600;
}

.DashboardReviews .Product-section .Content-fifth-Tab .circle {
    padding: 6px 8px;
    width: 38px;
    height: 38px;
    background-color: #F2F2F2;
    border-radius: 100px;
}

.DashboardReviews .Product-section .Content-fifth-Tab .circle img.prodcut-icon {
    height: 22px;
    width: 22px;
}

/* Fifth css End */



/* Fourth css Started */
.DashboardReviews .Product-section .Content-fourth-Tab .table,
.DashboardReviews .Product-section .Content-fourth-Tab th,
.DashboardReviews .Product-section .Content-fourth-Tab tr,
.DashboardReviews .Product-section .Content-fourth-Tab td {
    border: 1px solid #bbbbbb;
    border-collapse: collapse;
    /* border-bottom: 0px; */
}

.DashboardReviews .Product-section .Content-fourth-Tab table,
th {
    /* background-color: #F1F1F1; */
    font-size: 14px;
}

.DashboardReviews .Product-section .Content-fourth-Tab td {
    font-size: 12px;
}

.DashboardReviews .Product-section .Content-fourth-Tab th.weight {
    background-color: #ebebeb;
}

.DashboardReviews .Product-section .Content-fourth-Tab .title-tab {
    font-weight: 600;
    font-size: 14px;
}

.DashboardReviews .Product-section .Content-fourth-Tab .title-tab {
    font-weight: 600;
    font-size: 18px;
}

/* Fourth css End */



/* third tab  css Started */
.DashboardReviews .Product-section .Content-third-Tab li.title-tab {
    margin-bottom: 7px;
}

.DashboardReviews .Product-section .Content-third-Tab {
    margin-top: 2%;
}

.DashboardReviews .Product-section .tableDdemo tr td:first-child {
    background-color: #f1f1f1;
    font-size: 14px;
}

.DashboardReviews .Product-section .Content-third-Tab .tableDdemo .name {
    font-weight: 500;
    color: #000000;
    font-size: 14px;
    border-top: 1px solid #dfdfdf;
}

.DashboardReviews .Product-section .Content-third-Tab .title-tab {
    margin-bottom: 15px;
    margin-top: 9px;
}

/* third tab  css End */

/* Second tab  css Started */
.DashboardReviews .Product-section .Content-Second-Tab span.title-tab {
    text-decoration: underline;
}

.DashboardReviews .Product-section .contentsecond {
    text-align: end;
}

.DashboardReviews .Product-section .Content-Second-Tab .content-two {
    margin-left: 34px;
}

.DashboardReviews .Product-section .Content-Second-Tab .title-tab {
    margin-bottom: 11px;
}

.DashboardReviews .Product-section .Content-Second-Tab {
    margin-top: 2%;
    margin-bottom: 1%;
}

.DashboardReviews .Product-section .Content-First-Tab img.product-image {
    width: 100%;
    height: 301px;
}

.DashboardReviews .Product-section .Content-First-Tab p.text-lorem {
    font-size: 12px;
    text-align: justify;
    line-height: 21px;
}

.DashboardReviews .Product-section .Content-Second-Tab ul {
    padding-left: 0px;
}

/* Second tab  css End */

.DashboardReviews .Product-section .Content-First-Tab {
    margin-top: 3%;
    margin-bottom: 1%;
}

/* Tabel css */
.DashboardReviews .Product-section .table> :not(caption)>*>* {
    padding: 0.5rem 20px;
}

.DashboardReviews .Product-section .Content-First-Tab .tableDdemo .name {
    font-weight: 500;
    color: #000000;
    font-size: 14px;
    border-top: 1px solid #dfdfdf;
}

/* Media Query Started */
@media (min-width: 0px) and (max-width: 1199px) {
    .DashboardReviews .Product-section .main-nav .nav {
        flex-wrap: unset;
        display: flex;
        width: 100%;
        overflow-y: hidden;
        overflow-x: scroll;
        padding-bottom: 13px;
    }

    .DashboardReviews .Product-section .nav-link.active {
        width: 200px;
        height: auto;
    }

    .DashboardReviews .Product-section .nav-link {
        width: 200px !important;
        height: auto;
    }

    .DashboardReviews .Product-section .contentsecond {
        text-align: start;
    }
}

@media (min-width: 0px) and (max-width: 767px) {
    .DashboardReviews .Product-section .Content-Six-Tab .Review-btn {
        margin-top: -2%;
        text-align: end;
        margin-bottom: 2%;
    }

    .DashboardReviews .Product-section .Content-First-Tab .tableDdemo .name {
        font-size: 13px;
    }
}

@media (min-width: 0px) and (max-width: 485px) {
    .DashboardReviews .Product-section .name {
        font-size: 13px;
    }
}

@media (min-width: 486px) and (max-width: 575px) {
    .DashboardReviews .Product-section .title-tab {
        font-size: 15px;
    }

    .DashboardReviews .Product-section .name {
        font-size: 14px;
    }
}



.DashboardReviews .Product-section .Content-Second-Tab .title-tab svg.svg-inline--fa.fa-circle-check {
    margin: 2px 10px;
    color: #26D701;
    width: 18px;
    height: 18px;

}

.DashboardReviews .Content-Second-Tab ul {
    list-style-type: none;
}

.DashboardReviews .product-table {
    margin-top: 3%;
}



.DashboardReviews .Customer-Service .Seeking {
    background-color: #F6F6F6;
}

.DashboardReviews .Customer-Service .Customer-Question {
    background-color: #F6F6F6;
}

.DashboardReviews .Customer-Service h4.heading-seeking {
    border-bottom: 1px solid rgb(181, 181, 181);
    padding: 13px 13px;
    color: #000000;
    font-size: 16px;
    font-weight: 500;
}

.DashboardReviews .Customer-Service .content p.customer {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 6px;
}

.DashboardReviews .Customer-Service .content p.text {
    margin-bottom: 33px;
    font-size: 11px;
}

.DashboardReviews .Customer-Service .content button.post.btn.btn-primary {
    border: none;
    color: black;
    font-size: 12px;
    border-radius: 24px;
    background-color: #c7c7c7;
}

.DashboardReviews .Customer-Service .content {
    padding: 13px 19px;
}

.DashboardReviews .Customer-Service img.customer {
    width: 24px;
    height: 24px;
}

.DashboardReviews .Customer-Service p.custome-name {
    font-weight: 500;
    font-size: 14px;
    padding: 0px 14px;
}

.DashboardReviews .Customer-Service p.customer-Question {
    font-size: 12px;
    font-weight: 500;
}

.DashboardReviews .Customer-Service p.ans {
    font-size: 11px;
}

.DashboardReviews .Customer-Service .question-customer {
    margin-top: 23px;
}