/* Maintenance css started */

.Maintenance {
    padding-bottom: 13px;
    margin-top: 5%;
    background: #f5f8ff 0% 0% no-repeat padding-box;
}
.Maintenance p.sub-title {
    text-align: center;
    padding-top: 32px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}
.Maintenance .maintenance-title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
}


.Maintenance .maintenance-slider .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    left: -12px;
    
 }
 .Maintenance .maintenance-slider .swiper-pagination-bullet {
     border-radius: 4px !important;
     height: 5px !important;
     width: 20px !important;
     background: black !important;
     opacity: 1 !important;
 }
 
 .Maintenance .maintenance-slider  .swiper-pagination-bullet-active {
     background: #779CFF !important;
     height: 5px !important;
     width: 27px !important;
 }
 .Maintenance .maintenance-slider  .View-More {
    opacity: 1 !important;
    padding-top: 119px;
    width: 112px;
    height: 328px;
    background-color: #1D44AD;
    margin-left: -209px;
    border-radius: 10px;
}
.Maintenance .maintenance-slider  .View-More:hover{
    text-decoration: underline;
    color: white;
}
.Maintenance .maintenance-slider  .swiper-slide.swiper-slide {
    width: 308px !important;
}
.Maintenance .maintenance-slider  svg.svg-inline--fa.fa-arrow-right {
    color: white;
}
.Maintenance .maintenance-slider   .swiper {
    padding-bottom: 41px;
    width: 100%;
    height: 100%;
}

.Maintenance .maintenance-slider  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Maintenance .maintenance-slider  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.Maintenance .maintenance-slider{
    padding-top: 65px;
}

/********************** Media Query Started ***************************/

@media (min-width:0px) and (max-width:485px){
    .Maintenance {
        margin-top: -10%;
    }
    .Maintenance .maintenance-slider  .swiper-slide.swiper-slide {
        width: auto !important;
    }
    .Maintenance .maintenance-slider  .View-More {
       margin-left: -2px;
    }
}
@media (min-width:486px) and (max-width:575px){
    .Maintenance {
        margin-top: 0%;
    }
}
@media (min-width:576px) and (max-width:767px){
    .Maintenance {
        margin-top: 0%;
    }
}
@media (min-width:768px) and (max-width:991px){
    .Maintenance {
        margin-top: 5%;
    }
}
@media (min-width:992px) and (max-width:1199px){
    .Maintenance {
        margin-top: 7%;
    }
}
/********************** Media Query End ******************************/