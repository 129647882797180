.view-quotes-offcanvas {
    background: #F9F9F9 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 17px #00000030;
    border-radius: 10px 0px 0px 10px;
}

.view-quotes-offcanvas .offcanvas-header {
    padding-top: 3%;
    padding-bottom: 0%;
}

/* View Rewquested CSS start */

.view-requested .close-quote-first {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 24px;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    padding: 7px 50px;

}

.view-requested .close-quote-first:focus {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 24px;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    padding: 7px 50px;

}

.view-requested .proceed-payment {
    background: #F88D28 0% 0% no-repeat padding-box;
    border-radius: 24px;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    padding: 7px 18px;
    border: none;
    color: #FFF;
}

.view-requested .first-white-bg {
    background: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0px 0px 5px #00000030;
    padding: 10px 10px;
}

.view-requested .verified-txt {
    font-size: 12px;
    color: #31BF07;
    font-weight: 600;
    margin-bottom: 0%;
}

.view-requested .status {
    font-size: 12px;
    margin-bottom: 0%;
}

.view-requested .approved {
    font-size: 12px;
    color: #31BF07;
    margin-bottom: 0%;
}

.view-requested .chat-icon {
    background: #EBEBEB;
    padding: 10px;
    border-radius: 50px;
    color: #585858;
}

.view-requested .ganzu-txtt {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 0%;
}

.view-requested .piece-txtt {
    font-size: 12px;
    color: #3D3D3D;
}

.view-requested .unit-price {
    font-size: 14px;
    font-weight: 600;
}

.view-requested .unit-price .set-txtt {
    font-size: 10px;
    font-weight: 400;
}


.view-requested .accordion-item {
    background-color: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0%;
}

.view-requested .accordion-button {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    box-shadow: none;
    color: #000;
    font-size: 12px;
    font-weight: 500;
}

.view-requested .accordion-button:focus {
    background-color: transparent;
    box-shadow: none;
}

.view-requested .accordion-button:not(.collapsed) {
    background-color: transparent;
    box-shadow: none;
    color: #000;
    font-size: 12px;
    font-weight: 500;
}

.view-requested .information {
    font-size: 12px;
}

/* View Rewquested CSS end*/

.view-requested .pro-img {
    width: 58px;
    height: 58px;
}

.view-requested .first-white-bg {
    background: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0px 0px 5px #00000030;
    padding: 10px 10px 7px 10px;
}

.view-requested .txt-first {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 0%;
}

.view-requested .status {
    font-size: 10px;
    color: #575757;
}

.view-requested .close-quote {
    font-size: 12px;
    border: 1px solid;
    border-radius: 25px;
    background: transparent;
    padding: 5px 15px;
}

.view-requested .brown-border {
    border-bottom: 1px solid #C5C5C5;
}

.view-requested .title-producttt {
    font-size: 14px;
    font-weight: 700;
}

.view-requested .txtt-data {
    font-size: 12px;
    text-align: justify;
}

.view-requested .cat-name {
    font-size: 12px;
    font-weight: 700;
}

@media(min-width:0px) and (max-width:486px) {
    .view-requested .pro-img {
        width: 46px;
        height: 46px;
    }

    .view-requested .txt-first {
        font-size: 11px;
    }

    .view-requested .ganzu-txtt {
        font-size: 12px;
    }

    .view-requested .proceed-payment {
        font-size: 12px;
    }

    .view-requested .close-quote-first {
        font-size: 12px;
        padding: 7px 26px;
    }
}