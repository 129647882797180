.save-modal .modal-content {
  background: #07555f 0% 0% no-repeat padding-box !important;
  border-radius: 10px;
  height: auto;
}

.save-modal .modal-body {
  text-align: center;
}

.save-modal .circle-img {
  width: 114px;
  height: 114px;
}

.save-modal .add-success {
  color: #fff;
  font-size: 25px;
  margin-top: 24px;
}
