.e-commerce-sec {
    margin: 7% 0%;
}

.e-commerce-sec .main-img {
    position: relative;
}

.e-commerce-sec .main-img .pricing-bg-img {
    width: 100%;
}

.e-commerce-sec .blk-overlay {
    background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
    border-radius: 33px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
}

.e-commerce-sec .txt-ecom {
    position: absolute;
    top: 14%;
    left: 5%;
}

.e-commerce-sec .txt-ecom h3 {
    font-size: 24px;
    color: #FFF;
}

.e-commerce-sec .txt-ecom .subtitle-ecom {
    font-size: 18px;
    color: #FFF;
}

.e-commerce-sec .txt-ecom p {
    font-size: 14px;
    color: #FFF;
    width: 40%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
}

.e-commerce-sec .main-img .free-trial {
    border-radius: 50px;
    border: 1px solid #F88D28;
    background: #F88D28;
    color: #FFF;
    font-size: 14px;
    border: none;
    padding: 10px 20px;
}
.e-commerce-sec .main-img .free-trial:hover {
    background: transparent linear-gradient(180deg, #F88D28 0%, #CE6500 100%) 0% 0% no-repeat padding-box;
    border-radius: 19px;
}
.e-commerce-sec .main-img .explore {
    border-radius: 50px;
    background: transparent;
    color: #FFF;
    font-size: 12px;
    border: 1px solid #FFF;
    padding: 10px 20px;
}

.e-commerce-sec .main-img .explore:hover {
    color: #F88D28;
    text-decoration: underline;
}

/* Media */

@media(min-width:0px) and (max-width:576px) {
    .e-commerce-sec .main-img .pricing-bg-img {
        height: 300px;
    }

    .e-commerce-sec .txt-ecom h3 {
        font-size: 16px;
    }

    .e-commerce-sec .txt-ecom .subtitle-ecom {
        font-size: 12px;
    }

    .e-commerce-sec .txt-ecom p {
        font-size: 11px;
        width: 100%;
    }

    .e-commerce-sec .main-img .free-trial {
        font-size: 12px;
    }

    .e-commerce-sec .main-img .explore {
        font-size: 11px;
    }
}

@media(min-width:577px) and (max-width:767px) {
    .e-commerce-sec .main-img .pricing-bg-img {
        height: 300px;
    }

    .e-commerce-sec .txt-ecom h3 {
        font-size: 16px;
    }

    .e-commerce-sec .txt-ecom .subtitle-ecom {
        font-size: 12px;
    }

    .e-commerce-sec .txt-ecom p {
        font-size: 11px;
        width: 100%;
    }

    .e-commerce-sec .main-img .free-trial {
        font-size: 12px;
    }

    .e-commerce-sec .main-img .explore {
        font-size: 11px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .e-commerce-sec .main-img .pricing-bg-img {
        height: 300px;
    }
}

@media(min-width:993px) and (max-width:1200px) {
    .e-commerce-sec .main-img .pricing-bg-img {
        height: 340px;
    }
}