.PCS_Services .bg-img-holder .banner-bg-img {
  width: 100%;
  height: 569px;
  opacity: 0.2;
}

.PCS_Services {
  position: relative;
}

.PCS_Services .process-content {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.PCS_Services
  .process-content
  .PCS_Services_content
  .girl_img-holder
  .girl-img {
  height: 444px;
  width: 100%;
  object-fit: contain;
}

.PCS_Services .process-content .PCS_Services_content .list-content {
  display: flex;
  justify-content: start;
  align-items: start;
  height: 100%;
}

.PCS_Services .process-content .PCS_Services_content .heading-holder p {
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.PCS_Services .process-content .PCS_Services_content .process-list {
  margin-top: 40px;
  position: relative;
}

.PCS_Services .process-content .PCS_Services_content .process-list .vr {
  border-right: 2px dashed #535353;
  height: 88%;
  position: absolute;
  top: 0;
  left: 1px;
  z-index: -1;
  margin: 15px 0px;
}
.PCS_Services .process-content .PCS_Services_content .process-list ul {
  padding-left: 16px;
}

.PCS_Services .process-content .PCS_Services_content .process-list li {
  font-weight: 500;
  font-size: 15px;
  color: #000000;
  margin-bottom: 15px;
}
/* .PCS_Services .process-content  h4.heading {
  font-size: 24px;
  font-weight: bold;
} */
@media (min-width: 0px) and (max-width: 320px) {
  .PCS_Services
    .process-content
    .PCS_Services_content
    .girl_img-holder
    .girl-img {
    height: 150px;
  }

  .PCS_Services
    .process-content
    .PCS_Services_content
    .list-content
    .heading-holder
    h4 {
    text-align: center;
  }

  .PCS_Services .process-content .PCS_Services_content .heading-holder p {
    font-size: 12px;
    text-align: center;
  }

  .PCS_Services .process-content .PCS_Services_content .list-content {
    margin-top: 5%;
  }

  .PCS_Services .process-content .PCS_Services_content .process-list li {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .PCS_Services .bg-img-holder .banner-bg-img {
    height: 500px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .PCS_Services
    .process-content
    .PCS_Services_content
    .girl_img-holder
    .girl-img {
    height: 150px;
  }

  .PCS_Services
    .process-content
    .PCS_Services_content
    .list-content
    .heading-holder
    h4 {
    text-align: center;
  }

  .PCS_Services .process-content .PCS_Services_content .heading-holder p {
    font-size: 12px;
    text-align: center;
  }

  .PCS_Services .process-content .PCS_Services_content .list-content {
    margin-top: 5%;
  }

  .PCS_Services .process-content .PCS_Services_content .process-list li {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .PCS_Services .bg-img-holder .banner-bg-img {
    height: 500px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .PCS_Services
    .process-content
    .PCS_Services_content
    .girl_img-holder
    .girl-img {
    height: 150px;
  }

  .PCS_Services
    .process-content
    .PCS_Services_content
    .list-content
    .heading-holder
    h4 {
    text-align: center;
  }

  .PCS_Services .process-content .PCS_Services_content .heading-holder p {
    font-size: 12px;
    text-align: center;
  }

  .PCS_Services .process-content .PCS_Services_content .list-content {
    margin-top: 5%;
  }

  .PCS_Services .process-content .PCS_Services_content .process-list li {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .PCS_Services .bg-img-holder .banner-bg-img {
    height: 500px;
  }
}

@media (min-width: 576px) and (max-width: 675px) {
  .PCS_Services
    .process-content
    .PCS_Services_content
    .list-content
    .heading-holder
    h4 {
    text-align: center;
  }

  .PCS_Services .process-content .PCS_Services_content .heading-holder p {
    font-size: 12px;
    text-align: center;
  }

  .PCS_Services .process-content .PCS_Services_content .list-content {
    margin-top: 5%;
  }

  .PCS_Services .process-content .PCS_Services_content .process-list li {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .PCS_Services .bg-img-holder .banner-bg-img {
    height: 500px;
  }
}

@media (min-width: 676px) and (max-width: 765px) {
  .PCS_Services
    .process-content
    .PCS_Services_content
    .list-content
    .heading-holder
    h4 {
    text-align: center;
  }

  .PCS_Services .process-content .PCS_Services_content .heading-holder p {
    font-size: 12px;
    text-align: center;
  }

  .PCS_Services .process-content .PCS_Services_content .list-content {
    margin-top: 5%;
  }

  .PCS_Services .process-content .PCS_Services_content .process-list li {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .PCS_Services .bg-img-holder .banner-bg-img {
    height: 500px;
  }
}
