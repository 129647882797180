/* .seller-dash-top-main{
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
} */
.dash-main-cont-sec {
  padding: 20px;
  background: #f8f8f8;
  height: 100%;
  /* overflow-y: scroll; */
}

.dash-t-ttde {
  font-weight: 500;
  font-size: 18px;
  color: #303030;
  margin-bottom: 0px;
}

.dasvvv-sub-p {
  font-weight: normal;
  font-size: 12px;
  color: #303030;
  margin-bottom: 0px;
}

.month-wise-droppp .form-select {
  font-size: 13px;
  color: #000;
  width: 100px;
  margin-left: auto;
}

.dash-t-ttde {
  font-weight: 500;
  font-size: 18px;
  color: #303030;
  margin-bottom: 0px;
}

.dasvvv-sub-p {
  font-weight: normal;
  font-size: 12px;
  color: #303030;
  margin-bottom: 0px;
}

.month-wise-droppp .form-select {
  font-size: 13px;
  color: #000;
  width: 100px;
  margin-left: auto;
  border: 0px;
}

.seller-dash-top-main .sidebar-flex {
  width: 80px;
  transition: all 0.3s ease-in-out;
}

.dashboard-part {
  height: 100vh;
  background: #f8f8f84a;
  transition: all 0.3s ease-in-out  !important;
}


.seller-dash-top-main .sidebarflexone {
  width: 264px !important;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden;
}

.seller-dash-top-main .sidebarflexone::-webkit-scrollbar {
  width: 0px;
}
.seller-dash-top-main .sidebarflexone::-webkit-scrollbar:horizontal {
  height: 8px;
}
/* Track */
.seller-dash-top-main .sidebarflexone::-webkit-scrollbar-track {
  background: #E8E8E8;
}
.seller-dash-top-main .sidebarflexone::-webkit-scrollbar-track:horizontal {
  background: #E8E8E8;
}
/* Handle */
.seller-dash-top-main .sidebarflexone::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 5px;
}
 .seller-dash-top-main .sidebarflexone::-webkit-scrollbar-thumb:horizontal{
   background: #c7c7c7;
  border-radius: 5px;
   }


.seller-dash-top-main .content-flex {
  width: 100%;
  overflow-y: auto;
  transition: all 0.3s ease-in-out !important;
}

.seller-dash-top-main .acceptbtn {
  background-color: green;
  border: 0;
  border-radius: 15px;
  font-size: 12px;
  margin-right: 5px;
}

.seller-dash-top-main .rejectbtn {
  background-color: red;
  border: 0;
  border-radius: 15px;
  font-size: 12px;
}

.seller-dash-top-main .OrderTabs .nav-link {
  color: #000;
  font-size: 14px;
}

.seller-dash-top-main .OrderTabs .iconEyetextimg p {
  font-size: 14px;
}

.seller-dash-top-main .OrderTabs .iconEyetextimg {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.seller-dash-top-main .OrderTabs .eyeIcon {
  color: #f88d28;
}

.seller-dash-top-main .OrderTabs .nav-link.active {
  color: #1c42a9;
  font-weight: bold;
}

.questionmarkdiv {
  position: fixed;
  top: auto;
  bottom: 15%;
  right: 20px;
  width: 55px;
  height: 55px;
  z-index: 999;
  border-radius: 100px;
  background-color: #f88d28;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quetjbcicon {
  color: #fff;
  font-size: 25px;
}

.scale-up-hor-left {
  -webkit-animation: scale-up-hor-left 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-hor-left 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-hor-left {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
@keyframes scale-up-hor-left {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}

.scale-down-hor-left {
  -webkit-animation: scale-down-hor-left 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-down-hor-left 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-down-hor-left {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(0.3);
    transform: scaleX(0.3);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
@keyframes scale-down-hor-left {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .dash-t-ttde {
    font-size: 15px;
  }

  .dasvvv-sub-p {
    font-size: 11px;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
}

@media (min-width: 992px) and (max-width: 1200px) {
}
