.Api_list-secttt {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0px;
}

.Api_list-secttt .accordion {
  --bs-accordion-border-color: transparent;
}

.Api_list-secttt .accordiontabs {
  background: #fff;
  box-shadow: 0 0 4px #ccc;
  padding: 15px;
}

.Api_list-secttt .accordion .accordion-button {
  border: 0px;
  background-color: transparent;
  font-size: 14px;
  padding: 8px 5px;
}

.Api_list-secttt .accordion .accordion-button {
  border: 0px;
  background-color: transparent;
  font-size: 15px;
  color: #000;
  font-weight: 500;
}

.Api_list-secttt .innerlinks .nav-pills .nav-link {
  color: #000;
  font-size: 15px;
  font-weight: 500;
}

.Api_list-secttt .nav-pills .nav-link.active {
  background-color: #f88d28;
  font-weight: 500;
  color: #fff;
}

.Api_list-secttt .accordion .accordion-button:focus {
  box-shadow: none !important;
}

.Api_list-secttt .accordion .accordion-item {
  border: 0px;
  background-color: transparent;
}

.Api_list-secttt .accordion .accordion-item .accordion-body {
  padding: 5px;
  margin-left: 20px;
}

.Api_list-secttt .accordion .accordion-button::after {
  background-size: 15px;
}

.Api_list-secttt .accordion .accordion-item .innerlimksss a {
  color: #000;
  font-size: 13px;
}

.Api_list-secttt .tab-content {
  display: block !important;
}

.Api_list-secttt .tab-content > .tab-pane {
  display: block !important;
}
.Api_list-secttt .fade:not(.show) {
  opacity: 1 !important;
}
