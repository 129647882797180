.customer-info-section {
    background: #FFFFFF;
    box-shadow: 0px 0px 20px #0000000D;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
    padding: 28px;
}
.customer-info-section .form-chek-in {
    position: relative;
}

.customer-info-section .form-chek-in .form-check {
    display: block;
    padding: 3% 10%;
    margin-bottom: .125rem;
    background: #F5F5F5;
    border-radius: 5px;
    font-size: 14px;
}

.customer-info-section .form-chek-in .pay-img {
    position: absolute;
    width: 40px;
    top: 14px;
    right: 19px;
}

.customer-info-section .form-chek-in .paypal-img {
    position: absolute;
    width: 20px;
    top: 10px;
    right: 19px;
}

.customer-info-section .form-check-input:checked {
    background-color: #000000;
    border-color:  #F88D28;
}

.customer-info-section .title-customer {
    font-size: 18px;
    color: #000;
    font-weight: 700;
    margin-top: 3%;
}

.customer-info-section .form-label {
    font-size: 14px;
    font-weight: 500;
}

.customer-info-section .form-control {
    border: none;
    background: #F5F5F5;
}

.customer-info-section .check-brown-border {
    border-bottom: 1px solid #707070;
}

.customer-info-section .title-customer-orange {
    font-size: 18px;
    color: #F88D28;
    font-weight: 500;
}

.customer-info-section .card-holder-class {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 3%;
}

.customer-info-section .card-holder-class .form-control {
    background: #FFF;
}

.customer-info-section .proceed-btn {
    color: #FFF;
    background: #F88D28;
    border: none;
    font-size: 16px;
    border-radius: 25px;
    padding: 8px 80px;
}

.customer-info-section .proceed-btn:hover {
    background: transparent linear-gradient(180deg, #F88D28 0%, #9C4D00 100%) 0% 0% no-repeat padding-box;
}


/* Media */

@media(min-width:0px) and (max-width:576px) {
    .customer-info-section {
        padding: 18px;
    }

    .customer-info-section .title-customer {
        font-size: 16px;
    }

    .customer-info-section .form-label {
        font-size: 12px;
    }

    .customer-info-section .title-customer {
        font-size: 14px;
    }

    .customer-info-section .title-customer-orange {
        font-size: 14px;
    }

    .customer-info-section .proceed-btn {
        font-size: 14px;
        padding: 8px 19px;
    }
}

@media(min-width:577px) and (max-width:768px) {
    .customer-info-section {
        padding: 18px;
    }

    .customer-info-section .title-customer {
        font-size: 16px;
    }

    .customer-info-section .form-label {
        font-size: 12px;
    }

    .customer-info-section .title-customer {
        font-size: 14px;
    }

    .customer-info-section .title-customer-orange {
        font-size: 14px;
    }

    .customer-info-section .proceed-btn {
        font-size: 14px;
        padding: 8px 19px;
    }
}