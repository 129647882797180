.b2bmain-header-section {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 26px 37px #00000012;
  opacity: 1;
  position: sticky;
  top: 0px;
  z-index: 999;
  height: 100%;
  width: 100%;
}

.b2bmain-header-section .header-main .cattt-droppppp-pos {
  position: relative;
}

.b2bmain-header-section .header-main .cattt-droppppp-pos .category-list-main-drop {
  position: absolute;
  top: 39px;
}

.b2bmain-header-section .noti-btn {
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: #141414;
}

.b2bmain-header-section .noti-btn:focus {
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: #141414;
}

.b2bmain-header-section .notifi-btn-div {
  position: relative;
}

.b2bmain-header-section .notifi-btn-div .dot {
  height: 5px;
  background-color: red;
  position: absolute;
  width: 5px;
  top: 9px;
  right: 10px;
  border-radius: 50px;
}

@keyframes smooth-appear {
  to {
    left: 0px;
    opacity: 1;
  }
}

.b2bmain-header-section .header-main .cattt-droppppp-pos .category-list-main-drop .main-cat-div {
  width: 300px;
  height: 400px;
  background-color: #fff;
  box-shadow: 0px 0px 3px 0px #f1f1f1;
  padding: 20px 0px 20px 10px;
  overflow-wrap: break-word;
  position: relative;
  left: -100px;
  animation: smooth-appear 0.5s ease forwards;
}

.b2bmain-header-section .header-main .cattt-droppppp-pos .category-list-main-drop .main-cat-div:hover .subcat-div {
  display: block;
}

.b2bmain-header-section .header-main .cattt-droppppp-pos .category-list-main-drop .subcat-div {
  width: 200px;
  height: 400px;
  background-color: #fff;
  box-shadow: 0px 0px 3px 0px #f1f1f1;
  padding: 20px 0px 20px 10px;
  overflow-wrap: break-word;
  position: absolute;
  top: 0;
  left: 303px;
  display: none;
}

.b2bmain-header-section .header-main .cattt-droppppp-pos .category-list-main-drop .main-cat-div .list-title {
  font-weight: 600;
  font-size: 18px;
  color: #000;
}

.b2bmain-header-section .header-main .cattt-droppppp-pos .category-list-main-drop .main-cat-div .name-div {
  margin: 5px 0px;
  position: relative;
}

.b2bmain-header-section .header-main .navbar {
  border-bottom: 0.5px solid #ccc;
}

/* .b2bmain-header-section .header-main .cattt-droppppp-pos .category-list-main-drop .main-cat-div .name-div:hover { 
  border-right: 5px solid #f88d28;
}
.b2bmain-header-section .header-main .cattt-droppppp-pos .category-list-main-drop .main-cat-div .name-div:focus { 
  border-right: 5px solid #f88d28;
} */
/* .floatactive-list{
  border-right: 5px solid #f88d28;
} */
.b2bmain-header-section .header-main .cattt-droppppp-pos .category-list-main-drop .main-cat-div .nav-pills .nav-link {
  font-weight: normal;
  font-size: 14px;
  color: #000;
  padding: 0px;
  border-radius: 0px;
}

/* .b2bmain-header-section .header-main .cattt-droppppp-pos .category-list-main-drop .main-cat-div .nav-pills .nav-link:hover{
  border-right: 5px solid #f88d28;
  background-color: transparent;
} */
.b2bmain-header-section .header-main .cattt-droppppp-pos .category-list-main-drop .main-cat-div .listdata-overflow {
  height: 350px;
  overflow-y: scroll;
}

.b2bmain-header-section .header-main .cattt-droppppp-pos .category-list-main-drop .main-cat-div .nav-pills .nav-link.active {
  border-right: 5px solid #f88d28;
  background-color: transparent;
}

.b2bmain-header-section .header-main .cattt-droppppp-pos .category-list-main-drop .main-cat-div .name-div .name {
  font-weight: normal;
  font-size: 14px;
  color: #000;
}

.b2bmain-header-section .header-main .cattt-droppppp-pos .category-list-main-drop .main-cat-div .name-div .see-cat {
  font-weight: 600;
  font-size: 14px;
  color: #000;
  margin-bottom: 0px;
}

.b2bmain-header-section .header-main .cattt-droppppp-pos .category-list-main-drop .main-cat-div .slider-list-div {
  width: 500px;
  height: 400px;
  background-color: #fff;
  box-shadow: 0px 0px 3px 0px #f1f1f1;
  padding: 20px 0px 20px 10px;
  overflow-wrap: break-word;
  position: absolute;
  top: 0;
  left: 203px;
  display: none;
}

.b2bmain-header-section .header-main .cattt-droppppp-pos .category-list-main-drop .main-cat-div .subcat-div:hover .slider-list-div {
  display: block;
}

.b2bmain-header-section .header-main .cattt-droppppp-pos .slider-list-div .cattt-sub-prod {
  text-align: center;
}

.b2bmain-header-section .header-main .cattt-droppppp-pos .slider-list-div .cattt-sub-prod .slidee {
  aspect-ratio: 1/1;
  height: 90px;
  width: auto;
}

.b2bmain-header-section .header-main .cattt-droppppp-pos .slider-list-div .cattt-sub-prod .catt-nam {
  font-weight: normal;
  font-size: 12px;
  color: #000;
  margin-bottom: 0px;
}

.b2bmain-header-section .header-main .header-img .header-logo {
  width: 173px;
  height: auto;
  background-color: transparent;
  opacity: 1;
}

.b2bmain-header-section .header-main .search-prod-div {
  width: 600px;
  position: relative;
}

.b2bmain-header-section .header-main .search-prod-div .form-control {
  border: 1px solid #afafaf;
  border-radius: 50px;
}

.b2bmain-header-section .header-main .search-prod-div .form-control::placeholder {
  font-weight: normal;
  font-size: 12px;
  color: #9c9c9c;
}

.b2bmain-header-section .header-main .search-prod-div .search-icc {
  position: absolute;
  width: 36px;
  height: 28px;
  background: #f88d28;
  border-radius: 50px;
  border: 0px;
  top: 5px;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.b2bmain-header-section .header-main .search-prod-div .search-icc .search {
  color: #fff;
  font-size: 15px;
}

.b2bmain-header-section .header-main .header-lists {
  text-align: center;
  color: #000;
  font-size: 14px;
  margin: 0px 9px;
  background: transparent;
  border: 0;
  padding-left: 0;
  font-weight: 500;
}

.b2bmain-header-section .header-main .header-lists:hover {
  color: #f88d28;
  font-weight: 500;
}

.b2bmain-header-section .header-main .header-lists .fontaw-icc {
  font-size: 18px;
}

.b2bmain-header-section .header-main a.nav-link {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #141414;
  opacity: 1;
}

.b2bmain-header-section .header-main .kingicn {
  color: #ffbb00;
  opacity: 1;
}

/* .b2bmain-header-section .header-main .dropdown-menu {
  width: 90%;
  inset: 0px auto auto 15px !important;
} */

.b2bmain-header-section .header-main .dropdown-menu .dropdown-item {
  font-size: 13px;
  line-height: 15px;
  font-weight: 300;
  letter-spacing: 0px;
  color: #191919;
  opacity: 1;
  margin-bottom: 7px;
}

.b2bmain-header-section .header-main .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
}

.b2bmain-header-section .header-main .slider-list-div .swiper {
  padding-bottom: 30px;
}

.b2bmain-header-section .header-main .accountDrop .dropdown-menu {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 13px #0000001F;
  border: 0;
}

.b2bmain-header-section .header-main .search-prod-div .trending-searches-div {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #ccc;
  padding: 20px;
  position: absolute;
  top: 50px;
  border-radius: 10px;
  z-index: 999;
}

.b2bmain-header-section .header-main .search-prod-div .trending-searches-div .trend-ppp {
  font-weight: 600;
  font-size: 12px;
  color: #727272;
  margin-bottom: 0px;
  padding: 10px 0px;
}

.b2bmain-header-section .header-main .search-prod-div .trending-searches-div .nav-link {
  padding: 5px 10px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #1655ff;
  margin: 5px 5px;
  font-weight: 600;
  font-size: 12px;
  color: #1d44ad;
}

.b2bmain-header-section .header-main .search-prod-div .trending-searches-div .navbar-nav {
  flex-flow: wrap;
}

.b2bmain-header-section .header-main .trending-searches-div .trending-slider-list .slidee {
  height: 90px;
  width: auto;
}

.b2bmain-header-section .header-main .trending-searches-div .trending-slider-list .catt-nam {
  font-size: 12px;
  color: #000;
  margin-bottom: 0px;
}

.b2bmain-header-section .header-main .trending-searches-div .trending-slider-list .swiper {
  padding-bottom: 30px;
}

.b2bmain-header-section .navbar-collapse {
  justify-content: end;
}

.b2bmain-header-section .swiper-pagination {
  display: none;
}


.b2bmain-header-section .swiper-button-prev:after,
.b2bmain-header-section .swiper-button-next:after {
  display: none;
}

.b2bmain-header-section .trending-searches-div {
  position: relative;
}

.b2bmain-header-section .navigation-arrow-holder .swiper-button-prev,
.b2bmain-header-section .navigation-arrow-holder .swiper-button-next {
  cursor: pointer;
}

.b2bmain-header-section .navigation-arrow-holder .swiper-button-prev {
  position: absolute;
  top: 96%;
  left: 42%;
  z-index: 2;
  width: 20px;
  height: 15px;
  background: #f3efef 0% 0% no-repeat padding-box;
  color: #000;
}

.b2bmain-header-section .navigation-arrow-holder .swiper-button-next {
  position: absolute;
  top: 96%;
  right: 42%;
  color: #000;
  z-index: 2;
  cursor: pointer;
  width: 20px;
  height: 15px;
  background: #f3efef 0% 0% no-repeat padding-box;
}


.b2bmain-header-section {
  display: block;
}

.b2bmain-mobview-hed-sec {
  display: none;
}



@media (min-width:0px) and (max-width:1024px) {
  .b2bmain-header-section {
    display: none;
  }

  .b2bmain-mobview-hed-sec {
    display: block;
  }

  .b2bmain-mobview-hed-sec .searchhh-divvv .logggg .dropdown-toggle {
    font-size: 13px;
  }

  .b2bmain-mobview-hed-sec .form-control {
    font-size: 12px;
  }

  .mobhedoffcan .mobnav-links-div .noti-btn-mo {
    background-color: transparent;
    border: none;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #141414;
    opacity: 1;
    padding: 0px;
  }

  .mobhedoffcan .mobnav-links-div .notifi-btn-div {
    position: relative;
    width: fit-content;
  }

  .mobhedoffcan .mobnav-links-div .notifi-btn-div .dot {
    height: 5px;
    background-color: red;
    position: absolute;
    width: 5px;
    top: 6px;
    right: -2px;
    border-radius: 50px;
  }

  .b2bmain-mobview-hed-sec {
    background-color: #fff;
    padding: 10px;
    box-shadow: 0px 0px 10px 0px #ccc;
    position: sticky;
    top: 0px;
    width: 100%;
    z-index: 999;
  }

  .b2bmain-mobview-hed-sec .flexlog-bar {
    display: flex;
    align-items: center;
  }

  .b2bmain-mobview-hed-sec .flexlog-bar .fabar {
    font-size: 25px;
  }

  .b2bmain-mobview-hed-sec .flexlog-bar .header-logo {
    width: 100px;
    height: auto;
    margin-left: 10px;
  }

  .b2bmain-mobview-hed-sec .flex-logcart {
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .b2bmain-mobview-hed-sec .flex-logcart a {
    color: #000;
  }

  .b2bmain-mobview-hed-sec .flex-logcart a .falog {
    font-size: 17px;
    margin-left: 15px;
  }

  .b2bmain-mobview-hed-sec .searchhh-divvv .group {
    position: relative;
    margin-top: 5px;
  }

  .mobhedoffcan .dropdown-menu .dropdown-item {
    font-size: 13px;
    line-height: 15px;
    font-weight: 300;
    letter-spacing: 0px;
    color: #191919;
    opacity: 1;
    margin-bottom: 7px;
  }


  .mobhedoffcan .dropdown-toggle {
    padding: 0px;
    color: #000;
    font-size: 14px;
  }

  .mobhedoffcan .dropdown-menu {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 13px #0000001F;
    border: 0;
  }

  .b2bmain-mobview-hed-sec .searchhh-divvv .group .serchicdiv {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .b2bmain-mobview-hed-sec .searchhh-divvv .group .serchicdiv button {
    border: 0px;
    height: 31px;
    width: 27px;
    border-radius: 3px;
    background: #ff8c28;
  }

  .b2bmain-mobview-hed-sec .searchhh-divvv .group .serchicdiv button .icon {
    color: #fff;
  }
}


@media (min-width:1025px) and (max-width:1200px) {
  .b2bmain-header-section .header-main .search-prod-div {
    width: 400px;
  }

  .b2bmain-header-section .header-main .header-lists {
    margin: 0px 2px;
    padding-left: 0%;
    padding-right: 0%;
  }

  .b2bmain-header-section .header-main a.nav-link {
    font-size: 12px;
  }
}


@media (min-width:1201px) and (max-width:1400px) {
  .b2bmain-header-section .header-main .search-prod-div {
    width: 500px;
  }

  .b2bmain-header-section .header-main .header-lists {
    margin: 0px 4px;
  }

  .b2bmain-header-section .header-main a.nav-link {
    font-size: 13px;
  }
}





.b2bmain-mobview-hed-sec .searchhh-divvv .logggg .dropdown-toggle {
  padding: 0px;
  color: #000;
  font-size: 14px;
}

.b2bmain-mobview-hed-sec .searchhh-divvv .logggg .dropdown .dropdown-menu {
  font-size: 12px;
}

.mobhedoffcan {
  width: 300px !important;
}

.mobhedoffcan .offcanvas-body .mobnav-links-div .navdiv {
  margin: 10px 0px;
}

.mobhedoffcan .offcanvas-body .mobnav-links-div .navdiv a {
  color: #000;
  font-size: 14px;
}