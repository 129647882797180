.surmount .surmount-white-bg {
    background: #FFFFFF 0% 0%;
    box-shadow: 0px 3px 6px #00000029;
    padding: 15px;
    position: relative;
}

.surmount .section-title h4 {
    margin-bottom: 3%;
    font-size: 20px;
    color: #000000;
    font-weight: 700;
    text-align: center;
    margin-top: 4%;
    position: relative;
}

.surmount .section-title h4::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    width: 125px;
    height: 2px;
    background-color: #FF4948;
    left: 30%;
}

.surmount .section-title h4::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    width: 125px;
    height: 2px;
    background-color: #FF4948;
    right: 30%;
}


.surmount .purple-bg {
    background: #F2F0FD;
    border-radius: 33px 34px 34px 0px;
    padding: 15px;
}

.surmount .purple-bg-right {
    background: #F2F0FD;
    border-radius: 34px 33px 0px 34px;
    padding: 15px;
}


.surmount .surmount-white-bg p {
    font-size: 14px;
    color: #000000;
    font-weight: 400;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
}

.surmount .surmount-white-bg .surmount-user-img {
    width: 44px;
    height: 44px;
    position: absolute;
    top: 58%;
    left: 1%;
}

.surmount .surmount-white-bg .surmount-user-img-right {
    width: 44px;
    height: 44px;
    position: absolute;
    top: 56%;
    right: -3%;
}

.surmount .surmount-white-bg .brand-name {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
}

.surmount .surmount-white-bg .brand-name-right {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    text-align: end;
}

/* media */

@media(min-width:0px) and (max-width:320px) {
    .surmount .section-title h4::before {
        display: none;
    }

    .surmount .section-title h4::after {
        display: none;
    }

    .surmount .section-title h4 {
        font-size: 16px;
    }
    .surmount .surmount-white-bg p {
        font-size: 13px;
    }
}

@media(min-width:321px) and (max-width:485px) {
    .surmount .section-title h4::before {
        display: none;
    }

    .surmount .section-title h4::after {
        display: none;
    }

    .surmount .section-title h4 {
        font-size: 16px;
    }
    .surmount .surmount-white-bg p {
        font-size: 13px;
    }
}

@media(min-width:486px) and (max-width:576px) {
    .surmount .section-title h4::before {
        display: none;
    }

    .surmount .section-title h4::after {
        display: none;
    }

    .surmount .section-title h4 {
        font-size: 16px;
    }
    .surmount .surmount-white-bg p {
        font-size: 13px;
    }
}

@media(min-width:577px) and (max-width:767px) {
    .surmount .section-title h4::before {
        width: 52px;
        left: 4%;
    }
    .surmount .section-title h4::after{
        width: 52px;
        right: 4%;
    }
    .surmount .surmount-white-bg p {
        font-size: 13px;
    }
}

@media(min-width:767px) and (max-width:768px) {
    .surmount .section-title h4::before {
        left: 4%;
    }
    .surmount .section-title h4::after{
        right: 4%;
    }
    .surmount .surmount-white-bg p {
        font-size: 13px;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .surmount .section-title h4::before {
        left: 4%;
    }
    .surmount .section-title h4::after{
        right: 4%;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .surmount .section-title h4::before {
        left: 4%;
    }
    .surmount .section-title h4::after{
        right: 4%;
    }
}