.Procurement_Customized {
  margin-bottom: 3%;
}

.Procurement_Customized .heading_holder h4 {
  text-align: center;
}

.Procurement_Customized .heading_holder p {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #000000;
}

@media (min-width: 0px) and (max-width: 320px) {
  .Procurement_Customized .heading_holder p {
    font-size: 12px;
  }
}
