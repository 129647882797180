.Report_flag_modal_sec .submit-btn {
    background-color: #F88D28;
    padding: 5px 20px;
    color: #fff;
    border-radius: 25px;
    font-size: 14px;
    margin: 15px 0px;
}
.Report_flag_modal_sec .form-control{
    font-size: 14px;
    margin: 15px 0px;
}