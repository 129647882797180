.leavecomment_section .leavecomment_heading {
    text-align: center;
    margin: 24px 0px;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.leave-comment {
    background-image: url('../../../../../public/assest/images/MyWebsite/Blogs/comment-back.png');
    /* margin-top: -4%; */
    height: 445px;
    margin-bottom: 5%;
}

.leavecomment_section .leaveForm .leavecommmenttext {
    font-size: 14px;
    font-weight: 400;
    padding: 15px;
    letter-spacing: 0px;
    color: #a7a7a7;
    opacity: 1;
}

.leavecomment_section .leaveForm .leaveform_label {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0px;
    /* color: #ffffff; */
    opacity: 1;
}

.leavecomment_section .leavecomment_main {
    padding: 30px;
}

.leavecomment_section .leavecomment_main .leavecommentbttondiv {
    text-align: center;
    margin-top: 20px;
}

.leavecomment_section .leavecomment_main .leavecommentbttondiv .leavecommentbutton {
    max-width: 496px;
    width: 100%;
    height: 48px;
    background: #f88d28 0% 0% no-repeat padding-box;
    border-radius: 24px;
    opacity: 1;
    border: none;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: center;
    color: #ffffff;
    opacity: 1;
}

.leavecomment_section .leavecomment_main .leavecommentbttondiv .leavecommentbutton:hover {
    background: transparent linear-gradient(180deg, #F88D28 0%, #D26700 100%) 0% 0% no-repeat padding-box;
    border-radius: 24px;
}

@media (min-width: 0px) and (max-width: 485px) {
    .leavecomment_section .leavecomment_heading {
        margin: 15px 0px;
        font-size: 20px;
    }

    .leavecomment_section .leavecomment_main {
        padding: 10px;
    }

    .leavecomment_section .leaveForm .leavecommmenttext {
        font-size: 11px;
        padding: 10px;
    }

    .leavecomment_section .leaveForm .leaveform_label {
        font-size: 11px;
    }

    .leavecomment_section .leavecomment_main .leavecommentbttondiv {
        margin-top: 0px;
    }

    .leavecomment_section .leavecomment_main .leavecommentbttondiv .leavecommentbutton {
        height: 35px;
        font-size: 11px;
    }
    .leave-comment {
      height: 412px; 
    }
}

@media (min-width: 486px) and (max-width: 767px) {
    .leavecomment_section .leavecomment_heading {
        margin: 15px 0px;
        font-size: 22px;
    }

    .leavecomment_section .leavecomment_main {
        padding: 10px;
    }

    .leavecomment_section .leaveForm .leavecommmenttext {
        font-size: 12px;
        padding: 10px;
    }

    .leavecomment_section .leaveForm .leaveform_label {
        font-size: 12px;
    }

    .leavecomment_section .leavecomment_main .leavecommentbttondiv {
        margin-top: 0px;
    }

    .leavecomment_section .leavecomment_main .leavecommentbttondiv .leavecommentbutton {
        height: 38px;
        font-size: 12px;
    }
    .leave-comment {
        height: 426px; 
      }
}
@media (min-width: 768px) and (max-width: 991px) {
    .leave-comment {
        height: 532px;
    }
}