.b2b-mywallet-main-page .transection-details {
    margin-top: 3%;
}

.b2b-mywallet-main-page .transection-details .heading-holder h2 {
    font-size: 20px;
    font-weight: 600;
}

.b2b-mywallet-main-page .transection-details .form-label {
    font-size: 11px;
}

.b2b-mywallet-main-page .transection-details p {
    font-size: 13px;
    margin-top: 10px;
}

.b2b-mywallet-main-page .transection-details .status-text {
    font-size: 13px;
    margin-top: 40px;
}

.b2b-mywallet-main-page .transection-details .form-control {
    background: #F6F6F6;
    border-radius: 10px;
    font-size: 12px;
}

.b2b-mywallet-main-page .transection-details .success-text {
    font-size: 13px;
    font-weight: 500;
}

.b2b-mywallet-main-page .transection-details .fa-circle-check {
    color: #2AA805;
}

.b2b-mywallet-main-page .transection-details .main-table-div {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border-radius: 10px;
}

.b2b-mywallet-main-page .transection-details img {
    width: 80px;
    height: 80px;
    margin-right: 20px;
}

.b2b-mywallet-main-page .transection-details th {
    font-size: 15px;
    font-weight: 500;
    border-bottom: 1px solid #ccc;
    text-wrap: nowrap;
}

.b2b-mywallet-main-page .transection-details .product-info-div h6 {
    font-size: 14px;
    font-weight: 600;
    color: #000;
}

.b2b-mywallet-main-page .transection-details .grey-text {
    font-size: 12px;
    color: #707070;
    margin: 0px;
}

.b2b-mywallet-main-page .transection-details .table {
    --bs-table-bg: none;
}

.b2b-mywallet-main-page .transection-details .product-info-div {
    display: flex;
    padding: 10px 0px;
}

.b2b-mywallet-main-page .transection-details .status-green {
    color: #31BF07;
    /* Green color for success status */
}

.b2b-mywallet-main-page .transection-details .status-red {
    color: #EE0000;
    /* Red color for other statuses */
}

.b2b-mywallet-main-page .table>:not(:last-child)>:last-child>* {
    border-bottom-color: #ccc;
}

@media(min-width:0px) and (max-width:578px) {
    .b2b-mywallet-main-page .transection-details .table {
        --bs-table-bg: none;
        width: 513px;
    }

    .b2b-mywallet-main-page .transection-details .heading-holder h2 {
        font-size: 18px;
    }

    .b2b-mywallet-main-page .transection-details .product-info-div h6 {
        font-size: 12px;
    }
}