.main-bann {
  position: relative;
}

.bann-section .tops-headig h2 {
  margin: 33px 0px;
  text-align: center;
  font-size: 24px;
  font-weight: 560;
  line-height: 29px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.main-bann .bannn-img {
  width: 100%;
  height: 450px;
}

.main-bann .ban-cont-main {
  position: absolute;
  bottom: 5%;
  left: 5%;
  z-index: 1;
}

.main-bann .ban-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: transparent
    linear-gradient(0deg, #000000 0%, #222222cf 27%, #80808000 100%) 0% 0%
    no-repeat padding-box;
  opacity: 1;
}
.main-bann .ban-cont-main h6 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500px;
  letter-spacing: 0px;
  color: #f88d28;
}
.main-bann .ban-cont-main .unwraps {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500px;
  letter-spacing: 0px;
  color: #ffffff;
}

.main-bann .ban-cont-main .ban-icn {
  width: 13px;
  height: 13px;
  color: #f88d28;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}

.main-bann .info-name {
  font-size: 14px;
  line-height: 17px;
  font-weight: 400px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.main-bann .ban-info {
  display: flex;
  align-items: center;
}

.main-bann .ban-info-1.info-1 {
  padding-right: 20px;
  border-right: 1px solid white;
}

.main-bann .info-2 {
  padding-left: 20px;
}

@media (min-width: 0px) and (max-width: 485px) {
  .bann-section .tops-headig h2 {
    margin: 11px 0px;
    font-size: 17px;
    line-height: 20px;
  }

  .main-bann .bannn-img {
    width: 100%;
    height: 250px;
  }
  .main-bann .ban-cont-main h6 {
    font-size: 15px;
    line-height: 20px;
  }
  .main-bann .ban-cont-main .unwraps {
    font-size: 12px;
    line-height: 19px;
  }
  .main-bann .ban-cont-main .ban-icn {
    width: 11px;
    height: 11px;
  }
  .main-bann .info-name {
    font-size: 11px;
    line-height: 15px;
  }
}

@media (min-width: 486px) and (max-width: 767px) {
  .bann-section .tops-headig h2 {
    margin: 15px 0px;
    font-size: 19px;
    line-height: 23px;
  }
  .main-bann .bannn-img {
    width: 100%;
    height: 310px;
  }

  .main-bann .ban-cont-main h6 {
    font-size: 17px;
    line-height: 20px;
  }
  .main-bann .ban-cont-main .unwraps {
    font-size: 13px;
    line-height: 19px;
  }
  .main-bann .ban-cont-main .ban-icn {
    width: 12px;
    height: 12px;
  }
  .main-bann .info-name {
    font-size: 12px;
    line-height: 15px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .bann-section .tops-headig h2 {
    margin: 17px 0px;
    font-size: 21px;
    line-height: 25px;
  }
  .main-bann .bannn-img {
    width: 100%;
    height: 360px;
  }

  .main-bann .ban-cont-main h6 {
    font-size: 18px;
    line-height: 22px;
  }
  .main-bann .ban-cont-main .unwraps {
    font-size: 14px;
    line-height: 21px;
  }
  .main-bann .ban-cont-main .ban-icn {
    width: 13px;
    height: 13px;
  }
  .main-bann .info-name {
    font-size: 13px;
    line-height: 15px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .bann-section .tops-headig h2 {
    margin: 19px 0px;
    font-size: 22px;
    line-height: 27px;
  }

  .main-bann .bannn-img {
    width: 100%;
    height: 400px;
  }

  .main-bann .ban-cont-main h6 {
    font-size: 18px;
    line-height: 22px;
  }
  .main-bann .ban-cont-main .unwraps {
    font-size: 14px;
    line-height: 21px;
  }
  .main-bann .ban-cont-main .ban-icn {
    width: 13px;
    height: 13px;
  }
  .main-bann .info-name {
    font-size: 13px;
    line-height: 15px;
  }
}
