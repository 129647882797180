.OffcanvasChatnow-new1 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 14px #00000012;
    border-radius: 10px;
    padding: 10px;
}

.OffcanvasChatnow-new1 .userImg {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 12px;
}

.OffcanvasChatnow-new1 .userImg-big {
    width: 35px;
    height: 35px;
    margin-right: 12px;
}

.OffcanvasChatnow-new1 .userImg-two {
    width: 30px;
    height: 30px;
    margin-right: 12px;
}

.OffcanvasChatnow-new1 h1 {
    font-size: 16px;
    font-weight: bold;
    margin: 0px;
}

.OffcanvasChatnow-new1 .message-div {
    display: flex;
    justify-content: space-between;
    /* border-bottom: 1px solid #C9C9C9; */
    padding-bottom: 14px;
    align-items: center;
}

.OffcanvasChatnow-new1 .cls-btn {
    background-color: transparent;
    border: none;
}

.OffcanvasChatnow-new1 .title {
    border-bottom: 1px solid #C9C9C9;
    display: flex;
    justify-content: space-between;
}

.OffcanvasChatnow-new1 .title .cls-btn {
    display: none;
}

.OffcanvasChatnow-new1 .left-border {
    border-right: 1px solid #C9C9C9;
}

.OffcanvasChatnow-new1 .left-border1 {
    border-left: 1px solid #C9C9C9;
}

.OffcanvasChatnow-new1 .bottom-border {
    border-bottom: 1px solid #C9C9C9;
}

.OffcanvasChatnow-new1 .d-flex-div {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}

.OffcanvasChatnow-new1 .left-side-content .bg-div {
    background: #E2BD03 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}

.OffcanvasChatnow-new1 .left-side-content .bg-div-two {
    background: #03E2A5 0% 0% no-repeat padding-box;
    opacity: 1;
}

.OffcanvasChatnow-new1 .left-side-content .left-flex p {
    font-size: 10px;
    margin: 0px;
    color: #585858;
}

.OffcanvasChatnow-new1 .left-side-content .left-flex {
    display: flex;
    justify-content: center;
    gap: 7px;
    align-items: center;
    margin: 10px 0px;
}

.OffcanvasChatnow-new1 .info-div p {
    font-size: 10px;
    font-weight: 400;
    color: #000;
    margin: 0px;
}

.OffcanvasChatnow-new1 .price-div h6 {
    font-size: 18px;
    font-weight: bold;
    margin: 0px;
}

.OffcanvasChatnow-new1 .price-div p {
    font-size: 10px;
    font-weight: 500;
    color: #000;
}

.OffcanvasChatnow-new1 .quantity-div p {
    font-size: 12px;
    font-weight: 500;
    color: #000;
}

.OffcanvasChatnow-new1 .info-div {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    padding: 10px;

}

.OffcanvasChatnow-new1 .offcanvas-body {
    background: #F9F9F9 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 17px #00000030;
    border-radius: 10px 10px 0px 0px;
    opacity: 1;
}

.OffcanvasChatnow-new1 .form-control {
    font-size: 14px;
    font-weight: 400;
}

.OffcanvasChatnow-new1 .form-div {
    position: relative;
}

.OffcanvasChatnow-new1 .form-div .textAreaMain {
    position: absolute;
    width: 100%;
    bottom: -6px;
}

.OffcanvasChatnow-new1 .form-div .textAreaMain .sendbtn {
    background: #C5C4C4 0% 0% no-repeat padding-box;
    border-radius: 24px;
    opacity: 1;
    font-size: 12px;
    border: none;
    color: #fff;
    padding: 4px 13px;
    margin: 0px 10px;
}

.OffcanvasChatnow-new1 .form-div .textAreaMain .Icon {
    color: #727272;
}

.OffcanvasChatnow-new1 .message-container h6 {
    font-size: 14px;
    font-weight: bold;
    margin: 0px;
    color: #000;
}

.OffcanvasChatnow-new1 .message-container p {
    font-size: 10px;
    font-weight: 500;
    margin: 0px;
    color: #858585;
}

.OffcanvasChatnow-new1 .nav-pills .nav-link.active {
    background-color: #E9EFFF;
    border-radius: 0%;
}