/* .My-Portfolio Css Started */

.My-Portfolio {
    background-image: url("../../../../../public/assest/images/MyWebsite/HomeImages/Portfolio/portfolio-banner.png");
    height: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-bottom: 1%;
    
}

.My-Portfolio .swiper-portfolio .blue-background {
    width: 100%;
    height: 281px;
    background: transparent linear-gradient(180deg, #1d44ad 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3px #00000029;
    border-radius: 24px;
}

.My-Portfolio .swiper-portfolio .blue-background .white-background {
    position: relative;
    top: 65px;
    width: 100%;
    height: 215px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3px #00000029;
    border-radius: 24px;
    padding: 0px 17px;
}

.My-Portfolio .swiper-portfolio .blue-background .white-background .content .women-image img.women {
    width: 70px;
    height: 70px;
    border-radius: 50px;
}

.My-Portfolio .swiper-portfolio .blue-background .white-background .content {
    width: 95%;
    position: absolute;
    top: -33px;
}

.My-Portfolio .swiper-portfolio .blue-background .white-background .content .women-image {
    text-align: center;
}

.My-Portfolio .swiper-portfolio .blue-background .white-background .content .maintenance-text {
    color: black;
    margin-top: 9px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4;
    /* padding: 0px 25px; */
}

.My-Portfolio .swiper-portfolio .blue-background .white-background .content .name-content .maintenance-text {
    color: black;
    margin-top: 9px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

.My-Portfolio .swiper-portfolio .blue-background .white-background .content .name-content p.name.maintenance-text {
    margin-bottom: -7px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

.My-Portfolio .swiper-portfolio .blue-background .white-background .content .name-content {
    border-left: 3px solid #1d44ad;
    padding-left: 12px;
}

.My-Portfolio .swiper-portfolio .blue-background .white-background .content .star {
    position: absolute;
    bottom: 11px;
    right: 15px;
}

.My-Portfolio .swiper-portfolio .blue-background .white-background .content .star svg.star-svg {
    height: 24px;
    width: 22px;
}

.My-Portfolio .swiper-portfolio .swiper-slide.swiper-slide-active .blue-background {
    height: 323px;
}

.My-Portfolio .swiper-portfolio .swiper-slide.swiper-slide-active .white-background {
    height: 258px;
}

.My-Portfolio .swiper-portfolio .swiper-slide.swiper-slide-active .blue-background .white-background .content .women-image img.women {
    width: 80px;
    height: 80px;
}

.My-Portfolio .swiper-portfolio .swiper-slide.swiper-slide-active .blue-background .white-background .content .name-content .maintenance-text {
    margin-top: 30px;
}

.My-Portfolio .swiper-portfolio .swiper-slide.swiper-slide-active .blue-background .white-background .content .name-content p.name.maintenance-text {
    margin-bottom: -24px;
}

.My-Portfolio .swiper-portfolio .swiper-slide.swiper-slide-active .blue-background .white-background .content .maintenance-text {

    margin-top: 20px;
}

.My-Portfolio .swiper-portfolio .swiper {
    padding-bottom: 21px;
    padding-top: 65px;
}

.My-Portfolio .swiper-portfolio .swiper-slide.swiper-slide-prev {
    margin-top: 42px;
}

.My-Portfolio .swiper-portfolio .swiper-slide.swiper-slide-next {
    margin-top: 42px;
}

.My-Portfolio .swiper-portfolio .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    display: none;
}

.My-Portfolio .view-portfolio button.btn.btn-portfolio {
    background: #1d44ad 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 19px;
    color: white;
    padding: 8px 36px;
}
.My-Portfolio .view-portfolio button.btn.btn-portfolio:hover {
    background: transparent linear-gradient(180deg, #1D44AD 0%, #000D33 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 19px;
}

.My-Portfolio .view-portfolio {
    text-align: center;
}

.My-Portfolio .swiper-portfolio {
    padding-top: 9%;
}

.My-Portfolio .swiper-portfolio .silder-btn {
    position: relative;
}

.My-Portfolio .swiper-portfolio .silder-btn .back-btn {
    content: url("../../../../../public/assest/images/MyWebsite/HomeImages/Portfolio/right.png");
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: absolute;
    bottom: 400px;
    z-index: 99;
    left: 93%;
    transform: translateX(-50%);
}

.My-Portfolio .swiper-portfolio .silder-btn .next-btn {
    content: url("../../../../../public/assest/images/MyWebsite/HomeImages/Portfolio/left.png");
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: absolute;
    bottom: 400px;
    z-index: 99;
    right: 7%;
    transform: translateX(-50%);
}

/* .My-Portfolio Css End */

/******************************Media Query Started ***************************************/
@media (min-width: 0px) and (max-width: 320px) {
    .My-Portfolio {
        height: auto;
    }

    .My-Portfolio .swiper-portfolio .swiper {
        padding-bottom: 22px;
    }

    .My-Portfolio .view-portfolio {
        padding-bottom: 22px;
    }

    .My-Portfolio .swiper-portfolio .swiper-slide.swiper-slide-active .blue-background .white-background .content .maintenance-text {
        margin-top: 7px;
    }

    .My-Portfolio .swiper-portfolio .swiper-slide.swiper-slide-active .blue-background .white-background .content .name-content p.name.maintenance-text {
        margin-bottom: 0px;
    }

    .My-Portfolio .swiper-portfolio .swiper-slide.swiper-slide-active .blue-background .white-background .content .name-content .maintenance-text {
        margin-top: 0px;
    }

    .My-Portfolio .swiper-portfolio .blue-background .white-background .content .star svg.star-svg {
        height: 18px;
        width: 18px;
    }

    .My-Portfolio .swiper-portfolio .blue-background .white-background .content .star {
        bottom: -7px;
    }

    .My-Portfolio .swiper-portfolio .swiper-slide.swiper-slide-active .white-background {
        height: 266px;
    }

    .My-Portfolio .swiper-portfolio .swiper-slide.swiper-slide-active .blue-background {
        height: auto;
    }
}

@media (min-width: 321px) and (max-width: 485px) {
    .My-Portfolio {
        height: auto;
    }

    .My-Portfolio .swiper-portfolio .swiper {
        padding-bottom: 22px;
    }

    .My-Portfolio .view-portfolio {
        padding-bottom: 22px;
    }

    .My-Portfolio .swiper-portfolio .swiper-slide.swiper-slide-active .blue-background .white-background .content .maintenance-text {
        margin-top: 7px;
    }

    .My-Portfolio .swiper-portfolio .swiper-slide.swiper-slide-active .blue-background .white-background .content .name-content p.name.maintenance-text {
        margin-bottom: 0px;
    }

    .My-Portfolio .swiper-portfolio .swiper-slide.swiper-slide-active .blue-background .white-background .content .name-content .maintenance-text {
        margin-top: 0px;
    }

    .My-Portfolio .swiper-portfolio .blue-background .white-background .content .star svg.star-svg {
        height: 18px;
        width: 18px;
    }

    .My-Portfolio .swiper-portfolio .blue-background .white-background .content .star {
        bottom: -7px;
    }

    .My-Portfolio .swiper-portfolio .swiper-slide.swiper-slide-active .white-background {
        height: 258px;
    }

    .My-Portfolio .swiper-portfolio .swiper-slide.swiper-slide-active .blue-background {
        height: auto;
    }
}

@media (min-width: 486px) and (max-width: 767px) {
    .My-Portfolio {
        height: auto;
    }

    .My-Portfolio .swiper-portfolio .swiper {
        padding-bottom: 0px;
    }

    .My-Portfolio .view-portfolio {
        padding-bottom: 22px;
    }

    .My-Portfolio .swiper-portfolio .swiper-slide.swiper-slide-active .blue-background .white-background .content .maintenance-text {
        margin-top: 7px;
    }

    .My-Portfolio .swiper-portfolio .swiper-slide.swiper-slide-active .blue-background .white-background .content .name-content p.name.maintenance-text {
        margin-bottom: 0px;
    }

    .My-Portfolio .swiper-portfolio .swiper-slide.swiper-slide-active .blue-background .white-background .content .name-content .maintenance-text {
        margin-top: 0px;
    }

    .My-Portfolio .swiper-portfolio .blue-background .white-background .content .star svg.star-svg {
        height: 18px;
        width: 18px;
    }

    .My-Portfolio .swiper-portfolio .blue-background .white-background .content .star {
        bottom: -7px;
    }

    .My-Portfolio .swiper-portfolio .swiper-slide.swiper-slide-active .white-background {
        height: 230px;
    }

    .My-Portfolio .swiper-portfolio .swiper-slide.swiper-slide-active .blue-background {
        height: auto;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    .My-Portfolio .swiper-portfolio .swiper-slide.swiper-slide-active .white-background {
        height: 280px;
    }

    .My-Portfolio .swiper-portfolio .blue-background .white-background {
        height: 242px;
    }

    .My-Portfolio .swiper-portfolio .swiper {
        padding-bottom: 45px;

    }
}

@media (min-width: 992px) and (max-width: 1399px) {
    .My-Portfolio .swiper-portfolio .swiper-slide.swiper-slide-active .white-background {
        height: 295px;
    }

    .My-Portfolio .swiper-portfolio .blue-background .white-background {
        height: 255px;
    }

    .My-Portfolio .swiper-portfolio .swiper {
        padding-bottom: 52px;

    }
}

/******************************Media Query End ***************************************/