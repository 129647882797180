.cancel-modal .modal-title {
    font-size: 18px;
    font-weight: 500;
}
.cancel-modal .modal-body
{
    font-size: 16px;
    font-weight: 400;
}
.cancel-modal .form-control {
    background: #fff;
    border-radius: 10px;
}
.cancel-modal label {
    font-size: 14px;
}
.cancel-modal .border-line{
border-top: 1px solid #000;
}
@media (min-width: 0px) and (max-width: 768px){
    .cancel-modal .modal-body
    {
        font-size: 14px;

    }
    .cancel-modal label {
        font-size: 13px;
    }
}

