.FaqForm {
  margin-top: 5%;
  margin-bottom: 30%;
}

.FaqForm .faqFormMain {
  background: #f9fbff 0% 0% no-repeat padding-box;
  padding: 2%;
  position: absolute;
  top: 85%;
  transform: translate(-50%, 0%);
  left: 50%;
}

.FaqForm .faqFormMain .form-label {
  font-size: 14px;
}

.FaqForm .faqassist {
  /* background-image: url('../../../../../public/assest/images/Seller_panel/FAQ/business-people-shaking-hands-finishing-up-meeting-deals-business-concept.png'); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 314px;
  position: relative;
}

.FaqForm .delight-text {
  font-size: 24px;
  color: #001B7C;
  font-weight: 600;
  padding-top: 4%;
}

.FaqForm .delight-text p {
  font-size: 14px;
  color: #000;
}

.FaqForm .send-query-btn {
  color: #FFF;
  font-size: 15px;
  font-weight: 600;
  border: none;
  background: #F88D28 0% 0% no-repeat padding-box;
  border-radius: 24px;
  padding: 10px;
  width: 172px;
}

.FaqForm .form-control {
  font-size: 14px;
  color: #A7A7A7;
}


@media(min-width:0px) and (max-width:320px) {
  .FaqForm .delight-text {
    font-size: 18px;
  }

  .FaqForm .delight-text p {
    font-size: 12px;
  }

  .FaqForm .faqFormMain {
    position: unset !important;
    transform: unset !important;
  }

}


@media(min-width:321px) and (max-width:485px) {
  .FaqForm .delight-text {
    font-size: 20px;
  }

  .FaqForm .delight-text p {
    font-size: 13px;
  }

  .FaqForm .faqFormMain {
    position: unset !important;
    transform: unset !important;
  }
}

@media(min-width:486px) and (max-width:576px) {
  .FaqForm .delight-text {
    font-size: 20px;
  }

  .FaqForm .delight-text p {
    font-size: 13px;
  }

  .FaqForm .faqassist {
    position: unset;
  }

  .FaqForm .faqFormMain {
    position: unset !important;
    transform: unset !important;
  }
}

@media(min-width:577px) and (max-width:768px) {
  .FaqForm .delight-text {
    font-size: 20px;
  }

  .FaqForm .delight-text p {
    font-size: 13px;
  }

  .FaqForm {
    margin-bottom: 84%;
  }
}

@media(min-width:769px) and (max-width:992px) {
  .FaqForm .delight-text {
    font-size: 20px;
  }

  .FaqForm .delight-text p {
    font-size: 13px;
  }

  .FaqForm {
    margin-bottom: 75%;
  }
}

@media(min-width:992px) and (max-width:1200px) {
  .FaqForm .delight-text {
    font-size: 20px;
  }

  .FaqForm .delight-text p {
    font-size: 13px;
  }

  .FaqForm {
    margin-bottom: 48%;
  }
}

@media(min-width:1201px) and (max-width:1440px) {

  .FaqForm {
    margin-bottom: 35%;
  }
}