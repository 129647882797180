/* Pricing Tab CSS Start */

.sitebuilder .nav-pills .nav-link.active {
    color: #FFF;
    background: #1D44AD;
    border-radius: 25px;
    font-size: 16px;
    text-align: center;
}

.sitebuilder .nav-pills .nav-link {
    color: #000000;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
}


.sitebuilder .swiper {
    background: #ECECEC;
    border-radius: 25px;
}

/* Pricing Tab CSS End */

/* Desktop CSS Start */

.payment-tab {
    background: #FFFFFF;
    box-shadow: 0px 0px 20px #0000000D;
    border: 1px solid #E8E8E8;
    border-radius: 10px;
    padding: 20px;
    margin-top: 4%;
}

.payment-tab .purple-bg-payment {
    text-align: center;
    background: #1D44AD;
    border-radius: 10px;
    color: #FFFFFF;
    font-size: 18px;
    padding-top: 17px;
}

.payment-tab .purple-bg-payment p {
    color: #FFF;
    font-size: 18px;
    border-right: 1px solid #FFF;
}

.payment-tab .light-blue-bg {
    background: #DFE8FF;
    border-radius: 5px;
    text-align: start;
    margin-top: 8%;
}

.payment-tab .feature-txtt {
    font-size: 18px;
    font-weight: 600;
    padding: 13px;
}

.payment-tab .brown-bg-check {
    background: #ECECEC;
    border-radius: 5px;
    margin-top: 8%;

}

.payment-tab .brown-bg-check .check-img {
    height: 26px;
    width: 26px;
    margin: 14px;
}


/* Desktop CSS End */


/* Mobile Tab CSS Start */
.mobile-payment-tab {
    background: #FFFFFF;
    box-shadow: 0px 0px 20px #0000000D;
    border: 1px solid #E8E8E8;
    padding: 20px;
    margin-top: 4%;
}

.mobile-payment-tab .table-heading {
    border-radius: 10px;
    border: 20px solid #FFF;
}

.mobile-payment-tab .purple-bg-payment {
    text-align: center;
    background: #1D44AD;
    color: #FFFFFF;
    font-size: 18px;
    padding: 17px 0px;
    border-right: 1px solid #FFF;
}

.mobile-payment-tab .purple-bg-payment p {
    color: #FFF;
    font-size: 18px;
    padding-top: 17px;
    border-right: 1px solid #FFF;
}

.mobile-payment-tab .light-blue-bg {
    background: #DFE8FF;
    text-align: start;
    margin-top: 8%;
    font-size: 18px;
    font-weight: 400;
    width: 25%;
    padding-left: 20px;
}

.mobile-payment-tab .brown-bg-check {
    background: #ECECEC;
    border: 20px solid #fff;
}

.mobile-payment-tab tbody {
    border: 20px solid #fff;
}

.mobile-payment-tab .brown-bg-check .check-img {
    height: 26px;
    width: 26px;
    margin: 14px;
}

.mobile-payment-tab tr {
    border: 1px solid #DFE8FF;
}

/* Mobile Tab CSS Start */


/* Mobile css */

@media(min-width:0px) and (max-width:576px) {
    .sitebuilder .nav-pills .nav-link {
        font-size: 10px;
    }

    .sitebuilder .nav-pills .nav-link.active {
        font-size: 10px;
    }

    .mobile-payment-tab .purple-bg-payment {
        font-size: 14px;
    }

    .mobile-payment-tab .light-blue-bg {
        font-size: 12px;
    }

    .mobile-payment-tab .brown-bg-check .check-img {
        height: 16px;
        width: 16px;
    }
}

@media(min-width:577px) and (max-width:768px) {
    .sitebuilder .nav-pills .nav-link {
        font-size: 13px;
    }

    .sitebuilder .nav-pills .nav-link.active {
        font-size: 13px;
    }

    .mobile-payment-tab .light-blue-bg {
        font-size: 14px;
    }

    .mobile-payment-tab .brown-bg-check .check-img {
        height: 20px;
        width: 20px;
    }
}

@media(min-width:769px) and (max-width:1024px) {
    .sitebuilder .nav-pills .nav-link {
        font-size: 14px;
    }

    .sitebuilder .nav-pills .nav-link.active {
        font-size: 14px;
    }
         .sitebuilder .nav-pills .nav-link {
             font-size: 13px;
         }
    
         .sitebuilder .nav-pills .nav-link.active {
             font-size: 15px;
         }
    
         .mobile-payment-tab .light-blue-bg {
             font-size: 16px;
         }
    
         .mobile-payment-tab .brown-bg-check .check-img {
             height: 24px;
             width: 24px;
         }
}