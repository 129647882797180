.SuggestedProductSlider .SuggestedProductSlider-card .product-img {
    width: 150px;
    height: 150px;
}

.SuggestedProductSlider .SuggestedProductSlider-card p {
    font-size: 12px;
    color: #000000;
    font-weight: 500;
    margin: 0px;
}

.SuggestedProductSlider .SuggestedProductSlider-card h6 {
    font-size: 18px;
    color: #000000;
    font-weight: bold;
}
.SuggestedProductSlider .SuggestedProductSlider-card {
    text-align: center;
    justify-content: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 14px #00000012;
    border-radius: 10px;
    opacity: 1;
    margin: 10px;
    padding: 10px;
}
.SuggestedProductSlider .swiper {
    padding-bottom: 20px;
}
.SuggestedProductSlider  .swiper-pagination-bullet-active {

    background: grey;
}