.Jp-home-testimonial {
  background-image: url("../../../../../public/assest/images/Seller_panel/seller-image/banner/triumps-banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 730px;
  width: 100%;
  position: relative;
}

.Jp-home-testimonial .heading {
  position: absolute;
  top: 17%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Jp-home-testimonial {
  margin: 4% 0;
}

.swiper.swiper-coverflow.swiper-3d.swiper-initialized.swiper-horizontal.swiper-watch-progress.mySwiper {
  height: 520px;
}

.Jp-home-testimonial .heading h4 {
  font-size: 30px;
  font-weight: bold;
  color: #000;
  margin-bottom: 11px;
  font-weight: 600;
}

.Jp-home-testimonial .heading p {
  font-size: 16px;
  color: #000;
  margin-bottom: 3px;
}

.Jp-home-testimonial .jp-home-slider {
  margin-top: 12%;
}

.Jp-home-testimonial .jp-home-slider .swiper-slide {
  transform: translate3d(0px, 67px, 0px) rotateX(0deg) rotateY(0deg) scale(1) !important;
}

.Jp-home-testimonial .jp-home-slider .swiper-slide-active {
  transform: translate3d(-3px, 10px, 66px) scale(1.1) !important;
}

.Jp-home-testimonial.swiper.swiper-coverflow.swiper-3d.swiper-initialized.swiper-horizontal.swiper-watch-progress.mySwiper {
  height: 600px;
}

.Jp-home-testimonial .swiper-wrapper {
  margin-top: 4%;
}

.Jp-home-testimonial .jp-home-slider .swiper-slide-active .main-card-div .right-div {
  width: 100%;
  height: 244px;
  position: relative;
}

.Jp-home-testimonial .jp-home-slider .swiper-slide-active .main-card-div .pararr p{
  color: #fff !important;
}

.Jp-home-testimonial .jp-home-slider .swiper-slide-prev .main-card-div p {
  color: #8a8a8a !important;
}

.Jp-home-testimonial .jp-home-slider .swiper-slide-next .main-card-div p {
  color: #8a8a8a !important;
}

.Jp-home-testimonial .jp-home-slider .swiper-pagination-bullets {
  display: none;
}

.Jp-home-testimonial .jp-home-slider .swiper-slide-active .main-card-div .right-div {}

.Jp-home-testimonial .jp-home-slider .main-card-div .left-div {
  background: #ffcf8f70;
  box-shadow: 0px 0px 10px #00000029;
  /* opacity: 0.1; */
  width: 330px;
  position: relative;
}

.Jp-home-testimonial .jp-home-slider .main-card-div .left-div .main-card-div {
  width: 100%;
}

.Jp-home-testimonial .jp-home-slider .main-card-div .left-div .colan-img-div {
  position: absolute;
  top: 0px;
}

.Jp-home-testimonial .jp-home-slider .main-card-div .left-div .col-img {
  width: 100%;
}

.Jp-home-testimonial .jp-home-slider .main-card-div {
  width: 500px;
  height: 192px;
  display: flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #0000005e;
  opacity: 1;
  border-radius: 10px;
  position: relative;
}

.Jp-home-testimonial .jp-home-slider .main-card-div .right-div {
  font-size: 14px;
  font-weight: 500;
  color: black;
  margin: auto;
  padding: 5px;
}

.Jp-home-testimonial .jp-home-slider .main-card-div .right-div p {
  font-size: 14px;
  color: #000;
  font-weight: 500;
}

.Jp-home-testimonial .jp-home-slider .main-card-div .right-div h6 {
  font-size: 18px;
  color: #fff;
  font-weight: bold;
}

.Jp-home-testimonial .jp-home-slider .main-card-div .inner-div {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Jp-home-testimonial .jp-home-slider .main-card-div .inner-div .man-img {
  width: 100%;
  padding-left: 10px;
}

.Jp-home-testimonial .jp-home-slider .main-slider .arrow-back,
.Jp-home-testimonial .jp-home-slider .main-slider .arrow-next {
  cursor: pointer;
}

.Jp-home-testimonial .jp-home-slider .main-slider .arrow-back {
  width: 8px;
}

.Jp-home-testimonial .jp-home-slider .main-slider .main-arrow-next {
  position: absolute;
  right: 46%;
  z-index: 1;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  transform: translate(-50%, 0%);
  box-shadow: 0px 0px 13px #0000001c;
}

.Jp-home-testimonial .jp-home-slider .main-slider .main-arrow-back {
  position: absolute;
  left: 46%;
  z-index: 2;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  transform: translate(-50%, 0px);
  box-shadow: 0px 0px 13px #0000001c;
}

.Jp-home-testimonial .jp-home-slider .main-slider .arrow-next {
  width: 8px;
}

.Jp-home-testimonial .jp-home-slider .main-slider {
  position: relative;
}

.Jp-home-testimonial .jp-home-slider .swiper-slide-active .main-card-div {
  background-image: url("../../../../../public/assest/images/Seller_panel/seller-image/banner/Sellerbanner.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 3%;
  position: relative;
}

.Jp-home-testimonial .jp-home-slider .swiper-slide-prev .main-card-div .right-div p {
  color: #000;
}

.Jp-home-testimonial .swiper-slide-active .imgSecslider .imgprofSide {
  width: 88px;
  height: 88px;
  border-radius: 50%;
}

.Jp-home-testimonial .swiper-slide-prev .imgSecslider .imgprofSide {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

.Jp-home-testimonial .swiper-slide-next .imgSecslider .imgprofSide {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

.Jp-home-testimonial .imgSecslider .imgprofSide {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

.Jp-home-testimonial .swiper-slide-active .imgSecslider p {
  color: #000 !important;
  font-size: 14px;
}

.Jp-home-testimonial .swiper-slide-prev .imgSecslider p,
.Jp-home-testimonial .swiper-slide-next .imgSecslider p {
  color: #000 !important;
  font-size: 12px;
}

.Jp-home-testimonial .swiper-slide-active .imgSecslider {
  position: absolute;
  bottom: -74px;
  left: 50%;
  transform: translate(-50%, 10px);
}

.Jp-home-testimonial .swiper-slide-prev .imgSecslider {
  width: 44px;
  height: 44px;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, 10px);
}

.Jp-home-testimonial .swiper-slide-next .imgSecslider {
  width: 44px;
  height: 44px;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, 10px);
}

.Jp-home-testimonial .swiper-wrapper {
  height: 360px;
}

.swiper.swiper-coverflow.swiper-3d.swiper-initialized.swiper-horizontal.swiper-watch-progress.mySwiper {
  text-align: -webkit-center;
}

@media (min-width: 0px) and (max-width: 576px) {
  .Jp-home-testimonial .jp-home-slider .swiper-slide-active {
    transform: scale(1) !important;
    padding: 3% 1%;
    margin: 0px 0px;
  }

  .Jp-home-testimonial .jp-home-slider .swiper-slide-active .main-card-div {
    width: 100%;
    height: 100%;
  }

  .Jp-home-testimonial .jp-home-slider .main-slider .main-arrow-next {
    display: none;
  }

  .Jp-home-testimonial .jp-home-slider .main-slider .main-arrow-back {
    display: none;
  }

  .Jp-home-testimonial .jp-home-slider .main-card-div .right-div p {
    font-size: 13px;
  }

  .Jp-home-testimonial .heading h4 {
    font-size: 25px;
  }

  .Jp-home-testimonial .heading p {
    font-size: 15px;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .Jp-home-testimonial .heading h4 {
    font-size: 25px;
  }

  .Jp-home-testimonial .heading p {
    font-size: 15px;
  }

  .Jp-home-testimonial .jp-home-slider .swiper-slide-active {
    transform: scale(1) !important;
    padding: 0% 1%;
  }

  .Jp-home-testimonial .jp-home-slider .swiper-slide-active .main-card-div {
    width: 100%;
    height: 100%;
  }

  .Jp-home-testimonial .jp-home-slider .main-slider .main-arrow-next {
    top: -131px;
    right: -24px;
    width: 20px;
    height: 20px;
  }

  .Jp-home-testimonial .jp-home-slider .main-slider .main-arrow-back {
    top: -131px;
    left: -24px;
    width: 20px;
    height: 20px;
  }

  .Jp-home-testimonial .jp-home-slider .main-card-div .right-div p {
    font-size: 13px;
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  .Jp-home-testimonial .jp-home-slider .swiper-slide-active .main-card-div {
    width: 138%;
    height: 375px;
  }

  .Jp-home-testimonial .jp-home-slider .swiper-slide-active {
    transform: translate3d(-60px, -4px, 0px) scale(1.1) !important;
    padding: 10% 0%;
  }

  .Jp-home-testimonial .jp-home-slider .main-slider .main-arrow-next {
    top: -287px;
    right: -33px;
  }

  .Jp-home-testimonial .jp-home-slider .main-slider .main-arrow-back {
    top: -287px;
    left: -33px;
  }

  .Jp-home-testimonial .jp-home-slider .main-card-div .left-div {
    width: 487px;
  }

  .Jp-home-testimonial .jp-home-slider .main-card-div .right-div p {
    font-size: 13px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {

  /* .Jp-home-testimonial .jp-home-slider .swiper-slide {
        transform: translate3d(0px, 102px, -194px) rotateX(0deg) rotateY(0deg) scale(1.5) !important;
    }

    .Jp-home-testimonial .jp-home-slider .swiper-slide-active {
        transform: scale(1.8) !important;
    }

    .Jp-home-testimonial .swiper-video .video-border {
        height: 6px;

    }

    .Jp-home-testimonial .jp-home-slider .arrow-next::before {
        right: 82px;
    }

    .Jp-home-testimonial .jp-home-slider .arrow-back::after {
        left: 82px;
    }
    .Jp-home-testimonial .heading h4 {
        font-size: 25px;
    } */
  .Jp-home-testimonial .jp-home-slider .swiper-slide-active {
    transform: translate3d(-90px, 6px, 0px) scale(1.1) !important;
    padding: 7% 0px;
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .Jp-home-testimonial .jp-home-slider .swiper-slide-active {
    transform: translate3d(-94px, 16px, 0px) scale(1.1) !important;
  }

  .Jp-home-testimonial .jp-home-slider .main-slider .main-arrow-next {
    top: -198px;
  }

  .Jp-home-testimonial .jp-home-slider .main-slider .main-arrow-back {
    top: -198px;
  }
}