.Beauty_Slider .Beauty_Swiper {
  padding-bottom: 4%;
  padding-left: 19px;
}

.Beauty_Slider .Beauty_Swiper .swiper-pagination-bullet-active {
  background: #000;
}

.Beauty_Slider .Beauty_Swiper .Beauty-img-holder {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 14px #00000012;
  height: 195px;
  padding: 10px 20px;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out; /* Animation */
}

.Beauty_Slider .Beauty_Swiper .Beauty-img-holder:hover .Beauty-img {
  transition: transform 0.3s ease-in-out; /* Animation */
  transform: scale(1.1);
}

.Beauty_Slider .Beauty_Swiper .Beauty-img-holder:hover {
  background: #fff7ef 0% 0% no-repeat padding-box;
}

.Beauty_Slider .Beauty_Swiper .swiper-pagination-bullet-active {
  background: #000;
  width: 10px !important;
  height: 2px;
  border-radius: 0px;
}

.Beauty_Slider .Beauty_Swiper .swiper-pagination-bullet {
  width: 15px;
  height: 2px;
  border-radius: 0px;
  margin: 0px 1px;
}

.Beauty_Slider .Beauty_Swiper .Beauty-img-holder .Beauty-img-img .Beauty-img {
  width: 100%;
  height: 110px;
  object-fit: contain;
}

.Beauty_Slider .Beauty_Swiper .Beauty-img-holder h4 {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  text-align: center;
  margin-bottom: 20%;
}

.Beauty_Slider .Beauty_Swiper .Beauty-img-holder .button-holder {
  text-align: center;
  margin: 15px 0%;
}

.Beauty_Slider .Beauty_Swiper .Beauty-img-holder .button-holder .sell-btn {
  border-radius: 19px;
  color: #000;
  font-size: 11px;
  font-weight: 500;
  background: #68d1ff 0% 0% no-repeat padding-box;
  border-radius: 17px;
  padding: 3px 10px;
}

.Beauty_Slider
  .Beauty_Swiper
  .Beauty-img-holder
  .button-holder
  .sell-btn:focus {
  box-shadow: none;
}

.Beauty_Slider
  .Beauty_Swiper
  .Beauty-img-holder
  .button-holder
  .sell-btn:hover {
  background-color: #68d1ff;
}

.Beauty_Slider .Beauty_Swiper .half-width-button-container .view-more-button {
  background: #f88d28 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 14px #00000012;
  border-radius: 10px;
  height: 195px;
  border: none;
  width: 50%;
  color: #fff;
}

.Beauty_Slider .Beauty_Swiper .half-width-button-container {
  display: flex;
  justify-content: end;
  align-items: center;
}

@media (min-width: 0px) and (max-width: 320px) {
  .Beauty_Slider .Beauty_Swiper .Beauty-img-holder h4 {
    font-size: 11px;
  }

  .Beauty_Slider .Beauty_Swiper .Beauty-img-holder {
    height: 150px;
    margin-bottom: 25px;
  }

  .Beauty_Slider .Beauty_Swiper .Beauty-img-holder .Beauty-img {
    height: 100px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Beauty_Slider .Beauty_Swiper .Beauty-img-holder h4 {
    font-size: 15px;
    margin-bottom: 10%;
  }

  .Beauty_Slider .Beauty_Swiper .Beauty-img-holder {
    height: 150px;
  }

  .Beauty_Slider .Beauty_Swiper .Beauty-img-holder .Beauty-img-img .Beauty-img {
    width: 100%;
    height: 80px;
    object-fit: contain;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Beauty_Slider .Beauty_Swiper .Beauty-img-holder h4 {
    font-size: 15px;
    margin-bottom: 10%;
  }

  .Beauty_Slider .Beauty_Swiper .Beauty-img-holder {
    height: 150px;
  }

  .Beauty_Slider .Beauty_Swiper .Beauty-img-holder .Beauty-img-img .Beauty-img {
    width: 100%;
    height: 80px;
    object-fit: contain;
  }
}

@media (min-width: 576px) and (max-width: 675px) {
  .Beauty_Slider .Beauty_Swiper .Beauty-img-holder h4 {
    font-size: 15px;
    margin-bottom: 10%;
  }

  .Beauty_Slider .Beauty_Swiper .Beauty-img-holder {
    height: 150px;
  }

  .Beauty_Slider .Beauty_Swiper .Beauty-img-holder .Beauty-img-img .Beauty-img {
    width: 100%;
    height: 80px;
    object-fit: contain;
  }
}

@media (min-width: 676px) and (max-width: 765px) {
  .Beauty_Slider .Beauty_Swiper .Beauty-img-holder h4 {
    font-size: 15px;
    margin-bottom: 10%;
  }

  .Beauty_Slider .Beauty_Swiper .Beauty-img-holder {
    height: 150px;
  }

  .Beauty_Slider .Beauty_Swiper .Beauty-img-holder .Beauty-img-img .Beauty-img {
    width: 100%;
    height: 80px;
    object-fit: contain;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Beauty_Slider .Beauty_Swiper {
    padding-bottom: 10%;
  }
}
