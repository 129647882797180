.Empower_banner-sec {
  position: relative;
  margin: 20px;
}

.Empower_banner-sec .kjbhimg {
  width: 100%;
  border-radius: 8px;
}

.Empower_banner-sec .blnere-overlay {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: #0000005c;
  border-radius: 8px;
}

.Empower_banner-sec .overlay-conttt {
  position: absolute;
  top: 35%;
  width: 95%;
  margin: auto;
  left: 30px;
}

.Empower_banner-sec .overlay-conttt .baner-trir {
  font-weight: normal;
  font-size: 20px;
  color: #fff;
  margin-bottom: 0px;
}

.Empower_banner-sec .overlay-conttt .view-demo-btn {
  color: #fff;
  font-size: 14px;
  padding: 3px 8px;
  border-radius: 50px;
  background-color: transparent;
  border: 1px solid #fff;
  margin: 0px 5px;
}

.Empower_banner-sec .overlay-conttt a {
  color: #fff;
}

.Empower_banner-sec .overlay-conttt .list-product-btn {
  color: #fff;
  font-size: 14px;
  padding: 3px 8px;
  border-radius: 50px;
  background-color: #f88d28;
  border: 1px solid #f88d28;
  margin: 0px 5px;
}

@media (min-width: 577px) and (max-width: 768px) {
  .Empower_banner-sec .kjbhimg {
    height: 144px;
  }

  .Empower_banner-sec .overlay-conttt .baner-trir {
    font-size: 15px;
  }

  .Empower_banner-sec .overlay-conttt a {
    font-size: 9px;
    padding: 6px;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .Empower_banner-sec .kjbhimg {
    height: 144px;
  }

  .Empower_banner-sec .overlay-conttt .baner-trir {
    font-size: 15px;
  }

  .Empower_banner-sec .overlay-conttt a {
    font-size: 9px;
    padding: 6px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .Empower_banner-sec .kjbhimg {
    height: 150px;
  }

  .Empower_banner-sec .overlay-conttt .baner-trir {
    font-size: 17px;
  }

  .Empower_banner-sec .overlay-conttt a {
    padding: 6px;
  }
}

@media (min-width: 1201px) and (max-width: 1399px) {
  .Empower_banner-sec .kjbhimg {
    height: 150px;
  }

  .Empower_banner-sec .overlay-conttt .baner-trir {
    font-size: 17px;
  }

  .Empower_banner-sec .overlay-conttt a {
    padding: 6px;
  }
}
