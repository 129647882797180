.com-sidebar {
    background: #121C38;
    padding: 0px 15px;
    box-shadow: 0px 3px 19px #0000001A;
    height: 100%;
}

.com-sidebar h1 {
    font-size: 20px;
    color: #FFF;
    font-weight: 500;
    padding: 9px 19px;
}

.com-sidebar .brown-border {
    border-bottom: 2px solid #BCBCBC;
}

.com-sidebar .accordion-button {
    font-size: 14px;
    color: #FFF;
    text-align: center;
    border: none;
    background-color: transparent;
    margin-top: 4%;
    padding: 9px 17px;
    width: 85%;
}

.com-sidebar .accordion-button:focus {
    box-shadow: none;
}

.com-sidebar .accordion .accordion-arrow {
    display: none;
}

.com-sidebar .accordion-button::after {
    background-image: none;
}

.com-sidebar .accordion .accordion-item {
    background-color: transparent;
    border: none;
    border-radius: 50px;
    outline: none;

}

.com-sidebar .accordion-button:not(.collapsed) {
    box-shadow: none;
    background: #F88D28 0% 0% no-repeat padding-box;
    border-radius: 17px;
    color: #FFF;
    font-size: 14px;
    padding: 9px 17px;
    width: 85%;
}

.com-sidebar .accordion-item:first-of-type>.accordion-header .accordion-button {
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
}

@media(min-width:768px) and (max-width:992px) {
    .com-sidebar .accordion-button:not(.collapsed) {
        font-size: 12px;
        padding: 7px 7px;
        width: 100%;
    }

    .com-sidebar .accordion-button {
        font-size: 12px;
        padding: 7px 7px;
        width: 100%;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .com-sidebar .accordion-button:not(.collapsed) {
        font-size: 13px;
        padding: 7px 7px;
        width: 100%;
    }

    .com-sidebar .accordion-button {
        font-size: 13px;
        padding: 7px 7px;
        width: 100%;
    }
}

@media(min-width:1200px) and (max-width:1400px) {
    .com-sidebar .accordion-button:not(.collapsed) {
        font-size: 12px;
        padding: 7px 4px;
        width: 100%;
    }

    .com-sidebar .accordion-button {
        font-size: 12px;
        padding: 7px 4px;
        width: 100%;
    }
}

@media(min-width:1400px) and (max-width:1700px) {
    .com-sidebar .accordion-button:not(.collapsed) {

        padding: 7px 7px;
        width: 100%;
    }

    .com-sidebar .accordion-button {

        padding: 7px 7px;
        width: 100%;
    }
}