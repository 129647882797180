.reason-slider .main-img-reason .reason-img {
    overflow: hidden;
    height: 57px;
    width: 65px;
    margin-top: 10%;
    box-sizing: border-box;
    display: block;
}

.reason-slider .reason-card .main-img-reason .reason-img {
    position: relative;
    background: no-repeat;
    width: 57px;
    display: block;
    transition: 0.4s;
    margin: auto;
    margin-top: 10%;
}

.reason-slider .reason-card:hover .main-img-reason .reason-img {
    transform: scale(1.1);
    filter: invert(74%) sepia(31%) saturate(6116%) hue-rotate(1deg) brightness(103%) contrast(106%);
    box-shadow: unset !important;
    background: transparent;
}

.reason-slider .card-text-first {
    font-size: 16px;
    font-weight: 700;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
}

.reason-slider .card-text-second {
    font-size: 14px;
    text-align: justify;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4;
}


.reason-slider .swiper-pagination-bullet {
    width: 13px;
    height: 4px;
    border-radius: 5px;
    opacity: 1;
    background-color: #000;
}

.reason-slider .swiper-pagination-bullet-active {
    width: 25px;
    background: #FF7C00;
}

.reason-slider .reason-card {
    background: #FFFFFF;
    box-shadow: 0px 0px 3px #00000029;
    border: none;
    border-radius: 5px;
    height: 270px;
}

.Plan-Btn .primary {
    box-shadow: 0px 0px 3px #00000029;
    border: 0.5px solid #707070;
    border-radius: 19px;
    color: black;
    padding: 6px 27px;
    background-color: white;
}

.Plan-Btn:hover .primary {
    box-shadow: 0px 0px 3px #FF7C00;
    background-color: #FF7C00;
    border: 0.5px solid transparent;
    border-radius: 19px;
    color: white;
    padding: 6px 27px;
}

.Plan-Btn {
    margin-top: -29px;
}

.HomeReason {
    margin-top: 5%;
}

/* Media */
@media(min-width:0px) and (max-width:576px) {
    .reason-slider .card-text-first {
        font-size: 14px;
    }

    .reason-slider .card-text-second {
        font-size: 12px;
    }
}

@media(min-width:577px) and (max-width:767px) {
    .reason-slider .card-text-first {
        font-size: 15px;
    }

    .reason-slider .card-text-second {
        font-size: 13px;
    }
}