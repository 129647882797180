.Product_total_seccc {
    background: #f2f2f2;
    padding: 25px 10px;
    border-radius: 5px;
}

.Product_total_seccc .payimg-div {
    min-width: 90px;
}

.Product_total_seccc .payimg-div img {
    width: 85px;
    height: auto;
}

.Product_total_seccc .proddd-nnnn .prood-nname {
    font-weight: 600;
    font-size: 14px;
    color: #000;
    line-height: 20px;
}

.Product_total_seccc .sizeeppp {
    font-weight: 500;
    font-size: 12px;
    color: #000;
}

.Product_total_seccc .totallprrr .hghdg {
    font-weight: normal;
    font-size: 12px;
    color: #000;
    margin-bottom: 0px;
}

.Product_total_seccc .protect-odd-div {
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    margin-top: 15px;
}

.Product_total_seccc .protect-odd-div .prod-h6{
    font-weight: 600;
    font-size: 14px;
    color: #000;
    padding-left: 20px;
}
.Product_total_seccc .protect-odd-div ul{
    padding-left: 20px;
}
.Product_total_seccc .protect-odd-div ul li{
    font-weight: 600;
    font-size: 14px;
    color: #000;
}
.Product_total_seccc .protect-odd-div ul p{
    font-weight: normal;
    font-size: 12px;
    color: #000;
    margin-bottom: 10px;
}