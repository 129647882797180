.MyprofileSec h1.account-text {
    font-size: 32px;
    font-weight: bold;
}

.MyprofileSec .b2bprofileCard {
    background: #FFFFFF;
    box-shadow: 0px 3px 14px #00000012;
    border: none;
    border-radius: 10px;
    margin: 17px;
}

.MyprofileSec .scrollable-content-div .nav-link.active #sample-code2 {
    border: 2px solid #F88D28;
    /* Adjust border thickness and color as needed */
}

.MyprofileSec {
    padding-top: 3%;
    margin-bottom: 10%;
    background-image: url("../../../../../public/assest/images/B2Bbuyer/Accounts/dashboard-bg.png");
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.MyprofileSec .scrollable-content-div {
    margin-top: 4%;
    position: relative;
}

.MyprofileSec .b2bprofileCard .personalDetail h4 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
}

.MyprofileSec .acounttab-stick {
    /* position: sticky;
    z-index: 999; */
    /* background-color: #FFFFFF; */

}

.MyprofileSec .middle-div {
    height: 800px;
    overflow-y: scroll;
}

.MyprofileSec .scrollable-content-div {
    /* overflow: auto;
    height: 300px;
    margin-bottom: 21px; */
}

/* .MyprofileSec .middle-div {
    height: 430px;
    overflow-y: scroll;
} */
.MyprofileSec .list-group-item {
    border: 0 !important;
}

.MyprofileSec .b2bprofileCard .Innernamee {
    margin-bottom: 15px;
}

.MyprofileSec .b2bprofileCard .Innernamee h3 {
    font-size: 14px;
    font-weight: 500;
}

.MyprofileSec .b2bprofileCard .Innernamee p {
    font-size: 11px;
    color: #636363;
    margin-bottom: 6px;
}

.MyprofileSec .b2bprofileCard .Innernamee .img-div .pdf-img {
    width: 100%;
    height: 130px;
}

.MyprofileSec .b2bprofileCard .Innernamee .img-div {
    position: relative;
}

.MyprofileSec .b2bprofileCard .Innernamee .img-div .view-icon {
    position: absolute;
    background: transparent;
    border: none;
    font-size: 9px;
    right: -2px;
    /* color: grey; */
}

.MyprofileSec .accountbanner {
    width: 100%;
}

.MyprofileSec .scrollable-content-div .nav-link {
    display: flex;
    justify-content: space-between;
    width: 162px;
}

.MyprofileSec .scrollable-content-div .nav-link h6 {
    font-weight: 400;
    font-size: 14px;
}


.MyprofileSec .scrollable-content-div .nav-link.active {

    color: #1D44AD;
}

.MyprofileSec .scrollable-content-div .b2bprofileCard {
    position: relative;
}

.MyprofileSec .scrollable-content-div .edit-btn {
    position: absolute;
    right: 5px;
    background-color: transparent;
    border: none;
    color: grey;
    top: 5px;
    font-size: 12px;
}

.MyprofileSec .scrollable-content-div .border-div {
    border-bottom: 1px solid black;
    border-top: 1px solid black;
    padding: 13px 0px 6px 0px;
}

.MyprofileSec .scrollable-content-div p.small-heading-text {
    font-size: 12px;
    font-weight: 500;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
    .MyprofileSec .acounttab-stick {
        position: static;
        margin: 90px 20px 30px 20px;
    }

    .MyprofileSec .accountbanner {

        margin-top: 28px;
    }
}

@media screen and (min-width: 0px) and (max-width: 576px) {
    .MyprofileSec h1.account-text {
        font-size: 16px;
        font-weight: bold;
    }

    .MyprofileSec .scrollable-content-div .nav-link h6 {
        font-size: 12px;
    }

    .MyprofileSec .acounttab-stick {
        margin: 135px 20px 30px 20px;
    }

    .MyprofileSec .b2bprofileCard .personalDetail h4 {
        font-size: 13px;
    }

    .MyprofileSec .b2bprofileCard .Innernamee p {
        font-size: 10px;
    }

    .MyprofileSec .b2bprofileCard .Innernamee h3 {
        font-size: 13px;
    }

}

@media screen and (min-width: 577px) and (max-width: 768px) {
    .MyprofileSec h1.account-text {
        font-size: 18px;
    }

    .MyprofileSec .scrollable-content-div .nav-link h6 {
        font-size: 12px;
    }
}

@media screen and (min-width: 769px) and (max-width: 992px) {


    .MyprofileSec h1.account-text {
        font-size: 23px;
    }

    .MyprofileSec .scrollable-content-div .nav-link h6 {
        font-size: 13px;
    }

}