.plan-pricing {
    background-image: url('../../../../../public/assest/images/MyWebsite/Pricing/purple-bg.png');
    padding: 60px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 25px;
}

.plan-pricing .pricing-title h3 {
    font-size: 28px;
    color: #FFF;
}

.plan-pricing .pricing-white-bg {
    background: #FFF;
    border-radius: 25px;
    text-align: center;
    padding: 15px;
}

.plan-pricing .pricing-white-bg .purple-dot {
    height: 15px;
    width: 15px;
    background: #1D44AD;
    border-radius: 50px;
    margin: auto;
}

.plan-pricing .pricing-white-bg .plan-txt {
    background: #dde0e5;
    padding: 5px;
    font-size: 14px;
    color: #000;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
}

.plan-pricing .pricing-white-bg .plan-txt-number {
    font-size: 25px;
    color: #000;
    font-weight: 600;
}

.plan-pricing .pricing-white-bg .start-demo {
    border-radius: 50px;
    background: #F88D28;
    color: #FFF;
    font-size: 14px;
    border: none;
    width: 100%;
    padding: 10px 20px;
}

.plan-pricing .pricing-white-bg .start-demo {
    border-radius: 50px;
    border: 1px solid #F88D28;
    background: #F88D28;
    color: #FFF;
    font-size: 14px;
    border: none;
    width: 100%;
    padding: 10px 20px;
}
.plan-pricing .pricing-white-bg .start-demo:hover {
    background: transparent linear-gradient(180deg, #F88D28 0%, #CE6500 100%) 0% 0% no-repeat padding-box;
    border-radius: 19px;
}
.plan-pricing .pricing-white-bg .more-info {
    border-radius: 50px;
    background: transparent;
    color: #F88D28;
    font-size: 14px;
    border: 1px solid #000000;
    width: 100%;
    padding: 10px 20px;
}

.plan-pricing .pricing-white-bg .more-info:hover {
    color: #000;
    font-size: 14px;
    text-decoration: underline;
}

.plan-pricing .main-slider {
    position: relative;
}

.plan-pricing .main-slider .arrow-back {
    content: url("../../../../../public/assest/images/MyWebsite/Pricing/back.png");
    position: absolute;
    top: -303px;
    left: -52px;
    z-index: 2;
    cursor: pointer;
    height: 42px;
}

.plan-pricing .main-slider .arrow-next {
    content: url("../../../../../public/assest/images/MyWebsite/Pricing/next.png");
    position: absolute;
    top: -303px;
    z-index: 2;
    cursor: pointer;
    height: 42px;
    right: -52px;
}

/* Media */
@media(min-width:0px) and (max-width:576px) {
    .plan-pricing .pricing-title h3 {
        font-size: 24px;
    }

    .plan-pricing {
        padding: 25px;
    }

    .plan-pricing .pricing-white-bg .plan-txt {
        font-size: 12px;
    }

    .plan-pricing .pricing-white-bg .plan-txt-number {
        font-size: 18px;
    }

    .plan-pricing .pricing-white-bg .start-demo {
        font-size: 12px;
    }

    .plan-pricing .pricing-white-bg .more-info {
        font-size: 12px;
    }

    .plan-pricing .main-slider .arrow-back {
        top: -251px;
        height: 26px;
        left: -25px;
    }

    .plan-pricing .main-slider .arrow-next {
        top: -251px;
        height: 26px;
        right: -25px;
    }
}

@media(min-width:577px) and (max-width:767px) {
    .plan-pricing .pricing-title h3 {
        font-size: 24px;
    }

    .plan-pricing {
        padding: 25px;
    }

    .plan-pricing .pricing-white-bg .plan-txt {
        font-size: 12px;
    }

    .plan-pricing .pricing-white-bg .plan-txt-number {
        font-size: 18px;
    }

    .plan-pricing .pricing-white-bg .start-demo {
        font-size: 12px;
    }

    .plan-pricing .pricing-white-bg .more-info {
        font-size: 12px;
    }
}