.CreateAccountSeccseccfullyModal .modal-header {
  border-bottom: 0px solid #dee2e6;
}

.CreateAccountSeccseccfullyModal .modal-content {
  border: 0px;
  border-radius: 9px;
  box-shadow: 0px 0px 5px 0px #ccc;
}

.CreateAccountSeccseccfullyModal .modal-title h4 {
  font-weight: 500;
  font-size: 16px;
  color: #000;
  text-align: center;
  margin-bottom: 5%;
}

.CreateAccountSeccseccfullyModal .modal-title h2 {
  font-weight: 500;
  font-size: 30px;
  color: #f88d28;
  text-align: center;
}

.CreateAccountSeccseccfullyModal .modal-title p {
  font-weight: 500;
  font-size: 15px;
  color: #575757;
  text-align: center;
}


.CreateAccountSeccseccfullyModal .lotty-div .vdo-btn {
  height: 100px;
  width: 100px;
  margin: 0px auto;
}