.moddd.OffcanvasAddtocard {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 17px #00000030;
  border-radius: 10px 0px 0px 10px;
  border: 0;
  width: 450px;
}

.OffcanvasAddtocard .offcanvas-title {
  font-size: 16px;
}

.OffcanvasAddtocard .offcanvas-header {
  border-bottom: 1px solid #ccc;
}

.OffcanvasAddtocard .OffAddtocart {
  border-bottom: 1px solid #ccc;
  padding-bottom: 39px;
  margin-bottom: 33px;
}

.OffcanvasAddtocard .OffAddtocart .ProdpricebeforeShip h3 {
  font-size: 16px;
  font-weight: 500;
}

.OffcanvasAddtocard .OffAddtocart .ProdpricebeforeShip p {
  font-size: 14px;
}

.OffcanvasAddtocard .OffAddtocart .ProdpricebeforeShip h2 {
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
}

.OffcanvasAddtocard .OffAddtocart .Colourhead h3 {
  font-size: 16px;
  margin-top: 15px;
}

.OffcanvasAddtocard .OffAddtocart .qty-input {
  color: #000;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.OffcanvasAddtocard .OffAddtocart .qty-input .product-qty {
  width: 29px;
  min-width: 0;
  display: inline-block;
  text-align: center;
  appearance: textfield;
}

.OffcanvasAddtocard .OffAddtocart .qty-input .qty-count {
  padding: 0;
  cursor: pointer;
  width: 2.0rem;
  font-size: 1.25em;
  text-indent: -100px;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  border: 0.800000011920929px solid #c7c7c7;
  border-radius: 2px;
}

.OffcanvasAddtocard .OffAddtocart .qty-input .qty-count:before,
.OffcanvasAddtocard .OffAddtocart .qty-input .qty-count:after {
  content: "";
  height: 2px;
  width: 10px;
  position: absolute;
  display: block;
  background: #000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.OffcanvasAddtocard .OffAddtocart .colourTextsec {
  display: flex;
}

.OffcanvasAddtocard .OffAddtocart .Coloursec .qty-input .qty-count--add:after {
  transform: rotate(90deg);
}

.OffcanvasAddtocard .colourImg img {
  width: 44px;
  height: 44px;
}

.OffcanvasAddtocard .OffAddtocart .colourTextsec p {
  margin-bottom: 0;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
}

.OffcanvasAddtocard .OffAddtocart .Coloursec .row {
  margin-top: 6%;
}

.OffcanvasAddtocard .subtotalMain .Subtotal {
  display: flex;
  justify-content: space-between;
}

.OffcanvasAddtocard .subtotalMain .Subtotal p {
  font-size: 12px;
}

.OffcanvasAddtocard .subtotalMain .Subtotal p:nth-of-type(2) {
  font-size: 12px;
  /* font-weight: bold; */
}

.OffcanvasAddtocard .subtotalMain .AddtoCardbtn .btn-buy {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  padding: 7px 40px;
  /* margin-top: 5%; */
}

.OffcanvasAddtocard .subtotalMain .AddtoCardbtn .btn-add {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  /* margin-top: 5%; */
  background: #f88d28 0% 0% no-repeat padding-box;
  border-radius: 24px;
  padding: 7px 40px;
  /* width: 100%; */
  border: 0;
}

.OffcanvasAddtocard .Coloursec p {
  font-size: 14px;
}

.OffcanvasAddtocard .subtotalMain {
  /* position: absolute; */
  bottom: 12px;
  left: 12px;
  right: 12px;
}

.OffcanvasAddtocard .canvasVariants p.product-id {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
}

.OffcanvasAddtocard .canvasVariants p.quantity {
  color: #000;
  font-size: 14px;
  margin-bottom: 11px;
}

.OffcanvasAddtocard .canvasVariants {
  height: 165px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.OffcanvasAddtocard .canvasVariants .btn-standard {
  background: #ececec 0 0 no-repeat padding-box;
  border-radius: 12px;
  color: #535252;
  font-size: 12px;
  font-weight: 400;
  padding: 2px 11px;
  margin-bottom: 3%;
}

@media(min-width:0px) and (max-width:767px) {
  .OffcanvasAddtocard .OffAddtocart .Coloursec .colourTextsec p {
    font-size: 13px;
  }

  .OffcanvasAddtocard .Coloursec p {
    font-size: 12px;
  }

  .OffcanvasAddtocard .subtotalMain .AddtoCardbtn .btn-buy {
    padding: 7px 30px;
    font-size: 13px;
  }

  .OffcanvasAddtocard .subtotalMain .AddtoCardbtn .btn-add {
    padding: 7px 30px;
    font-size: 13px;
  }

  .OffcanvasAddtocard .offcanvas-title {
    font-size: 14px;
  }

  .OffcanvasAddtocard .OffAddtocart .ProdpricebeforeShip h3 {
    font-size: 14px;
  }

  .OffcanvasAddtocard .OffAddtocart .ProdpricebeforeShip p {
    font-size: 13px;
  }

  .moddd.OffcanvasAddtocard {
    width: 350px;
  }
}
