.ShoppingCart-sec {
    margin-top: 2%;
    margin-bottom: 6%;
}

.ShoppingCart-sec .heading {
    border-bottom: 1px solid #B1B1B1;
}

.ShoppingCart-sec .shipping-div .border-div {
    border-bottom: 1px solid #B1B1B1;
    padding: 0px 0px 10px 0px;
    margin: 0px 0px 14px 0px;
}

.ShoppingCart-sec .heading h1 {
    font-size: 20px;
    font-weight: bold;

}

.ShoppingCart-sec label {
    font-size: 14px;
    font-weight: 500;
}

.ShoppingCart-sec .shipping-div .icon-bg-div {
    background-color: #F88D28;
    height: 18px;
    width: 18px;
    border-radius: 50px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.ShoppingCart-sec .shipping-div .icon {
    font-size: 10px;
    color: #fff;
}

.ShoppingCart-sec .shipping-div .ship-text {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
}

.ShoppingCart-sec .shipping-div button {
    background-color: transparent;
    color: #000;
    border: none;
    font-size: 12px;
}

.ShoppingCart-sec .shipping-div .resi-btn {
    background-color: #FFD500;
    font-size: 10px;
    border-radius: 20px;
    margin-bottom: 10px;
    margin-left: 5px;
}

.ShoppingCart-sec .shipping-div h6 {
    font-size: 16px;
    font-weight: 500;
    color: #000;

}


.ShoppingCart-sec .main-adress {
    border-bottom: 1px solid #ccc;
    padding: 15px 0px;
    margin-bottom: 5px;
}

.ShoppingCart-sec .shipping-div .edit-icon {
    font-size: 10px;
}

.ShoppingCart-sec .check-btn-div label {
    font-weight: 400;
    font-size: 12px;
    color: #000;
}

.ShoppingCart-sec .radio-btns label {
    font-weight: 500;
    font-size: 12px;
    color: #000;
}

.ShoppingCart-sec .radio-btns {
    display: flex;
    background: #F2F2F2 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    padding: 10px;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 20px;
}

.ShoppingCart-sec .radio-btns span {
    font-size: 8px;
    font-weight: 400;
    color: #000000;
    margin-left: 23px;
}

.ShoppingCart-sec .payment-sec .form-div label {
    font-size: 12px;
    font-weight: 400;
    color: #000;
}

.ShoppingCart-sec .payment-sec .form-control {
    font-size: 12px;
    color: #B1B1B1;
    font-weight: 400;
}

.ShoppingCart-sec .payment-sec p.other-pay-text {
    font-size: 12px;
    font-weight: 400;
    color: #000000;
}

.ShoppingCart-sec .payment-sec img {
    width: 44px;
    margin-right: 10px;
}

.ShoppingCart-sec .input-group-text {
    background-color: transparent;
    border-left: none;
}

.ShoppingCart-sec .form-cvv {
    border-right: none;
}
.ShoppingCart-sec .eye-icon{
    color: #B9B9B9 ;
}
@media (min-width: 0px) and (max-width: 576px) {
    .ShoppingCart-sec .radio-btns {
        display: flow;
        width: 100%;
    }
}

@media (min-width: 0px) and (max-width: 768px) {
    .ShoppingCart-sec .heading h1 {
        font-size: 16px;

    }

    .ShoppingCart-sec label {
        font-size: 13px;
    }

    .ShoppingCart-sec .shipping-div .ship-text {
        font-size: 13px;
    }

    .ShoppingCart-sec .shipping-div h6 {
        font-size: 14px;
    }
}