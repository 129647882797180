.Product_history_modal .modal-header{
    background: #e1f6ff;
    margin: 10px;
}
.Product_history_modal .mod-tt{
    font-weight: bold;
    font-size: 16px;
    color: #000;
}
.Product_history_modal .nav{
    border-bottom: 1px solid #ccc;
}
.Product_history_modal .nav .nav-link{
    color: #000;
    font-size: 14px;
    border-radius: 0px;
    border-bottom: 3px solid #fff;
}
.Product_history_modal .nav .nav-link.active {
    color: #000;
    background-color: #fff;
    border-color: #000;
    border: 0px;
    border-bottom: 3px solid #000;
}
.Product_history_modal .nav .nav-link:hover.active {
    color: #000;
    background-color: #fff;
    border-color: #000;
    border: 0px;
    border-bottom: 3px solid #000;
}
.Product_history_modal .nav .nav-link:hover{
    border: 0px;
}
.Product_history_modal table thead th{
    font-weight: normal;
    font-size: 14px;
    color: #000;    
}

.Product_history_modal table tbody td{
    font-weight: normal;
    font-size: 12px;
    color: #000;    
}
.Product_history_modal table tbody td .pd-name{
    font-weight: 600;
}

