.Business_Requirements {
  margin-bottom: 5%;
}

.Business_Requirements .img_content_holder .img_overlay_holder .BR_bg_img_holder .BR_banner_bg_img {
  width: 100%;
  height: 312px;
  border-radius: 10px;
}

.Business_Requirements .img_content_holder .img_overlay_holder {
  position: relative;
}

.Business_Requirements .img_content_holder .img_overlay_holder .overlay {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg,
      rgb(0 0 0 / 76%) 100%,
      rgba(0, 0, 0, 1) 100%);
  border-radius: 10px;
}

.Business_Requirements .img_content_holder .Business_Requirements_content .content_text_holder h4 {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  color: #ffffff;
}

.Business_Requirements .img_content_holder .Business_Requirements_content .content_text_holder .counter {
  margin-top: 5%;
}

.Business_Requirements .img_content_holder .Business_Requirements_content .content_text_holder .counter .counter_number_holder {
  text-align: center;
  /* border-right: 1px solid #fff; */
}

.Business_Requirements .img_content_holder .Business_Requirements_content .content_text_holder .counter .vr-line {
  border-right: 2px solid #fff;
}

.Business_Requirements .img_content_holder .Business_Requirements_content .content_text_holder .counter .counter_number_holder h3 {
  font-size: 30px;
  font-weight: 600;
  color: #ff7c00;
}

.Business_Requirements .img_content_holder .Business_Requirements_content .content_text_holder .counter .counter_number_holder p {
  font-size: 16px;
  font-weight: 200;
  color: #ffffff;
}

.Business_Requirements .img_content_holder {
  position: relative;
}

.Business_Requirements .img_content_holder .Business_Requirements_content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media (min-width: 0px) and (max-width: 320px) {
  .Business_Requirements .img_content_holder .Business_Requirements_content .content_text_holder h4 {
    font-size: 15px;
    margin-top: 5%;
  }

  .Business_Requirements .img_content_holder .Business_Requirements_content .content_text_holder .counter .counter_number_holder h3 {
    font-size: 25px;
  }

  .Business_Requirements .img_content_holder .Business_Requirements_content .content_text_holder .counter .counter_number_holder p {
    font-size: 12px;
  }

  .Business_Requirements .img_content_holder .Business_Requirements_content .content_text_holder .counter .vr {
    border-right: none;
  }

  .Business_Requirements .img_content_holder .img_overlay_holder .BR_bg_img_holder .BR_banner_bg_img {
    object-fit: cover;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Business_Requirements .img_content_holder .Business_Requirements_content .content_text_holder h4 {
    font-size: 20px;
    margin-top: 5%;
  }

  .Business_Requirements .img_content_holder .Business_Requirements_content .content_text_holder .counter .counter_number_holder h3 {
    font-size: 25px;
  }

  .Business_Requirements .img_content_holder .Business_Requirements_content .content_text_holder .counter .counter_number_holder p {
    font-size: 12px;
  }

  .Business_Requirements .img_content_holder .Business_Requirements_content .content_text_holder .counter .vr {
    border-right: none;
  }

  .Business_Requirements .img_content_holder .img_overlay_holder .BR_bg_img_holder .BR_banner_bg_img {
    object-fit: cover;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Business_Requirements .img_content_holder .Business_Requirements_content .content_text_holder h4 {
    font-size: 20px;
    margin-top: 5%;
  }

  .Business_Requirements .img_content_holder .Business_Requirements_content .content_text_holder .counter .counter_number_holder h3 {
    font-size: 25px;
  }

  .Business_Requirements .img_content_holder .Business_Requirements_content .content_text_holder .counter .counter_number_holder p {
    font-size: 12px;
  }

  .Business_Requirements .img_content_holder .Business_Requirements_content .content_text_holder .counter .vr {
    border-right: none;
  }

  .Business_Requirements .img_content_holder .img_overlay_holder .BR_bg_img_holder .BR_banner_bg_img {
    object-fit: cover;
  }
}

@media (min-width: 576px) and (max-width: 675px) {
  .Business_Requirements .img_content_holder .Business_Requirements_content .content_text_holder h4 {
    font-size: 20px;
    margin-top: 5%;
  }

  .Business_Requirements .img_content_holder .Business_Requirements_content .content_text_holder .counter .counter_number_holder h3 {
    font-size: 25px;
  }

  .Business_Requirements .img_content_holder .Business_Requirements_content .content_text_holder .counter .counter_number_holder p {
    font-size: 12px;
  }

  .Business_Requirements .img_content_holder .Business_Requirements_content .content_text_holder .counter .vr {
    border-right: none;
  }

  .Business_Requirements .img_content_holder .img_overlay_holder .BR_bg_img_holder .BR_banner_bg_img {
    object-fit: cover;
  }
}

@media (min-width: 676px) and (max-width: 765px) {
  .Business_Requirements .img_content_holder .Business_Requirements_content .content_text_holder h4 {
    font-size: 20px;
    margin-top: 5%;
  }

  .Business_Requirements .img_content_holder .Business_Requirements_content .content_text_holder .counter .counter_number_holder h3 {
    font-size: 25px;
  }

  .Business_Requirements .img_content_holder .Business_Requirements_content .content_text_holder .counter .counter_number_holder p {
    font-size: 12px;
  }

  .Business_Requirements .img_content_holder .Business_Requirements_content .content_text_holder .counter .vr {
    border-right: none;
  }

  .Business_Requirements .img_content_holder .img_overlay_holder .BR_bg_img_holder .BR_banner_bg_img {
    object-fit: cover;
  }
}