/* Portfolio Css Started */

.PortfolioCart .Cart {
  margin-top: 5%;
  width: 100%;
  margin-bottom: 5%;
  height: 535px;
  background: #f5f8fe 0% 0% no-repeat padding-box;
  border-radius: 5px;
  position: relative;
}
.PortfolioCart .maintenance-title {
  font-size: 28px;
  font-weight: 500;
}
.PortfolioCart .Cart .content-cart {
  position: absolute;
  top: 50%;
  width: 100%;
}
.PortfolioCart .Cart .content-cart .KeepBrowsing button.btn.btn-Browsing {
  padding: 6px 25px;
  border: 1px solid #707070;
  border-radius: 24px;
}
.KeepBrowsing {
    margin-top: 3%;
    text-align: center;
}
.PortfolioCart .Cart .content-cart .KeepBrowsing:hover button.btn.btn-Browsing {
    text-decoration: underline;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border: 1px solid #F88D28;
    border-radius: 24px;
}
.PortfolioCart .Cart .content-cart .KeepBrowsing button.maintenance-text.btn.btn-Browsing {
    color: black;
    font-weight: 500;
}


/* Portfolio Css End */



/* Media Query started */
@media (min-width:0px) and (max-width:575px) {
  .PortfolioCart .maintenance-title {
    font-size: 20px;
  }
}
@media (min-width:576px) and (max-width:767px) {
  .PortfolioCart .maintenance-title {
    font-size: 22px;
  }
}
@media (min-width:768px) and (max-width:992px) {
  .PortfolioCart .maintenance-title {
    font-size: 24px;
  }
}