.b2b-main-dashboard .be-image {
    background-image: url("../../../../../../public/assest/images/B2Bbuyer/Accounts/bg-banner.png");
    padding: 10px;
    position: fixed;
    z-index: 9;
}

.b2b-main-dashboard .left-sec-dashbord {
    background-image: url("../../../../../../public/assest/images/B2Bbuyer/Accounts/dashboard-bg.png");
    height: 100%;
    background-size: cover;
    margin-bottom: 5%;
    padding: 15px 18px;
    background-repeat: no-repeat;
}

.desh-design {
    margin-top: 60px;
}