.why-pursuing {
    /* background: transparent linear-gradient(129deg, #FCEABB 0%, #F8B500 100%) 0% 0% no-repeat padding-box; */
    border-radius: 10px;
}

.why-pursuing .main-img-class {
    padding: 10px 15px 0px 15px;
}

.why-pursuing .main-img-class .girl-img {
    width: 100%;
}

.why-pursuing .main-img-class .company-img {
    width: 100px;
    height: 64px;
}

.why-pursuing .main-img-class .apply-now-btn {
    background: #F88D28;
    font-size: 12px;
    font-weight: 600;
    border-radius: 20px;
    border: none;
    padding: 4px 17px;
}

.why-pursuing .why-pursuing-txt {
    font-size: 14px;
    font-weight: 700;
}

.why-pursuing .title-online {
    font-size: 24px;
    color: #F88D28;
    font-weight: 500;
}

.why-pursuing .smart-choice {
    font-size: 12px;
    color: #000;

}
.why-pursuing  img.company-img {
    width: 100%;
}

@media (min-width:0px) and (max-width:991px) {
.why-pursuing {
  margin-top: 2%; 
}
}