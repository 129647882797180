section.B2BHomeBanner {
    position: relative;
}

.B2BHomeBanner .B2B_HomePage_banner {
    /* background: transparent linear-gradient(107deg, #a4ffd7 0%, #0dce6ec2 100%) 0% 0% no-repeat padding-box; */
    border-radius: 0px;
    margin: 16px 20px;
    padding: 0% 0px 1% 0%;
    width: -webkit-fill-available;
}

.B2BHomeBanner_content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.B2BHomeBanner .bg-img-holder .banner-bg-img {
    width: 100%;
    opacity: 0.1;
    height: 569px;
}

.B2BHomeBanner .B2B_HomePage_banner .bg-color {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.B2BHomeBanner .B2B_HomePage_banner .banner-text-holder {
    display: inline-grid;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.B2BHomeBanner .B2B_HomePage_banner .heading-holder h5 {
    font-size: 30px;
    font-weight: 700;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4;
    line-clamp: 4;
}

.B2BHomeBanner .B2B_HomePage_banner .heading-holder p {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
}

.B2BHomeBanner .B2B_HomePage_banner .button-holder .sell-btn {
    background: transparent linear-gradient(108deg, #4fbc8c 0%, #0dce6e 100%) 0% 0% no-repeat padding-box;
    border-radius: 19px;
    color: #fff;
    font-size: 16px;
    padding: 5px 25px;
}

.B2BHomeBanner .B2B_HomePage_banner .button-holder .sell-btn:focus {
    box-shadow: none;
}

.B2BHomeBanner .B2B_HomePage_banner .button-holder .sell-btn:hover {
    background-color: #4fbc8c;
}

.B2BHomeBanner .B2B_HomePage_banner .imge-holder .bannn-img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.B2BHomeBanner .B2BHomeBanner_content .B2B_HomePage_banner .B2B_Banner_Swiper {
    /* padding-bottom: 5%; */
    /* padding-top: 5%; */
}

.B2BHomeBanner .B2BHomeBanner_content .B2B_HomePage_banner .B2B_Banner_Swiper .swiper-pagination-bullet {
    background: #fff;
    opacity: 1;
}

.B2BHomeBanner .B2BHomeBanner_content .B2B_HomePage_banner .B2B_Banner_Swiper .swiper-pagination-bullet-active {
    background: #000;
}

.B2BHomeBanner .B2BHomeBanner_content .B2B_HomePage_banner .B2B_Banner_Swiper .swiper-button-prev:after {
    display: none;
}

.B2BHomeBanner .B2BHomeBanner_content .B2B_HomePage_banner .B2B_Banner_Swiper .swiper-button-next:after {
    display: none;
}

.B2BHomeBanner .B2BHomeBanner_content .B2B_HomePage_banner .B2B_Banner_Swiper .navigation-arrow-holder .navigation-arrow {
    width: 14px;
    height: 30px;
    color: #000;
    background: #ccc;
    padding: 0px 8px;
    border-radius: 50px;
}

.B2BHomeBanner .B2BHomeBanner_content .B2B_HomePage_banner .B2B_Banner_Swiper .navigation-arrow-holder .swiper-button-prev {
    position: absolute;
    top: 30px;
    left: 94%;
}

.B2BHomeBanner .B2BHomeBanner_content .B2B_HomePage_banner .B2B_Banner_Swiper .navigation-arrow-holder .swiper-button-next {
    position: absolute;
    top: 30px;
    right: 1%;
}

.B2BHomeBanner .fixed_image {
    position: fixed;
    width: 56px;
    height: 56px;
    bottom: 20px;
    right: 3%;
    z-index: 100;
    cursor: pointer;
}

@media (min-width: 0px) and (max-width: 320px) {
    .button-holder {
        text-align: center;
    }

    .B2BHomeBanner .bg-img-holder .banner-bg-img {
        height: 420px;
    }

    .B2B_HomePage_banner .imge-holder .bannn-img {
        height: 200px;
        margin-top: 5%;
    }

    .B2BHomeBanner .B2B_HomePage_banner .heading-holder h5 {
        font-size: 15px;
        text-align: center;
    }

    .B2BHomeBanner .B2B_HomePage_banner .heading-holder p {
        font-size: 12px;
        text-align: center;
    }

    .B2BHomeBanner .B2BHomeBanner_content .B2B_HomePage_banner .B2B_Banner_Swiper .navigation-arrow-holder .navigation-arrow {
        width: 8px;
        height: 19px;
        padding: 0px 5px;
    }

    .B2BHomeBanner .B2BHomeBanner_content .B2B_HomePage_banner .B2B_Banner_Swiper .navigation-arrow-holder .swiper-button-prev {
        top: 30px;
        left: 80%;
    }

    .B2BHomeBanner .B2B_HomePage_banner .imge-holder .bannn-img {
        height: 100px;
    }

    .B2BHomeBanner .B2BHomeBanner_content .B2B_HomePage_banner .B2B_Banner_Swiper {
        padding-top: 15%;
        padding-bottom: 15%;
    }

    .B2BHomeBanner .B2B_HomePage_banner .button-holder .sell-btn {
        font-size: 13px;
        padding: 5px 15px;
    }

    .B2BHomeBanner .B2B_HomePage_banner {
        margin: 40px 15px;
    }
}

@media (min-width: 321px) and (max-width: 485px) {
    .button-holder {
        text-align: center;
    }

    .B2BHomeBanner .bg-img-holder .banner-bg-img {
        height: 420px;
    }

    .B2B_HomePage_banner .imge-holder .bannn-img {
        height: 200px;
        margin-top: 5%;
    }

    .B2BHomeBanner .B2B_HomePage_banner .heading-holder h5 {
        font-size: 15px;
        text-align: center;
    }

    .B2BHomeBanner .B2B_HomePage_banner .heading-holder p {
        font-size: 12px;
        text-align: center;
    }

    .B2BHomeBanner .B2BHomeBanner_content .B2B_HomePage_banner .B2B_Banner_Swiper .navigation-arrow-holder .navigation-arrow {
        width: 8px;
        height: 20px;
        padding: 0px 5px;
    }

    .B2BHomeBanner .B2BHomeBanner_content .B2B_HomePage_banner .B2B_Banner_Swiper .navigation-arrow-holder .swiper-button-prev {
        top: 30px;
        left: 85%;
    }

    .B2BHomeBanner .B2BHomeBanner_content .B2B_HomePage_banner .B2B_Banner_Swiper {
        padding-top: 15%;
        padding-bottom: 15%;
    }

    .B2BHomeBanner .B2B_HomePage_banner .button-holder .sell-btn {
        font-size: 13px;
        padding: 5px 15px;
    }

    .B2BHomeBanner .B2B_HomePage_banner .imge-holder .bannn-img {
        height: 100px;
    }
}

@media (min-width: 486px) and (max-width: 575px) {
    .button-holder {
        text-align: center;
    }

    .B2B_HomePage_banner .imge-holder .bannn-img {
        height: 200px;
        margin-top: 5%;
    }

    .B2BHomeBanner .B2B_HomePage_banner .heading-holder h5 {
        font-size: 20px;
        text-align: center;
    }

    .B2BHomeBanner .B2B_HomePage_banner .heading-holder p {
        font-size: 14px;
        text-align: center;
    }

    .B2BHomeBanner .B2BHomeBanner_content .B2B_HomePage_banner .B2B_Banner_Swiper .navigation-arrow-holder .swiper-button-prev {
        top: 30px;
        left: 85%;
    }

    .B2BHomeBanner .B2BHomeBanner_content .B2B_HomePage_banner .B2B_Banner_Swiper {
        padding-top: 15%;
        padding-bottom: 15%;
    }

    .B2BHomeBanner .B2B_HomePage_banner .button-holder .sell-btn {
        font-size: 13px;
        padding: 5px 15px;
    }

    .B2BHomeBanner .B2B_HomePage_banner .imge-holder .bannn-img {
        height: 200px;
    }
}

@media (min-width: 576px) and (max-width: 675px) {
    .button-holder {
        text-align: center;
    }

    .B2B_HomePage_banner .imge-holder .bannn-img {
        height: 200px;
        margin-top: 5%;
    }

    .B2BHomeBanner .B2B_HomePage_banner .heading-holder h5 {
        font-size: 20px;
        text-align: center;
    }

    .B2BHomeBanner .B2B_HomePage_banner .heading-holder p {
        font-size: 14px;
        text-align: center;
    }

    .B2BHomeBanner .B2BHomeBanner_content .B2B_HomePage_banner .B2B_Banner_Swiper .navigation-arrow-holder .swiper-button-prev {
        top: 30px;
        left: 85%;
    }

    .B2BHomeBanner .B2BHomeBanner_content .B2B_HomePage_banner .B2B_Banner_Swiper {
        padding-top: 15%;
        padding-bottom: 15%;
    }

    .B2BHomeBanner .B2B_HomePage_banner .button-holder .sell-btn {
        font-size: 13px;
        padding: 5px 15px;
    }

    .B2BHomeBanner .B2B_HomePage_banner .imge-holder .bannn-img {
        height: 200px;
    }
}

@media (min-width: 676px) and (max-width: 767px) {
    .button-holder {
        text-align: center;
    }

    .B2B_HomePage_banner .imge-holder .bannn-img {
        height: 200px;
        margin-top: 5%;
    }

    .B2BHomeBanner .B2B_HomePage_banner .heading-holder h5 {
        font-size: 20px;
        text-align: center;
    }

    .B2BHomeBanner .B2B_HomePage_banner .heading-holder p {
        font-size: 14px;
        text-align: center;
    }

    .B2BHomeBanner .B2BHomeBanner_content .B2B_HomePage_banner .B2B_Banner_Swiper .navigation-arrow-holder .swiper-button-prev {
        top: 30px;
        left: 85%;
    }

    .B2BHomeBanner .B2BHomeBanner_content .B2B_HomePage_banner .B2B_Banner_Swiper {
        padding-top: 15%;
        padding-bottom: 15%;
    }

    .B2BHomeBanner .B2B_HomePage_banner .button-holder .sell-btn {
        font-size: 13px;
        padding: 5px 15px;
    }

    .B2BHomeBanner .B2B_HomePage_banner .imge-holder .bannn-img {
        height: 200px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .B2BHomeBanner .bg-img-holder .banner-bg-img {
        height: 450px;
    }

    .B2BHomeBanner .B2BHomeBanner_content .B2B_HomePage_banner .B2B_Banner_Swiper .navigation-arrow-holder .swiper-button-prev {
        left: 90%;
    }
}

@media (min-width: 992px) and (max-width: 1023px) {
    .B2BHomeBanner .bg-img-holder .banner-bg-img {
        height: 500px;
    }

    .B2BHomeBanner .B2BHomeBanner_content .B2B_HomePage_banner .B2B_Banner_Swiper .navigation-arrow-holder .swiper-button-prev {
        left: 92%;
    }
}

@media (min-width: 1024px) and (max-width: 1199px) {
    .B2BHomeBanner .bg-img-holder .banner-bg-img {
        height: 500px;
    }

    .B2BHomeBanner .B2BHomeBanner_content .B2B_HomePage_banner .B2B_Banner_Swiper .navigation-arrow-holder .swiper-button-prev {
        left: 92%;
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .B2BHomeBanner .bg-img-holder .banner-bg-img {
        height: 500px;
    }

    .B2BHomeBanner .B2BHomeBanner_content .B2B_HomePage_banner .B2B_Banner_Swiper .navigation-arrow-holder .swiper-button-prev {
        left: 92%;
    }
}