/* B2bSellerDetails CSS Started */

.B2b-Seller-Detail .Background-image {
  background-repeat: no-repeat;
  position: relative;
  background-image: url("../../../../../public/assest/images/B2Bbuyer/BestSeller/bestSellerBackImg.png");
  height: auto;
  background-size: cover;
  padding-top: 1%;
  padding-bottom: 3%;
}

.B2b-Seller-Detail .Background-image .product-information p.price {
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.B2b-Seller-Detail .Background-image .product-information p.quantity {
  color: #000000;
  font-size: 13px;
  margin-bottom: 11px;
}

.B2b-Seller-Detail .Background-image .product-information p.product {
  font-size: 14px;
  color: #1d44ad;
  font-weight: 600;
  margin-bottom: 5px;
}

.B2b-Seller-Detail .Background-image .product-information .border-between {
  border-left: 1px solid #b7b7b7;
  height: 18px;
  margin: 1px 13px;
}

.B2b-Seller-Detail .Background-image .product-information p.product-id {
  font-size: 11px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 11px;
}

.B2b-Seller-Detail .Background-image .product-information .product-veriation {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.B2b-Seller-Detail .Background-image .product-information .rating {
  font-size: 10px;
}

.B2b-Seller-Detail .Background-image .product-information .heading {
  font-size: 16px;
  color: #000000;
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 26px;
}

.B2b-Seller-Detail .Background-image .product-information .main-price {
  display: flex;
  justify-content: space-between;
}

.B2b-Seller-Detail
  .Background-image
  .product-information
  .main-price
  button.btn.btn-primary {
  height: 30px;
  background: #f88d28 0% 0% no-repeat padding-box;
  border-radius: 15px;
  padding: 1px 15px;
  border: none;
  font-size: 12px;
  font-weight: 400;
}

.B2b-Seller-Detail
  .Background-image
  .product-information
  .standard-section
  button.btn.btn-standard {
  background: #ececec 0% 0% no-repeat padding-box;
  border-radius: 12px;
  padding: 2px 11px;
  color: #535252;
  font-size: 12px;
  font-weight: 400;
}

.B2b-Seller-Detail
  .Background-image
  .product-information
  .standard-section
  .buttons-section
  button.btn.btn-primary {
  padding: 7px 0px;
  background: #f88d28 0% 0% no-repeat padding-box;
  border-radius: 24px;
  border: none;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  width: 107px;
  margin: 0px 8px;
}

.B2b-Seller-Detail
  .Background-image
  .product-information
  .standard-section
  .buttons-section
  button.btn.btn-chat {
  background: #fdfcfc 0% 0% no-repeat padding-box;
  border-radius: 24px;
  border: 1px solid black;
  color: #010000;
  font-size: 12px;
  font-weight: 400;
  width: 107px;
  margin: 0px 8px;
}

.B2b-Seller-Detail
  .Background-image
  .product-information
  .standard-section
  .buttons-section {
  display: flex;
  justify-content: left;
  margin-top: 3%;
}

.B2b-Seller-Detail .Background-image .product-information .main-section-tabs {
  display: flex;
}

.B2b-Seller-Detail
  .Background-image
  .breadcrumb-item
  + .breadcrumb-item::before {
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, ">");
}

/***************** Swiper CSS ***********/

.B2b-Seller-Detail
  .Background-image
  .product-information
  .main-section-tabs
  .background-color-sofa {
  width: 58px;
  height: 58px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  padding: 8px 7px;
  border-radius: 5px;
  margin: 0px 0px 0px 9px;
}

.B2b-Seller-Detail
  .Background-image
  .product-information
  .main-section-tabs
  img.product {
  height: 42px;
  width: 42px;
}

.B2b-Seller-Detail
  .Background-image
  .product-information
  .main-section-tabs
  .nav-item {
  margin-left: 12px;
}

.B2b-Seller-Detail .Background-image a {
  font-size: 12px;
  color: black;
  text-decoration: none;
}

.B2b-Seller-Detail .Background-image .background-product-detail {
  min-width: 83%;
  height: 399px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin-left: 8px;
  text-align: -webkit-center;
}

.B2b-Seller-Detail .Background-image .sidebar-swiper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.B2b-Seller-Detail .Background-image .main .gallery-imgs img.inner-img {
  width: 52px;
  height: 52px;
}

.B2b-Seller-Detail .Background-image .main .gallery-imgs {
  text-align: center;
  margin-top: 13px;
}

.B2b-Seller-Detail .Background-image .sidebar-swiper .swiper-slide {
  height: 76px !important;
  width: 76px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin-bottom: 6px !important;
}

.B2b-Seller-Detail
  .Background-image
  .sidebar-swiper-mobile-view
  .swiper-slide.swiper-slide {
  height: 76px !important;
  width: 76px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin-bottom: 6px !important;
}

/* .B2b-Seller-Detail .Background-image .product-content {
    padding-top: 20px;
} */

.B2b-Seller-Detail .Background-image .main {
  display: flex;
}

.B2b-Seller-Detail .Background-image .main .silder-btn {
  position: relative;
}

.B2b-Seller-Detail
  .Background-image
  .main
  .sidebar-swiper
  .silder-btn
  .back-btn {
  content: url("../../../../../public/assest/images/B2Bbuyer/B2BSellerDetail/up.png");
  width: 15px;
  height: 15px;
  margin: 10px;
  cursor: pointer;
  position: absolute;
  bottom: 388px;
  z-index: 99;
  left: 19px;
}

.B2b-Seller-Detail
  .Background-image
  .main
  .sidebar-swiper
  .silder-btn
  .next-btn {
  content: url("../../../../../public/assest/images/B2Bbuyer/B2BSellerDetail/down.png");
  width: 15px;
  height: 15px;
  margin: 10px;
  cursor: pointer;
  position: absolute;
  bottom: -11px;
  z-index: 99;
  right: 20px;
}

.B2b-Seller-Detail .Background-image .swiper-button-prev.swiper-button-disabled,
.B2b-Seller-Detail
  .Background-image
  .swiper-button-next.swiper-button-disabled {
  display: none;
}

.B2b-Seller-Detail .Background-image .swiper-button-prev,
.B2b-Seller-Detail .Background-image .swiper-button-next {
  display: none;
}

.B2b-Seller-Detail .Background-image .sidebar-swiper .mySwiper {
  height: 406px !important;
}

.B2b-Seller-Detail
  .Background-image
  .main
  .background-product-detail
  .product-detail
  img.product-img {
  /* width: 100%;
  height: auto; */
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.B2b-Seller-Detail
  .Background-image
  .background-product-detail
  .product-detail
  .silder-btn {
  position: relative;
}

.B2b-Seller-Detail
  .Background-image
  .background-product-detail
  .product-detail
  .silder-btn
  .back-btn {
  content: url("../../../../../public/assest/images/B2Bbuyer/B2BSellerDetail/left.png");
  width: 30px;
  height: 30px;
  margin: 10px;
  cursor: pointer;
  position: absolute;
  bottom: 342px;
  z-index: 99;
  right: 43px;
}

.B2b-Seller-Detail
  .Background-image
  .background-product-detail
  .product-detail
  .silder-btn
  .next-btn {
  content: url("../../../../../public/assest/images/B2Bbuyer/B2BSellerDetail/right.png");
  width: 30px;
  height: 30px;
  margin: 10px;
  cursor: pointer;
  position: absolute;
  bottom: 342px;
  z-index: 99;
  right: 4px;
}

.B2b-Seller-Detail .Background-image .main .sidebar-swiper-mobile-view {
  display: none;
}

.B2b-Seller-Detail
  .Background-image
  .main
  .sidebar-swiper-mobile-view
  .silder-btn
  .back-btn {
  content: url("../../../../../public/assest/images/B2Bbuyer/B2BSellerDetail/up.png");
  width: 15px;
  height: 15px;
  margin: 10px;
  cursor: pointer;
  position: absolute;
  bottom: 342px;
  z-index: 99;
  left: -5px;
  transform: rotate(-90deg);
}

.B2b-Seller-Detail .Background-image .main .sidebar-swiper-mobile-view {
  display: none;
}

.B2b-Seller-Detail
  .Background-image
  .main
  .sidebar-swiper-mobile-view
  .silder-btn
  .back-btn {
  content: url("../../../../../public/assest/images/B2Bbuyer/B2BSellerDetail/up.png");
  width: 15px;
  height: 15px;
  margin: 10px;
  cursor: pointer;
  position: absolute;
  bottom: 23px;
  z-index: 99;
  left: -5px;
  transform: rotate(-90deg);
}

.B2b-Seller-Detail
  .Background-image
  .main
  .sidebar-swiper-mobile-view
  .silder-btn
  .next-btn {
  content: url("../../../../../public/assest/images/B2Bbuyer/B2BSellerDetail/down.png");
  width: 15px;
  height: 15px;
  margin: 10px;
  cursor: pointer;
  position: absolute;
  bottom: 23px;
  z-index: 99;
  right: -18px;
  transform: rotate(-90deg);
}

/***************** Swiper CSS ***********/

.B2b-Seller-Detail
  .Background-image
  .main
  .background-product-detail
  .product-detail
  .swiper {
  /* padding-top: 74px; */
  height: 397px;
}

.B2b-Seller-Detail .Background-image .sidebar-swiper {
  display: block;
}

.B2b-Seller-Detail .Background-image .sidebar-swiper {
  display: block;
}

.B2b-Seller-Detail .Background-image .sidebar-swiper-mobile-view {
  display: none;
}

.B2b-Seller-Detail .Background-image .sidebar-swiper-mobile-view {
  display: none;
}

.B2b-Seller-Detail .Background-image .breadcrumb {
  padding-top: 18px;
}

.B2b-Seller-Detail .manufacture-bar p {
  font-size: 10px;
  margin-bottom: 0%;
}

.B2b-Seller-Detail .manufacture-bar .progress {
  height: 6px;
}

.B2b-Seller-Detail .manufacture-bar .progress-bar {
  border-radius: 3px;
}

.B2b-Seller-Detail .manufacture-bar .exclamation-icon {
  color: #ccc;
  cursor: pointer;
}

.B2b-Seller-Detail .manufacture-bar .show-percentage {
  position: relative;
}

.B2b-Seller-Detail .manufacture-bar .show-percentage:hover .used-capacity {
  display: block;
  position: absolute;
}

.B2b-Seller-Detail .manufacture-bar .show-percentage .used-capacity {
  display: none;
}

.B2b-Seller-Detail .manufacture-bar .show-percentage .used-capacity p {
  font-size: 10px;
  width: 143px;
}

.B2b-Seller-Detail
  .manufacture-bar
  .show-percentage
  .used-capacity
  .red-circle {
  width: 5px;
  height: 5px;
  background: #840304;
  border-radius: 50px;
  margin-top: 4px;
}

.B2b-Seller-Detail
  .manufacture-bar
  .show-percentage
  .used-capacity
  .green-circle {
  width: 5px;
  height: 5px;
  background: #8cd676;
  border-radius: 50px;
  margin-top: 4px;
}

.B2b-Seller-Detail
  .manufacture-bar
  .show-percentage
  .used-capacity
  .yellow-circle {
  width: 5px;
  height: 5px;
  background: #f88d28;
  border-radius: 50px;
  margin-top: 4px;
}

/******************** Media Query Started *************************/

/********** common media query started ***********/
@media (min-width: 0px) and (max-width: 991px) {
  .B2b-Seller-Detail .Background-image .main {
    display: flex;
    flex-direction: column-reverse;
  }

  .B2b-Seller-Detail .Background-image .background-product-detail {
    height: auto;
    margin-left: 0px;
  }

  .B2b-Seller-Detail .Background-image .sidebar-swiper {
    display: none;
  }

  .B2b-Seller-Detail .Background-image {
    height: auto;
  }

  .B2b-Seller-Detail
    .Background-image
    .swiper.swiper-initialized.swiper-vertical.swiper-backface-hidden.mySwiper {
    height: auto;
  }

  .product-information {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .B2b-Seller-Detail
    .Background-image
    .background-product-detail
    .product-detail
    .silder-btn
    .back-btn {
    width: 25px;
    height: 25px;
  }

  .B2b-Seller-Detail
    .Background-image
    .background-product-detail
    .product-detail
    .silder-btn
    .next-btn {
    width: 25px;
    height: 25px;
  }

  .B2b-Seller-Detail .Background-image .sidebar-swiper-mobile-view {
    display: block !important;
    margin-top: 3%;
  }
}

/*************************** common media query end *************************/
@media (min-width: 0px) and (max-width: 420px) {
  .B2b-Seller-Detail .manufacture-bar .show-percentage:hover .used-capacity {
    left: -59px !important;
  }
}

@media (min-width: 0px) and (max-width: 485px) {
  .B2b-Seller-Detail .Background-image .product-content {
    margin-top: 0%;
    padding-top: 20px;
  }

  .B2b-Seller-Detail .Background-image .product-information .heading {
    font-size: 14px;
  }

  .B2b-Seller-Detail
    .Background-image
    .product-information
    .standard-section
    .buttons-section
    button.btn.btn-primary {
    padding: 6px 18px;
    font-size: 13px;
    width: 135px;
  }

  .B2b-Seller-Detail
    .Background-image
    .product-information
    .standard-section
    .buttons-section
    button.btn.btn-chat {
    padding: 6px 18px;
    width: 135px;
    font-size: 13px;
  }

  .B2b-Seller-Detail
    .Background-image
    .product-information
    .main-price
    button.btn.btn-primary {
    margin-right: 0%;
  }

  .B2b-Seller-Detail .Background-image .product-information p.product-id {
    font-size: 12px;
  }

  .B2b-Seller-Detail .Background-image .product-information p.quantity {
    font-size: 13px;
  }

  .B2b-Seller-Detail .Background-image .product-information p.product {
    font-size: 13px;
  }

  .B2b-Seller-Detail .Background-image .product-information p.price {
    font-size: 15px;
  }

  .B2b-Seller-Detail
    .Background-image
    .product-information
    .standard-section
    .buttons-section {
    flex-wrap: unset;
    display: flex;
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    padding-bottom: 13px;
  }

  .B2b-Seller-Detail .Background-image .main .sidebar-swiper .silder-btn {
    display: none;
  }

  .B2b-Seller-Detail
    .Background-image
    .main
    .background-product-detail
    .product-detail
    img.product-img {
    padding: 0px 24px;
    width: 100%;
  }

  .B2b-Seller-Detail .manufacture-bar .show-percentage:hover .used-capacity {
    left: -27px;
  }
}

@media (min-width: 486px) and (max-width: 767px) {
  .B2b-Seller-Detail .Background-image .product-information .heading {
    font-size: 15px;
  }

  .B2b-Seller-Detail .Background-image .product-information p.quantity {
    font-size: 13px;
  }

  .B2b-Seller-Detail .Background-image .product-information p.product {
    font-size: 13px;
  }

  .B2b-Seller-Detail .Background-image .product-information p.price {
    font-size: 16px;
  }

  .B2b-Seller-Detail
    .Background-image
    .product-information
    .standard-section
    .buttons-section
    button.btn.btn-primary {
    padding: 7px 18px;
    font-size: 13px;
  }

  .B2b-Seller-Detail
    .Background-image
    .product-information
    .standard-section
    .buttons-section
    button.btn.btn-chat {
    padding: 7px 18px;

    font-size: 13px;
  }

  .B2b-Seller-Detail
    .Background-image
    .product-information
    .main-price
    button.btn.btn-primary {
    margin-right: 0%;
  }

  .B2b-Seller-Detail .Background-image .main .sidebar-swiper .silder-btn {
    display: none;
  }

  .B2b-Seller-Detail
    .Background-image
    .main
    .sidebar-swiper-mobile-view
    .silder-btn {
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .B2b-Seller-Detail .Background-image .product-information .heading {
    font-size: 16px;
  }

  .B2b-Seller-Detail .Background-image .swiper-slide {
    margin-left: 13px;
  }

  .B2b-Seller-Detail
    .Background-image
    .main
    .sidebar-swiper-mobile-view
    .silder-btn {
    display: block;
  }
}

@media (min-width: 992px) and (max-width: 1399px) {
  .B2b-Seller-Detail .Background-image .background-product-detail {
    min-width: 79%;
  }

  .B2b-Seller-Detail
    .Background-image
    .main
    .background-product-detail
    .product-detail
    img.product-img {
    padding: 0px 15px;
    width: 100%;
  }
}

/****************************** Media Query End ******************************/
.B2b-Seller-Detail svg.star-svg {
  height: 11px;
  width: 11px;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .product-information
  .customize
  button.btn.btn-customize-btn {
  padding: 3px 10px;
  background: #ffd500 0% 0% no-repeat padding-box;
  border-radius: 11px;
  color: #000000;
  font-size: 11px;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .product-information
  .star
  p.rating {
  font-size: 10px;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .product-information
  .customize
  svg.svg-inline--fa.fa-print {
  height: 11px;
  width: 11px;
  cursor: pointer;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .product-information
  .customize
  svg.svg-inline--fa.fa-share-nodes {
  height: 11px;
  width: 11px;
  cursor: pointer;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .product-information
  .standard-section
  .buttons-section
  .circle {
  padding: 5px 8px;
  width: 30px;
  font-size: 14px;
  height: 30px;
  background-color: #ebebeb;
  border-radius: 100px;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .product-information
  .Warranty {
  margin: 0px 10px;
  width: 38px;
  height: 38px;
  background-color: #fff4e9;
  border-radius: 100px;
  padding: 6px 10px;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .product-information
  .year {
  text-align: center;
  width: 60px;
  font-size: 10px;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .product-information
  .Warranty
  img.icon {
  width: 18px;
  height: 20px;
}

.B2b-Seller-Detail .Background-image .product-content .brand-holder p {
  color: #717171;
  font-size: 16px;
  font-weight: 500;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .product-information
  .accordion-body {
  padding-left: 0%;
  padding-right: 0%;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .product-information
  .accordion-button {
  padding-left: 0%;
  padding-right: 0%;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .product-information
  .accordion-button:focus {
  box-shadow: none;
}

.B2b-Seller-Detail .Background-image .product-content .brand-holder span {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin-left: 10px;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .product-information
  .accordion-button:not(.collapsed) {
  color: #000000 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  padding-left: 0%;
  padding-right: 0%;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .product-information
  button.accordion-button {
  font-size: 16px;
  font-weight: 600;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .product-information
  .product {
  color: #6479b5;
  font-size: 12px;
  font-weight: 500;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .product-information
  .product-text {
  color: #000000;
  font-size: 12px;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .product-information
  .accordion-item {
  border-radius: 0px !important;
  border-top: 1px solid rgb(199, 199, 199);
  border-bottom: 1px solid rgb(212, 212, 212);
  border-left: none;
  border-right: none;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .product-information
  .star {
  margin: -6px 5px;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card {
  height: 428px;
  background: #f6f6f6 0% 0% no-repeat padding-box;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .Amount-Card
  .add-cart-box {
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  width: fit-content;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .Amount-Card
  .amount-header {
  border-bottom: 1px solid #707070;
  padding-left: 14px;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .Amount-Card
  .shopping-img-align {
  padding-left: 14px;
  padding-right: 5px;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .Amount-Card
  .amount-header
  .price {
  color: #e87100;
  font-size: 14px;
  font-weight: bold;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .Amount-Card
  .add-cart-box
  .minus {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.800000011920929px solid #c7c7c7;
  border-radius: 2px;
  padding: 0px 9px;
  font-size: 17px;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .Amount-Card
  .add-cart-box
  .add {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.800000011920929px solid #c7c7c7;
  border-radius: 2px;
  padding: 0px 9px;
  font-size: 17px;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .Amount-Card
  .add-cart-box
  .quantity {
  background-color: transparent;
  padding: 0px 15px;
  font-size: 14px;
  font-weight: 500;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .Amount-Card
  .amount-text {
  font-size: 10px;
  font-weight: 500;
  margin-top: 4px;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card .table {
  background-color: transparent !important;
  --bs-table-bg: none;
  padding-right: 0%;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card thead {
  border-bottom: 0.5px solid #ccc !important;
  padding-right: 0%;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card tbody,
th,
tr {
  /* border: none ; */
  padding-right: 0%;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .Amount-Card
  .table
  > :not(caption)
  > *
  > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 0px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .Amount-Card
  .text-rupees {
  text-decoration: line-through;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card .variants {
  height: 22px;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #c9c9c9;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card .chair {
  padding: 8px 8px;
  width: 46px;
  height: 46px;
  background: #e1e1e1 0% 0% no-repeat padding-box;
  border-radius: 5px;
  /* margin-top: 8px;
  margin-bottom: 26px; */
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card .Select {
  display: flex;
  justify-content: space-around;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .Amount-Card
  .chair
  .product-image {
  width: 33px;
  height: 34px;
  /* padding: 0px 1px; */
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .Amount-Card
  .Select
  .form-check-reverse {
  margin-top: 21px;
  margin-bottom: 47px;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card p.price {
  font-size: 11px;
  /* margin-top: 29px;
  margin-bottom: 55px; */
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card .Buy-now {
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 24px;
  width: 90%;
  font-size: 12px;
  color: #fff;
  border: none;
}

.B2b-Seller-Detail
  .Background-image
  .product-content
  .Amount-Card
  .Buy-now:focus {
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 24px;
  width: 90%;
  font-size: 12px;
  color: #fff;
  border: none;
}
