.SelleraffiliateStepMain .RSPBprogressBar {
  top: 60px;
  z-index: 1;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  background-color: #1c42a9 !important;
}

.SelleraffiliateStepMain .SelleraffiliateStepMain {
  /* background: #f9fbff 0% 0% no-repeat padding-box; */
  padding: 0% 1% 1% 0%;
}

.SelleraffiliateStepMain .left-goto-homee,
.SelleraffiliateStepMain .left-goto-homee:hover {
  color: #000;
  font-size: 15px;
}

.SelleraffiliateStepMain .child-subcat-listtt li {
  list-style: none;
  font-size: 14px;
  color: #1c42a9;
  font-weight: 600;
  margin-bottom: 10px;
}

.SelleraffiliateStepMain .bd-underr {
  position: relative;
}

.SelleraffiliateStepMain .bd-underr::before {
  content: '';
  position: absolute;
  width: 1px;
  background-color: #ccc;
  height: 30px;
  z-index: 999999999999 !important;
  display: block;
  top: 19px;
}

.SelleraffiliateStepMain .bd-underr::after {
  content: '';
  position: absolute;
  width: 22px;
  height: 1px;
  background: #ccc;
  left: 0px;
  top: 48px;
}

.SelleraffiliateStepMain .tri-shap {
  content: '';
  position: absolute;
  height: 0;
  border-top: 5px solid transparent;
  border-left: 5px solid #ccc;
  border-bottom: 5px solid transparent;
  top: 43px;
  left: 20px;
}


.SelleraffiliateStepMain .ProgressBar .step {
  align-items: center;
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 50%;
  color: #562278;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  height: 44px;
  font-family: "Poppins";
  justify-content: center;
  width: 44px;
}

.SelleraffiliateStepMain .ProgressBar .step.completed {
  background-color: #664de5;
}

.SelleraffiliateStepMain .userForm {
  height: 100%;
  padding: 15% 0% 0% 0%;
  position: relative;
  /* margin-bottom: 15%; */
}

.SelleraffiliateStepMain .userForm .SaveAndNext {
  position: absolute;
  bottom: -64px;
  left: 0;
  right: 0;
}

.SelleraffiliateStepMain .ProgressBar .step-name {
  position: absolute;
  bottom: -42px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  color: #000000;
}

.SelleraffiliateStepMain .personal-details-form .categoriesList .selected {
  color: #1c42a9;
  font-weight: 600;
}

.SelleraffiliateStepMain .userForm .apply_now_btn .tabs-btn {
  background: #f88d28 0% 0% no-repeat padding-box;
  border-radius: 24px;
  border: 0px;
  margin-left: 15px;
  font-size: 14px;
  padding: 5px 25px;
  box-shadow: none;
}

.SelleraffiliateStepMain .apply_now_btn .back-btn {
  background: #dfdfdf 0% 0% no-repeat padding-box;
  border-radius: 19px;
  border: 0px;
  color: #000000;
  font-size: 14px;
  padding: 5px 25px;
  box-shadow: none;
}

.SelleraffiliateStepMain .userForm .apply_now_btn {
  padding: 7px 22px;
  /* position: absolute; */
  bottom: 51px;
  right: 30px;
  margin-top: 25px;
  cursor: pointer;
}

.SelleraffiliateStepMain .userForm .apply_now_btn p {
  font-size: 11px;
}

.SelleraffiliateStepMain .personal-details-form .categoriesList .all-active {
  color: #1c42a9;
  font-weight: 600;
}

.SelleraffiliateStepMain .userForm .apply_now_btn p span {
  color: #1c42a9;
  margin-left: 4px;
  font-weight: 500;
}

.SelleraffiliateStepMain .userForm .personal-details-form .form-header-holder {
  border-radius: 10px 10px 0px 0px;
  padding: 1% 10px;
}

.SelleraffiliateStepMain .userForm .personal-details-form .form-header-holder .heading-holder h6 {
  text-align: start;
  font-size: 24px;
  font-weight: 600;
  color: #131313;
  margin-bottom: 0px;
}



.SelleraffiliateStepMain .userForm .personal-details-form .form-header-holder .heading-holder {
  display: flex;
  justify-content: start;
  align-items: center;
}

.SelleraffiliateStepMain .personal-details-form .stepthreeformRowmain .CategoriesHead .left-arrow {
  margin-right: 14px;
}

.SelleraffiliateStepMain .userForm .personal-details-form .form-header-holder .Back-span-holder {
  text-align: left;
}

.SelleraffiliateStepMain .userForm .personal-details-form .form-header-holder .Back-span-holder span {
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  color: #131313;
}

.SelleraffiliateStepMain .userForm .personal-details-form .form-header-holder .span-holder {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}



.SelleraffiliateStepMain .userForm .personal-details-form .form-header-holder .span-holder span {
  /* text-decoration: underline; */
  font-size: 14px;
  font-weight: 500;
  color: #131313;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 13px;
}

.SelleraffiliateStepMain .overviewMain .personal-details-form .form-header-holder .span-holder span {
  /* text-decoration: underline; */
  font-size: 14px;
  font-weight: 500;
  color: #131313;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 13px;
}

.SelleraffiliateStepMain .SelleraffiliateStepMain .go-to-home {
  position: relative;
}

.SelleraffiliateStepMain .go-to-home .go-to-home-text {
  text-align: center;
  font-size: 14px;
  color: #000000;
  font-weight: 500;
  position: absolute;
  top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 1;
}

.SelleraffiliateStepMain .go-to-home .go-to-home-text .left-angle-arrow {
  font-size: 12px;
  font-weight: 500;
  color: #000000;
  margin-right: 5px;
}

.SelleraffiliateStepMain .userForm .personal-details-form .form-header-holder .span-holder .CircularProgressbar .CircularProgressbar-path {
  stroke: #1967d3;
}

.SelleraffiliateStepMain .userForm .personal-details-form .form-header-holder .span-holder .CircularProgressbar .CircularProgressbar-text {
  font-size: 25px;
  dominant-baseline: middle;
  color: #131313 !important;
  text-anchor: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: 600;
}

.SelleraffiliateStepMain .personal-details-form .Percentages-Add-Education .previous-job-details .job-content-holder {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  padding: 5px 10px;
}

.SelleraffiliateStepMain .personal-details-form .Percentages-Add-Education .previous-job-details .job-content-holder h1 {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}

.SelleraffiliateStepMain .personal-details-form .Percentages-Add-Education .previous-job-details .job-content-holder p {
  font-size: 12px;
  font-weight: 500;
  color: #646464;
  margin-bottom: 5px;
}

.SelleraffiliateStepMain .userForm .Employment_Details_award_form {
  margin-top: 5%;
}

.SelleraffiliateStepMain .userForm .Employment_Details_award_form .personal-details-form .details-form-holder {
  padding: 2% 2% 5% 2%;
}

.SelleraffiliateStepMain .userForm .employee-details-step .details-form-holder {
  padding: 2% 2% 5% 2%;
}

.SelleraffiliateStepMain .personal-details-form .Add-education-btn-holder .apply-btn-holder {
  text-align: end;
}

.SelleraffiliateStepMain .userForm .personal-details-form .react-tel-input .form-control {
  width: 100%;
}

.SelleraffiliateStepMain .userForm .education-card {
  margin-top: 5%;
}

.SelleraffiliateStepMain .userForm .education-card .card-holder h6 {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.SelleraffiliateStepMain .userForm .education-card .card-holder .card-info-holder h4 {
  text-align: left;
  font-size: 10px;
  font-weight: 500;
  color: #131313;
}

.SelleraffiliateStepMain .userForm .education-card .card-holder .card-info-holder {
  background: #fff9f2 0% 0% no-repeat padding-box;
  border: 1px solid #ee9723;
  border-radius: 5px;
  padding: 5px 5px;
  height: 100px;
}

.SelleraffiliateStepMain .personal-details-form .stepthreeformRowmain .form-control {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 19px;
  margin-bottom: 25px;
  /* position: relative; */
}

.SelleraffiliateStepMain .personal-details-form .stepthreesearch {
  position: relative;
}

.personal-details-form .stepthreeformRowmain .searchStepIon {
  position: absolute;
  top: 10px;
  right: 9px;
  font-size: 16px;
  color: #9b9b9b;
}

.SelleraffiliateStepMain .personal-details-form .stepthreeformRowmain .CategoriesHead h3 {
  font-size: 16px;
  margin-bottom: 25px;
  border-bottom: 2px solid #ccc;
  padding-bottom: 15px;
  font-weight: 600;
}

.SelleraffiliateStepMain .personal-details-form {
  padding: 2%;
  background: #fffcf5 0% 0% no-repeat padding-box;
  margin-bottom: 5%;
}

.SelleraffiliateStepMain .affiliateUploadsec {
  background: #fff;
  border: 1px solid #ccc;
  padding: 2%;
}

.SelleraffiliateStepMain .personal-details-form .grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  overflow-y: auto;
  max-height: 350px;
  padding-bottom: 39px;
  padding-right: 20px;
}


.SelleraffiliateStepMain .personal-details-form .box {
  padding: 20px;
  text-align: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  font-size: 14px;
}

.SelleraffiliateStepMain .personal-details-form .stepthreeformRowmain .categoriesList {
  overflow-y: auto;
  max-height: 350px;
}

.SelleraffiliateStepMain .personal-details-form .stepthreeformRowmain .categoriesList p {
  font-size: 14px;
  cursor: pointer;
}

.SelleraffiliateStepMain .personal-details-form ::-webkit-scrollbar {
  width: 5px;
}

.SelleraffiliateStepMain .personal-details-form ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.SelleraffiliateStepMain .personal-details-form ::-webkit-scrollbar-thumb {
  background: #888;
}

.SelleraffiliateStepMain .personal-details-form .stepsixform .form-control {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #ccc;
  border-radius: 5px;
}

.SelleraffiliateStepMain .personal-details-form .form-label {
  font-size: 14px;
  font-weight: 500;
}

.SelleraffiliateStepMain .personal-details-form .form-control {
  font-size: 14px !important;
  border-radius: 5px;
  margin-bottom: 23px;
}

.SelleraffiliateStepMain .personal-details-form .get-otp-text {
  position: relative;
}

.SelleraffiliateStepMain .personal-details-form .get-otp-text .telepara {
  position: absolute;
  top: 8px;
  right: 35px;
  font-size: 12px;
  color: #1c42a9;
  font-weight: 600;
  cursor: pointer;
}

.SelleraffiliateStepMain .personal-details-form .StepsixPassword {
  position: relative;
}

.SelleraffiliateStepMain .personal-details-form .StepsixPassword .StepsixeditIcon {
  position: absolute;
  top: 9px;
  right: 7px;
  color: #a1a1a1;
  font-size: 15px;
}

.SelleraffiliateStepMain .personal-details-form .field-bottom .emailmainsec {
  position: relative;
}

.SelleraffiliateStepMain .personal-details-form .inputFields .form-control {
  border-left: 0;
  border-top: 0;
  border-right: 0;
  border-radius: 0;
  background: transparent;
  margin-left: 10px;
  width: 40px;
  text-align: center;
}

.SelleraffiliateStepMain .personal-details-form .inputFields {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.SelleraffiliateStepMain .personal-details-form .inputFields .verifyBtn {
  padding: 2px 10px;
  margin-left: 5px;
  background: #ff923d;
  border: 0px;
  font-size: 13px;
}

.SelleraffiliateStepMain .personal-details-form .inputFields .resendbtnnn {
  border: 0px;
  background-color: transparent;
  color: #000000;
  font-size: 13px;
}

.SelleraffiliateStepMain .personal-details-form .inputFields .otp-timeset {
  font-size: 12px;
  color: #000;
}

.SelleraffiliateStepMain .personal-details-form .details-form-holder .verified {
  position: relative;
}

.SelleraffiliateStepMain .personal-details-form .details-form-holder .verified span {
  display: flex;
  position: absolute;
  top: 0;
  right: 8px;
  justify-content: end;
  align-items: center;
  height: 100%;
}

.SelleraffiliateStepMain .personal-details-form .details-form-holder .verified .verified-text {
  font-size: 15px;
  font-weight: 500;
  color: green;
}

.SelleraffiliateStepMain .personal-details-form .details-form-holder .verified .not-verified-text {
  font-size: 15px;
  font-weight: 500;
  color: red;
}


.SelleraffiliateStepMain .react-datepicker__input-container input {
  width: 100%;
}

.SelleraffiliateStepMain .personal-details-form .Addnewadresstxt {
  font-size: 14px;
  color: #1c42a9;
  font-weight: 500;
  margin-right: 27px;
  cursor: pointer;
}

.SelleraffiliateStepMain .personal-details-form .addremoveadress {
  display: flex;
  justify-content: end;
}

.SelleraffiliateStepMain .bankk-verrr .verrrr-bttt {
  position: absolute;
  top: 40px;
  right: 10px;
  background-color: #1c42a9;
  color: #fff;
  font-size: 11px;
  border: 0px;
  border-radius: 2px;
}

.SelleraffiliateStepMain .personal-details-form .StepfoursaveBtn {
  background: #f88d28 0% 0% no-repeat padding-box;
  border-radius: 24px;
  border: 0;
  padding: 5px 20px;
  margin-right: 15px;
  margin-bottom: 1%;
}

@media (min-width: 768px) and (max-width: 991px) {
  .SelleraffiliateStepMain .RSPBprogressBar {
    top: 35px;
  }
}

@media (min-width: 280px) and (max-width: 320px) {
  .SelleraffiliateStepMain .ProgressBar .step-name {
    display: none;
  }

  .SelleraffiliateStepMain .apply_now_btn .back-btn {
    font-size: 12px;
    padding: 5px 15px;

  }

  .SelleraffiliateStepMain .userForm .apply_now_btn .tabs-btn {
    font-size: 12px;
    padding: 5px 10px;
    margin: 10px;
  }

  .SelleraffiliateStepMain .userForm .apply_now_btn {
    padding: 7px 10px;
  }

  .SelleraffiliateStepMain .userForm {
    padding: 25% 0% 0% 0%;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .Languages-tick .form-check {
    margin-right: 35px;
  }

  .SelleraffiliateStepMain .RSPBprogressBar {
    top: 12px;
  }

  .SelleraffiliateStepMain .userForm .personal-details-form .form-header-holder .heading-holder h6 {
    font-size: 15px;
  }

  .SelleraffiliateStepMain .overviewMain .personal-details-form .form-header-holder .heading-holder h6 {
    font-size: 15px;
  }

  .SelleraffiliateStepMain .ProgressBar .step {
    font-size: 10px;
    height: 20px;
    width: 20px;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .Languages-row {
    margin-bottom: 15%;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .Languages-tick-holder {
    margin-top: 5%;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .personal-status-buttons .status-buttons {
    font-size: 13px;
    margin-bottom: 5%;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder {
    padding: 2% 2% 2% 2%;
  }

  .SelleraffiliateStepMain .userForm .SaveAndNext {
    bottom: 2%;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .select-to-holder {
    margin-bottom: 5%;
  }

  .SelleraffiliateStepMain .left-goto-homee,
  .SelleraffiliateStepMain .left-goto-homee:hover {
    color: #000;
    font-size: 12px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .SelleraffiliateStepMain .ProgressBar .step-name {
    display: none;
  }

  .SelleraffiliateStepMain .userForm .apply_now_btn {
    padding: 7px 10px;
  }

  .SelleraffiliateStepMain .apply_now_btn .back-btn {
    font-size: 12px;
    padding: 5px 22px;
  }

  .SelleraffiliateStepMain .userForm .apply_now_btn .tabs-btn {
    font-size: 12px;
    padding: 5px 25px;
    margin: 10px;
  }

  .SelleraffiliateStepMain .left-goto-homee,
  .SelleraffiliateStepMain .left-goto-homee:hover {
    color: #000;
    font-size: 12px;
  }

  .SelleraffiliateStepMain .userForm {
    padding: 15% 0% 0% 0%;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .Languages-tick .form-check {
    margin-right: 21px;
  }

  .SelleraffiliateStepMain .RSPBprogressBar {
    top: 12px;
  }

  .SelleraffiliateStepMain .userForm .personal-details-form .form-header-holder .heading-holder h6 {
    font-size: 15px;
  }

  .SelleraffiliateStepMain .ProgressBar .step {
    font-size: 12px;
    height: 25px;
    width: 25px;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .Languages-row {
    margin-bottom: 15%;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .Languages-tick-holder {
    justify-content: center;
    margin-top: 5%;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .personal-status-buttons .status-buttons {
    font-size: 13px;
    margin-bottom: 5%;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder {
    padding: 2% 2% 2% 2%;
  }

  .SelleraffiliateStepMain .userForm .SaveAndNext {
    bottom: 2%;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .select-to-holder {
    margin-bottom: 5%;
  }
}

@media (min-width: 486px) and (max-width: 576px) {
  .SelleraffiliateStepMain .ProgressBar .step-name {
    display: none;
  }

  .SelleraffiliateStepMain .apply_now_btn .back-btn {
    font-size: 12px;
    padding: 5px 25px;
    margin: 10px;
  }

  .SelleraffiliateStepMain .userForm .apply_now_btn {
    padding: 7px 0px;
    display: flex;
    justify-content: center;
  }

  .SelleraffiliateStepMain .userForm .apply_now_btn .tabs-btn {
    font-size: 12px;
    padding: 5px 25px;
    margin: 10px;
  }

  .SelleraffiliateStepMain .userForm {
    padding: 15% 0% 0% 0%;
  }

  .SelleraffiliateStepMain .RSPBprogressBar {
    top: 12px;
  }

  .SelleraffiliateStepMain .userForm .personal-details-form .form-header-holder .heading-holder h6 {
    font-size: 15px;
  }

  .SelleraffiliateStepMain .ProgressBar .step {
    font-size: 13px;
    height: 30px;
    width: 30px;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .Languages-row {
    margin-bottom: 15%;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .Languages-tick-holder {
    margin-top: 5%;
    justify-content: center;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .personal-status-buttons .status-buttons {
    font-size: 13px;
    margin-bottom: 5%;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder {
    padding: 2% 2% 2% 2%;
  }

  .SelleraffiliateStepMain .userForm .SaveAndNext {
    bottom: 2%;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .select-to-holder {
    margin-bottom: 5%;
  }

  .SelleraffiliateStepMain .JpJobseekerEditProfile .update-profile-section {
    margin-top: 5%;
  }
}

@media (min-width: 577px) and (max-width: 678px) {
  .SelleraffiliateStepMain .ProgressBar .step-name {
    display: none;
  }

  .SelleraffiliateStepMain .userForm {
    padding: 15% 0% 0% 0%;
  }

  .SelleraffiliateStepMain .JpJobseekerEditProfile .update-profile-section {
    margin-top: 5%;
  }

  .SelleraffiliateStepMain .RSPBprogressBar {
    top: 12px;
  }

  .SelleraffiliateStepMain .userForm .personal-details-form .form-header-holder .heading-holder h6 {
    font-size: 15px;
  }

  .SelleraffiliateStepMain .ProgressBar .step {
    font-size: 13px;
    height: 30px;
    width: 30px;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .Languages-row {
    margin-bottom: 15%;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .Languages-tick-holder {
    justify-content: center;
    margin-top: 5%;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .personal-status-buttons .status-buttons {
    font-size: 13px;
    margin-bottom: 5%;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder {
    padding: 2% 2% 2% 2%;
  }

  .SelleraffiliateStepMain .userForm .SaveAndNext {
    bottom: 2%;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .select-to-holder {
    margin-bottom: 5%;
  }
}

@media (min-width: 679px) and (max-width: 567px) {
  .SelleraffiliateStepMain .userForm {
    padding: 15% 0% 0% 0%;
  }

  .SelleraffiliateStepMain .JpJobseekerEditProfile .update-profile-section {
    margin-top: 5%;
  }

  .SelleraffiliateStepMain .RSPBprogressBar {
    top: 12px;
  }

  .SelleraffiliateStepMain .userForm .personal-details-form .form-header-holder .heading-holder h6 {
    font-size: 15px;
  }

  .SelleraffiliateStepMain .ProgressBar .step {
    font-size: 13px;
    height: 30px;
    width: 30px;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .Languages-row {
    margin-bottom: 15%;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .Languages-tick-holder {
    justify-content: center;
    margin-top: 5%;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .personal-status-buttons .status-buttons {
    font-size: 13px;
    margin-bottom: 5%;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder {
    padding: 2% 2% 40% 2%;
  }

  .SelleraffiliateStepMain .userForm .SaveAndNext {
    bottom: 2%;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .select-to-holder {
    margin-bottom: 5%;
  }
}

@media (min-width: 679px) and (max-width: 767px) {
  .SelleraffiliateStepMain .ProgressBar .step-name {
    display: none;
  }

  .SelleraffiliateStepMain .userForm {
    padding: 15% 0% 0% 0%;
  }

  .SelleraffiliateStepMain .JpJobseekerEditProfile .update-profile-section {
    margin-top: 5%;
  }

  .SelleraffiliateStepMain .RSPBprogressBar {
    top: 12px;
  }

  .SelleraffiliateStepMain .userForm .personal-details-form .form-header-holder .heading-holder h6 {
    font-size: 15px;
  }

  .SelleraffiliateStepMain .ProgressBar .step {
    font-size: 13px;
    height: 30px;
    width: 30px;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .Languages-row {
    margin-bottom: 15%;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .Languages-tick-holder {
    justify-content: center;
    margin-top: 5%;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .personal-status-buttons .status-buttons {
    font-size: 13px;
    margin-bottom: 5%;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder {
    padding: 2% 2% 2% 2%;
  }

  .SelleraffiliateStepMain .userForm .SaveAndNext {
    bottom: 2%;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .select-to-holder {
    margin-bottom: 5%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .SelleraffiliateStepMain .userForm {
    padding: 15% 0% 0% 0%;
  }

  .SelleraffiliateStepMain .JpJobseekerEditProfile .update-profile-section {
    margin-top: 5%;
  }

  .SelleraffiliateStepMain .userForm .personal-details-form .form-header-holder .heading-holder h6 {
    font-size: 15px;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .Languages-tick-holder {
    justify-content: center;
  }

  .SelleraffiliateStepMain .ProgressBar .step {
    font-size: 13px;
    height: 30px;
    width: 30px;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .personal-status-buttons .status-buttons {
    font-size: 13px;
    margin-bottom: 5%;
  }

  .SelleraffiliateStepMain .ProgressBar .step-name {
    bottom: -30px;
    font-size: 10px;
    line-height: 12px;
  }

  .SelleraffiliateStepMain .userForm .SaveAndNext {
    bottom: 2%;
  }

  .SelleraffiliateStepMain .personal-details-form .details-form-holder .select-to-holder {
    margin-bottom: 5%;
  }
}


@media (min-width: 992px) and (max-width: 1023px) {
  .SelleraffiliateStepMain .RSPBprogressBar {
    top: 30px;
  }
}