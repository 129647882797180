.subscription-class-seller .pricingList {
  list-style-type: none;
  padding-left: 17px;
}

.subscription-class-seller .pricingList .rightTick {
  background-color: #17ad37;
  border-radius: 50%;
  padding: 5px 7px;
  font-size: 10px;
  --tw-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.12);
  --tw-shadow-colored: 0 0.3125rem 0.625rem 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  margin-right: 16px;
  color: #fff;
}

.subscription-class-seller .pricingList li {
  margin-bottom: 13px;
}

.subscription-class-seller .swiper-wrapper {
  margin-bottom: 4%;
}

.subscription-class-seller .pricingcard {
  --tw-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 0 20px 27px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border: 0;
}

.subscription-class-seller .pricingcard .subscriptionhead {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subscription-class-seller .pricingcard .subscriptionhead p {
  font-size: 34px;
  font-weight: 600;
}

.subscription-class-seller .pricingcard .subscriptionhead h3 {
  font-size: 15px;
  background: rgb(233 236 239);
  width: fit-content;
  padding: 3px 17px;
  border-radius: 14px;
}

.subscription-class-seller .pricingcard .pricingList .minusTick {
  background-color: #627594;
  border-radius: 50%;
  padding: 5px 7px;
  font-size: 10px;
  --tw-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.12);
  --tw-shadow-colored: 0 0.3125rem 0.625rem 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  margin-right: 16px;
  color: #fff;
}

.subscription-class-seller .pricingcard .joinbutton {
  background-image: linear-gradient(to top left, #141727, #3a416f) !important;
  width: 100%;
  margin-top: 15px;
}

.subcsriptionMain
  .subscriptioncard
  .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  box-shadow: unset !important;
}

.subcsriptionMain .subscriptioncard .purchasebtn {
  width: fit-content;
  font-size: 13px;
  text-wrap: nowrap;
}

.subcsriptionMain .subscriptioncard .subscriptCon h3 {
  font-size: 18px;
}

.subcsriptionMain .subscriptioncard .subscriptionPrice {
  margin-bottom: 3%;
}

.subcsriptionMain .subscriptioncard .subscriptionPrice span:nth-of-type(1) {
  font-size: 28px;
  font-weight: bold;
}

.subcsriptionMain .subscriptioncard .subscriptInfo {
  font-size: 15px;
}

.subcsriptionMain .subscriptioncard .subscriptrow {
  margin-bottom: 4%;
}

.subcsriptionMain .subscriptioncard {
  --tw-shadow: 0px 8px 13px -3px rgba(0, 0, 0, 0.07);
  --tw-shadow-colored: 0px 8px 13px -3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border: 0;
}

.subcsriptionMain .subscriptioncard .css-177gid-MuiTableCell-root {
  text-align: center;
}

.subcsriptionMain .subscriptioncard .css-1azl6jz-MuiTableCell-root {
  text-align: center;
}

.subcsriptionMain .subscriptioncard .pricingrowmain {
  padding: 2%;
}

.subcsriptionMain .subscriptioncard .rightTicktwo {
  background-color: transparent;
  border-radius: 50%;
  padding: 5px 7px;
  font-size: 10px;
  margin-right: 16px;
  color: #17ad37;
  border: 1px solid #17ad37;
}

.subcsriptionMain .subscriptioncard .crossTicktwo {
  background-color: transparent;
  border-radius: 50%;
  padding: 5px 7px;
  font-size: 10px;
  margin-right: 16px;
  color: red;
  border: 1px solid red;
}

.subcsriptionMain .subscriptioncard .no-vertical-borders th,
.subcsriptionMain .subscriptioncard .no-vertical-borders td {
  border-left: none !important;
  border-right: none !important;
  padding: 15px;
  text-align: right;
}

.subcsriptionMain .subscriptioncard .no-vertical-borders thead th {
  border-top: none !important;
}

.subcsriptionMain .subscriptioncard .pricingtablemain {
  margin: 2%;
}
