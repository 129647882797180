.product-info-sec .main-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000012;
  border-radius: 10px;
  opacity: 1;
  padding: 10px 10px;
  margin: 20px 5px;
}

.product-info-sec .nav-pills .nav-link.active {
  background-color: #ffff;
  color: black;
  font-weight: 600;
  border-bottom: 2px solid #1c42a9;
  border-radius: 0px;
  padding: 8px 14px;
  font-size: 14px;
}

.product-info-sec .nav-pills .nav-link {
  background-color: #ffff;
  font-weight: 400;
  border-radius: 0px;
  padding: 8px 14px;
  font-size: 14px;
  color: #656565;
  font-weight: 400;
}

.product-info-sec .nav {
  border-bottom: 1px solid grey;
  margin-bottom: 20px;
}

.product-info-sec .heighlight-box {
  height: 143px;
  overflow-y: scroll;
  border: 1px solid black;
  padding: 10px;
  border-radius: 5px;
}

.product-info-sec .heighlight-box p {
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
}

.product-info-sec .heighlight-box h6 {
  font-size: 14px;
  font-weight: 500;
}

.product-info-sec ::-webkit-scrollbar {
  width: 5px;
}

.product-info-sec ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.product-info-sec ::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

.product-info-sec .form-label {
  font-size: 14px;
  font-weight: 500;
}

.product-info-sec .form-control {
  font-size: 14px;
  font-weight: 400;
}

.product-info-sec .star {
  color: red;
}

.product-info-sec .select-to-div .delet-row {
  width: 18px;
  height: 18px;
  border: 1px solid red;
  border-radius: 50%;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  background-color: #fff;
}

.product-info-sec .select-to-div .delet-row .fa-minus {
  color: red;
  font-size: 10px;
}

.product-info-sec .select-to-div .addrowiccc {
  border: 0px;
  padding: 0px;
  background-color: transparent;
}

.product-info-sec .select-to-div .addrowiccc img {
  width: 20px;
}
