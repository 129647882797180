.reson-page .reson-main-img {
    position: relative;
}

.reson-page .reason-img {
    width: 100%;
}

.reson-page .reson-main-img .black-overlay {
    background: transparent linear-gradient(90deg, #000000 0%, #000000D1 45%, #00000000 100%) 0% 0% no-repeat padding-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.reson-page .reson-main-img .txt-card-overlay {
    position: absolute;
    top: 17%;
    left: 8%;
}

.reson-page .reson-main-img .txt-card-overlay h2 {
    font-size: 28px;
    color: #FFF;
    border-left: 8px solid #FF7C00;
    padding: 0px 0px 0px 10px;
}

.reson-page .reson-main-img .txt-card-overlay p {
    font-size: 14px;
    color: #FFF;
    width: 80%;
    margin-top: 3%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4;
}


/* Media */
@media(min-width:0px) and (max-width:576px) {
    .reson-page .reson-main-img .txt-card-overlay h2 {
        font-size: 14px;
    }

    .reason-slider .reason-card {
        /* margin-top: 14%; */
        margin: 20px 14px;
    }

    .reson-page .reson-main-img .txt-card-overlay p {
        font-size: 10px;
    }

    .reson-page .reason-img {
        height: 180px;
    }
}

@media(min-width:577px) and (max-width:767px) {
    .reson-page .reson-main-img .txt-card-overlay h2 {
        font-size: 18px;
    }

    .reason-slider .reason-card {
        /* margin-top: 15%; */
        margin: 20px 14px;
    }

    .reson-page .reson-main-img .txt-card-overlay p {
        font-size: 12px;
    }

    .reson-page .reason-img {
        height: 200px;
    }
   
}

@media(min-width:769px) and (max-width:992px) {
    .reson-page .reson-main-img .txt-card-overlay h2 {
        font-size: 18px;
    }
    .reason-slider .reason-card {
        /* margin-top: 15%; */
        margin: 20px 14px;
    }

    .reson-page .reson-main-img .txt-card-overlay p {
        font-size: 12px;
    }

    .reson-page .reason-img {
        height: 200px;
    }

    .reason-slider .reason-card {
        height: 245px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .reson-page .reson-main-img .txt-card-overlay h2 {
        font-size: 18px;
    }

    .reson-page .reson-main-img .txt-card-overlay p {
        font-size: 12px;
    }

    .reson-page .reason-img {
        height: 200px;
    }

    .reason-slider .reason-card {
        height: 275px;
    }
}