.affi-setting-seccc {
    padding: 40px;
    background: #fff;
    border-radius: 5px;
    margin: 2% 4% 0% 4%;
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    box-shadow: 0px 0px 12px #00000029;
}

.affi-setting-seccc .form-check-input:checked {
    background-color: #f88d28;
    border-color: #f88d28;
}

.affi-setting-seccc .css-3w2yfm-ValueContainer {
    height: 45px;
    /* background: #c9c9c93d; */
    /* border: none; */
}

.affi-setting-seccc .orange-border {
    border-bottom: 3px solid #f88d28;
}

.affi-setting-seccc .form-control {
    height: 45px;
    font-size: 14px;
    /* background: #c9c9c93d; */
    /* border: none; */
}

.affi-setting-seccc .css-13cymwt-control {
    height: 45px;
    width: 300px;
    /* background: #c9c9c93d; */
    /* border: none; */
}

.affi-setting-seccc .aff-title {
    font-size: 20px;
    margin-top: 2%;
    /* border-bottom: 2px solid #f6f5f5; */
    border-bottom: 2px solid #dadada;
}


.affi-setting-seccc .form-label {
    font-size: 14px;
    font-weight: 500;
}

.socialmeddlinkflex .data {
    /* background-color: hsl(0deg 0% 90.2%); */
    border: 1px solid #3d3c3c;
    margin: 0px 10px;
    padding: 5px 41px;
    border-radius: 3px;
    position: relative;
}

.socialmeddlinkflex .data .x-markk {
    position: absolute;
    top: 5px ;
    right: 10px;
}

.socialmeddlinkflex .data-plus {
    background-color: hsl(0deg 0% 90.2%);
    margin: 0px 10px;
    padding: 42px 22px;
    border-radius: 3px;
    font-size: 22px;
}

.socialmeddlinkflex .data p {
    margin-bottom: 0px;
    font-size: 14px;
}

.socialmeddlinkflex .data .addico {
    background-color: hsl(0deg 0% 90.2%);
    font-size: 18px;
}

.otpppdiv {
    position: relative;
}

.otpppdiv .ottbtn {
    position: absolute;
    right: 7px;
    bottom: 7px;
    font-size: 14px;
    background: #f88d28;
    color: #FFFF;
    padding: 4px 8px;
}

.submittttbtnnn,
.submittttbtnnn:hover {
    background-color: #f88d28;
    color: #fff;
}

.formmbgd {
    background-color: #fff;
    /* padding: 20px; */
}