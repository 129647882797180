/* main-step active css  */

.add-productinfo-innerstepform {
  padding: 20px;
}

.add-productinfo-innerstepform .steps-div p.steps-name {
  font-size: 14px;
  color: #656565;
  font-weight: 400;
  margin: 20px 20px 0px 20px;
  cursor: pointer;
}

.add-productinfo-innerstepform .steps-div {
  display: flex;
}

.add-productinfo-innerstepform .steps-div .steps-name.active {
  font-weight: bold;
  border-bottom: 2px solid #1c42a9;
  padding-bottom: 8px;
  color: #000;
}

.add-productinfo-innerstepform .common-steps-btn {
  text-align: center;
}

.add-productinfo-innerstepform .common-steps-btn .back-btn {
  background-color: #e2e2e2;
  font-size: 15px;
  color: #5b5b5b;
  border: 1px solid #e2e2e2;
  border-radius: 24px;
  width: 120px;
  font-weight: 400;
  padding: 5px;
  margin: 10px;
}

.add-productinfo-innerstepform .common-steps-btn .next-btn {
  background-color: #f88d28;
  font-size: 15px;
  color: #fff;
  border: 1px solid #f88d28;
  border-radius: 24px;
  width: 150px;
  font-weight: 400;
  padding: 5px;
  margin: 10px;
}

.add-productinfo-innerstepform .common-steps-btn .draftbtn {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #C9C9C9;
  border-radius: 24px;
  padding: 5px 24px;
  font-size: 15px;
  text-align: center;
}