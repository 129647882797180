.B2b-Login .StepsixeditIcon {
    position: absolute;
    top: 10px;
    right: 8px;
    font-size: 14px;
}

.B2b-Login .loginHead a p {
    text-align: center;
    margin-top: 5%;
    color: #000;
    font-size: 14px;
}

.B2b-Login .loginHead p.dont-worrry {
    font-size: 14px;
    color: #000;
    font-weight: 400;
    text-align: center;
}

.B2b-Login .personal-details-form-b2b {
    margin: 5% 0px;
    padding: 11%;
    background: #FFFBF0 0% 0% no-repeat padding-box;
    opacity: 1;
}

.B2b-Login .loginHead h3 {
    text-align: center;
    font-size: 24px;
    margin-top: 3%;
}

.B2b-Login .loginHead .lessThanSymbol {
    font-size: 13px;
    margin-right: 5px;
}

.B2b-Login .login_now_btn {
    background: #f88d28 0% 0% no-repeat padding-box;
    border-radius: 24px;
    border: 0;
    padding: 8px 50px;
    font-size: 15px;
}

.B2b-Login .personal-details-form-b2b .form-control {
    font-size: 14px !important;
    border-radius: 5px;
    /* margin-bottom: 30px; */
}

.B2b-Login .field-bottom {
    margin-bottom: 20px;
}

.B2b-Login .personal-details-form-b2b label {
    font-size: 14px;
}

.B2b-Login .personal-details-form-b2b .Passwordfield {
    margin-bottom: 5px !important;
}

.B2b-Login .personal-details-form-b2b .get-otp-text a {
    text-align: end;
    font-size: 12px;
    color: #1c42a9;
    font-weight: 500;
    cursor: pointer;
    justify-content: end;
    display: flex;
}

.B2b-Login .personal-details-form-b2b .OpenGmailsec .opengmailbtn {
    background: #f88d28 0% 0% no-repeat padding-box;
    border-radius: 24px;
    border: 0;
    width: 80%;
    margin-bottom: 20px;
    font-size: 15px;
}

.B2b-Login .personal-details-form-b2b .OpenGmailsec p span:last-of-type {
    font-size: 16px;
    letter-spacing: 0px;
    color: #ff7d00;
}

.B2b-Login .personal-details-form-b2b .OpenGmailsec p span:first-of-type {
    font-size: 16px;
    letter-spacing: 0px;
    color: #000;
}

.B2b-Login .loginHead .headbelowtxt {
    text-align: center;
    font-size: 14px;
}

.B2b-Login .passwordreset {
    text-align: center;
    margin: 5%;
}

.B2b-Login .passwordreset .backlogin {
    color: #f88d28;
    font-size: 14px;
}

.B2b-Login .span-one {
    font-size: 12px;
    font-weight: 400;
    color: #000;
}

.B2b-Login .bottom-d-v a {
    font-size: 12px;
    font-weight: 500;
    color: #000;
}

.B2b-Login .passwordreset h3 {
    font-size: 24px;
    font-weight: bold;
}
.B2b-Login .passwordreset p {
    font-size: 14px;
    font-weight: 400;
}
@media (min-width: 0px) and (max-width: 768px) {
    .B2b-Login .loginHead h3 {
        font-size: 20px;
    }

    .B2b-Login .passwordreset h3 {
        font-size: 20px;
    }

    .B2b-Login .loginHead a p {
        font-size: 12px;
    }
    .B2b-Login .passwordreset p {
        font-size: 12px;

    }
    .B2b-Login .personal-details-form-b2b label {
        font-size: 13px;
    }

    .B2b-Login .personal-details-form-b2b .form-control {
        font-size: 13px !important;
    }

    .B2b-Login .login_now_btn {
        padding: 6px 40px;
        font-size: 14px;
    }

    .B2b-Login .personal-details-form-b2b .OpenGmailsec .opengmailbtn {
        font-size: 14px;
    }

    .B2b-Login .loginHead .headbelowtxt {
        font-size: 12px;
    }
    .B2b-Login .passwordreset .backlogin {
        color: #f88d28;
        font-size: 12px;
    }
}