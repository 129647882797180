.B2b-buyer-order-sec img {
    width: 80px;
    height: 80px;
    margin-right: 20px;
}

.B2b-buyer-order-sec .heading h1 {
    font-size: 20px;
    font-weight: bold;
    color: #000;
}

.B2b-buyer-order-sec .heading {
    border-bottom: 1px solid #b2b2b2;
}

.B2b-buyer-order-sec .product-info-div {
    display: flex;
    padding: 10px 0px;
    width: max-content;
}

.B2b-buyer-order-sec .product-info-div h6 {
    font-size: 14px;
    font-weight: 600;
    color: #000;
}

.B2b-buyer-order-sec p {
    font-size: 12px;
}

.B2b-buyer-order-sec p.price-text {
    font-size: 16px;
    font-weight: 600;
    color: #000;
}

.B2b-buyer-order-sec p.grey-text {
    font-size: 12px;
    color: #707070;
    margin: 0px;
}

.B2b-buyer-order-sec .payment-btn {
    background: #F88D28 0% 0% no-repeat padding-box;
    border-radius: 15px;
    opacity: 1;
    color: #fff;
    font-size: 12px;
    border: none;
    padding: 7px 16px;
}

.B2b-buyer-order-sec .Raise-btn {
    background: #1D44AD 0% 0% no-repeat padding-box;
    border-radius: 15px;
    color: #fff;
    font-size: 12px;
    border: none;
    padding: 4px 16px;
}

.B2b-buyer-order-sec .table {
    --bs-table-bg: transparent;
}

.B2b-buyer-order-sec .common-btn {
    border: none;
    background-color: transparent;
    font-size: 12px;
    color: #000;
    text-decoration: underline;
}

.B2b-buyer-order-sec th {
    font-size: 12px;
    font-weight: 400;
    border: none;
}

.B2b-buyer-order-sec .main-table-div .table {

    border-radius: 20px;
}

.B2b-buyer-order-sec .table>:not(:last-child)>:last-child>* {

    border: none;
}

.B2b-buyer-order-sec .banner-div img {
    width: 100%;
    height: 100%;
}

/* .B2b-buyer-order-sec tbody tr:nth-child(odd) {
    --bs-table-bg: red;
} */
.B2b-buyer-order-sec .bd-right {
    --bs-table-bg: transparent;
    border-radius: 10px;
}

.B2b-buyer-order-sec .bd-right:nth-child(odd) tr {
    --bs-table-bg: #fff;
    border-radius: 5px;
}

.B2b-buyer-order-sec .main-table-div {
    background: #F3F3F3 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 5px;
}

/* .B2b-buyer-order-sec table {
    --bs-table-bg: #F3F3F3;
}
.B2b-buyer-order-sec .bd-right:nth-child(even) tr {
    --bs-table-bg: #F3F3F3;
    border-radius: 20px;
}
.B2b-buyer-order-sec .bd-right:nth-child(odd) tr {
    --bs-table-bg: #fff;
    border-radius: 20px;
} */



@media (min-width: 0px) and (max-width: 575px) {
    .B2b-buyer-order-sec .main-table-div {
        width: 100%;
        overflow-x: scroll;
    }

    .B2b-buyer-order-sec .main-table-div table {
        width: 290%;
    }

    .B2b-buyer-order-sec .banner-div img {
        margin-top: 20px;
    }
}




@media (min-width: 576px) and (max-width: 991px) {
    .B2b-buyer-order-sec .main-table-div {
        width: 100%;
        overflow-x: scroll;
    }

    .B2b-buyer-order-sec .main-table-div table {
        width: 200%;
    }

    .B2b-buyer-order-sec .banner-div img {
        margin-top: 20px;
    }
}