@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700;800;900&display=swap");

body {
  margin: 0px;
  padding: 0px;
  font-family: "Roboto", "sans-serif" !important;
}

a {
  text-decoration: none;
}

.form-select:focus {
  box-shadow: none !important;
}

.wizard-form .apply_now_btn .tabs-btn {
  background: #000;
  border: 1px solid #000;
  border-radius: 50px;
  padding: 5px 26px;
  font-size: 15px;
  margin: 20px 0px;
}

.Variants_section_main .form-label {
  font-weight: 600;
  text-wrap: unset;
}

.form-control:focus {
  box-shadow: none !important;
}

.col-width {
  width: 70px;
}

.col-width-expand {
  width: 8.33333333%;
}

.col-width .websitestab p {
  display: none;
}

.col-width-expand .websitestab p {
  display: block;
  font-size: 11px;
  font-weight: 500;
  margin-left: 8px;
  margin-bottom: 0px;
  margin-top: 3px;
}

.col2-width {
  width: 91%;
}

.sidebar-tab-holder .nav-pills {
  height: 95vh;
  background: #ffffff;
  box-shadow: 0px 3px 29px #00000029;
  border-radius: 10px 10px 0px 0px;
  padding: 20px 0px;
  margin: 10px 0px;
  overflow: hidden;
  overflow-y: scroll;
  border-radius: 10px;
  display: block;
}

.main-advancedashboard {
  height: 95vh;
  overflow: hidden;
  overflow-y: scroll;
}

.mywebsiteMain .tab-content-holder.tab-content {
  height: 95vh;
  overflow: hidden;
  overflow-y: auto;
}

.sidebar-tab-holder .main-advancedashboard .AdvanceDashboard {
  margin: 10px 0px;
}

.sidebar-tab-holder .nav-pills::-webkit-scrollbar {
  width: 0px;
}
.sidebar-tab-holder .nav-pills::-webkit-scrollbar:horizontal {
  height: 8px;
}
/* Track */
.sidebar-tab-holder .nav-pills::-webkit-scrollbar-track {
  background: #e8e8e8;
}
.sidebar-tab-holder .nav-pills::-webkit-scrollbar-track:horizontal {
  background: #e8e8e8;
}
/* Handle */
.sidebar-tab-holder .nav-pills::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 5px;
}
.sidebar-tab-holder .nav-pills::-webkit-scrollbar-thumb:horizontal {
  background: #c7c7c7;
  border-radius: 5px;
}

.Mywebsite .mywebsiteMain .websitestab {
  background-color: #fff;
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
}

.Mywebsite .mywebsiteMain .websitestab::-webkit-scrollbar {
  width: 0px;
}
.Mywebsite .mywebsiteMain .websitestab::-webkit-scrollbar:horizontal {
  height: 8px;
}
/* Track */
.Mywebsite .mywebsiteMain .websitestab::-webkit-scrollbar-track {
  background: #e8e8e8;
}
.Mywebsite .mywebsiteMain .websitestab::-webkit-scrollbar-track:horizontal {
  background: #e8e8e8;
}

.add-productinfo-innerstepform .Variants_section_main .form-label {
  font-weight: 600;
  text-wrap: unset;
}

/* Handle */
.Mywebsite .mywebsiteMain .websitestab::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 5px;
}
.Mywebsite .mywebsiteMain .websitestab::-webkit-scrollbar-thumb:horizontal {
  background: #c7c7c7;
  border-radius: 5px;
}

.tab-content-holder::-webkit-scrollbar {
  width: 0px;
}
.tab-content-holder::-webkit-scrollbar:horizontal {
  height: 8px;
}
/* Track */
.tab-content-holder::-webkit-scrollbar-track {
  background: #e8e8e8;
}
.tab-content-holder::-webkit-scrollbar-track:horizontal {
  background: #e8e8e8;
}
/* Handle */
.tab-content-holder::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 5px;
}
.tab-content-holder::-webkit-scrollbar-thumb:horizontal {
  background: #c7c7c7;
  border-radius: 5px;
}

.btn:focus {
  box-shadow: none !important;
}

.form-control:focus {
  border-color: #ced4da !important;
}

.Mywebsite .mywebsiteMain .nav-pills .nav-link {
  padding: 7px 10px;
}

.Mywebsite
  .mywebsiteMain
  .nav-pills
  .nav-link.active:not(.exclude-active-styling) {
  padding: 7px 10px;
}

.WebsiteDashboard {
  margin-top: 10px;
}

.WebsiteDashboard .searchCard .endCont .input-group-text {
  background: none;
  border: none;
  font-size: 14px;
  color: #1c42a9;
  font-weight: 500;
}

.cardthree .card-header h5 {
  font-size: 20px;
  color: #000;
  font-weight: 600;
}

.progress {
  width: 210px;
  margin: 2px 7px;
  height: 5px;
  margin-top: 7px;
}

/* remove arrow from input type number */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ccc;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.modal-backdrop {
  backdrop-filter: blur(5px) !important;
  background-color: #fff !important;
}

.modal-backdrop.in {
  opacity: 1 !important;
}

.modal.fade.show {
  backdrop-filter: blur(2px);
}

.offcanvas.show {
  backdrop-filter: blur(2px);
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #000;
  content: var(--bs-breadcrumb-divider, ">");
  font-weight: 500;
}

.breadcrumb-item a {
  color: #000;
  font-size: 14px;
}

.breadcrumb-item.active {
  font-size: 14px;
}

.scale-up-hor-left {
  -webkit-animation: scale-up-hor-left 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-hor-left 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-hor-left {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
@keyframes scale-up-hor-left {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}

.scale-down-hor-left {
  -webkit-animation: scale-down-hor-left 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-down-hor-left 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-down-hor-left {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(0.3);
    transform: scaleX(0.3);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
@keyframes scale-down-hor-left {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}

/* .form-check-input[type='checkbox']{
  background-image: radial-gradient( circle at 100% 50%, #28bc0c 50%, #fff 0 ) !important;
  border: 1px solid gray !important;
}

.form-check-input[type='radio']{
  background-image: radial-gradient(
    circle at 50% 50%,
    grey 50%,
    #fff 0
  ) !important;
  border: 1px solid gray !important;
} */

/* .form-check-input[type=checkbox]:checked {
  background-image: radial-gradient(
    circle at 100% 50%,
    #28bc0c 50%,
    #fff 0
  ) !important;
  border: 1px solid gray !important;
} */
/* .form-check-input:checked {
  background-image: radial-gradient(
    circle at 50% 50%,
    grey 50%,
    #fff 0
  ) !important;
  border: 1px solid gray !important;
} */
.uploader .btn--primary,
.uploader .btn--primary:hover {
  color: var(--shade-900);
  background-color: #f88d28 !important;
  border-color: #f88d28 !important;
}

.form-switch .form-check-input:checked {
  /* background-image: radial-gradient( circle at 100% 50%, #28bc0c 50%, #fff 0 ) !important; */
  background-image: url("../public/assest/images/Seller_panel/dashboard/icons/check-success.png") !important;
  border: 1px solid gray !important;
  background-color: #fff;
}

.form-check-input:focus {
  box-shadow: none;
}

body {
  animation: fadeInAnimation ease 1s !important;
  animation-iteration-count: 1 !important;
  animation-fill-mode: forwards !important;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.erkgnkjngerg {
  color: #000;
}

.previeewxsxfcxx-height {
  height: 1000px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.title-stttee-align {
  width: 80%;
  text-align: center;
  justify-content: center;
}

.title-stttee-align h6 {
  font-weight: 600;
}

/* Reviews common css */
.name {
  color: #000000;
  font-size: 14px;
}

.title-tab {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}

button.btn.btn-review {
  color: #ffffff;
  font-size: 13px;
}

@media (min-width: 0px) and (max-width: 767px) {
  .B2bSeller-Product .Product-section .title-tab {
    font-size: 14px;
  }

  .breadcrumb-item a {
    font-size: 13px;
  }

  .title-stttee-align h6 {
    margin-top: 5px;
    font-size: 15px;
  }

  .title-stttee-align {
    /* width: 80%; */
    text-align: center;
    justify-content: center;
    margin: auto;
  }
}

/* My Website Common CSS Start */
.sub-title {
  font-size: 16px;
  font-family: "Roboto" !important;
  font-weight: 500;
}

.maintenance-title {
  font-size: 24px;
  font-weight: bold;
  font-family: "Roboto" !important;
  text-align: center;
  color: black;
}

.sub-title-medium {
  color: #ffffff;
  font-size: 18px;
  font-family: "Roboto" !important;
}

.maintenance-text {
  font-size: 14px;
  font-family: "Roboto" !important;
  color: #ffffff;
}

@media (min-width: 0px) and (max-width: 485px) {
  .sub-title {
    font-size: 14px;
  }

  .sub-title-medium {
    font-size: 14px;
  }

  .maintenance-text {
    font-size: 13px;
  }

  .maintenance-title {
    font-size: 18px;
  }
}

@media (min-width: 486px) and (max-width: 991px) {
  .sub-title {
    font-size: 14px;
  }

  .sub-title-medium {
    font-size: 16px;
  }

  .maintenance-text {
    font-size: 14px;
  }

  .maintenance-title {
    font-size: 20px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .col-width {
    width: 59px;
  }
}
/* My Website Common CSS End */
