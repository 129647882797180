.help-center .nav-pills .nav-link.active {
    color: #1D44AD;
    font-size: 14px;
    background: transparent;
    padding-left: 0;
    padding-right: 0;
}

.help-center .nav-pills .nav-link {
    color: #000000;
    font-size: 14px;
    background: transparent;
    padding-left: 0;
    padding-right: 0;
}

.help-center .nav-pills {
    position: relative;
}

.help-center .nav-pills .right-arrow {
    position: absolute;
    right: 70px;
}

.help-center .title-help-center h2 {
    font-size: 32px;
    font-weight: 700;
}

.help-center .title-help-center p {
    font-size: 14px;
    text-align: justify;
}

.help-center .white-help-bg {
    background: #FFFFFF;
    box-shadow: 0px 3px 14px #00000012;
    border-radius: 10px;
    padding: 20px;
}

.help-center .white-help-bg .help-text-main {
    font-size: 14px;
    text-align: justify;
}

.help-center .white-help-bg .help-text-sub {
    font-size: 14px;
    font-weight: 700;
    text-align: justify;
}

.help-center .accordion-item {
    border: none;
    font-weight: 700;
}

.help-center .accordion-item:focus {
    box-shadow: none;
}

.help-center .accordion-button {
    color: #000;
    font-weight: 700;
    font-size: 14px;
}

.help-center .accordion-button:focus {
    box-shadow: none;
}

.help-center .accordion-button:not(.collapsed) {
    color: #000;
    background: none;
    box-shadow: none;
}

.help-center .accordion-button::after {
    background-image: url("../../../../../public/assest/images/B2Bbuyer/HelpCenter/plus.png");
    /* width: 14px;
    height: 14px; */
}

.help-center .accordion-button:not(.collapsed)::after {
    background-image: url("../../../../../public/assest/images/B2Bbuyer/HelpCenter/minus.png");
    /* width: 14px;
    height: 14px; */
}

.help-center .accordion-body {
    font-size: 14px;
    font-weight: 400;
}

/* Media */

@media(min-width:0px) and (max-width:576px) {
    .help-center .title-help-center h2 {
        font-size: 25px;
        text-align: center;
    }

    .help-center .title-help-center p {
        font-size: 12px;
        text-align: center;
    }

    .help-center .white-help-bg {
        margin-top: 12%;
    }

    .help-center .white-help-bg .help-text-main {
        font-size: 12px;
    }

    .help-center .accordion-body {
        font-size: 12px;
    }

    .help-center .accordion-button {
        color: #000;
        font-size: 13px;
    }
}


@media(min-width:577px) and (max-width:768px) {
    .help-center .title-help-center h2 {
        font-size: 28px;
    }

    .help-center .title-help-center p {
        font-size: 13px;
    }

    .help-center .white-help-bg .help-text-main {
        font-size: 12px;
    }

    .help-center .accordion-body {
        font-size: 12px;
    }

    .help-center .accordion-button {
        color: #000;
        font-size: 13px;
    }

    .help-center .nav-pills .nav-link {
        font-size: 13px;
    }

    .help-center .nav-pills .nav-link.active {
        font-size: 13px;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .help-center .title-help-center h2 {
        font-size: 28px;
    }

    .help-center .title-help-center p {
        font-size: 13px;
    }

    .help-center .white-help-bg .help-text-main {
        font-size: 12px;
    }

    .help-center .accordion-body {
        font-size: 12px;
    }

    .help-center .accordion-button {
        color: #000;
        font-size: 13px;
    }

    .help-center .nav-pills .nav-link {
        font-size: 13px;
    }

    .help-center .nav-pills .nav-link.active {
        font-size: 13px;
    }
}

@media(min-width:992px) and (max-width:1400px) {
    .help-center .nav-pills .right-arrow {
        position: absolute;
        right: 0px;
    }
}