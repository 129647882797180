.inquiry-modal .modal-header {
    border-bottom: none;
}

.inquiry-modal .modal-content {
    border: none;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #00000017;
    border-radius: 5px;
}

.inquiry-modal .modal-body p {
    font-size: 15px;
    text-align: center;
}