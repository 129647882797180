.Portfolio-Cards  .card-portfolio {
  width: 100%;
  height: 310px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 24px;
  position: relative;
  margin-bottom: 10%;
}
.Portfolio-Cards .card-portfolio .overlay {
  width: 100%;
  top: 100%;
  position: absolute;
  height: 0px;
  background: transparent
    linear-gradient(180deg, #ffffff00 0%, #ffa65438 50%, #ffe8d2 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 0px 3px #00000029;
  border-radius: 24px;
}
.Portfolio-Cards .card-portfolio:hover .overlay {
  top: 0%;
  height: 100%;
}
.Portfolio-Cards .card-portfolio  .content .sub-title-medium {
  text-align: center;
  color: black;
  font-weight: bold;

  margin: 37px 0px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}
.Portfolio-Cards .card-portfolio .content .like-btn .circle {
  width: 34px;
  height: 34px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
}
.Portfolio-Cards .card-portfolio .content  .like-btn {
  text-align: -webkit-right;
  padding: 3px 12px;
}
.gobiheartss {
  color: red;
}
.gobiheart {
  color: grey;
}
.Portfolio-Cards .card-portfolio .content .like-btn .circle .hearticn {
  padding: 8px 9px;
}
.Portfolio-Cards .card-portfolio .content .portfolio-image img.portfolio {
  width: 192px;
  height: 150px;
  transition: 0.3s;
  object-fit: contain;
}
.Portfolio-Cards .card-portfolio:hover .content .portfolio-image img.portfolio {
  transform: scale(1.1);
}
.Portfolio-Cards .card-portfolio  .content .sub-title-medium {
  text-align: center;
  color: black;
  font-weight: bold;
  /* margin-top: 19%; */
  margin: 45px 0px;
}
.Portfolio-Cards .card-portfolio:hover .content .sub-title-medium {
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.Portfolio-Cards .card-portfolio  .content .Button-section {
  transition: 0.3s;
  display: none;
  margin-top: -40%;
}

.Portfolio-Cards .card-portfolio:hover .content .Button-section {
  margin-top: 14px;
  display: flex;
  justify-content: space-evenly;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; 
}

.Portfolio-Cards .card-portfolio .content .Button-section .live {
  background: #f88d28 0% 0% no-repeat padding-box;
  border-radius: 24px;
  padding: 6px 49px;
  color: #f5f5f5;
  border: none;
}
.Portfolio-Cards .card-portfolio .content .Button-section:hover .live {
  background: transparent linear-gradient(180deg, #F88D28 0%, #A45100 100%) 0% 0% no-repeat padding-box;
border-radius: 24px;
}
.Portfolio-Cards .card-portfolio .content .Buy-Now {
  margin-top: -10%;
}
.Portfolio-Cards  .portfolio-image {
  text-align: center;
  /* padding: 9px 37px; */
  overflow: hidden;
}
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-20px); 
    transform: translateY(-20px); 
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-20px); 
    transform: translateY(-20px); 
  }
}
.Portfolio-Cards .card-portfolio  .content {
  position: absolute;
  top: 2px;
  width: 100%;
}

 @media (min-width:280px) and (max-width: 320px) {
  .Portfolio-Cards .card-portfolio .content .portfolio-image img.portfolio {
    width: 100%;
    height: 187px;
}
.portfolio-image {
  text-align: center;
 
}
.Portfolio-Cards .card-portfolio .content .Buy-Now {
  margin-top: -4%;
}
.Portfolio-Cards .card-portfolio  .content .sub-title-medium {

  margin: 26px 0px;
}
.Portfolio-Content .Search-Box {
  margin: 10px 10px;
}
}
@media (min-width:321px) and (max-width: 485px) {
  .Portfolio-Cards .card-portfolio .content .portfolio-image img.portfolio {
    width: 75%;
    height: 187px;
}
.portfolio-image {
  text-align: center;
 
}
.Portfolio-Cards .card-portfolio .content .Buy-Now {
  margin-top: -4%;
}
.Portfolio-Cards .card-portfolio  .content .sub-title-medium {

  margin: 26px 0px;
}
.Portfolio-Content .Search-Box {
  margin: 10px 10px;
}
}
@media (min-width:486px) and (max-width: 575px) {
  .Portfolio-Cards .card-portfolio .content .portfolio-image img.portfolio {
    width: 300px;
    height: 187px;
}
.portfolio-image {
  text-align: center;
 
}
.Portfolio-Cards .card-portfolio .content .Buy-Now {
  margin-top: -4%;
}
.Portfolio-Cards .card-portfolio  .content .sub-title-medium {

  margin: 26px 0px;
}
.Portfolio-Content .Search-Box {
  margin: 10px 10px;
}
}
@media (min-width:576px) and (max-width: 992px) {
  .Portfolio-Cards  .card-portfolio {
    height: 320px;
  }
  .Portfolio-Content .Search-Box {
    margin: 0px 10px;
}
}

@media (min-width: 992px) and (max-width: 1200px) {
  .Portfolio-Cards  .card-portfolio {
    height: 304px;
}
/* .Portfolio-Content .Search-Box {
  margin: 10px 10px;
} */
}
