.BlogsPage .Blogs-Card {
    padding: 24px 22px;
    /* height: 475px; */
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3px #00000029;
    border-radius: 14px;
    margin-bottom: 45px;
    opacity: 1;
}

.BlogsPage .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(180deg, #00000047 0%, #000000 100%) 0% 0% no-repeat padding-box;
    border-radius: 21px;
}

.BlogsPage .comment_text_div .maintenance-text {
    text-align: start;
    color: black;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 5;
}

.BlogsPage .Blogs-Card .book img.books-img {
    height: 300px;
    width: 100%;
    /* object-fit: cover; */
}

.BlogsPage .Blogs-Card .book {
    position: relative;
}

.BlogsPage .Blogs-Card .book .content {
    position: absolute;
    bottom: 15px;
    width: 100%;
    padding: 0px 7px;
}

.BlogsPage .Blogs-Card .book .content h1.maintenance-title {
    padding: 0px 13px;
    color: #f88d28;
    text-align: start;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

.BlogsPage .Blogs-Card .book .content p.sub-title {
    color: white;
    font-weight: 100;
    margin-bottom: 0;
}

.BlogsPage .Blogs-Card .content .maintenance-text {
    color: white;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

.BlogsPage .Blogs-Card .maintenance-text {
    color: black;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 6;
    text-align: start;
}

.BlogsPage p.maintenance-text {
    color: black;
    margin: 5px 12px;
}

.comment_section .comment_head_div .comment_heading {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.16px;
    color: #000000;
    margin-bottom: 24px;
    opacity: 1;
    margin-top: 23px;
}

.comment_section_main {
    border-radius: 5px;
    padding: 23px;
    margin-bottom: 30px;
    opacity: 1;
}

.comment_section_main:nth-child(odd) {
    background-color: #f2f2f2;
}

.comment_section_main:nth-child(even) {
    background-color: #fff;
    border: 1px solid #707070;
}

.comment_section .comment_div {
    display: flex;
    align-items: center;
}

.comment_section .comment_img {
    width: 100px;
    text-align: center;
}

.comment_section .mens_img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
}

.comment_section .comment_image_div .commment_date {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #000000;
    margin: 22px 0px 0px 0px;
    opacity: 1;
}

.comment_section .comment_text_div .comment_text {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 5px;
    opacity: 1;
}

.comment_section .reply_button_div {
    text-align: right;
}

.comment_section .reply_button_div .reply_button {
    background: #779cff 0% 0% no-repeat padding-box;
    border-radius: 7px;
    font: normal normal normal 15px/16px Roboto;
    letter-spacing: 0px;
    color: #ffffff;
    width: 86px;
    height: 28px;
    border: none;
    opacity: 1;
}

.comment_section .reply_button_div .reply_button:hover {
    background: #1d44ad 0% 0% no-repeat padding-box;
}

/* latest blog section css start */
.latest_blog_section {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3px #00000029;
    padding: 22px;
    border-radius: 14px;
    opacity: 1;
}

.latest_blog_section .latest_blog_heading_div {
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border-radius: 5px;
    text-align: center;
    padding: 12px 0px;
    opacity: 1;
}

.latest_blog_section .latest_blog_heading_div .latest_blog_heading {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

.latest_blog_section .all_blog_cards_div {
    height: auto;
    max-height: 600px;
    overflow-y: scroll;
    scrollbar-width: thin;
}

.latest_blog_section .blog_card_div {
    display: flex;
    align-items: center;
    background: #f9f2eb 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    padding: 17px 12px;
    margin-top: 20px;
}

.latest_blog_section .blog_img {
    width: 116px;
    height: 93px;
    border-radius: 12px;
    background: transparent;
}

.latest_blog_section .blog_text_div {
    padding-left: 8px;
}

.latest_blog_section .blog_text_div .blog_text {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
}

section.BlogsPage {
    position: relative;
    top: -125px;
}

.BlogsPage .Blogs-Card .content .calender {
    display: flex;
}

/* latest blog section css end */


/* comment css */
textarea {
    width: 100%;
}

.BlogsPage .comment-box {
    height: 80px;
    border: 1px solid;

}

.BlogsPage .comment .publish-btn .publish {
    background: #779cff 0% 0% no-repeat padding-box;
    border-radius: 7px;
    font: normal normal normal 15px / 16px Roboto;
    letter-spacing: 0px;
    color: #ffffff;
    width: 86px;
    height: 28px;
    border: none;
    opacity: 1;
}

.BlogsPage .comment .publish-btn {
    text-align: end;
}

.BlogsPage .comment p.text {
    padding: 27px 13px;
}

@media (min-width: 0px) and (max-width: 485px) {
    .BlogsPage .Blogs-Card .book img.books-img {
        height: 260px;
    }

    .BlogsPage .Blogs-Card {
        padding: 13px 13px;
        margin-bottom: 20px;
    }

    .comment_section_main {
        padding: 15px;
        margin-bottom: 15px;
    }

    .BlogsPage .Blogs-Card .content .maintenance-text {
        -webkit-line-clamp: 2;
    }

    .latest_blog_section .latest_blog_heading_div {
        padding: 10px 0px;
    }

    .latest_blog_section .blog_img {
        width: 80px;
        height: 65px;
    }

    .latest_blog_section {
        padding: 10px;
    }

    section.BlogsPage {
        top: -47px;
    }

    .comment_section .comment_div {
        display: block;
        align-items: center;
    }

    .BlogsPage .Blogs-Card .content .calender {
        display: block;
    }

    .BlogsPage .Blogs-Card .maintenance-text {
        text-align: justify;

    }

    .BlogsPage .comment_text_div .maintenance-text {
        text-align: justify;

    }
}

@media (min-width: 486px) and (max-width: 575px) {
    .BlogsPage .Blogs-Card .book img.books-img {
        height: 270px;
    }

    .BlogsPage .Blogs-Card {
        padding: 13px 13px;
        margin-bottom: 20px;
    }

    .comment_section_main {
        padding: 20px;
        margin-bottom: 20px;
    }

    .latest_blog_section .latest_blog_heading_div {
        padding: 12px 0px;
    }

    .latest_blog_section .blog_img {
        width: 90px;
        height: 72px;
    }

    .latest_blog_section {
        padding: 13px;
    }

    .BlogsPage .Blogs-Card .content .calender {
        display: block;
    }

    section.BlogsPage {

        top: -45px;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .BlogsPage .Blogs-Card .book img.books-img {
        height: 270px;
    }

    .BlogsPage .Blogs-Card {
        padding: 13px 13px;
        margin-bottom: 20px;
    }

    .comment_section_main {
        padding: 20px;
        margin-bottom: 20px;
    }

    .latest_blog_section .latest_blog_heading_div {
        padding: 12px 0px;
    }

    .latest_blog_section .blog_img {
        width: 90px;
        height: 72px;
    }

    .latest_blog_section {
        padding: 13px;
    }

    .BlogsPage .Blogs-Card .content .calender {
        display: block;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .BlogsPage .Blogs-Card .content .calender {
        display: block;
    }
}