/* Home Banner CSS Started */
.Home-Banner-Section {
    position: relative;
    top: -145px;
}

.Home-Banner-Section .mySwiper .swiper {
    width: 100%;
    height: 100%;
}

.Home-Banner-Section .mySwiper .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Home-Banner-Section .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Home-Banner-Section .slider-btn {
    position: relative;
}

.Home-Banner-Section .slider-btn .back-btn {
    content: url("../../../../../public/assest/images/MyWebsite/HomeImages/HomePage/right.png");
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: absolute;
    bottom: 66px;
    z-index: 99;
    left: 50%;
    transform: translateX(-50%);
}

.Home-Banner-Section .slider-btn .next-btn {
    content: url("../../../../../public/assest/images/MyWebsite/HomeImages/HomePage/left.png");
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: absolute;
    bottom: 66px;
    z-index: 99;
    right: 50%;
    transform: translateX(-50%);
}

.Home-Banner-Section .Banner-Swiper {
    position: relative;
}

/* Home Banner CSS End */

/* Providing-Plans css started */

.Home-Banner-Section .Providing-Plans {
    position: absolute;
    top: 94%;
    width: 100%;
    z-index: 99;
}

.Home-Banner-Section .Provide-Plans-Background {
    height: 171px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 14px;
}

.Home-Banner-Section .Providing-Plans .Provide-Plans-Background .Plan .main-blue .blue-circle {
    padding: 18px 19px;
    border-radius: 100px;
    width: 62px;
    height: 62px;
    background: transparent linear-gradient(180deg, #1d44ad 0%, #1553f9 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
}

.Home-Banner-Section .Providing-Plans .Provide-Plans-Background .Plan .main-blue .blue-circle img.icon {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;
}
.Home-Banner-Section .Providing-Plans .Provide-Plans-Background .Plan .main-blue:hover .blue-circle  img.icon {
    transform: scale(1.2);
    cursor: pointer;
}

.Home-Banner-Section .Providing-Plans .Provide-Plans-Background .Plan {
    padding: 35px 0px;
    display: flex;
    justify-content: space-around;
    cursor: pointer;
}

.Home-Banner-Section .Providing-Plans .Provide-Plans-Background .Plan .main-blue {
    text-align: -webkit-center;
}

.Home-Banner-Section .Providing-Plans .Provide-Plans-Background .Plan .border-plans {
    margin: 16px 0px;
    border-right: 1px solid;
}

.Home-Banner-Section .Providing-Plans .Provide-Plans-Background .Plan .main-blue .sub-title {
    margin-top: 9px;
    margin-bottom: 0px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}
.Home-Banner-Section .Providing-Plans .Provide-Plans-Background .Plan .main-blue:hover .sub-title {
    text-decoration: underline;
    margin-top: 9px;
cursor: pointer;
}
/* Providing-Plans css End */

/*************************** Media Query Started ***************************/

@media (min-width: 0px) and (max-width: 767px) {
    .Home-Banner-Section .Providing-Plans .Provide-Plans-Background .Plan {
        display: block;
    }

    .Home-Banner-Section .Provide-Plans-Background {
        height: auto;
    }

    .Home-Banner-Section .Providing-Plans {
        position: unset;
        margin-top: 7%;
    }
    .Home-Banner-Section .slider-btn .back-btn {
        width: 35px;
        height: 35px;
        bottom: 3px;
    }

    .Home-Banner-Section .slider-btn .next-btn {
        width: 35px;
        height: 35px;
        bottom: 3px;
    }

    .Home-Banner-Section {
        top: 0;
    }

    .Home-Banner-Section {
        top: -57px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .Home-Banner-Section .Providing-Plans .Provide-Plans-Background .Plan .main-blue .blue-circle {
        padding: 10px 11px;
        width: 45px;
        height: 45px;
    }

    .Home-Banner-Section .Providing-Plans .Provide-Plans-Background .Plan .main-blue .blue-circle img.icon {
        width: 20px;
        height: 20px;
    }

    .Home-Banner-Section .Providing-Plans .Provide-Plans-Background .Plan {
        padding: 40px 0px;

    }
}
@media (min-width: 2000px) and (max-width: 2685px) {
.Maintenance {
    margin-top: 12%;
}
.Home-Banner-Section .slider-btn .back-btn {
   
    bottom: 78px;
}
.Home-Banner-Section .slider-btn .next-btn {
    
    bottom: 78px;
}
}