.Overview .overViewpageHEad h3 {
  font-size: 24px;
}

.Overview .overviewContent {
  background: #f9fbff 0% 0% no-repeat padding-box;
  padding: 3%;
}

.Overview .personalBox {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d2deff;
  border-radius: 10px;
  padding: 2% 2%;
  position: relative;
}

.Overview .personalBox .editIcon {
  position: absolute;
  top: 8px;
  right: 0;
  font-size: 14px;
  text-align: end;
  color: #1c42a9;
  cursor: pointer;
}

.Overview .personalBox .personalDetailsInner p {
  font-size: 14px;
}
.Overview .PersonalHead h3 {
  font-size: 16px;
}

.Overview .personalDetailsInner p:nth-of-type(2) {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0;
}
.Overview .tabs-btn {
  background: #f88d28 0% 0% no-repeat padding-box;
  border-radius: 24px;
  border: 0;
  font-size: 13px;
}

.Overview .apply_now_btn p {
  font-size: 13px;
}

.Overview .apply_now_btn {
  margin-top: 45px;
}

.Overview .overviewPerRow {
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 22px;
}

.Overview .personalBox .firmDetailsCard {
  background: #e1f5ff 0% 0% no-repeat padding-box;
  border: 0;
  height: 160px;
}

.Overview .personalBox .firmDetailsCard .firmcardInsideCon h3 {
  font-size: 14px;
}

.Overview .personalBox .firmDetailsCard .firmcardInsideCon p {
  font-size: 16px;
  font-weight: 600;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .Overview .personalDetailsInner p:nth-of-type(2) {
    font-size: 13px;
  }

  .Overview .personalBox .firmDetailsCard .firmcardInsideCon p {
    font-size: 14px;
  }
}
