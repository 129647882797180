.b2b-mywallet-main-page {
    margin-top: 2%;
    margin-bottom: 5%;
}

.b2b-mywallet-main-page .slider-img {
    width: 100%;
    height: 100%;
}

.b2b-mywallet-main-page .main-banner-div {
    position: relative;
}

.b2b-mywallet-main-page .main-banner-div .bg-img {
    width: 100%;
    height: 150px;
    border-radius: 10px;
}

.b2b-mywallet-main-page .main-banner-div {
    position: relative;
}

.b2b-mywallet-main-page .main-banner-div-two {
    background: #E5ECFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    /* opacity: 0.46; */
    height: 150px;
    padding: 14px 14px 14px 20px;
}

.b2b-mywallet-main-page .main-banner-div-two .rs-img {
    width: 36px;
    height: 53px;
    margin-bottom: 10px;
}

.b2b-mywallet-main-page .main-banner-div-two p {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    margin: 0px;
}

.b2b-mywallet-main-page .main-banner-div-two h6 {
    font-size: 24px;
    font-weight: bold;
    color: #000;
}

.b2b-mywallet-main-page .main-banner-div .overlay-content .add-btn {
    background: #F88D28 0% 0% no-repeat padding-box;
    border-radius: 24px;
    opacity: 1;
    border: none;
    font-size: 14px;
    color: #fff;
    padding: 5px 20px;
}

.b2b-mywallet-main-page .main-banner-div .overlay-content h6 {
    font-size: 32px;
    font-weight: bold;
    color: #000;
}

.b2b-mywallet-main-page .main-banner-div .overlay-content p {
    font-size: 16px;
    font-weight: 500;
    color: #000;
}

.b2b-mywallet-main-page .main-banner-div .overlay-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 14px 14px 14px 20px;
}

.b2b-mywallet-main-page .main-banner-div .overlay-color {
    background: #FFE6CE 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 0.46;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
}

@media(min-width:0px) and (max-width:1200px) {
    .b2b-mywallet-main-page .main-banner-div .overlay-content h6 {
        font-size: 20px;
    }

    .b2b-mywallet-main-page .main-banner-div .overlay-content p {
        font-size: 14px;
    }

    .b2b-mywallet-main-page .main-banner-div .overlay-content .add-btn {
        font-size: 12px;

    }

    .b2b-mywallet-main-page .main-banner-div-two h6 {
        font-size: 20px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .b2b-mywallet-main-page .main-banner-div .overlay-content h6 {
        font-size: 20px;
    }

    .b2b-mywallet-main-page .main-banner-div .overlay-content p {
        font-size: 14px;
    }

    .b2b-mywallet-main-page .main-banner-div .overlay-content .add-btn {
        font-size: 12px;
        padding: 5px 11px;
    }
}