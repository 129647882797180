.Advertisement .advetTabs .advertmaintabs {
    background-image: url("../../../../../../public/assest/images/Seller_panel/dashboard/images/advertisement.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 16px 0px 16px 31px;
    position: relative;
}

.Advertisement .advetTabs .settingIcon {
    position: absolute;
    right: 22px;
    top: 50%;
    transform: translate(0%, -50%);
}

.Advertisement .advetTabs .nav-pills .nav-link {
    color: #000;
    font-size: 13px;
    padding: 3px 18px;
}

/* .Advertisement .advetTabs .dowwnloaddata {
    display: flex;
} */

.Advertisement .advetTabs .nav-pills .nav-link.active {
    color: #fff;
    background: #1C42A9 0% 0% no-repeat padding-box;
    border-radius: 14px;
    padding: 3px 18px;
}

.Advertisement .advetTabs .addtaskbtn {
    background: #F88D28 0% 0% no-repeat padding-box;
    border-radius: 16px;
    border: 0;
    font-size: 14px;
    padding: 5px 25px;
}

.Advertisement .actionIcon {
    margin-right: 15px;
    text-align: center;
    margin-left: 15px;
}

.Advertisement .imgMain .bjvbhbvh-img{
   width: 50px;
   height: 50px;
}