.home-banner {
    background: transparent linear-gradient(180deg, #ffc670e3 0%, #ffc40099 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    /* opacity: 0.72; */
    margin: 40px 20px;
    height: 452px;
}

.home-banner .heading-holder {
    margin-top: 20%;
}

.home-banner .heading-holder h5 {
    font-size: 30px;
    font-weight: 700;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4;
    line-clamp: 4;
}

.home-banner .button-holder {
    margin-top: 14%;
}

.home-banner .button-holder .sell-btn {
    background-color: #F88D28;
    border-radius: 0%;
    color: #FFF;
    font-size: 16px;
    width: 122px;
    height: 38px;

}
.home-banner .button-holder .sell-btn:focus{
    box-shadow: none;
}

.home-banner .button-holder .sell-btn:hover {
    background-color: black;
    border-radius: 0%;
    color: #FFF;
    font-size: 16px;
    width: 122px;
    height: 38px;

}

.home-banner .imge-holder .bannn-img {
    width: 424px;
    height: 520px;
}

@media(min-width:992px) and (max-width:1200px) {
    .home-banner {
        height: 390px;
    }

    .home-banner .imge-holder .bannn-img {
        width: 374px;
        height: 453px;
    }
}

@media(min-width:768px) and (max-width:991px) {
    .home-banner {
        height: 348px;
    }

    .home-banner .imge-holder .bannn-img {
        width: 348px;
        height: 406px;
    }

    .home-banner .heading-holder h5 {
        font-size: 24px;
    }

    .home-banner .heading-holder {
        margin-top: 34%;
    }
}


@media(min-width:0px) and (max-width:767px) {
    .home-banner {
        height: auto;
    }

    .home-banner .imge-holder .bannn-img {
        width: 327px;
        height: 364px;
    }

    .home-banner .heading-holder h5 {
        font-size: 20px;
        text-align: center;
    }

    .home-banner .heading-holder {
        margin-top: 11%;
    }

    .home-banner .button-holder {
        text-align: center;
        margin-top: 9%;
    }
}

@media(min-width:0px) and (max-width:360px) {
    .home-banner .imge-holder .bannn-img {
        width: 273px !important;
        height: 338px !important;
    }
}