.order-summary-card {
    background-color: #FFF7EF;
    padding: 10px 20px;
}

.order-summary-card .flex-div {
    display: flex;
    justify-content: space-between;
}

.order-summary-card .border-div {
    border-bottom: 1px solid #B1B1B1;
}

.order-summary-card .flex-div p {

    color: #000000;
    font-size: 12px;
    font-weight: 400;
}

.order-summary-card .flex-div h6 {

    color: #000000;
    font-size: 16px;
    font-weight: 500;
}

.order-summary-card .proceed-btn {
    width: 80%;
    background: #F88D28 0% 0% no-repeat padding-box;
    border-radius: 24px;
    opacity: 1;
    color: #fff;
    border: none;
    margin: auto;
    font-size: 14px;
}

.success-modal-payment .modal-header {
    border: none;
}
.success-modal-payment .modal-body {
    text-align: center;
    font-size: 16px;
}
.success-modal-payment .modal-content {
   
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #00000017;
    border-radius: 5px;
    opacity: 1;
    border: none;
}