.CategoryDetails .modal-body {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px #0000003B;
    padding: 0;
    border: 1px solid #AEAEAE;
}

.CategoryDetails .modal-body .categoryMaintable table {
    margin-bottom: 0;
}

.CategoryDetails .categoryMaintable .table>:not(caption)>*>* {
    font-size: 12px;
    padding: 15px 15px !important;
    text-wrap: nowrap;
}

.CategoryDetails .modal-content {
    border: 0;
    background-color: transparent !important;
}

.CategoryDetails .modal-header {
    border: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    background: transparent;
}