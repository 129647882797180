.SkinCare {
  margin: 0px auto;
  width: 100%;
  margin-bottom: 5%;
}

.SkinCare .SkinCare_Swiper .img-holder {
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
  border-radius: 10px;
}

.SkinCare .SkinCare_Swiper .img-holder:hover .SkinCare-img {
  transition: transform 0.3s ease-in-out;
  transform: scale(1.1);
}

.SkinCare .SkinCare_Swiper .img-holder .SkinCare-img {
  width: auto;
  border-radius: 10px;
}
