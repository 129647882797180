.ViewEditModal .viewProdbtn {
  background: #efefef 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: 0;
  font-size: 13px;
  padding: 7px 12px;
  width: 116px;
}

.ViewEditModal .modal-title {
  font-size: 15px;
}
