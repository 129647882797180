
.password-successful .success-reset {
    text-align: center;
    padding: 30px ;
}

.password-successful .success-reset h3 {
    font-size: 20px;
    font-weight: 700;
    color: #000;
}

.password-successful .success-reset p {
    font-size: 14px;
    color: #000;
}

.password-successful .back-login {
    background: transparent;
    color: #F88D28;
    font-size: 14px;
    border: none;
}