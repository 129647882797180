.Sidebar_affilate_dashboard {
    background: #F7F4F1;
    padding: 0px 15px;
    box-shadow: 0px 3px 19px #0000001A;
    position: relative;
    z-index: 999;
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
}
.Sidebar_affilate_dashboard .admin_profile .img-holder .logo-img {
    width: 80px;
    height: 80px;
    border-radius: 50px;
}

.Sidebar_affilate_dashboard .admin_profile .text-holder h4 {
    text-align: center;
    font-size: 14px;
    color: #000000;
}

.Sidebar_affilate_dashboard .admin_profile .text-holder .blue-tick{
    color: #49ADF4;
}

.Sidebar_affilate_dashboard .admin_profile .text-holder p {
    text-align: center;
    font-size: 10px;
    color: #8E8E8E;
    margin-bottom: 0px;
}

.Sidebar_affilate_dashboard .admin_profile .text-holder .rating {
    background: #FFFFFF;
    box-shadow: 0px 0px 5px #0000001A;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 12px;
    border-radius: 50px;
    height: 20px;
    margin: 5px auto;
}

.Sidebar_affilate_dashboard .admin_profile .text-holder .rating span {
    text-align: center;
    font-size: 10px;
    color: #000000;
    margin-left: 5px;
}



.Sidebar_affilate_dashboard .admin_profile .img-holder {
    margin: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Sidebar_affilate_dashboard .brown-border {
    border-bottom: 2px solid #BCBCBC;
}

.Sidebar_affilate_dashboard .logo-image-main .logo-img {
    width: 100%;
    padding: 10px 30px;
}

.Sidebar_affilate_dashboard .tab-main {
    margin-top: 10%;
}



.Sidebar_affilate_dashboard .accordion .accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    color: #333333;
    text-align: center;
    border: none;
    background-color: #F7F4F1;
    margin-top: 6%;
}

.Sidebar_affilate_dashboard .accordion-button:focus {
    z-index: 3;
    border: none;
    font-size: 14px;
    border-radius: 50px;
    outline: none;
    box-shadow: unset !important;
}

.Sidebar_affilate_dashboard .accordion .accordion-button a {
    color: #000;
}

.Sidebar_affilate_dashboard .accordion-button:not(.collapsed) a {
    color: #fff;
}

.Sidebar_affilate_dashboard .accordion-item {
    background-color: transparent;
    border: 0px;
}

.Sidebar_affilate_dashboard .accordion .accordion-arrow {
    display: none;
}

.Sidebar_affilate_dashboard .accordion-button::after {
    background-image: none;
}

.Sidebar_affilate_dashboard a {
    text-decoration: none;
}

.Sidebar_affilate_dashboard .accordion-button:not(.collapsed) {
    box-shadow: none;
    background: #f88d28;
    color: #FFFFFF;
    border-radius: 50px;
    font-size: 14px;
    margin-top: 6%;
}

.Sidebar_affilate_dashboard .side-img {
    width: 18px;
    height: 18px;
}

.Sidebar_affilate_dashboard .accordion .accordion-item .ico-span .svg-inline--fa {
    color: #bcbcbc;
    font-size: 16px;
    margin-right: 10px;
}

.Sidebar_affilate_dashboard .accordion .accordion-item .accordion-button:not(.collapsed) .ico-span .svg-inline--fa {
    color: #fff;
}

.Sidebar_affilate_dashboard .accordion .accordion-item .inner-dropp {
    background-color: #f1f1f1;
    margin-top: 15px;
    padding: 0px 5px;
}

.Sidebar_affilate_dashboard .accordion .accordion-item .inner-dropp .inner-menu {
    font-size: 13px;
    color: #000;
}

.Sidebar_affilate_dashboard .accordion .accordion-item .inner-dropp .inner-menu-icc {
    color: #f88d28;
    font-size: 15px;
    margin-right: 15px;
}

.Sidebar_affilate_dashboard .accordion .accordion-item .inner-menu-div {
    padding: 7px 3px;
    position: relative;
}

.Sidebar_affilate_dashboard .accordion .accordion-item .inner-menu-div .inner-menu .next-fafaf {
    position: absolute;
    right: 7px;
    top: 12px;
}

.Sidebar_affilate_dashboard .accordion .accordion-item .inner-menu-div:hover {
    background-color: #e8eef7;
}

.Sidebar_affilate_dashboard .accordion .accordion-item .inner-menu-div .sublistt-div {
    position: absolute;
    right: -155px;
    top: 0px;
    padding: 10px;
    background-color: #fff;
    width: 130px;
    box-shadow: 0px 0px 5px 0px #ccc;
}

.Sidebar_affilate_dashboard .accordion .accordion-item .inner-menu-div .sublistt-div .sublist-menu {
    color: #000;
    font-size: 13px;
}

.Sidebar_affilate_dashboard .accordion-item:first-of-type>.accordion-header .accordion-button {
    border-top-left-radius: 43px;
    border-top-right-radius: 43px;
}

.Sidebar_affilate_dashboard .accordion-item:first-of-type>.accordion-header .accordion-button:focus {
    border-top-left-radius: 43px;
    border-top-right-radius: 43px;
}

/* .Sidebar_affilate_dashboard .accordion-item:last-of-type .accordion-button.collapsed {
    padding-top: 0;
    padding-bottom: 0;
} */

.Sidebar_affilate_dashboard .accordion-body {
    padding: 0;
}

@media(min-width:992px) and (max-width:1200px) {
    .Sidebar_affilate_dashboard .accordion-button {
        padding: 10px;
    }
}

@media(min-width:768px) and (max-width:991px) {
    .Sidebar_affilate_dashboard .accordion-button {
        padding: 10px;
        font-size: 12px;
    }

    .Sidebar_affilate_dashboard .accordion-button:not(.collapsed) {
        font-size: 12px;
    }

    .Sidebar_affilate_dashboard {
        padding: 0px 5px;
    }
}