.banner-image-main {
    margin-top: 5%;
    position: relative;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.banner-image-main .seller-banner-img {
    background: no-repeat;
    width: 100%;
    height: 343;
    display: block;
    transition: 1.4s;
    border-radius: 20px;
}

.banner-image-main:hover .seller-banner-img {
    transform: scale(1.1);
}

.banner-image-main .banner-textt h5 {
    font-size: 30px;
    font-weight: 600;
    color: #FF4948;
    position: absolute;
    top: 4%;
    /* left: 27%; */
    width: 100%;
    text-align: center;
}

@media(min-width:0px) and (max-width:320px) {
    .banner-image-main .banner-textt h5 {
        font-size: 10px;
        top: 7%;
        left: 0%;
    }
}

@media(min-width:321px) and (max-width:485px) {
    .banner-image-main .banner-textt h5 {
        font-size: 10px;
    }
}

@media(min-width:486px) and (max-width:576px) {
    .banner-image-main .banner-textt h5 {
        font-size: 10px;
    }
}

@media(min-width:577px) and (max-width:768px) {
    .banner-image-main .banner-textt h5 {
        font-size: 15px;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .banner-image-main .banner-textt h5 {
        font-size: 25px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .banner-image-main .banner-textt h5 {
        font-size: 25px;
    }
}