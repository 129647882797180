.Header_seller_dashboard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 19px #0000001a;
  position: sticky;
  z-index: 99;
  top: 0;
}

.Header_seller_dashboard .user-img {
  height: 34px;
  width: 34px;
  border: 3px solid #f88d28;
  border-radius: 50px;
}

.Header_seller_dashboard .next-btn-class {
  background: #bcbcbc;
  border: none;
  padding: 1px 8px 1px 0px;
}

.Header_seller_dashboard .next-btn-class:hover {
  background: #bcbcbc;
  border: none;
  padding: 1px 8px 1px 0px;
}

.Header_seller_dashboard .header-text {
  font-size: 12px;
  font-weight: 500;
  color: #404040;
  text-wrap: nowrap;
}

.Header_seller_dashboard .notification-main {
  position: relative;
  cursor: pointer;
}

.Header_seller_dashboard .number-bg {
  position: absolute;
  top: 11px;
  left: 11px;
  border-radius: 5px;
  border: 2px solid #fff;
}

.Header_seller_dashboard .user-image {
  /* border-left: 1px solid #B1B1B1; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.Header_seller_dashboard .header-text span {
  font-size: 15px;
  color: #404040;
  font-weight: 600;
}

.Header_seller_dashboard .input-group-text {
  color: #b9b9b9;
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  border-radius: 50;
  border: none;
  border-radius: 50px;
  height: 40px;
}

.Header_seller_dashboard .input-group {
  border-radius: 50px;
  background: #f0f2f7;
}

.Header_seller_dashboard
  .input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border: none;
}

.Header_seller_dashboard .accordion .accordion-button::after {
  background-image: none !important;
}

.Header_seller_dashboard .accordion-button::after {
  background-image: none !important;
  width: 0px;
  height: 0px;
}

.Header_seller_dashboard .form-control {
  font-size: 12px !important;
  color: #a4a4a4;
  font-weight: 300;
  box-shadow: none;
  background-color: transparent;
}

.Header_seller_dashboard .form-control:focus {
  font-size: 12px !important;
  color: #a4a4a4;
  font-weight: 300;
  box-shadow: none;
}

/* notification model css */
.notification-section {
  background-color: transparent !important;
  border-bottom: none;
}

.notification-section.offcanvas-top {
  border-bottom: none !important;
}

.notification-section .notifi-pop-main {
  overflow-y: auto;
  background-color: aliceblue;
  border-radius: 10px;
  padding: 20px;
}

.notification-section .offcanvas-top.show {
  background-color: transparent;
}

.notification-section .offcanvas-header {
  position: relative;
}

.notification-section .offcanvas-header .btn-close {
  position: absolute;
  left: 73%;
  box-shadow: none;
}

.notification-section .offcanvas-backdrop.fade {
  background: #ffffff !important;
  backdrop-filter: blur(12px) !important;
  -webkit-backdrop-filter: blur(12px) !important;
}

.notifi-pop-main .notifi-first {
  display: flex;
  justify-content: space-between;
  padding: 21px 0px;
  margin: 0;
}

.notifi-pop-main .first1 {
  border-bottom: 1px solid #00000029;
}

.notifi-pop-main .notifi-first .righttxt {
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.notifi-pop-main .notifi-right .timetxt {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  letter-spacing: 0px;
  color: #8d8d8d;
  opacity: 1;
}
.notification-section .offcanvas-body {
  overflow-y: hidden;
}

/* logout model css */
.mod-title {
  margin-bottom: 25px;
}

.mod-title .mod-txt {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.mod-buuton-main {
  display: flex;
  justify-content: space-around;
}

.modbtn1 {
  width: 130px;
  height: 38px;
  background: #e9e9e9 0% 0% no-repeat padding-box;
  border-radius: 24px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #000000;
  border: none;
  opacity: 1;
}

.modbtn2 {
  width: 130px;
  height: 38px;
  background: #f42b2d 0% 0% no-repeat padding-box;
  border-radius: 24px;
  border: none;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.Header_seller_dashboard .head-notti-flex .noti-div {
  position: relative;
}
.Header_seller_dashboard .head-notti-flex .nottibel-icco {
  font-size: 18px;
  color: #b1b1b1;
}
.Header_seller_dashboard .head-notti-flex .noti-div .not-contt {
  color: #fff;
  font-size: 8px;
  width: 20px;
  border-color: #fff;
  height: 20px;
  border: 3px solid #fff;
  border-radius: 50px;
  background-color: #f88d28;
  border-color: #fff;
  position: absolute;
  left: 7px;
  top: -9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Header_seller_dashboard .bg-body-tertiary .search-notification {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
}

.Header_seller_dashboard .bg-body-tertiary .search-notification .search-bar {
  width: 50%;
}

.Header_seller_dashboard .head-notti-flex .space-bd {
  padding: 0px 30px;
  border-right: 1px solid #b1b1b1;
}
.Header_seller_dashboard .head-notti-flex .space-bd2 {
  padding: 0px 30px;
}
