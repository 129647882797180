/* main-step active css  */

.main-view-product-stepform {
    padding: 20px;
}

.main-view-product-stepform .view-arrow h3 {
    font-size: 16px;
    font-weight: bold;
}

.main-view-product-stepform .steps-div p.steps-name {
    font-size: 14px;
    color: #656565;
    font-weight: 400;
    margin: 20px 20px 0px 20px;
}

.main-view-product-stepform .steps-div {
    display: flex;
}

.main-view-product-stepform .steps-div .steps-name.active {
    font-weight: bold;
    border-bottom: 2px solid #1C42A9;
    padding-bottom: 8px;
    color: #000;
}

.main-view-product-stepform .common-steps-btn {
    text-align: center;
}

.main-view-product-stepform .common-steps-btn .back-btn {
    background-color: #E2E2E2;
    font-size: 15px;
    color: #5B5B5B;
    border: 1px solid #E2E2E2;
    border-radius: 24px;
    width: 120px;
    font-weight: 400;
    padding: 5px;
    margin: 10px;
}

.main-view-product-stepform .common-steps-btn .next-btn {
    background-color: #F88D28;
    font-size: 15px;
    color: #FFF;
    border: 1px solid #F88D28;
    border-radius: 24px;
    width: 120px;
    font-weight: 400;
    padding: 5px;
    margin: 10px;
}

/* common css */

.main-view-product-stepform .card-shadow {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000012;
    border-radius: 10px;
    opacity: 1;
    padding: 20px 10px;
    margin: 10px 10px;
}





/* stepone css */
.step1-info .card-shadow .thumb-text {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
}

.step1-info .card-shadow .span-text {
    font-size: 10px;
    font-weight: 500;
    color: #000000;
}
.step1-info .card-shadow .inner-card {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    padding: 10px;
    height: 180px;
}
.step1-info .card-shadow .inner-card .product-imgs {
    width: 100%;
    height: 158px;
    object-fit: cover;
    border-radius: 5px;
}
.step1-info .card-shadow .inner-card .product-video {
    width: 100%;
    height: 158px;
}
.step1-info .card-shadow .inner-card .product-imgs-small
{
    width: 100%;
    height: 100px;
}
.step1-info .card-shadow .inner-card .descriptn-div h6.title
{
color: #262626;
font-size: 12px;
font-weight: 600;
}
.step1-info .card-shadow .inner-card .descriptn-div p.sub-title
{
font-size: 10px;
color: #262626;
font-weight: 500;
}