/* Website Designer Css Started */

.Website-Designer{
    background-image: url('../../../../../public/assest/images/MyWebsite/HomeImages/Website/website-Banner.png');
    height: auto;
    background-position: center;
    background-size: cover;
    margin-top: 2%;
    padding-bottom: 2%;
}
.Website-Designer .main-image img.person {
    width: 250px;
    height: 228px;
}
.Website-Designer .main-image {
    text-align: center;
}
.Website-Designer .sub-title-medium {
    font-weight: 500;
    text-align: center;
    color: black;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}
.Website-Designer .main-card .website-card {
    margin-bottom: 2%;
    padding: 13px 10px;
    width: 100%;
    height: 156px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 11px #0000001A;
    border-radius: 10px;
}
.Website-Designer .main-card .website-card .sub-title {
    color: black;
   text-align: center;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   overflow: hidden;
   -webkit-line-clamp: 2;
}
.Website-Designer .main-card .website-card  .maintenance-text {
    color: black;
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
}
.Website-Designer  .main-card {
    padding: 0px 95px;
    text-align: -webkit-center;
}
.Website-Designer .maintenance-title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

/* Website Designer Css End */



/******************* Media Query Css Started ***************/

@media (min-width:0px) and (max-width:991px) {
    .Website-Designer {
        height: auto;
    }
    .Website-Designer .main-card .website-card {
        margin: 10px 0px;
        width: 100%;
    }
    .Website-Designer  .main-card {
        padding: 0px 18px;
    }
}
@media (min-width:992px) and (max-width:1199px) {
    .Website-Designer {
        height: auto;
        
    }
    .Website-Designer .main-card .website-card {
        margin: 10px 0px;
    }
    .Website-Designer  .main-card {
        padding: 0px 18px;
    }
}
@media (min-width:1200px) and (max-width:1399px) {
    .Website-Designer .main-card .website-card {
        margin: 10px 0px;
        width: 100%;
        height: 169px;
    }
    .Website-Designer  .main-card {
        padding: 0px 18px;
    }
}