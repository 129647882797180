.Stock_list_section {
  margin: 20px 0px;
  /*  margin: 20px;  */
}

.Stock_list_section .list-flex {
  display: flex;
}

.Stock_list_section .col-widthhhh {
  /* width: 20% !important; */
  margin-bottom: 20px !important;
}

.Stock_list_section .list-flex .img-div .circle-ico {
  min-width: 63px;
  height: 63px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Stock_list_section .list-flex .img-div .circle-ico .icon {
  height: 30px;
  width: auto;
}

.Stock_list_section .list-flex .img-div .ico1 {
  background: #e5f6fd;
}

.Stock_list_section .list-flex .cont-div .name {
  font-weight: normal;
  font-size: 13px;
  font-weight: 500;
  color: #303030;
  margin-bottom: 0px;
}

.Stock_list_section .list-flex .cont-div .number {
  font-weight: 600;
  font-size: 20px;
  color: #303030;
  margin-bottom: 0px;
}

.Stock_list_section .list-flex .cont-div {
  margin: auto 0px auto 20px;
}

@media (min-width: 576px) and (max-width: 768px) {
  /* .Stock_list_section .col-widthhhh {
        width: 50% !important;
    } */
}

@media (min-width: 768px) and (max-width: 991px) {
  /* .Stock_list_section .col-widthhhh {
        width: 30% !important;
    } */
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* .Stock_list_section .col-widthhhh {
        width: 25% !important;
    } */

  .Stock_list_section .list-flex .cont-div .number {
    font-size: 18px;
  }
}
