.Walletdashboard {
  margin: 0px;
}

.Walletdashboard .datatableMain {
  background-color: #fff;
  padding: 1%;
}

.Walletdashboard .datatableMain .imppppp {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: 0;
  padding: 10px 14px;
  width: 100%;
  outline: none;
  margin-bottom: 5%;
}

.Walletdashboard .Getrepbtnn {
  color: #fff;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 50px;
  background-color: #f88d28;
  border: 1px solid #f88d28;
  margin: 0px 5px;
}

.Walletdashboard .filterperrrbtnn {
  color: #000;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 50px;
  background-color: #fff;
  border: 1px solid #000;
  margin: 0px 5px;
}
.Walletdashboard .selectoldenew {
  color: #000;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 50px;
  background-color: #fff;
  border: 1px solid #000;
  margin: 0px 5px;
}
.Walletdashboard .wallselecttt input {
}

.Walletdashboard .wallet-table-sccc .misscallicon {
  background-color: red;
  padding: 10px;
  color: #fff;
  border-radius: 5px;
}
.Walletdashboard .wallet-table-sccc .upcomimgcallicon {
  background-color: green;
  padding: 10px;
  color: #fff;
  border-radius: 5px;
}
.Walletdashboard .wallet-table-sccc tbody tr {
  text-align: center;
}
.Walletdashboard .wallet-table-sccc thead tr {
  text-align: center;
}
.Walletdashboard .wallet-table-sccc tbody tr td {
  font-size: 14px;
}
.Walletdashboard .wallet-table-sccc tbody .cancc-app {
  color: red;
}
.Walletdashboard .wallet-table-sccc tbody .compp-app {
  color: green;
}
