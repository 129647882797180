.categories-mobile-page .heading {
    display: flex;
    margin: 20px 0px;
    align-items: center;
}

.categories-mobile-page .heading h1 {
    font-size: 18px;
    margin: 0px;
}

.categories-mobile-page a {
    color: #000;
}

.categories-mobile-page .nav {
    flex-wrap: unset;
    /* width: 1800px; */
    overflow-x: scroll;
}

.categories-mobile-page .nav-pills .nav-link {

    width: 180px;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    text-align: center;
}

.categories-mobile-page .nav-pills .nav-link.active {
    background-color: transparent;
    width: 180px;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    border-bottom: 2px solid black;
    border-radius: 0px;
    margin-bottom: 5px;
}

.categories-mobile-page .nav-head p {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
    margin: 0px;
    font-size: 13px;
}

.categories-mobile-page .img-bg {

    background-color: rgba(0, 0, 0, .04);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 10px;
}

.categories-mobile-page .img-bg .product-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    padding: 15px;
}

.categories-mobile-page .row-div p {
    font-size: 12px;
    color: #000;
    font-weight: 500;
    text-align: center;
}

.categories-mobile-page .swiper-pagination-bullet-active {

    background-color: orange;
}

.categories-mobile-page .swiper-horizontal {
    padding-bottom: 20px;
}

.categories-mobile-page ::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.categories-mobile-page ::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.categories-mobile-page ::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.categories-mobile-page ::-webkit-scrollbar-thumb:hover {
    background: #555;
}


@media (min-width:0px) and (max-width:320px) {
    .categories-mobile-page .img-bg .product-img {
        padding: 11px;
    }
}

@media (min-width:321px) and (max-width:567px) {
    .categories-mobile-page .img-bg .product-img {
        padding: 14px;
    }
}

@media (min-width:568px) and (max-width:768px) {
    .categories-mobile-page .img-bg .product-img {
        padding: 7px;
    }
}

@media (min-width:1025px) and (max-width:2560px) {
    .categories-mobile-page {
        display: none;
    }
}