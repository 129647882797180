.footer-color {
    width: 100%;
    height: auto;
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 20px;
    border-radius: 20px;
}

.footer-section .footer-submain {
    width: 100%;
    height: auto;
}

.footer-section .footer-submain .section-rows {
    border-bottom: 1px solid #4e4e4e;
}

.footer-section .footer-submain .bottoms-texts {
    display: flex;
    justify-content: space-between;
    padding: 25px;
}

.footer-section .footer-submain .bottoms-texts p {
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0.12px;
    color: #ffffff;
    opacity: 1;
}

.footer-section .footer-submain .bottoms-lefts {
    display: flex;
    justify-content: space-between;
}

.footer-section .footer-submain .bottoms-lefts .privacy {
    margin-right: 25px;
}

.footer-section .footer-submain .footer-heads p {
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.16px;
    color: #585858;
    opacity: 1;
}

.footer-section .footer-submain .footer-txts p {
    font-size: 13px;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0.13px;
    color: #ffffff;
    opacity: 1;
    display: flex;
    align-items: center;
}

.footer-section .footer-submain .footer-iconss {
    display: flex;
}

.footer-section .footer-submain .section-rows {
    padding: 30px 16px 15px 15px;
}

.footer-section .footer-submain .icnmain {
    color: #ffffff;
}

.footer-section .footer-submain .footer-icn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    background: #474747 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 50%;
    margin-right: 4%;
    color: #fff;
}

.footer-section .footer-submain .footer-logo {
    width: 155px;
    height: 60px;
    background-color: transparent;
    opacity: 1;
}

.footer-section .footer-submain .profcyma {
    color: #f88d28;
}

.footer-section .footer-submain .cont-icn {
    margin-right: 10px;
}



@media (min-width: 0px) and (max-width: 485px) {
    .footer-section .footer-submain .footer-txts p {
        font-size: 11px;
        line-height: 11px;
    }

    .footer-section .footer-submain .footer-heads p {
        font-size: 14px;
        line-height: 14px;
    }

    .footer-section .footer-submain .footer-logo {
        width: 140px;
        height: 50px;
        margin-bottom: 10px;
    }

    .footer-section .footer-submain .section-rows {
        padding: 20px 15px 15px 15px;
    }

    .footer-section .footer-submain .footer-icn {
        width: 35px;
        height: 35px;
        margin-right: 2%;
    }

    .footer-section .footer-submain .bottoms-texts {
        display: block;
        padding: 15px;
    }

    .footer-section .footer-submain .bottoms-texts p {
        font-size: 11px;
        line-height: 18px;
    }
}

@media (min-width: 486px) and (max-width: 767px) {
    .footer-section .footer-submain .footer-heads p {
        font-size: 17px;
        line-height: 17px;
    }

    .footer-section .footer-submain .footer-txts p {
        font-size: 12px;
        line-height: 14px;
    }

    .footer-section .footer-submain .footer-logo {
        width: 140px;
        height: 50px;
        margin-bottom: 10px;
    }

    .footer-section .footer-submain .section-rows {
        padding: 20px 15px 15px 15px;
    }

    .footer-section .footer-submain .footer-icn {
        width: 35px;
        height: 35px;
        margin-right: 2%;
    }

    .footer-section .footer-submain .bottoms-texts {
        display: block;
        padding: 15px;
    }

    .footer-section .footer-submain .bottoms-texts p {
        font-size: 11px;
        line-height: 18px;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .footer-section .footer-submain .footer-heads p {
        font-size: 17px;
        line-height: 17px;
    }

    .footer-section .footer-submain .footer-txts p {
        font-size: 12px;
        line-height: 14px;
    }

    .footer-section .footer-submain .footer-logo {
        width: 140px;
        height: 50px;
        margin-bottom: 10px;
    }

    .footer-section .footer-submain .section-rows {
        padding: 20px 15px 15px 15px;
    }

    .footer-section .footer-submain .footer-icn {
        width: 35px;
        height: 35px;
        margin-right: 2%;
    }

    .footer-section .footer-submain .bottoms-texts {
        padding: 15px;
    }

    .footer-section .footer-submain .bottoms-texts p {
        font-size: 11px;
        line-height: 18px;
    }
}