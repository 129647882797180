.seller-header-section {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 26px 37px #00000012;
  opacity: 1;
  height: 60px;
  position: sticky;
  top: 0px;
  z-index: 999;
}

.seller-header-section .header-main .header-img .header-logo {
  width: 123px;
  height: 47px;
  background-color: transparent;
  opacity: 1;
}



.seller-header-section .header-main .header-lists {
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-left: 48px;
}

.seller-header-section .header-main .header-lists2 {
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  letter-spacing: 0px;
  display: flex;
  color: #000000;
  opacity: 1;
  margin: 0px 16px;
  align-content: center;
  align-items: center;
}

.seller-header-section .header-main .btndiv {
  height: 57px;
  width: 112px;
  background-color: #000000;
  text-align: center;
  font-size: 15px !important;
  font-weight: 500;
  display: flex;
  line-height: 21px;
  letter-spacing: 0.18px;
  color: #ffff !important;
  opacity: 1;
  justify-content: center;
  align-content: center;
}

.seller-header-section .header-main .btndiv .btnss {
  width: 100%;
  height: 100%;
  background-color: #000000;
  color: #ffffff;
  border: none;
}

.seller-header-section .header-main span.navbar-toggler-icon {
  font-size: 15px;
}

.seller-header-section .header-main .navbar-light .navbar-nav .nav-link:focus,
.seller-header-section .header-main .navbar-light .navbar-nav .nav-link:hover,
.seller-header-section .header-main .navbar-light .navbar-nav .nav-link {
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 13px;
  margin: 0px 12px !important;
}

/* dropdown css */
.seller-header-section .header-main img.dropdown-img {
  width: 100%;
  height: 150px;
  opacity: 1;
}

.seller-header-section .header-main .dropdown-menu.show {
  width: 723px;
  height: 261px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 9px 37px #0000000a;
  border: none;
  opacity: 1;
  position: absolute;
  top: 90px;
  left: -256px;
  transform: translateY(0);
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-top {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

.seller-header-section .header-main .dropdown-mains .row {
  width: 100%;
}

.seller-header-section .header-main .dropdown-mains .dp-text {
  padding-top: 11px;
}

.seller-header-section .header-main .dropdown-mains .dp-txt-p {
  font: normal normal medium 14px/21px Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.14px;
  color: #000000;
  opacity: 1;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.seller-header-section .header-main .dropdown-mains .drop-links {
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
  letter-spacing: 0.18px;
  margin-bottom: 5px;
  color: #000000;
  opacity: 1;
}

.seller-header-section .header-main .dropdown-item:focus,
.seller-header-section .header-main .dropdown-item:hover {
  background-color: #fff;
}

.seller-header-section .header-main .dropdown-mains .aro-icn {
  color: #000000;
  transition: margin-left 0.3s ease, color 0.3s ease;
}

.seller-header-section .header-main .dropdown-mains .dp-icn .aro-icn:hover {
  margin-left: 10px;
  color: #ffc400;
}

/* .seller-header-section .header-main .dropdown-mains {
  padding-top: 3px;
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
} */

/* @-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
} */

.seller-header-section .header-main .rightside-border {
  width: 100%;
  height: 100%;
  border-right: 1px solid #00000017;
}

@media (min-width: 0px) and (max-width: 485px) {
  .seller-header-section .header-main .header-lists {
    font-size: 12px;
    line-height: 12px;
    margin-left: 0px;
  }

  .seller-header-section .header-main .header-lists2 {
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    margin: 0px 0px;
  }

  .seller-header-section .header-main .btndiv {
    height: 30px;
    width: 80px;
    font-size: 12px;
    line-height: 12px;
  }

  .seller-header-section .header-main .dropdown-menu.show {
    width: 250px;
    height: auto;
    left: 0;
  }

  .seller-header-section .header-main .dropdown-mains {
    padding-left: 21px;
  }

  .seller-header-section .header-main .dp-mains {
    margin-bottom: 15px;
  }

  .seller-header-section .header-main .dropdown-mains .dp-txt-p {
    font-size: 11px;
    line-height: 16px;
  }

  .seller-header-section .header-main .dropdown-mains .drop-links {
    font-size: 11px;
    line-height: 11px;
  }

  .seller-header-section .header-main img.dropdown-img {
    height: auto;
  }

  .seller-header-section .header-main .navbar-collapse {
    background: aliceblue;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .seller-header-section .header-main .header-lists {
    font-size: 13px;
    line-height: 13px;
    margin-left: 0px;
  }

  .seller-header-section .header-main .header-lists2 {
    font-size: 13px;
    line-height: 13px;
    font-weight: 500;
    margin: 0px 0px;
  }

  .seller-header-section .header-main .btndiv {
    height: 35px;
    width: 95px;
    font-size: 13px;
    line-height: 13px;
  }

  .seller-header-section .header-main .dropdown-menu.show {
    width: 250px;
    height: auto;
    left: 0;
    top: 95px;
  }

  .seller-header-section .header-main .dropdown-mains {
    padding-left: 21px;
  }

  .seller-header-section .header-main .dp-mains {
    margin-bottom: 15px;
  }

  .seller-header-section .header-main .dropdown-mains .dp-txt-p {
    font-size: 11px;
    line-height: 16px;
  }

  .seller-header-section .header-main .dropdown-mains .drop-links {
    font-size: 11px;
    line-height: 11px;
  }

  .seller-header-section .header-main img.dropdown-img {
    height: auto;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .seller-header-section .header-main .header-lists {
    font-size: 14px;
    line-height: 14px;
    margin-left: 0px;
  }

  .seller-header-section .header-main .header-lists2 {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    margin: 0px 0px;
  }

  .seller-header-section .header-main .btndiv {
    height: 35px;
    width: 95px;
    font-size: 14px;
    line-height: 14px;
  }

  .seller-header-section .header-main .dropdown-menu.show {
    width: 350px;
    height: auto;
    position: absolute;
    left: 0;
    top: 100px;
  }

  .seller-header-section .header-main .dropdown-mains {
    padding-left: 21px;
  }

  .seller-header-section .header-main .dp-mains {
    margin-bottom: 15px;
  }

  .seller-header-section .header-main .dropdown-mains .dp-txt-p {
    font-size: 13px;
    line-height: 18px;
  }

  .seller-header-section .header-main .dropdown-mains .drop-links {
    font-size: 13px;
    line-height: 13px;
  }

  .seller-header-section .header-main img.dropdown-img {
    height: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .seller-header-section .header-main .header-lists {
    font-size: 15px;
    line-height: 17px;
    margin-left: 0px;
  }

  .seller-header-section .header-main .header-lists2 {
    font-size: 15px;
    line-height: 17px;
    font-weight: 500;
    margin: 0px 0px;
  }

  .seller-header-section .header-main .btndiv {
    height: 40px;
    width: 115px;
    font-size: 15px;
    line-height: 17px;
  }

  .seller-header-section .header-main .dropdown-menu.show {
    width: 550px;
    height: auto;
    position: absolute;
    left: 0;
    top: 110px;
  }

  .seller-header-section .header-main img.dropdown-img {
    width: 100%;
    height: auto;
    opacity: 1;
  }

  .seller-header-section .header-main .dropdown-mains .drop-links {
    font-size: 13px;
    line-height: 13px;
  }

  .seller-header-section .header-main .dropdown-mains .dp-txt-p {
    font-size: 13px;
    line-height: 18px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .seller-header-section .header-main .header-lists {
    font-size: 14px;
    line-height: 16px;
  }

  .seller-header-section .header-main .header-lists2 {
    font-size: 14px;
    line-height: 16px;
    margin-right: 15px !important;
  }

  .seller-header-section .header-main .btndiv {
    height: 56px;
    width: 125px;
    font-size: 14px;
    line-height: 16px;
  }

  .seller-header-section .header-main .dropdown-menu.show {
    left: -400px;
  }


}

@media (min-width: 1201px) and (max-width: 1400px) {
  .seller-header-section .header-main .dropdown-menu.show {
    left: -340px;
  }
}