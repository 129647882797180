.b2b-buyer-notificaton-off {
    background: #F9F9F9 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 17px #00000026;
    border-radius: 10px 0px 0px 10px;
}

.b2b-buyer-notificaton-off .offcanvas-body {
    border-top: 1px solid #ccc;
}

.b2b-buyer-notificaton-off .notification-img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
}

.b2b-buyer-notificaton-off .nav {
    flex-wrap: unset;
    width: 364px;
    overflow-x: scroll;
}

.b2b-buyer-notificaton-off .offcanvas-title {
    font-size: 16px;
}

.b2b-buyer-notificaton-off .nav-pills .nav-link.active {
    background: #FF830E 0% 0% no-repeat padding-box;
    border-radius: 17px;
    opacity: 1;
    color: #fff;
    padding: 4px 26px;
    font-size: 12px;
}

.b2b-buyer-notificaton-off .nav-pills .nav-link {
    color: #000;
    background: #FFF;
    border-radius: 14px;
    border: none;
    padding: 4px 26px;
    font-size: 12px;
}

.b2b-buyer-notificaton-off .nav-div .flex-div .profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.b2b-buyer-notificaton-off .nav-div .flex-div {
    display: flex;
    margin: 20px 0px;
    border-bottom: 1px solid #ccc;
}

.b2b-buyer-notificaton-off .nav-div .flex-div .img-div {
    position: relative;
}

.b2b-buyer-notificaton-off .nav-div .flex-div .dot {
    background-color: red;
    width: 5px;
    height: 5px;
    position: absolute;
    top: 0px;
    right: 15px;
    border-radius: 50px;
}

.b2b-buyer-notificaton-off .nav-div .flex-div .from-text {
    font-size: 16px;
    font-weight: 400;
    color: #000;
}

.b2b-buyer-notificaton-off .nav-div .flex-div .com-name {
    font-size: 16px;
    font-weight: 500;
    color: #000;
}

.b2b-buyer-notificaton-off .nav-div .flex-div p.grey-text {
    color: #757575;
    font-size: 12px;
    font-weight: 500;
}

.b2b-buyer-notificaton-off.offcanvas {
    height: 640px;
    overflow-y: scroll;
    margin-top: auto;
}

@media (min-width: 0px) and (max-width: 400px) {
    .b2b-buyer-notificaton-off .nav {
        width: 285px;

    }

}

@media (min-width: 0px) and (max-width: 768px) {

    .b2b-buyer-notificaton-off .nav-div .flex-div .from-text {
        font-size: 14px;

    }

    .b2b-buyer-notificaton-off .nav-div .flex-div .com-name {
        font-size: 14px;

    }
}