.faq-banner .banner-img {
    height: 367px;
    width: 100%;
    /* height: 327px; */
    margin-bottom: 5%;
}

.faq-banner .faq-title {
    font-size: 28px;
    font-weight: 600;
}

.faq-banner  .heading {
    width: -webkit-fill-available;
}
.faq-banner .breadcrumb {
    width: max-content;
    padding: 0px 31px;
}
section.faq-banner {
    position: relative;
    top: 0px;
}

/* faq content */
img.banner-img {
    width: 100%;
    height: 376px;
}
.Faq-Content {
    position: relative;
    top: 160px;
}
@media (min-width:0px) and (max-width:991px) {
    section.faq-banner {
      
        top: -52px;
    }
    .faq-banner .faq-title {
        font-size: 22px;
       
    }
    .faq-banner .banner-img {
     
        height: 203px;
       
    }
  
}
@media (min-width:280px) and (max-width:320px) {
   
    img.banner-img {
       
        height: 163px;
    }
}
@media (min-width:321px) and (max-width:485px) {
    img.banner-img {
       
        height: 163px;
    }
}
@media (min-width:486px) and (max-width:768px) {
    img.banner-img {
       
        height: 163px;
    }
}
@media (min-width:769px) and (max-width:991px) {
    img.banner-img {
       
        height: 163px;
    }
}

