.self-relient {
  background-image: url("../../../../../public/assest/images/Seller_panel/sellersuccessstory/Group\ 23950.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: 8%;
  margin-bottom: 6%;
  padding: 1px;
}

.self-relient .section-class h4 {
  font-size: 20px;
  color: #000000;
  font-weight: 700;
  text-align: center;
  margin: 4% 0%;
}

.self-relient .self-relient-first-section {
  background: #f2f0fd 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 2px;
  padding: 10px;
  direction: ltr;
}

.self-relient .self-img {
  width: 58px;
  height: 66px;
}

.self-relient .self-relient-scroll {
  height: 500px;
  overflow-y: scroll;
  direction: rtl;
  overflow-x: auto;
}

.self-relient .self-relient-scroll .story-desc {
  font-size: 16px;
}

.self-relient .main-white-card-class-selff {
  background: #ffffff;
  box-shadow: #00000029 0px 3px 6px;
  border-radius: 28px 28px;
  border: none;
  padding: 30px 30px 0px 30px;
}

.self-relient .main-white-card-class-selff .main-img-class .person-imgg {
  width: 100%;
  border-radius: 28px;
  height: 360px;
}

.self-relient .main-white-card-class-selff .sales-content {
  font-size: 16px;
  color: #000000;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
}

.self-relient h4 {
  position: relative;
}

.self-relient h4::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  width: 125px;
  height: 2px;
  background-color: #ff4948;
  left: 33%;
}

.self-relient h4::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  width: 125px;
  height: 2px;
  background-color: #ff4948;
  right: 33%;
}

.self-relient ::-webkit-scrollbar {
  width: 6px;
}

.self-relient ::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 5px;
}

.self-relient .main-slider {
  position: relative;
}

.self-relient .main-slider .arrow-back,
.self-relient .main-slider .arrow-next {
  cursor: pointer;
}

.self-relient .main-slider .arrow-back {
  content: url("../../../../../public/assest/images/Seller_panel/sellersuccessstory/back.png");
  position: absolute;
  bottom: 273px;
  left: -69px;
  z-index: 2;
  cursor: pointer;
}

.self-relient .main-slider .arrow-next {
  content: url("../../../../../public/assest/images/Seller_panel/sellersuccessstory/previous.png");
  position: absolute;
  bottom: 273px;
  right: -69px;
  z-index: 2;
  cursor: pointer;
}

@media (min-width: 0px) and (max-width: 320px) {
  .self-relient h4::before {
    display: none;
  }

  .self-relient h4::after {
    display: none;
  }

  .self-relient .self-relient-scroll .story-desc {
    font-size: 14px;
  }

  .self-relient .main-white-card-class-selff {
    margin-top: 13%;
  }

  .self-relient .main-white-card-class-selff .sales-content {
    font-size: 14px;
  }

  .self-relient .main-slider .arrow-back {
    bottom: 201px;
    left: 0;
  }

  .self-relient .main-slider .arrow-next {
    bottom: 201px;
    right : 0;
  }

  .self-relient .main-white-card-class-selff .main-img-class .person-imgg {
    height: 200px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .self-relient h4::before {
    display: none;
  }

  .self-relient h4::after {
    display: none;
  }

  .self-relient .self-relient-scroll .story-desc {
    font-size: 14px;
  }

  .self-relient .main-white-card-class-selff {
    margin-top: 13%;
  }

  .self-relient .main-white-card-class-selff .sales-content {
    font-size: 14px;
  }

  .self-relient .main-slider .arrow-next {
    right: -40px;
    width: 40px;
    bottom: 184px;
  }

  .self-relient .main-slider .arrow-back {
    left: -40px;
    width: 40px;
    bottom: 184px;
  }

  .self-relient .main-white-card-class-selff .main-img-class .person-imgg {
    object-fit: contain;
    height: 170px;
  }
  
}

@media (min-width: 486px) and (max-width: 576px) {
  .self-relient h4::before {
    display: none;
  }

  .self-relient h4::after {
    display: none;
  }

  .self-relient .self-relient-scroll .story-desc {
    font-size: 14px;
  }

  .self-relient .main-white-card-class-selff {
    margin-top: 13%;
  }

  .self-relient .main-white-card-class-selff .sales-content {
    font-size: 14px;
  }

  .self-relient .main-slider .arrow-next {
    right: -40px;
    width: 40px;
    bottom: 184px;
  }

  .self-relient .main-slider .arrow-back {
    left: -40px;
    width: 40px;
    bottom: 184px;
  }

  .self-relient .main-white-card-class-selff .main-img-class .person-imgg {
    object-fit: contain;
    height: 170px;
  }

  .self-relient .main-slider .arrow-back {
    bottom: 209px;
    left: 0;
  }

  .self-relient .main-slider .arrow-next {
    bottom: 209px;
    right : 0;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .self-relient h4::before {
    left: 8%;
  }

  .self-relient h4::after {
    right: 8%;
  }

  .self-relient .self-relient-scroll .story-desc {
    font-size: 14px;
  }

  .self-relient .main-white-card-class-selff {
    margin-top: 13%;
  }

  .self-relient .main-slider .arrow-next {
    right: 34%;
  }

  .self-relient .main-slider .arrow-back {
    left: 37%;
  }

  .self-relient .main-white-card-class-selff .sales-content {
    font-size: 14px;
  }

  .self-relient .main-slider .arrow-next {
    right: -40px;
    width: 40px;
    bottom: 184px;
  }

  .self-relient .main-slider .arrow-back {
    left: -40px;
    width: 40px;
    bottom: 184px;
  }

  .self-relient .main-white-card-class-selff .main-img-class .person-imgg {
    object-fit: contain;
    height: 170px;
  }
}

@media (min-width: 767px) and (max-width: 768px) {
  .self-relient h4::before {
    left: 8%;
  }

  .self-relient h4::after {
    right: 8%;
  }

  .self-relient .self-relient-scroll .story-desc {
    font-size: 14px;
  }

  .self-relient .main-white-card-class-selff {
    margin-top: 13%;
  }

  .self-relient .main-slider .arrow-next {
    right: 34%;
  }

  .self-relient .main-slider .arrow-back {
    left: 37%;
  }

  .self-relient .main-white-card-class-selff .sales-content {
    font-size: 14px;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .self-relient h4::before {
    left: 8%;
  }

  .self-relient h4::after {
    right: 8%;
  }

  .self-relient .self-relient-scroll .story-desc {
    font-size: 14px;
  }

  .self-relient .main-white-card-class-selff {
    margin-top: 13%;
  }

  .self-relient .main-white-card-class-selff .sales-content {
    font-size: 14px;
  }

  .self-relient .main-slider .arrow-next {
    right: -40px;
    width: 40px;
    bottom: 184px;
  }

  .self-relient .main-slider .arrow-back {
    left: -40px;
    width: 40px;
    bottom: 184px;
  }

  .self-relient .main-white-card-class-selff .main-img-class .person-imgg {
    object-fit: contain;
    height: 170px;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .self-relient h4::before {
    left: 8%;
  }

  .self-relient h4::after {
    right: 8%;
  }

  .self-relient .main-white-card-class-selff .sales-content {
    font-size: 14px;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .self-relient h4::before {
    left: 8%;
  }

  .self-relient h4::after {
    right: 8%;
  }
}