
.New_Customers_Clicks {
    padding: 12px 20px;
}


.New_Customers_Clicks .line_chart .canvasjs-chart-canvas {
    height: 220px;
    width: 100%;
}

.New_Customers_Clicks .line_chart  .canvasjs-chart-toolbar {
    display: none;
}


.New_Customers_Clicks .line_chart {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 27px #00000014;
    border-radius: 10px;
    height: 230px;
}

.New_Customers_Clicks .line_chart .canvasjs-chart-credit{
display: none;
}