.Affilate_Empower_banner-sec {
    position: relative;
    margin: 20px;
}

.Affilate_Empower_banner-sec .kjbhimg {
    width: 100%;
    border-radius: 8px;
}

.Affilate_Empower_banner-sec .blnere-overlay {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: #0000005c;
    border-radius: 8px;
}

.Affilate_Empower_banner-sec .overlay-conttt {
    position: absolute;
    top: 35%;
    width: 95%;
    margin: auto;
    left: 30px;
}

.Affilate_Empower_banner-sec .overlay-conttt .baner-trir {
    font-weight: normal;
    font-size: 20px;
    color: #fff;
    margin-bottom: 0px;
}

.Affilate_Empower_banner-sec .overlay-conttt .view-demo-btn {
    color: #fff;
    font-size: 14px;
    padding: 8px 20px;
    border-radius: 50px;
    background-color: transparent;
    border: 1px solid #fff;
    margin: 0px 5px;
}

.Affilate_Empower_banner-sec .overlay-conttt a {
    color: #fff;
}

.Affilate_Empower_banner-sec .overlay-conttt .list-product-btn {
    color: #fff;
    font-size: 14px;
    padding: 8px 20px;
    border-radius: 50px;
    background-color: #F88D28;
    border: 1px solid #F88D28;
    margin: 0px 5px;
}


@media(min-width:577px) and (max-width:768px) {
    .Affilate_Empower_banner-sec .kjbhimg {
        height: 144px;
    }

    .Affilate_Empower_banner-sec .overlay-conttt .baner-trir {
        font-size: 15px;
    }

    .Affilate_Empower_banner-sec .overlay-conttt a {
        font-size: 9px;
        padding: 6px;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .Affilate_Empower_banner-sec .kjbhimg {
        height: 144px;
    }

    .Affilate_Empower_banner-sec .overlay-conttt .baner-trir {
        font-size: 15px;
    }

    .Affilate_Empower_banner-sec .overlay-conttt a {
        font-size: 9px;
        padding: 6px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .Affilate_Empower_banner-sec .kjbhimg {
        height: 150px;
    }

    .Affilate_Empower_banner-sec .overlay-conttt .baner-trir {
        font-size: 17px;
    }

    .Affilate_Empower_banner-sec .overlay-conttt a {
        font-size: 10px;
        padding: 6px;
    }
}

@media(min-width:1201px) and (max-width:1399px) {
    .Affilate_Empower_banner-sec .kjbhimg {
        height: 150px;
    }

    .Affilate_Empower_banner-sec .overlay-conttt .baner-trir {
        font-size: 17px;
    }

    .Affilate_Empower_banner-sec .overlay-conttt a {
        font-size: 10px;
        padding: 6px;
    }
}