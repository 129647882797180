.Earning {
    padding: 12px 20px;
}

.Earning .dashboard-box-heading-holder {
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.Earning .dashboard-box-heading-holder .text-holder h6{
    text-align: center;
    font-weight: 500;
    font-size: 10px;
    color: #303030;
}

.Earning .css-10vg0a6-MuiResponsiveChart-container>svg {
    background: #F6F6F6;
}

.Earning .css-10vg0a6-MuiResponsiveChart-container{
    width: 100%;
}

.Earning .dashboard-box-heading-holder .text-holder p{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #0E0E0E;
}