.Procurement_Customized_Slider {
  margin-top: 3%;
}

.Procurement_Customized_Slider
  .Services_Swiper
  .Services_card
  .img_overlay_holder
  .Services_card_img_holder {
  border-radius: 10px;
}

.Procurement_Customized_Slider
  .Services_Swiper
  .Services_card
  .img_overlay_holder
  .Services_card_img_holder
  .Services_card_img {
  width: 100%;
  height: 267px;
  border-radius: 10px;
}

.Procurement_Customized_Slider
  .Services_Swiper
  .Services_card
  .img_overlay_holder {
  position: relative;
}

.Procurement_Customized_Slider .Services_Swiper {
  padding-bottom: 5%;
}

.Procurement_Customized_Slider
  .Services_Swiper
  .swiper-pagination-bullet-active {
  background: #ababab;
}

.Procurement_Customized_Slider
  .Services_Swiper
  .Services_card
  .img_overlay_holder
  .overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgb(0 0 0 / 0%) 27%,
    rgba(0, 0, 0, 1) 100%
  );
}

.Procurement_Customized_Slider .Services_Swiper .Services_card .text-holder h4 {
  text-align: left;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
}

.Procurement_Customized_Slider
  .Services_Swiper
  .Services_card
  .text-holder
  .services_list
  ul {
  padding-left: 0px;
}

.Procurement_Customized_Slider
  .Services_Swiper
  .Services_card
  .text-holder
  .services_list
  li {
    line-height: 21px;
    text-align: left;
    font-size: 12px;
    font-weight: 100;
    color: #ffffff;
    list-style: none;
}

.Procurement_Customized_Slider .Services_Swiper .Services_card {
  position: relative;
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
  border-radius: 10px;
}

.Procurement_Customized_Slider
  .Services_Swiper
  .Services_card:hover
  .Services_card_img {
  transition: transform 0.3s ease-in-out;
  transform: scale(1.1);
}

.Procurement_Customized_Slider .Services_Swiper .Services_card .text-holder {
  position: absolute;
  top: 50%;
  padding-left: 5%;
}

@media (min-width: 0px) and (max-width: 320px) {
  .Procurement_Customized_Slider
    .Services_Swiper
    .Services_card
    .img_overlay_holder
    .Services_card_img_holder
    .Services_card_img {
    height: 230px;
  }

  .Procurement_Customized_Slider
    .Services_Swiper
    .Services_card
    .text-holder
    h4 {
    font-size: 15px;
  }

  .Procurement_Customized_Slider .Services_Swiper {
    padding-bottom: 12%;
  }

  .Procurement_Customized_Slider
    .Services_Swiper
    .Services_card
    .text-holder
    .services_list
    li {
    font-size: 11px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Procurement_Customized_Slider .Services_Swiper {
    padding-bottom: 12%;
  }

}

@media (min-width: 486px) and (max-width: 575px) {
  .Procurement_Customized_Slider .Services_Swiper {
    padding-bottom: 12%;
  }

}

@media (min-width: 576px) and (max-width: 675px) {
  .Procurement_Customized_Slider .Services_Swiper {
    padding-bottom: 12%;
  }

}

@media (min-width: 676px) and (max-width: 765px) {
  .Procurement_Customized_Slider .Services_Swiper {
    padding-bottom: 12%;
  }

}
