.moving-person {
    margin-top: 6%;
}

.moving-person .section-title h4 {
    margin-bottom: 6%;
    font-size: 20px;
    color: #000000;
    font-weight: 700;
    text-align: center;
    position: relative; 
}

.moving-person .section-title h4::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    width: 125px;
    height: 2px;
    background-color: #FF4948;
    left: 24%;
}
.moving-person .section-title h4::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    width: 125px;
    height: 2px;
    background-color: #FF4948;
    right: 24%;
}

.main-white-card-class .main-img-class {
    position: relative;
}

.main-white-card-class .main-img-class .person-name-class {
    position: absolute;
    top: 62%;
    left: 10%;
    z-index: 9;
}

.main-white-card-class  .sales-content {
    font-size: 16px;
    color: #000000;
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4;
}


.main-white-card-class .main-img-class .black-overlay {
    background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%);
    position: absolute;
    transition: .2s ease;
    width: 100%;
    height: 40%;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 28px;
}

.main-white-card-class  {
    background: #FFFFFF;
    box-shadow: #00000029 0px 3px 6px;
    border-radius: 28px 28px 0px 0px;
    border: none;
    padding: 30px;
}

.person-name-class h5 {
    font-size: 31px;
    color: #FFFFFF;
}

.main-white-card-class .main-img-class .person-imgg {
    width: 100%;
    border-radius: 28px;
}


.moving-person  .swiper-pagination-bullet {
    width: 10px;
    height: 4px;
    border-radius: 5px;
    opacity: 1;
    background-color: #CECECE;
}

.moving-person .swiper-pagination-bullet-active {
    width: 18px;
    background: #000;
}

@media(min-width:0px) and (max-width:320px) {
    .moving-person .section-title h4 {
        font-size: 16px;
    }
    .person-name-class h5 {
        font-size: 21px;
    }
    .main-white-card-class  .sales-content {
        font-size: 13px;
    }
    .moving-person .section-title h4::after {
       display: none;
    }
    .moving-person .section-title h4::before {
        display: none;
     }
}

@media(min-width:321px) and (max-width:485px) {
    .moving-person .section-title h4 {
        font-size: 16px;
    }
    .person-name-class h5 {
        font-size: 21px;
    }
    .main-white-card-class  .sales-content {
        font-size: 13px;
    }
    .moving-person .section-title h4::after {
        display: none;
     }
     .moving-person .section-title h4::before {
         display: none;
      }
}

@media(min-width:486px) and (max-width:576px) {
    .moving-person .section-title h4 {
        font-size: 18px;
    }
    .person-name-class h5 {
        font-size: 21px;
    }
    .main-white-card-class  .sales-content {
        font-size: 13px;
    }
    .moving-person .section-title h4::after {
        display: none;
     }
     .moving-person .section-title h4::before {
         display: none;
      }
}

@media(min-width:577px) and (max-width:767px) {
    .moving-person .section-title h4::before {
        width: 52px;
        left: 3%;
    }
    .moving-person .section-title h4::after {
        width: 52px;
        right: 3%;
    }
    .person-name-class h5 {
        font-size: 16px;
    }
    .main-white-card-class  .sales-content {
        font-size: 13px;
    }
}

@media(min-width:767px) and (max-width:768px) {
    .moving-person .section-title h4::after {
        right: 4%;
     }
     .moving-person .section-title h4::before {
         left: 4%;
      }
      .person-name-class h5 {
        font-size: 18px;
    }
    .main-white-card-class  .sales-content {
        font-size: 14px;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .moving-person .section-title h4::after {
        right: 4%;
     }
     .moving-person .section-title h4::before {
         left: 4%;
      }
      .person-name-class h5 {
        font-size: 20px;
    }
    .main-white-card-class  .sales-content {
        font-size: 14px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .moving-person .section-title h4::after {
        right: 4%;
     }
     .moving-person .section-title h4::before {
         left: 4%;
      }
      .main-white-card-class  .sales-content {
        font-size: 14px;
    }
    .person-name-class h5 {
        font-size: 20px;
    }
}

@media(min-width:1201px) and (max-width:1399px) {
    .moving-person .section-title h4::after {
        right: 8%;
     }
     .moving-person .section-title h4::before {
         left: 8%;
      }
      .main-white-card-class  .sales-content {
        font-size: 14px;
    }
    .person-name-class h5 {
        font-size: 20px;
    }
}