.form-container .addnewaddress {
    color: #3b5bb4;
    font-weight: 500;
    margin-right: 15px;
    font-size: 14px;
    cursor: pointer;
}

.firmDetailsFirst .uploadGsthead h3{
   margin-bottom: 20px;
}