.Api_documentattions .flexxxxdivvv .firsttt {
    min-width: 200px;
}

.Api_documentattions .flexxxxdivvv {
    display: flex;
    margin: 20px;
}

.Api_documentattions .flexxxxdivvv .secondddd {
    width: 100%;
}


 