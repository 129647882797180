.checkout-form .check-bg {
    background-image: url('../../../../../../public/assest/images/MyWebsite/Checkoutform/check-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding: 5%;
    margin-bottom: 6%;
}

.checkout-form .back-btn-main {
    width: 7%;
}

.checkout-form .heading {
    width: -webkit-fill-available;
}

.checkout-form .back-btn {
    font-size: 16px;
    background: transparent;
    border: none;
    color: #000;
}

.checkout-form .bck-icon {
    color: #F88D28;
}


/* Main Checout css end */

.product-portfolio {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 25px;
    text-align: center;
}

.product-portfolio .orange-bg-portfolio {
    background: #F88D28;
    padding: 15px;
    position: relative;
    height: 125px;
}

.product-portfolio .prod-port {
    font-size: 24px;
    color: #FFF;
}

.product-portfolio .orange-bg-portfolio .white-bg-portfolio {
    background: #FFF;
    border-radius: 100px;
    width: 110px;
    height: 110px;
    padding: 5px;
    margin: auto;
}

.product-portfolio .dark-bg-brown {
    background: #eff0f2;
    padding: 10px 5px 1px 5px;
    margin-top: 3%;
}

.product-portfolio .dark-bg-brown p {
    color: #000;
    font-size: 12px;
}

.product-portfolio .main-dark {
    margin-top: 16%;
}

.product-portfolio .check-con-class {
    color: #F88D28;
    font-size: 20px;
}

.product-portfolio .orange-bg-portfolio .white-bg-portfolio .per-month {
    font-size: 20px;
    font-weight: 700;
}

.product-portfolio .start-with {
    font-size: 14px;
    color: #FFF;
    background: #F88D28;
    border-radius: 25px;
    border: none;
    padding: 8px;
    width: 100%;
    border: 1px solid #F88D28;
}

.product-portfolio .start-with:hover {
    background: transparent linear-gradient(180deg, #F88D28 0%, #CE6500 100%) 0% 0% no-repeat padding-box;
}

.product-portfolio .more-info {
    font-size: 14px;
    color: #F88D28;
    background: transparent;
    width: 100%;
    padding: 8px;
    border-radius: 25px;
    border: 1px solid #000;
}

.product-portfolio .more-info:hover {
    color: #000;
    text-decoration: underline;
}

/* Media */

@media(min-width:0px) and (max-width:576px) {
    .product-portfolio {
        margin-top: 15%;
    }

    .product-portfolio .main-dark {
        margin-top: 21%;
    }

    .product-portfolio .prod-port {
        font-size: 20px;
    }

    .product-portfolio .orange-bg-portfolio .white-bg-portfolio .per-month {
        font-size: 17px;
    }

    .product-portfolio .orange-bg-portfolio .white-bg-portfolio {
        width: 100px;
        height: 100px;
    }

        .checkout-form .back-btn-main {
            width: fit-content;
        }
}

@media(min-width:577px) and (max-width:768px) {
    .product-portfolio {
        margin-top: 10%;
    }

    .product-portfolio .main-dark {
        margin-top: 10%;
    }

    .product-portfolio .prod-port {
        font-size: 20px;
    }

    .product-portfolio .orange-bg-portfolio .white-bg-portfolio .per-month {
        font-size: 17px;
    }

    .product-portfolio .orange-bg-portfolio .white-bg-portfolio {
        width: 100px;
        height: 100px;
    }

        .checkout-form .back-btn-main {
            width: fit-content;
        }
}

@media(min-width:769px) and (max-width:1024px) {
    .product-portfolio {
        margin-top: 10%;
    }

    .product-portfolio .main-dark {
        margin-top: 20%;
    }

    .product-portfolio .prod-port {
        font-size: 20px;
    }

    .product-portfolio .orange-bg-portfolio .white-bg-portfolio .per-month {
        font-size: 17px;
    }

    .product-portfolio .orange-bg-portfolio .white-bg-portfolio {
        width: 100px;
        height: 100px;
    }

        .checkout-form .back-btn-main {
            width: 14%;
        }
}

@media(min-width:1025px) and (max-width:1399px) {

    .product-portfolio .main-dark {
        margin-top: 23%;
    }

    .checkout-form .back-btn-main {
        width: 9%;
    }
}