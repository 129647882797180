.shopping-card-main-card {
    margin: 15px 0px;
    border-bottom: 1px solid grey;
    padding-bottom: 16px;
}

.shopping-card-main-card .card-sec {
    background: #F3F3F3 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    padding: 10px;
}

.shopping-card-main-card p {
    /* margin: 0px; */
}

.shopping-card-main-card .card-sec .prduct-img {
    width: 80px;
    height: 80px;
    border-radius: 20px;
}

.shopping-card-main-card .card-sec .left-sec {
    display: flex;


}

.shopping-card-main-card .card-sec .right-sec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 10px 10px;
}

.ShoppingCart-sec .form-check-input:checked {
    background-color: #000;
    border: #000;
}

.shopping-card-main-card .card-sec .left-sec .details h6 {
    font-size: 14px;
    font-weight: 500;
    color: #000;
}

.shopping-card-main-card .card-sec .left-sec .details p {
    font-size: 12px;
    font-weight: 400;
    color: #707070;
    margin: 0px;
}

.shopping-card-main-card .card-sec .add-btns {
    background: #FFF;
    color: #000;
    border: 1px solid grey;
    border-radius: 5px !important;
    padding: 0px;
    width: 25px;
    height: 25px;
    margin: 5px !important;
}

.shopping-card-main-card .card-sec .btn-grp p {
    margin: 0px;
    margin-top: 5px;
}

.shopping-card-main-card .card-sec .trash-btn {
    border: none;
    background-color: transparent;
}


.shopping-card-main-card .card-sec .right-sec p.price-text {
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 0px;
}

.shopping-card-main-card label {

    font-size: 15px;
    color: #000;
    font-weight: 500;
}

.shopping-card-main-card .sec-card-head p {
    font-size: 16px;
    color: #000;
    font-weight: 500;
}

.shopping-card-main-card .sec-card-head {
    display: flex;
    justify-content: space-between;
}

.shopping-card-main-card .sec-card-head span {
    color: #000;
    font-weight: 400;
    font-size: 14px;
    margin-right: 5px;
}

.shopping-card-main-card .sec-card-head button {
    color: #000;
    font-weight: 500;
    font-size: 14px;
    background-color: transparent;
    border: none;
}

.note-modal .form-control {
    background: #EEEEEE 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    height: 100px;
}

.note-modal .cancel-btn {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #7A7A7A;
    border-radius: 24px;
    opacity: 1;
    color: #000;
    font-size: 14px;
    padding: 4px 17px;
}

.note-modal .conform-btn {
    background: #F88D28 0% 0% no-repeat padding-box;
    border-radius: 24px;
    opacity: 1;
    color: #fff;
    font-size: 14px;
    border: none;
    padding: 4px 22px;
}

.note-modal .modal-title {
    font-size: 16px;
}

.note-modal .modal-content {

    box-shadow: 0px 0px 16px #0000001C;
    border-radius: 10px;
    opacity: 1;
    border: none;
}

@media (min-width: 0px) and (max-width: 768px) {
    .shopping-card-main-card label {
        font-size: 13px;

    }

    .shopping-card-main-card .card-sec .left-sec .details h6 {
        font-size: 12px;
    }

    .shopping-card-main-card .card-sec .left-sec .details p {
        font-size: 11px;

    }

    .shopping-card-main-card .card-sec .right-sec p.price-text {
        font-size: 14px;

    }

    .shopping-card-main-card .card-sec .prduct-img {
        width: 65px;
        height: 65px;
    }

    .shopping-card-main-card .sec-card-head p {
        font-size: 13px;
    }

    .shopping-card-main-card .sec-card-head span {

        font-size: 12px;
    }

    .shopping-card-main-card .sec-card-head button {

        font-size: 12px;

    }

    .shopping-card-main-card .sec-card-head {
        display: inline;
    }
}