.FAQ-banner .heading h1 {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  padding: 2%;
}

.FAQ-banner .Faqbannerimg {
  width: 100%;
}

.FAQ-banner {
  margin-bottom: 2%;
}
