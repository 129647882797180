.TermsModal .modalTitle {
  font-size: 16px;
}

.TermsModal .modalTitle h4 {
  font-size: 16px;
}

.TermsModal .termsList ol li {
  margin-bottom: 15px;
  font-size: 13px;
}
