.Furniture_sec .Furniture .Furniture-main-holder {
  background: #d4edf8 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 10px 20px;
}

.Furniture_sec
  .Furniture
  .Furniture-main-holder
  .Furniture-main-img
  .Furniture-img {
  width: 100%;
  height: 90px;
  object-fit: contain;
}

.Furniture_sec .Furniture .Furniture-main-holder h4 {
  font-size: 25px;
  font-weight: 600;
  color: #000000;
  text-align: center;
}

.Furniture_sec .Furniture .Furniture-main-holder .button-holder {
  text-align: center;
  margin: 15px 0%;
}

.Furniture_sec .Furniture .Furniture-main-holder .button-holder .sell-btn {
  border-radius: 19px;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  background: #68d1ff 0% 0% no-repeat padding-box;
  border-radius: 17px;
  padding: 9px 14px;
  cursor: pointer;
}
.Furniture_sec
  .Furniture
  .Furniture-main-holder
  .button-holder
  .sell-btn:focus {
  box-shadow: none;
}

.Furniture_sec
  .Furniture
  .Furniture-main-holder
  .button-holder
  .sell-btn:hover {
  background-color: #68d1ff;
}


@media (min-width: 0px) and (max-width: 320px) {
  .Furniture_sec .Furniture .Furniture-main-holder h4 {
    font-size: 15px;
  }

  .Furniture_sec .Furniture .Furniture-main-holder {
    margin-bottom: 5%;
  }
  
}

@media (min-width: 321px) and (max-width: 485px) {
  .Furniture_sec .Furniture .Furniture-main-holder h4 {
    font-size: 15px;
  }

  .Furniture_sec .Furniture .Furniture-main-holder {
    margin-bottom: 5%;
  }
  
}

@media (min-width: 486px) and (max-width: 575px) {
  .Furniture_sec .Furniture .Furniture-main-holder h4 {
    font-size: 15px;
  }

  .Furniture_sec .Furniture .Furniture-main-holder {
    margin-bottom: 5%;
  }
  
}

@media (min-width: 576px) and (max-width: 675px) {
  .Furniture_sec .Furniture .Furniture-main-holder h4 {
    font-size: 15px;
  }

  .Furniture_sec .Furniture .Furniture-main-holder {
    margin-bottom: 5%;
  }
  
}

@media (min-width: 676px) and (max-width: 765px) {
  .Furniture_sec .Furniture .Furniture-main-holder h4 {
    font-size: 15px;
  }

  .Furniture_sec .Furniture .Furniture-main-holder {
    margin-bottom: 5%;
  }
  
}




