/* MyWishlist Css Started */

.My-Wishlist .Background {
    position: relative;
    top: -143px;
    height: 189px;
    background: #eef2ff 0% 0% no-repeat padding-box;
    opacity: 1;
}
.My-Wishlist .main {
    padding-top: 10%;
    padding-left: 2%;
}
.My-Wishlist nav.bread .breadcrumb-item + .breadcrumb-item::before {
    color: #1a1a1a;
}
.My-Wishlist .maintenance-title {
    font-size: 28px;
}
.My-Wishlist .Wishlist-Cards {
    position: relative;
    top: -130px;
    margin-bottom: -95px;
}
.My-Wishlist ol.breadcrumb {
    margin-bottom: 0px;
}
.My-Wishlist .Show-More {
    text-align: center;
}
.My-Wishlist .Show-More .show {
    font-size: 14px;
    background: #1d44ad 0 0 no-repeat padding-box;
    border-radius: 19px;
    box-shadow: 0 3px 6px #00000029;
    color: #fff;
    padding: 3px 21px;
}
.My-Wishlist .Show-More .show:hover{
    background: #0000 linear-gradient(180deg, #1d44ad, #000d33) 0 0 no-repeat padding-box;
    border-radius: 19px;
    box-shadow: 0 3px 6px #00000029;
}
/* MyWishlist Css End */

/**************************** Media Query Started *************************/
@media (min-width: 0px) and (max-width: 485px) {
    .My-Wishlist .Background {
        top: -112px;
        height: 172px;
    }
    .My-Wishlist .main {
        padding-top: 18%;
        padding-left: 2%;
    }
    .My-Wishlist nav.bread {
        display: flex;
        justify-content: center;
    }
    .My-Wishlist .maintenance-title {
        padding: 13px 0px;
        font-size: 23px;
    }
    .My-Wishlist .Wishlist-Cards {
        top: -69px;
        margin-bottom: -32px;
    }
}
@media (min-width: 486px) and (max-width: 576px) {
    .My-Wishlist .Background {
        top: -112px;
        height: 172px;
    }
    .My-Wishlist .main  {
        padding-top: 13%;
        padding-left: 2%;
    }
    .My-Wishlist nav.bread {
        display: flex;
        justify-content: center;
    }
    .My-Wishlist .maintenance-title {
        padding: 13px 0px;
        font-size: 23px;
    }
    .My-Wishlist .Wishlist-Cards {
        top: -63px;
        margin-bottom: -21px;
    }
}
@media (min-width: 577px) and (max-width: 767px) {
    .My-Wishlist .main  {
        padding-top: 20%;
        padding-left: 3%;
    }
    .My-Wishlist nav.bread {
        display: flex;
        justify-content: center;
    }
    .My-Wishlist .maintenance-title {
        padding: 13px 0px;
        font-size: 25px;
    }
  
}
@media (min-width: 768px) and (max-width: 991px) {
    .My-Wishlist .main  {
        padding-top: 19%;
        padding-left: 3%;
    }
    .My-Wishlist nav.bread {
        display: flex;
        justify-content: center;
    }
    .My-Wishlist .maintenance-title {
        padding: 13px 0px;
        font-size: 26px;
    }
    .My-Wishlist .Background {
        height: 215px;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .My-Wishlist .main  {
        padding-top: 14%;
        padding-left: 4%;
    }
    .My-Wishlist .maintenance-title {
        font-size: 28px;
        text-align: left;
        padding: 0% 32%;
    }
}
@media (min-width: 1200px) and (max-width: 1399px) {
    .My-Wishlist .main  {
        padding-top: 12%;
        padding-left: 2%;
    }
}