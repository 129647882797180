.view-request .modal-content {
    background: #F9F9F9 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 17px #00000030;
    border-radius: 10px;
    border: none;
}

.view-request .submit-btn {
    background: #F88D28;
    font-size: 14px;
    color: #FFF;
    border: none;
    border-radius: 25px;
    padding: 5px 30px;
    border: 1px solid #F88D28;
}

.view-request .cancel-btn {
    background: transparent;
    font-size: 14px;
    color: #000;
    border: 1px solid;
    border-radius: 25px;
    padding: 5px 30px;
}

.view-request .first-title-view-request {
    font-size: 16px;
    color: #000;
    font-weight: 500;
}

.view-request .second-title {
    font-size: 14px;
    color: #000;
    font-weight: 500;
}

.view-request .radio-btn-class {
    font-size: 12px;
}