.b2bBestSeller .bestSellerMain .b2bbackImg {
  width: 100%;
  height: 100vh;
}

.b2bBestSeller .bestSellerMain {
  position: relative;
  background-image: url("../../../../public/assest/images/B2Bbuyer/BestSeller/bestSellerBackImg.png");
  background-size: contain;
  background-repeat: no-repeat;
}

/* .b2bBestSeller .bestSellerMain .b2bbestsellsec {
  position: absolute;
  top: 0%;
  width: 100%;
} */



.b2bBestSeller .bestSellerMain .bestSellercard {
  border: 0;
  margin-bottom: 15px;
}



.b2bBestSeller .bestSellerMain .ProductmainCard {
  padding: 18px 0px 4px 18px;
}

.b2bBestSeller .bestSellerMain .bestsellermainrow {
  padding-top: 5%;
}

.b2bBestSeller .bestSellerMain .subCategorieshead h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}

.b2bBestSeller .bestSellerMain .subCategorieshead div .nav-link {
  margin-bottom: 10px;
  font-size: 14px;
  color: #000;
  background-color: transparent;
  padding: 3px;
}

.b2bBestSeller .bestSellerMain .subCategorieshead div .nav-link.active {
  border-right: 5px solid #f88d28;
  border-radius: 0;
}

.b2bBestSeller .bestSellerMain .bestSellerrightcard .nav-pills .nav-link {
  color: #000;
  font-size: 18px;
  background-color: transparent;
  font-weight: 600;
}

.b2bBestSeller .bestSellerMain .bestSellerrightcard {
  border: 0;
  margin-bottom: 3%;
}


.b2bBestSeller .bestSellerMain .rightCradmainSec .rightCardsecImage img {
  width: 100%;
  border-radius: 10px;
  aspect-ratio: 2/2;
}

.b2bBestSeller .contactSupplbtn {
  background: #f88d28 0% 0% no-repeat padding-box;
  border: 0;
  margin-top: 15px;
  color: #fff;
}

.b2bBestSeller .rightcardbtn {
  /* padding: 8px; */
  border-radius: 24px;
  text-align: center;
  /* width: 100%; */
  padding: 7px 20px;
}

.b2bBestSeller .chatnowbtn {
  margin-top: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  color: #000;
}

.b2bBestSeller .bestSellerMain .rightMaincardRow {
  margin-bottom: 15px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
}

.b2bBestSeller .bestSellerMain .rightMaincardRow:last-child {
  border-bottom: 0;
}

.b2bBestSeller .bestSellerMain .rightCradmainSec .rightproductHead .SupplierHeadimg h3 {
  font-size: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  margin-top: auto;
  margin-bottom: auto;
}

/* .b2bBestSeller
  .bestSellerMain
  .rightCradmainSec
  .rightproductHead
  p:nth-of-type(2) {
  font: normal normal 600 12px/15px Inter;
} */

.b2bBestSeller .bestSellerMain .rightCradmainSec .rightproductHead .NoOrdersPieces h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 2px;
}

/* .b2bBestSeller
  .bestSellerMain
  .rightCradmainSec
  .rightproductHead
  p:first-of-type {
  margin-bottom: 0;
  font-size: 12px;
} */

.b2bBestSeller .bestSellerMain .rightCradmainSec .rightproductHead .verifiesMiansec {
  font-size: 14px;
  display: flex;
}

.b2bBestSeller .bestSellerMain .rightCradmainSec .checkSymbol {
  width: 10px;
  height: 10px;
  margin-bottom: 3px;
  margin-right: 7px;
}

.b2bBestSeller .bestSellerMain .rightCradmainSec ul {
  list-style-type: none;
  padding-left: 0px;
}

.b2bBestSeller .bestSellerMain .rightCradmainSec ul li {
  font-size: 10px;
}

.b2bBestSeller .bestSellerMain .rightCradmainSec .verifiedCheck {
  width: 16px;
  height: 16px;
  margin-right: 7px;
}

.b2bBestSeller .bestSellerMain .bestSellercard .Pricemainsec .searchtextsec {
  display: flex;
  justify-content: space-between;
}

.b2bBestSeller .bestSellerMain .bestSellercard .MinMaxrowmain {
  margin-bottom: 15px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
}

.b2bBestSeller .bestSellerMain .bestSellercard .MinMaxrowmain:last-child {
  border-bottom: 0;
}

.b2bBestSeller .bestSellerMain .bestSellercard .qty-input {
  color: #000;
  background: #fff;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.b2bBestSeller .B2b-ProductCard-sec .bestSellercard .qty-input .product-qty,
.b2bBestSeller .B2b-ProductCard-sec .bestSellercard .qty-input .qty-count {
  background: transparent;
  color: inherit;
  font-weight: bold;
  font-size: inherit;
  display: inline-block;
  min-width: 0;
  line-height: 1;
}

.b2bBestSeller .bestSellerMain .bestSellercard .qty-input .product-qty {
  width: 70px;
  min-width: 0;
  display: inline-block;
  text-align: center;
  appearance: textfield;
  border: 0;
}

.b2bBestSeller .bestSellerMain .bestSellercard .qty-input .product-qty::-webkit-outer-spin-button,
.b2bBestSeller .bestSellerMain .bestSellercard .qty-input .product-qty::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.b2bBestSeller .bestSellerMain .bestSellercard .qty-input .qty-count:before,
.b2bBestSeller .bestSellerMain .bestSellercard .qty-input .qty-count:after {
  content: "";
  height: 2px;
  width: 10px;
  position: absolute;
  display: block;
  background: #000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.b2bBestSeller .bestSellerMain .bestSellercard .qty-input .qty-count--minus {
  border-right: 1px solid #e2e2e2;
}

.b2bBestSeller .bestSellerMain .bestSellercard .qty-input .qty-count--add {
  border-left: 1px solid #e2e2e2;
}

.b2bBestSeller .bestSellerMain .swipersuggestedImg {
  width: 111px;
  height: 114px;
  border-radius: 10px;
}

.b2bBestSeller .bestSellerMain .suggestedProd {
  margin-top: 5%;
}

.b2bBestSeller .bestSellerMain .suggestedProd .SwiperLeftCrad .card-body {
  text-align: center;
}

.b2bBestSeller .bestSellerMain .suggestedProd .SwiperLeftCrad {
  border-radius: 10px;
  margin-bottom: 13%;
  height: 200px;
  border: 0;
  margin: 1% 4% 12% 7%;
}

.b2bBestSeller .bestSellerMain .suggestedProd .swipersuggestedImgtwo {
  width: 100%;
  margin-bottom: 7%;
  height: 200px;
}

.b2bBestSeller .bestSellerMain .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #ababab;
}

.b2bBestSeller .bestSellerMain .bestsellermainrow .card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 14px #00000012;
  border-radius: 10px;
  border: 0;
}

.b2bBestSeller .bestSellerMain .bestsellermainrow .swiperMainrow {
  margin-bottom: 3%;
}

.b2bBestSeller .bestSellerMain .bestsellermainrow .SwiperLeftCrad .card-body h3 {
  font-size: 18px;
}

.b2bBestSeller .bestSellerMain .bestsellermainrow .SwiperLeftCrad .card-body p {
  font-size: 12px;
  margin-bottom: 3px;
  margin-top: 14px;
}

/* All Supplier tab and Verified Manufacture tab */
.b2bBestSeller .bestSellerMain .capabilityHead {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
  margin-top: 10px;
}

.b2bBestSeller .bestSellerMain .supplierLogo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.b2bBestSeller .bestSellerMain .verifiesMiansec p:first-of-type {
  font-size: 12px;
  margin-left: 5px;
  margin-bottom: 0;
}

.b2bBestSeller .bestSellerMain .verifiesMiansec p:nth-of-type(2) {
  margin-left: 7px;
  font-size: 12px;
  margin-bottom: 0;
}

.b2bBestSeller .bestSellerMain .verifiesMiansec .ratingTxt p span:first-of-type {
  font-size: 10px;
  font-weight: 600;
}

.b2bBestSeller .bestSellerMain .verifiesMiansec .ratingTxt {
  background: #e1e1e1 0% 0% no-repeat padding-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-left: 7px;
  padding: 0px 3px;
}

.b2bBestSeller .bestSellerMain .rightproductHead .SupplierHeadimg {
  display: flex;
  margin-bottom: 18px;
}

.b2bBestSeller .bestSellerMain .verifyImgtxt {
  border-right: 1px solid #ccc;
  padding-right: 9px;
  font-size: 12px;
  color: #49adf4;
}

.b2bBestSeller .bestSellerMain .vierfiedProduct {
  padding-right: 9px;
  font-size: 12px;
  color: #49adf4;
  margin-bottom: 14px;
}

.b2bBestSeller .bestSellerMain .rightproductHead .NoOrdersPieces .minOrderProduct {
  display: flex;
  margin-bottom: 5px;
}

.b2bBestSeller .bestSellerMain .rightproductHead .NoOrdersPieces .minOrderProduct p:nth-of-type(2) {
  font-size: 12px;
  margin-left: 5px;
  font-weight: 600;
  margin-bottom: 0;
}

.b2bBestSeller .bestSellerMain .rightproductHead .NoOrdersPieces .minOrderProduct p:nth-of-type(1) {
  font-size: 12px;
  font-weight: normal;
  border-right: 1px solid #ccc;
  padding-right: 6px;
}

.b2bBestSeller .bestSellerMain .rightproductHead .NoOrdersPieces p:first-of-type {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 3px;
}

.b2bBestSeller .bestSellerMain .verifyFiltersHead h3 {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.b2bBestSeller .bestSellerMain .verifyFiltersHead p:first-of-type {
  margin-bottom: 2px;
  font-size: 13px;
  font-weight: 600;
}

.b2bBestSeller .bestSellerMain .verifyFiltersHead p:last-of-type {
  margin-bottom: 13px;
  color: #727272;
  font-size: 10px;
}

.b2bBestSeller .bestSellerMain .verifiedRadios label {
  font-size: 14px;
  margin-bottom: 10px;
}

.b2bBestSeller .bestSellerMain .MinMaxrowmain .AgileSupplyhead h3 {
  font-size: 13px;
  font-weight: 600;
}

.b2bBestSeller .bestSellerMain .bsciImg {
  width: 20px;
  height: 18px;
  margin-right: 13px;
}

.b2bBestSeller .heading {
  background-color: #fff;
  padding: 14px;
  margin-bottom: 10px;
}

.b2bBestSeller .heading h1 {
  font-size: 20px;
  color: #000;
  margin: 0px;
  font-weight: bold;
}

.b2bBestSeller .main-card-calling-div {
  background-color: #fff;
  padding: 10px 17px;
}

.suggested-product h1 {
  font-size: 16px;
  font-weight: bold;
}

.suggested-product .product-slide .add-img {
  height: 100%;
  min-height: 250px;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .b2bBestSeller .bestSellerMain .bestsellermainrow .bestSellerrightcard {
    overflow-x: scroll;
  }

  .b2bBestSeller .bestSellerMain .bestsellermainrow .bestSellerrightcard .nav {
    width: max-content;
  }

  .b2bBestSeller .bestSellerMain .verifiesMiansec p:first-of-type {
    margin-bottom: 3px;
    margin-left: 0;
  }

  .b2bBestSeller .bestSellerMain .verifiesMiansec p:nth-of-type(2) {
    margin-bottom: 4px;
    margin-left: 0;
  }

  .b2bBestSeller .bestSellerMain .breadcrumb-item a {
    font-size: 10px;
  }

  .suggested-product .product-slide .add-img {
    height: 100%;
    min-height: 100%;
  }

  .b2bBestSeller .bestSellerMain .breadcrumb-item+.breadcrumb-item::before {
    font-size: 12px;
    padding-top: 6px;
  }

  .b2bBestSeller .bestSellerMain .rightCradmainSec .rightCardsecImage img {
    margin-bottom: 19px;
  }

  .b2bBestSeller .bestSellerMain .suggestedProd .swipersuggestedImgtwo {
    margin-bottom: 13%;
    height: 87px;
  }

  .b2bBestSeller .bestSellerMain .suggestedProd .SwiperLeftCrad {
    height: auto;
  }

  .b2bBestSeller .bestSellerMain .rightCradmainSec .rightcardbtn {
    padding: 7px;
    font-size: 12px;
  }

  .b2bBestSeller .bestSellerMain .MinMaxrowmain label {
    font-size: 12px;
  }

  .b2bBestSeller .colourselect__menu,
  .colourselect__placeholder {
    font-size: 12px;
  }

  .b2bBestSeller .MinMaxrowmain .form-control {
    font-size: 12px;
  }

  .b2bBestSeller .bestSellerMain .bestSellercard .qty-input {
    font-size: 12px;
  }

  .b2bBestSeller .bestSellerMain .verifyImgtxt {
    font-size: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .b2bBestSeller .bestSellerMain .rightCradmainSec .rightproductHead .SupplierHeadimg h3 {
    font-size: 12px;
  }

  .b2bBestSeller .bestSellerMain .rightCradmainSec .rightproductHead .verifiesMiansec {
    font-size: 14px;
    display: block;
  }

  .b2bBestSeller .bestSellerMain .rightproductHead .NoOrdersPieces .minOrderProduct p:nth-of-type(1) {
    margin-left: 0px;
    margin-bottom: 5px;
    font-size: 11px;
  }

  .b2bBestSeller .bestSellerMain .rightproductHead .NoOrdersPieces p:first-of-type {
    margin-bottom: 5px;
  }

  .b2bBestSeller .bestSellerMain .verifiesMiansec .ratingTxt {
    margin-left: 0px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .b2bBestSeller .bestSellerMain .bestsellermainrow .SwiperLeftCrad .card-body h3 {
    font-size: 14px;
  }

  .b2bBestSeller .bestSellerMain .breadcrumb-item a {
    font-size: 10px;
  }

  .b2bBestSeller .bestSellerMain .breadcrumb-item+.breadcrumb-item::before {
    font-size: 12px;
    padding-top: 6px;
  }

  .b2bBestSeller .bestSellerMain .swipersuggestedImg {
    width: 87px;
    height: 87px;
  }

  .b2bBestSeller .bestSellerMain .MinMaxrowmain label {
    font-size: 14px;
  }

  .b2bBestSeller .bestSellerMain .subCategorieshead div p {
    font-size: 12px;
  }
}

@media screen and (min-width: 769px) and (max-width: 991px) {
  .b2bBestSeller .bestSellerMain .rightCradmainSec .rightcardbtn {
    padding: 4px;
    font-size: 11px;
  }

  .b2bBestSeller .bestSellerMain .bestSellercard .qty-input {
    font-size: 12px;
  }

  .b2bBestSeller .colourselect__menu,
  .colourselect__placeholder {
    font-size: 12px;
  }

  .b2bBestSeller .MinMaxrowmain .form-control {
    font-size: 12px;
  }

  .b2bBestSeller .bestSellerMain .subCategorieshead div p {
    font-size: 12px;
  }

  .b2bBestSeller .bestSellerMain .rightproductHead .NoOrdersPieces .minOrderProduct p:nth-of-type(2) {
    font-size: 10px;
  }

  .b2bBestSeller .bestSellerMain .rightproductHead .NoOrdersPieces .minOrderProduct p:nth-of-type(1) {
    font-size: 10px;
  }

  .b2bBestSeller .bestSellerMain .rightproductHead .NoOrdersPieces p:first-of-type {
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .b2bBestSeller .bestSellerMain .verifyImgtxt {
    padding-right: 9px;
    display: flex;
    font-size: 10px;
  }

  .b2bBestSeller .bestSellerMain .rightCradmainSec .rightcardbtn {
    padding: 6px;
    font-size: 12px;
  }
}

.b2bBestSeller .main-slider-section {
  position: relative;
}

.b2bBestSeller .main-slider .arrow-back,
.b2bBestSeller .main-slider .arrow-next {
  cursor: pointer;
}

.b2bBestSeller .main-slider-section:hover .main-slider .arrow-back {
  content: url("../../../../public/assest/images/B2Bbuyer/B2BSellerDetail/left.png");
  position: absolute;
  top: 50%;
  left: -6%;
  z-index: 2;
  cursor: pointer;
  width: 30px;
  transform: translate(0px, -50%);
}

.b2bBestSeller .main-slider-section:hover .main-slider .arrow-next {
  content: url("../../../../public/assest/images/B2Bbuyer/B2BSellerDetail/right.png");
  position: absolute;
  top: 50%;
  right: -7%;
  z-index: 2;
  cursor: pointer;
  width: 30px;
  transform: translate(0px, -50%);
}