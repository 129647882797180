.B2B_Addvertise .Addvertise_Slider .Addvertise_Slider_img_holder {
  border-radius: 10px;
}

.B2B_Addvertise
  .Addvertise_Slider
  .Addvertise_Slider_img_holder
  .Addvertise_Slider_img {
  height: 180px;
  width: 100%;
  border-radius: 10px;
}

.B2B_Addvertise .Addvertise_Slider .swiper-pagination-bullet {
  background: #fff;
  opacity: 1;
}

.B2B_Addvertise .Addvertise_Slider .swiper-pagination-bullet-active {
  background: #000;
}

.B2B_Addvertise .Addvertise_Slider .swiper-pagination {
  text-align: end;
}


@media (min-width: 0px) and (max-width: 320px) {
  .B2B_Addvertise
  .Addvertise_Slider
  .Addvertise_Slider_img_holder
  .Addvertise_Slider_img {
    height: 80px;
    object-fit: contain;
}
}

@media (min-width: 321px) and (max-width: 485px) {
  .B2B_Addvertise
  .Addvertise_Slider
  .Addvertise_Slider_img_holder
  .Addvertise_Slider_img {
    height: 100px;
    object-fit: contain;
}
}

@media (min-width: 486px) and (max-width: 575px) {
  .B2B_Addvertise
  .Addvertise_Slider
  .Addvertise_Slider_img_holder
  .Addvertise_Slider_img {
    height: 100px;
    object-fit: contain;
}
}

@media (min-width: 576px) and (max-width: 675px) {
  .B2B_Addvertise
  .Addvertise_Slider
  .Addvertise_Slider_img_holder
  .Addvertise_Slider_img {
    height: 100px;
    object-fit: contain;
}
}

@media (min-width: 676px) and (max-width: 765px) {
  .B2B_Addvertise
  .Addvertise_Slider
  .Addvertise_Slider_img_holder
  .Addvertise_Slider_img {
    height: 100px;
    object-fit: contain;
}
}