.Customer_engagement {
    padding: 0px 20px;
}



.Customer_engagement .MuiBarElement-root {
    width: 10px !important; 
  }

