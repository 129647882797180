.tracker-sec {
    padding-bottom: 127px;
    background-color: #FFFF;
    border-radius: 10px;
    margin: 12px;
    padding-top: 23px;
}

.tracker-sec .stepper-div .order {
    font-size: 16px;
    font-weight: 500;
    margin: 20px 0px;
    text-align: left;
    color: #000000;
}

.tracker-sec .range-text-div {
    position: absolute;
    top: 27px;
    left: -24px;
    right: 0;
    width: 100px;
    text-align: center;
}

.tracker-sec .range-text-div .placed {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0px;
    text-align: left;
    color: #000000;
}

.tracker-sec .range-text-div .date {
    font-size: 10px;
    margin-bottom: 0px;
    font-weight: 400;
    color: #8B8B8B;
    text-align: left;
}

.tracker-sec input[type="range"] {
    -webkit-appearance: none;
}

.tracker-sec input[type="range"]::-webkit-slider-runnable-track {
    width: 300px;
    height: 5px;
    background: #f05340;
    border: none;
    border-radius: 3px;
}

.tracker-sec input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 3px solid #f05340;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #fff;
}

.tracker-sec input[type="range"]:focus {
    outline: none;
}

.tracker-sec input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #1D44AD;
}

.tracker-sec .stepper-div .RSPBprogressBar {
    height: 3px;
    background-color: #BCBCBC !important;
    top: 0px;
    width: 70%;

    margin-left: 45px;
}

.tracker-sec .stepper-div .RSPBprogressBar .indexedStep.null {
    width: 10px;
    height: 10px;
}

.tracker-sec .stepper-div .indexedStep.accomplished {
    background: #1D44AD !important;
    width: 10px;
    height: 10px;
}

.tracker-sec .stepper-div .indexedStep {
    position: relative;
    color: white;
    width: 15px;
    height: 15px;
    font-size: 12px;
    background-color: rgba(211, 211, 211, 0.8) !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tracker-sec .stepper-div h6.parcel-one {
    color: #0735B2;
    font-size: 12px;
    text-align: left;
    margin: 0px;
}

.tracker-sec .stepper-div h6.parcel {
    color: #6B6B6B;
    font-size: 12px;
    text-align: left;
    margin: 0px;
}

.tracker-sec .stepper-div p.loc {
    color: #6B6B6B;
    font-size: 9px;
    text-align: left;
}

.tracker-sec .main-relative-div {
    position: relative;
}

@media (min-width: 0px) and (max-width: 768px) {

    .tracker-sec .stepper-div .RSPBprogressBar {
        width: 500px;
    }

    .tracker-sec .range-text-div {
        position: absolute;
        top: -109px;
        left: -14px;
        right: 0px;
        width: 100px;
        text-align: center;
        transform: rotate(270deg);
    }
    /* .tracker-sec {

        background-color: transparent;
      
    } */

    .tracker-sec .main-relative-div {
        position: relative;
        left: -34%;
    }
}

@media (min-width: 0px) and (max-width: 400px) {
    .tracker-sec .stepper-div {
        transform: rotate(90deg);
        margin-top: 127px;
        margin-bottom: 379px;
    }
    .tracker-sec {
        padding-bottom: 169px;
       
    }
}

@media (min-width: 401px) and (max-width: 480px) {
    .tracker-sec .stepper-div {
        transform: rotate(90deg);
        margin-top: 180px;
        margin-bottom: 351px;
    }
}

@media (min-width: 481px) and (max-width: 575px) {
    .tracker-sec .stepper-div {
        transform: rotate(90deg);
        margin-top: 253px;
        margin-bottom: 321px;
    }
}



@media (min-width: 576px) and (max-width: 768px) {
    .tracker-sec .stepper-div {
        transform: rotate(90deg);
        margin-top: 50%;
        margin-bottom: 50%;
    }
    .tracker-sec {
        padding-bottom: 164px;
      
    }
    
}