.Beauty_sec .Beauty .Beauty-main-holder {
  background: #feebeb;
  border-radius: 10px;
  padding: 10px 20px;
}

.Beauty_sec .Beauty .Beauty-main-holder .Beauty-main-img .Beauty-img {
  width: 100%;
  height: 90px;
  object-fit: contain;
}

.Beauty_sec .Beauty .Beauty-main-holder h4 {
  font-size: 25px;
  font-weight: 600;
  color: #000000;
  text-align: center;
}

.Beauty_sec .Beauty .Beauty-main-holder .button-holder {
  text-align: center;
  margin: 15px 0%;
}

.Beauty_sec .Beauty .Beauty-main-holder .button-holder .sell-btn {
  border-radius: 19px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  background: #ff4343;
  border-radius: 17px;
  padding: 7px 14px;
  cursor: pointer;
}
.Beauty_sec .Beauty .Beauty-main-holder .button-holder .sell-btn:focus {
  box-shadow: none;
}

.Beauty_sec .Beauty .Beauty-main-holder .button-holder .sell-btn:hover {
  background-color: #68d1ff;
}

@media (min-width: 0px) and (max-width: 320px) {
  .Beauty_sec .Beauty .Beauty-main-holder h4 {
    font-size: 15px;
  }

  .Beauty_sec .Beauty .Beauty-main-holder {
    margin-bottom: 5%;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Beauty_sec .Beauty .Beauty-main-holder h4 {
    font-size: 18px;
  }

  .Beauty_sec .Beauty .Beauty-main-holder {
    margin-bottom: 5%;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Beauty_sec .Beauty .Beauty-main-holder h4 {
    font-size: 18px;
  }

  .Beauty_sec .Beauty .Beauty-main-holder {
    margin-bottom: 5%;
  }
}

@media (min-width: 576px) and (max-width: 675px) {
  .Beauty_sec .Beauty .Beauty-main-holder h4 {
    font-size: 18px;
  }

  .Beauty_sec .Beauty .Beauty-main-holder {
    margin-bottom: 5%;
  }
}

@media (min-width: 676px) and (max-width: 765px) {
  .Beauty_sec .Beauty .Beauty-main-holder h4 {
    font-size: 18px;
  }

  .Beauty_sec .Beauty .Beauty-main-holder {
    margin-bottom: 5%;
  }
}

