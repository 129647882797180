.SelectedTrendingProducts .SelectedTrendingProducts-main-img-holder {
  border-radius: 10px;
  margin-bottom: 5%;
  margin: 0% 2% 5% 2%;
}

.SelectedTrendingProducts
  .SelectedTrendingProducts-main-img-holder
  .SelectedTrendingProducts-main-img {
  width: 100%;
  border-radius: 10px;
  /* height: 266px; */
  height: auto;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.SelectedTrendingProducts
  .SelectedTrendingProducts-img-holder
  .SelectedTrendingProducts-img {
  width: 100%;
  border-radius: 10px;
  height: 98px;
  object-fit: contain;
}

.SelectedTrendingProducts .SelectedTrendingProducts_Swiper {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding: 20px 20px 50px 20px;
  height: 450px;
}

.SelectedTrendingProducts
  .SelectedTrendingProducts_Swiper
  .swiper-pagination-bullet-active {
  background: #ababab;
}

.SelectedTrendingProducts
  .SelectedTrendingProducts_Swiper
  .SelectedTrendingProducts-img-holder {
  margin: 0px 5px;
}

.SelectedTrendingProducts
  .SelectedTrendingProducts_Swiper
  .swiper-button-prev:after {
  display: none;
}

.SelectedTrendingProducts
  .SelectedTrendingProducts_Swiper
  .swiper-button-next:after {
  display: none;
}

.SelectedTrendingProducts
  .SelectedTrendingProducts_Swiper
  .navigation-arrow-holder
  .navigation-arrow {
  width: 14px;
  height: 30px;
  color: #000;
  background: #ccc;
  padding: 0px 8px;
  border-radius: 50px;
}

.SelectedTrendingProducts
  .SelectedTrendingProducts_Swiper
  .navigation-arrow-holder
  .swiper-button-prev {
  position: absolute;
  top: 40px;
  left: 78%;
}

.SelectedTrendingProducts
  .SelectedTrendingProducts_Swiper
  .navigation-arrow-holder
  .swiper-button-next {
  position: absolute;
  top: 40px;
  right: 7%;
}

@media (min-width: 0px) and (max-width: 320px) {
  .SelectedTrendingProducts
    .SelectedTrendingProducts-main-img-holder
    .SelectedTrendingProducts-main-img {
    height: 150px;
  }

  .SelectedTrendingProducts
    .SelectedTrendingProducts_Swiper
    .navigation-arrow-holder
    .navigation-arrow {
    width: 8px;
    height: 18px;
    padding: 0px 5px;
  }

  .SelectedTrendingProducts
    .SelectedTrendingProducts_Swiper
    .navigation-arrow-holder
    .swiper-button-prev {
    left: 75%;
  }

  .SelectedTrendingProducts
    .SelectedTrendingProducts-img-holder
    .SelectedTrendingProducts-img {
    height: 60px;
  }

  .SelectedTrendingProducts .SelectedTrendingProducts_Swiper {
    height: 290px;
    margin-bottom: 5%;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .SelectedTrendingProducts
    .SelectedTrendingProducts_Swiper
    .navigation-arrow-holder
    .navigation-arrow {
    width: 8px;
    height: 18px;
    padding: 0px 5px;
  }

  .SelectedTrendingProducts
    .SelectedTrendingProducts_Swiper
    .navigation-arrow-holder
    .swiper-button-prev {
    left: 77%;
}

  .SelectedTrendingProducts .SelectedTrendingProducts_Swiper {
    margin-bottom: 5%;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .SelectedTrendingProducts
    .SelectedTrendingProducts_Swiper
    .navigation-arrow-holder
    .swiper-button-prev {
    left: 80%;
  }

  .SelectedTrendingProducts .SelectedTrendingProducts_Swiper {
    margin-bottom: 5%;
  }
}

@media (min-width: 576px) and (max-width: 675px) {
  .SelectedTrendingProducts
    .SelectedTrendingProducts_Swiper
    .navigation-arrow-holder
    .swiper-button-prev {
    left: 80%;
  }

  .SelectedTrendingProducts .SelectedTrendingProducts_Swiper {
    margin-bottom: 5%;
  }
}

@media (min-width: 676px) and (max-width: 767px) {
  .SelectedTrendingProducts
    .SelectedTrendingProducts_Swiper
    .navigation-arrow-holder
    .swiper-button-prev {
    left: 80%;
  }

  .SelectedTrendingProducts .SelectedTrendingProducts_Swiper {
    margin-bottom: 5%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
.SelectedTrendingProducts
  .SelectedTrendingProducts_Swiper
  .navigation-arrow-holder
  .swiper-button-prev {
    left: 70%;
}
}

@media (min-width: 992px) and (max-width: 1023px) {
.SelectedTrendingProducts
  .SelectedTrendingProducts_Swiper
  .navigation-arrow-holder
  .swiper-button-prev {
    left: 70%;
}
}


@media (min-width: 1024px) and (max-width: 1299px) {
.SelectedTrendingProducts
  .SelectedTrendingProducts_Swiper
  .navigation-arrow-holder
  .swiper-button-prev {
    left: 72%;
}
}

