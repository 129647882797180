/* mrp modal css start  */
.mrp-modal .gamimg-h6{
    font-size: 16px;
    color: #000;
  }
  .mrp-modal .gamimg-h6 span{
    font-size: 12px;
  }
  .mrp-modal .dgfahdg{
    width: 50px;
    height: auto;
  }
  .mrp-modal .update-btn{
    background: #f88d28;
    border: 0px;
    color: #fff;
    font-size: 14px;
    padding: 7px 20px;
    border-radius: 20px;
    float: right;
  }

  .mrp-modal  .modal-header {
    border-bottom: 0px solid #dee2e6;
}
.mrp-modal{
  z-index: 999999999;
}
  
.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .5;
  &:before {
    content: '';
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;

    padding: 0 .5em;
    line-height: 1.5em;
    color: #818078;
    background-color: #fcfcfa;
  }
}
  
  /* mrp modal css end  */
  
