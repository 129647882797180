.OffcanvasContactSupply {
  width: 37%;
  height: 649px !important;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 17px #00000030;
  border-radius: 10px 10px 0px 0px;
  left: 43% !important;
}

.OffcanvasContactSupply .offcanvasmain .contactSupplycard {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: 0;
  margin-bottom: 20px;
}

.OffcanvasContactSupply .offcanvasmain .contactSupplycard .canvasImageTextsec {
  display: flex;
}

.OffcanvasContactSupply .offcanvasmain .contactSupplycard .canvasImagesec img {
  width: 48px;
  height: 48px;
}

.OffcanvasContactSupply .offcanvasmain .contactSupplycard .canvasImageTextsec p {
  font-size: 12px;
  margin-left: 15px;
}

.OffcanvasContactSupply .offcanvasmain .contactSupplycard .QuantityUnitsec label {
  font-size: 12px;
}

.OffcanvasContactSupply .offcanvasmain .contactSupplycard .Unitselect__menu,
.Unitselect__placeholder {
  font-size: 12px;
}

.OffcanvasContactSupply .offcanvasmain .contactSupplycard .QuantityUnitsec {
  border-left: 1px solid #000;
}

.OffcanvasContactSupply .offcanvasmain .detailedhead h3 {
  font-size: 16px;
}

.OffcanvasContactSupply .offcanvasmain .detailedSec .detailedhead p {
  font-size: 12px;
}

.OffcanvasContactSupply .offcanvasmain .detailedhead .form-floating {
  margin-bottom: 26px;
}

.OffcanvasContactSupply .offcanvasmain .detailedhead .Checkboxsec label {
  font-size: 12px;
  margin-bottom: 15px;
}

.OffcanvasContactSupply .offcanvasmain .attachedResume .detailedhead p {
  font-size: 12px;
  margin-bottom: 44px;
}

.OffcanvasContactSupply .offcanvasmain .Inquirybtnsec .btn {
  background: #f88d28 0% 0% no-repeat padding-box;
  border-radius: 24px;
  border: 0;
  text-align: center;
  padding: 11px 35px;
}

.OffcanvasContactSupply .offcanvasmain .contactSupplyOffFirst {
  display: flex;
  margin-bottom: 16px;
}

.OffcanvasContactSupply .offcanvasmain .contactSupplyOffFirst .profileImg {
  width: 30px;
  height: 30px;
  margin-left: 12px;
}

.OffcanvasContactSupply .offcanvasmain .contactSupplyOffFirst .nameCompanyTxt h3 {
  font-size: 14px;
  margin-top: 4px;
  margin-left: 10px;
}

.OffcanvasContactSupply .offcanvasmain .contactSupplyOffFirst p:first-of-type {
  font-size: 14px;
  margin-top: 4px;
}

.OffcanvasContactSupply .offcanvasmain .contactSupplyOffFirst .nameCompanyTxt p {
  font-size: 12px;
  margin-top: 4px;
  margin-left: 10px;
}

.OffcanvasContactSupply .offcanvas-header {
  border-bottom: 1px solid #ccc;
}

@media screen and (min-width: 0px) and (max-width: 767.98px) {
  .OffcanvasContactSupply {
    width: 100%;
    margin-left: auto;
    right: 0%;
    left: 0%;
  }

  .OffcanvasContactSupply .offcanvasmain .contactSupplycard .QuantityUnitsec {
    border-left: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .OffcanvasContactSupply {
    right: 0% !important;
    width: 60%;
    left: 35% !important;
  }

  .OffcanvasContactSupply .offcanvasmain .contactSupplycard .QuantityUnitsec {
    border-left: 0;
  }

  .OffcanvasContactSupply {}
}

@media screen and (min-width: 992px) and (max-width: 1600px) {
  .OffcanvasContactSupply {
    width: 50%;
    right: 0% !important;
  }
}

@media screen and (min-width: 2200px) and (max-width: 2560px) {
  .OffcanvasContactSupply {
    width: 35%;
    right: 0;
  }
}