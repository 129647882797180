.SelectCategory .categoryCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000012;
  border-radius: 10px;
  border: 0;
  height: 100%;
}

.SelectCategory .categoryCard h5 {
  font-size: 14px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
  padding: 11px;
}

.SelectCategory .categoryCard .form-control {
  background: #f0f2f7 0% 0% no-repeat padding-box;
  border-radius: 19px;
  font-size: 14px;
  box-shadow: unset;
}

.SelectCategory .categoryCard .categorySearch {
  position: relative;
}

.SelectCategory .categoryCard .categorySearch .searchIcon {
  position: absolute;
  top: 15px;
  right: 7px;
  font-size: 12px;
}

.SelectCategory .categoryCard .categoryImg1 {
  width: 34px;
  height: 34px;
  border-radius: 5px;
}

.SelectCategory .categoryCard .card-body {
  padding: 5px;
}

.SelectCategory .categoryCard .category_txt p {
  font-size: 13px;
  margin-top: 4px;
  margin-left: 15px;
  color: #000;
  font-weight: 500;
  margin-bottom: 0px;
}

.SelectCategory .categoryCard .categoryList {
  display: flex;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  padding: 5px 0px;
}
.SelectCategory .categoryCard .categoryList.selected {
  background-color: #fff6ee !important;
}

.SelectCategory .categorySliderCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000012;
  border-radius: 10px;
  border: 0;
}

.SelectCategory .categorySliderCard .categorySlidertxt p {
  font-size: 14px;
  font-weight: 500;
}

.SelectCategory .categorySliderCard .CategorySwiper .sliderImg1 {
  width: 100%;
  height: 120px;
}

.SelectCategory .categorySliderCard .sliderMainrow {
  border-bottom: 1px solid #ccc;
  padding: 2% 0% 4% 0%;
}

.SelectCategory .categorySliderCard .fieldsRow {
  padding-top: 8%;
  padding-bottom: 3%;
}

.SelectCategory .categorySliderCard .fieldsRow .form-control {
  /* border: 0.5px solid #ccc; */
  border-radius: 5px;
  font-size: 12px;
  box-shadow: unset;
}

.SelectCategory .categorySliderCard .fieldsRow .form-label {
  font-size: 14px;
}

.SelectCategory .categorySliderCard .fieldsRow .selectBrandbtn {
  background: #1c42a9 0% 0% no-repeat padding-box;
  border-radius: 24px;
  font-size: 12px;
}

.SelectCategory .categorySliderCard .fieldsRow .textbtn {
  text-align: center;
  margin-top: 5%;
  margin-bottom: 5%;
}

.SelectCategory .categorySliderCard .fieldsRow .textbtn p {
  font-size: 14px;
  font-weight: 500;
}

.SelectCategory .main-slider {
  position: relative;
  margin-top: 8%;
  /* margin-bottom: 5%; */
}

.SelectCategory .main-slider .arrow-back,
.SelectCategory .main-slider .arrow-next {
  cursor: pointer;
}

.SelectCategory .main-slider .arrow-back {
  /* content: url("/public/assets/images/online-learning/prev-icon.png"); */
  position: absolute;
  top: 43%;
  left: 38%;
  z-index: 2;
  cursor: pointer;
}

.SelectCategory .main-slider .arrow-next {
  /* content: url("/public/assets/images/online-learning/next-icon.png"); */
  position: absolute;
  top: -3px;
  right: 50%;
  z-index: 2;
  cursor: pointer;
  transform: translate(-50%, 0%);
}

.float-label-top {
  background-color: #fff6ee !important;
}
