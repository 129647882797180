/* UpdateInformation Css Started */

.UpdateInformation h4.maintenance-title {
    font-weight: 500;
    line-height: 40px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
}

.UpdateInformation .Book img.books {
    width: 100%;
    height: 522px;
    transition: 0.3s;
    border-radius: 25px;
    box-sizing: border-box;
}

.UpdateInformation  .Book:hover img.books {
    transform: scale(1.1);
}

.UpdateInformation .Book .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(180deg, #00000047 0%, #000000 100%) 0% 0% no-repeat padding-box;
    border-radius: 21px;
}

.UpdateInformation  .Book .content {
    position: absolute;
    top: 13px;
    left: 0px;
    padding: 15px 41px;
}
.UpdateInformation .Slider-update  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 226px;
    background: transparent linear-gradient(180deg, #00000047 0%, #000000 100%) 0% 0% no-repeat padding-box;
    border-radius: 21px;
}
.UpdateInformation  .Book .content svg.svg-inline--fa.fa-calendar-days {
    color: white;
    margin: 0px 10px;
}

.UpdateInformation  .Book .content .content-card .maintenance-title {
    text-align: left;
    color: white;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

.UpdateInformation  .content:hover .content-card .maintenance-title {
    color: #f88d28;
}

.UpdateInformation  .Book .content .content-card .sub-title {
    font-weight: 400;
    color: white;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
}

.UpdateInformation  .Book .content .content-card {
    margin-top: 78%;
}

.UpdateInformation  .Book  .content .content-card .Read-More .read {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 19px;
    padding: 8px 28px;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    border: none;
}

.UpdateInformation  .Book  .content .content-card .Read-More .read:hover {
    background: transparent linear-gradient(180deg, #f88d28 0%, #d26600 100%) 0% 0% no-repeat padding-box;
    border-radius: 19px;
    color: white;
}

.UpdateInformation
    
    .Book
    .content
    .content-card
    .Read-More
    .read
    svg.svg-inline--fa.fa-arrow-right {
    font-size: 14px;
    margin: 0px 8px;
}


.UpdateInformation .Book {
    height: 522px;
    position: relative;
    overflow: hidden;
    border-radius: 23px;
    cursor: pointer;
}

/* Information-Slider css */



.UpdateInformation .Update-Slider {
    position: relative;
    top: -10%;
}

.UpdateInformation .swiper-slide {
    margin-top: 0 !important;
    height: 252px !important;
    margin-bottom: 15px;
}

.UpdateInformation .swiper-wrapper {
    flex-direction: row;
}

.UpdateInformation .swiper-backface-hidden .swiper-slide {
    transform: translateZ(0);
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
}
.UpdateInformation .Slider-update  .slider-card-image img.card-image {
    width: 100%;
    transition: 0.3s;
    height: 227px;
}
.UpdateInformation  .Slider-update:hover .slider-card-image img.card-image {
    transform: scale(1.1);
}
.UpdateInformation .Slider-update {
    position: relative;
    cursor: pointer;
}
.UpdateInformation .Slider-update .content {
    position: absolute;
    top: 25px;
    /* left: 18px; */
    width: 100%;
    padding: 0px 11px;
}
.UpdateInformation .Slider-update .content svg.svg-inline--fa.fa-calendar-days {
    color: white;
    margin: 0px 10px;
}
.UpdateInformation .Slider-update .content .content-card .Read-More .read svg.svg-inline--fa.fa-arrow-right {
    font-size: 14px;
    margin: 0px 8px;
}
.UpdateInformation .Slider-update:hover .content .content-card .sub-title-medium {
    color: #f88d28;
}

.UpdateInformation .Slider-update .content .content-card .sub-title-medium {
    text-align: left;
    color: white;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
}

.UpdateInformation .Slider-update .content .content-card .sub-title {
    font-weight: 400;
    color: white;
}
.UpdateInformation .Slider-update .content .content-card {
    margin-top: 15%;
}
.UpdateInformation .Slider-update .content .content-card .Read-More .read {
    font-size: 14px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 19px;
    font-weight: 500;
    color: #000000;
    border: none;
}
.UpdateInformation .Slider-update .content .content-card .Read-More .read:hover   {
    background: transparent linear-gradient(180deg, #F88D28 0%, #D26600 100%) 0% 0% no-repeat padding-box;
    color: white;
}
.UpdateInformation .silder-btn {
    position: relative;
    margin-top: 16px;
}

.UpdateInformation .silder-btn .back-btn {
    content: url("../../../../../public/assest/images/MyWebsite/HomeImages/Portfolio/right.png");
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: absolute;
    top: 12px;
    z-index: 99;
    left: 50%;
}
.UpdateInformation  .slider-card-image{
overflow: hidden;
border-radius: 24px;
cursor: pointer;
}
.UpdateInformation .silder-btn .next-btn {
    content: url("../../../../../public/assest/images/MyWebsite/HomeImages/Portfolio/left.png");
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: absolute;
    top: 12px;
    z-index: 99;
    right: 49%;
    transform: translateX(-50%);
}

section.UpdateInformation {
    margin-bottom: 12%;
    margin-top: 10%;
}
.UpdateInformation
    .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    display: none;
}

.UpdateInformation .Book img.books {
    width: 100%;
    height: 522px;
}

/*************************** Media Query Css Started **************************/

@media (min-width: 0px) and (max-width: 991px) {
    .UpdateInformation .Book {
        height: 415px;
    }
    .UpdateInformation .Main-Book-section .Book img.books {
        height: auto;
    }
    .UpdateInformation .Main-Book-section .Book .content .content-card {
        margin-top: 51%;
    }
    .UpdateInformation .Book .content .content-card {
        margin-top: 53%;
    }
    .UpdateInformation .Book img.books {
        width: 100%;
        /* height: 381px; */
    }
    .UpdateInformation .Slider-update .content .content-card {
        margin-top: 6%;
    }
    .UpdateInformation .Information-Slider .Slider-update .Read-More {
        margin-top: 17%;
    }
    .UpdateInformation .silder-btn {
       margin-top: -12px;
       margin-bottom: 24%;
    }
    .UpdateInformation  .Book .content {
      
        padding: 15px 24px;
    }
}
