.Sidebar_seller_dashboard {
  background: #ffffff;
  padding: 15px 15px;
  box-shadow: 0px 3px 19px #0000001a;
  position: relative;
  z-index: 999;
  /* height: 100vh;
  overflow-y: auto;
  position: relative; */
}

.Sidebar_seller_dashboard .brown-border {
  border-bottom: 2px solid #bcbcbc;
  margin-bottom: 15px;
}

.Sidebar_seller_dashboard  .accordion .accordion-item .inner-dropp .inner-menu-div.active {
  background: #f88d28;
  border-radius: 50px;
  padding: 6px 7px;
  display: flex;
  justify-content: start;
  align-items: center;
}


.Sidebar_seller_dashboard  .accordion .accordion-header .ico-span {
  width: 30px;
}

.sidebar-flex   .accordion .accordion-item .inner-dropp .inner-menu-div.active {
  background: #f88d28;
  border-radius: 50px;
  padding: 8px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Sidebar_seller_dashboard  .accordion .accordion-item .inner-dropp .inner-menu-div.active  .inner-menu {
  font-size: 13px;
  color: #fff;
  font-weight: 500;
}

.Sidebar_seller_dashboard  .accordion .accordion-item .inner-dropp .inner-menu-div.active  .inner-menu-icc {
  color: #fff;
}

.Sidebar_seller_dashboard .logo-image-main .logo-img {
  width: 100%;
  padding: 10px 30px;
}

.sidebar-flex .accordion-button:not(.collapsed) {
  padding-left: 15px !important;
  border-radius: 0 !important;
}

.Sidebar_seller_dashboard .tab-main {
  margin-top: 10%;
}

.Sidebar_seller_dashboard .accordion .accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 12px;
  color: #000;
  font-weight: 500;
  text-align: center;
  border: none;
  padding: 8px 15px;
  margin: 10px 0px;
  background-color: #fff;
  text-wrap: nowrap;
}

.Sidebar_seller_dashboard .accordion-button:focus {
  z-index: 3;
  border: none;
  font-size: 12px;
  border-radius: 50px;
  outline: none;
  box-shadow: unset !important;
}

.Sidebar_seller_dashboard .accordion .accordion-button a {
  color: #000;
}

.Sidebar_seller_dashboard .accordion-button:not(.collapsed) a {
  color: #fff;
}

.Sidebar_seller_dashboard .accordion-item {
  background-color: transparent;
  border: 0px;
}

.Sidebar_seller_dashboard .drippp {
  display: flex;
}
.sidebar-flex .drippp {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Sidebar_seller_dashboard .accordion .accordion-arrow {
  display: none;
}

.Sidebar_seller_dashboard .accordion-button::after {
  background-image: none;
}

.Sidebar_seller_dashboard a {
  text-decoration: none;
}

.Sidebar_seller_dashboard .accordion-button:not(.collapsed) {
  box-shadow: none;
  background: #f88d28;
  color: #ffffff;
  border-radius: 50px;
  font-size: 12px;
  padding: 8px 15px;
}

.Sidebar_seller_dashboard .logo-image-main {
  margin-bottom: 15px;
}

.Sidebar_seller_dashboard .side-img {
  width: 18px;
  height: 18px;
}

.Sidebar_seller_dashboard .accordion .accordion-item .ico-span .svg-inline--fa {
  color: #000;
  font-size: 16px;
  margin-right: 10px;
}

.Sidebar_seller_dashboard
  .accordion
  .accordion-item
  .accordion-button:not(.collapsed)
  .ico-span
  .svg-inline--fa {
  color: #fff;
}

.Sidebar_seller_dashboard .accordion .accordion-item .inner-dropp {
  background-color: #f1f1f1;
  margin-top: 15px;
  padding: 0px 5px;
}

.Sidebar_seller_dashboard .accordion .accordion-item .inner-dropp .inner-menu {
  font-size: 13px;
  color: #000;
}

.Sidebar_seller_dashboard
  .accordion
  .accordion-item
  .inner-dropp
  .inner-menu-icc {
  color: #f88d28;
  font-size: 15px;
  margin-right: 15px;
}
.sidebar-flex .accordion .accordion-item .inner-dropp .inner-menu-icc {
  color: #f88d28;
  font-size: 15px;
  margin-right: 0px;
}

.Sidebar_seller_dashboard .accordion .accordion-item .inner-menu-div {
  padding: 7px 3px;
  position: relative;
}

.Sidebar_seller_dashboard
  .accordion
  .accordion-item
  .inner-menu-div
  .inner-menu
  .next-fafaf {
  position: absolute;
  right: 7px;
  top: 12px;
  display: none;
}

.Sidebar_seller_dashboard .accordion .accordion-item .inner-menu-div:hover {
  background-color: #e8eef7;
}

.Sidebar_seller_dashboard
  .accordion
  .accordion-item
  .inner-menu-div
  .sublistt-div {
  position: absolute;
  right: -155px;
  top: 0px;
  padding: 10px;
  background-color: #fff;
  width: 130px;
  box-shadow: 0px 0px 5px 0px #ccc;
}

.Sidebar_seller_dashboard
  .accordion
  .accordion-item
  .inner-menu-div
  .sublistt-div
  .sublist-menu {
  color: #000;
  font-size: 13px;
}

.Sidebar_seller_dashboard
  .accordion-item:first-of-type
  > .accordion-header
  .accordion-button {
  border-top-left-radius: 43px;
  border-top-right-radius: 43px;
}

.Sidebar_seller_dashboard
  .accordion-item:first-of-type
  > .accordion-header
  .accordion-button:focus {
  border-top-left-radius: 43px;
  border-top-right-radius: 43px;
}


.Sidebar_seller_dashboard  .collapse-toggle-btn-holder {
  background: #f88d28;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #fff;
  width: 20px;
  height: 20px;
  float: right;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.Sidebar_seller_dashboard  .collapse-toggle-btn-holder .rotate-180 {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.Sidebar_seller_dashboard  .collapse-toggle-btn-holder .rotate-0 {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.sidebar-flex  .collapse-toggle-btn-holder {
  margin: 0px auto;
  float: none;
}

.sidebar-flex .logo-image-main{
  margin-top: 15px;
  display: none;
}

.Sidebar_seller_dashboard  .tabs-main-secttt {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.Sidebar_seller_dashboard  .tabs-main-secttt::-webkit-scrollbar {
  width: 0px;
}
.Sidebar_seller_dashboard  .tabs-main-secttt::-webkit-scrollbar:horizontal {
  height: 8px;
}
/* Track */
.Sidebar_seller_dashboard  .tabs-main-secttt::-webkit-scrollbar-track {
  background: #E8E8E8;
}
.Sidebar_seller_dashboard  .tabs-main-secttt::-webkit-scrollbar-track:horizontal {
  background: #E8E8E8;
}
/* Handle */
.Sidebar_seller_dashboard  .tabs-main-secttt::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 5px;
}
 .Sidebar_seller_dashboard  .tabs-main-secttt::-webkit-scrollbar-thumb:horizontal{
   background: #c7c7c7;
  border-radius: 5px;
   }



.Sidebar_seller_dashboard .accordion-body {
  padding: 0;
}


 .scale-up-hor-left {
	-webkit-animation: scale-up-hor-left 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-hor-left 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


@-webkit-keyframes scale-up-hor-left {
  0% {
    -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}
@keyframes scale-up-hor-left {
  0% {
    -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}


.scale-down-hor-left {
	-webkit-animation: scale-down-hor-left 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-down-hor-left 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale-down-hor-left {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}
@keyframes scale-down-hor-left {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}


@media (min-width: 992px) and (max-width: 1200px) {
  .Sidebar_seller_dashboard .accordion-button {
    padding: 10px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Sidebar_seller_dashboard .accordion-button {
    padding: 10px;
    font-size: 12px;
  }

  .Sidebar_seller_dashboard .accordion-button:not(.collapsed) {
    font-size: 12px;
  }

  .Sidebar_seller_dashboard {
    padding: 0px 5px;
  }
}
