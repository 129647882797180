.my-header {
    position: sticky !important;
    top: 30px;
    width: 100%;
    z-index: 1000;
    margin-bottom: 70px;
    position: relative;
}

.my-header .bg-body-tertiary {
    background: #FFFFFF;
    box-shadow: 0px 3px 26px #00000029;
    border-radius: 50px;
    padding: 0px 50px;
}

.my-header .logo-img {
    width: 160px;
}

.my-header .navbar-light .navbar-toggler {
    color: #000;
    font-size: 10px;
    padding: 6px;
}

.my-header .log-in-btn {
    background: transparent linear-gradient(180deg, #1D44AD 0%, #1553F9 100%);
    box-shadow: 0px 0px 10px #00000029;
    border-radius: 50px;
    color: #FFF;
    border: none;
    padding: 8px 30px;
    font-size: 14px;
    display: block;
}

.my-header .log-in-btn-mobile {
    background: transparent linear-gradient(180deg, #1D44AD 0%, #1553F9 100%);
    box-shadow: 0px 0px 10px #00000029;
    border-radius: 50px;
    color: #FFF;
    border: none;
    padding: 8px 20px;
    font-size: 12px;
    display: none;
    width: 80px;
    margin-bottom: 6%;
}

.my-header .navbar-light .navbar-nav .nav-link {
    color: #141414;
    font-size: 14px;
    font-weight: 500;
    padding: 3px 20px;
}

.my-header .navbar-light .navbar-nav .nav-link:hover {
    color: #1D44AD;
}

.my-header .navbar-light .navbar-nav .nav-link.active {
    color: #141414;
    background: #E1E9FF;
    border-radius: 15px;
    padding: 3px 20px;
}

.my-header svg.svg-inline--fa.fa-heart {
    font-size: 25px;
    /* color: red; */
}

.my-header .login-txt {
    font-size: 14px;
    color: black;
}

/* for login dropdown */
.my-header .drop-bg-user {
    position: absolute;
    top: 66%;
    left: 77%;
}

.my-header .drop-bg-user .box.arrow-top:after {
    content: " ";
    position: absolute;
    right: 70px;
    top: -15px;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 20px solid #FFF;
    box-shadow: 0px 0px 20px #0000000D;
}

.my-header .user-icon-btn {
    background: transparent;
    border: none;
    color: #000;
}

.my-header .user-icon-img {
    width: 30px;
}

/* for login dropdown */
.my-header .drop-bg {
    position: absolute;
    top: 66%;
    left: 85%;
}

.my-header .box {
    height: auto;
    background-color: #FFF;
    color: #000;
    padding: 14px 30px;
    position: relative;
    margin: 40px;
    float: left;
    box-shadow: 0px 0px 20px #0000000D;
    border: 1px solid #E8E8E8;
    border-radius: 15px;
}

.my-header .box .login-txt {
    font-size: 14px;
    color: #141414;
    font-weight: 600;
}

.my-header .box .login-txt:hover {
    font-size: 14px;
    color: #1D44AD;
    font-weight: 600;
    text-decoration: underline;
}


.my-header .box.arrow-top {
    margin-top: 40px;
}

.my-header .box.arrow-top:after {
    content: " ";
    position: absolute;
    right: 40px;
    top: -15px;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 20px solid #FFF;
    box-shadow: 0px 0px 20px #0000000D;
}

.my-header .user-icon-mobile {
    display: none;
}

.my-header .user-icon-desktop {
    display: block;
}

.my-header .nav-link.active {
    background-color: red;
}

.wishlist {
    display: none;
}

.wishlist-one {
    display: block;
}

/* Media */

@media(min-width:0px) and (max-width:576px) {
    .my-header {
        position: sticky;
        top: 0;
        box-shadow: 0px 3px 26px #00000029;
    }

    .my-header .bg-body-tertiary {
        border-radius: 0px 0px;
        padding: 0px 12px;
        box-shadow: unset;
    }

    .my-header .logo-img {
        width: 82px;
    }

    .my-header .login-desk {
        display: none;
    }

    .my-header .log-in-btn-mobile {
        display: block;
    }

    .my-header .log-in-btn {
        display: none;
    }

    .my-header .navbar-light .navbar-nav .nav-link {
        padding: 6px 10px;
    }

    .my-header .user-icon-mobile {
        display: block;
    }

    .my-header .user-icon-desktop {
        display: none;
    }

    .my-header .drop-bg-user {
        top: 83%;
        left: 5%;
    }

    .my-header .drop-bg {
        top: 65%;
        left: 20%;
    }

    .wishlist {
        display: block;
    }

    .wishlist-one {
        display: none;
    }

    .my-header svg.svg-inline--fa.fa-heart {
        padding: 0px 14px;
        font-size: 18px;

    }
}

@media(min-width:577px) and (max-width:768px) {
    .my-header .login-desk {
        display: none;
    }

    .my-header .log-in-btn-mobile {
        display: block;
    }

    .my-header .navbar-light .navbar-nav .nav-link {
        padding: 6px 10px;
    }

    .my-header {
        top: 9px;
    }

    .my-header .logo-img {
        width: 110px;
    }

    .my-header .user-icon-mobile {
        display: block;
    }

    .my-header .user-icon-desktop {
        display: none;
    }

    .my-header .drop-bg {
        top: 63%;
        left: 13%;
    }

    .my-header .drop-bg-user {
        top: 83%;
        left: 9%;
    }

    .wishlist {
        display: block;
    }

    .wishlist-one {
        display: none;
    }

    .my-header svg.svg-inline--fa.fa-heart {
        padding: 0px 14px;
        font-size: 18px;

    }
}

@media(min-width:769px) and (max-width:991px) {
    .my-header .navbar-light .navbar-nav .nav-link {
        color: #141414;
        font-size: 11px;
        font-weight: 500;
        padding: 0px 20px;
    }

    .my-header .user-icon-mobile {
        display: block;
    }

    .my-header .user-icon-desktop {
        display: none;
    }

    .my-header .drop-bg-user {
        left: 16%;
        top: 60%;
    }

    .my-header .drop-bg {
        left: 21%;
        top: 78%;
    }

    .my-header .log-in-btn-mobile {
        display: block;
    }

    .my-header .login-desk {
        display: none;
    }

    .wishlist {
        display: block;
    }

    .wishlist-one {
        display: none;
    }
}

@media(min-width:992px) and (max-width:1024px) {
    .my-header .navbar-light .navbar-nav .nav-link {
        font-size: 12px;
    }

    .my-header .log-in-btn {
        padding: 3px 18px;
        font-size: 12px;
    }

    .my-header .drop-bg-user {
        left: 71%;
    }

    .my-header .drop-bg {
        left: 82%;
    }

    .my-header .bg-body-tertiary {

        padding: 0px 30px;
    }
}

@media(min-width:1025px) and (max-width:1399px) {
    .my-header .navbar-light .navbar-nav .nav-link {
        font-size: 12px;
    }

    .my-header .log-in-btn {
        padding: 3px 18px;
        font-size: 12px;
    }

    .my-header .drop-bg-user {
        left: 76%;
    }

    .my-header .bg-body-tertiary {

        padding: 0px 30px;
    }
}