.Mywebsite .mywebsiteMain .websitestab {
  background-color: #fff;
  height: 95vh;
}

.Mywebsite .mywebsiteMain {
  margin: 0px 0px;
}

.Mywebsite .mywebsiteMain .nav-pills .nav-link {
  color: #000;
  background: transparent;
  border: 0 !important;
  font-size: 12px;
  padding: 7px 10px;
}

.Mywebsite .mywebsiteMain .websitestab .nav-pills .nav-link {
  padding: 7px 0px;
}

.Mywebsite
  .mywebsiteMain
  .websitestab
  .nav-pills
  .nav-link.active:not(.exclude-active-styling) {
  padding: 7px 0px;
}

/* .Mywebsite .mywebsiteMain .nav-pills .nav-link.active {
    color: #fff;
    background-color: #f88d28;
} */

.Mywebsite
  .mywebsiteMain
  .nav-pills
  .nav-link.active:not(.exclude-active-styling) {
  color: #fff;
  background-color: #f88d28;
  padding: 7px 10px;
}
