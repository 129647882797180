/* Portfolio Css Started */

.Common-Banner {
    background: #fff2e5 0% 0% no-repeat padding-box;
    position: relative;
    top: -153px;
}

.Common-Banner .portfolio-content {
    padding: 12% 2% 0% 2%;
}

.Common-Banner .breadcrumb{
  width: max-content;
}

.Common-Banner .portfolio-content .maintenance-text {
    padding: 4px 15px;
    color: black;
}
.Common-Banner .breadcrumb-item a {
    font-weight: 500;
    color: #000;
    font-size: 16px;
}
.Common-Banner .portfolio-content .heading {
    width: -webkit-fill-available;
}
.Common-Banner .portfolio-content .back-btn-main {
    width: 19%;
}
.Common-Banner  p.whish {
    font-size: 28px;
}
.Common-Banner  p.maintenance-text {
    text-align: center;
    font-weight: 500;
}
/* Portfolio Css End */

/* Media Query Started */

@media (min-width: 0px) and (max-width: 320px) {
    .Common-Banner {
        top: -69px;
    }
    .Common-Banner .portfolio-content {
        padding: 7% 0% 2% 0%;
    }
    section.Portfolio-Content {
        margin-top: -17%;
    }
    .Common-Banner .portfolio-content .back-btn-main {
        display: flex;
        width: 100%;
        justify-content: center;
    }
    p.whish {
        font-size: 22px;
    }
    .Common-Banner .breadcrumb-item a {
        font-size: 13px;
    }
  
}
@media (min-width: 321px) and (max-width: 485px) {
    .Common-Banner {
        top: -69px;
    }
    .Common-Banner .portfolio-content {
        padding: 7% 0% 2% 0%;
    }
    section.Portfolio-Content {
        margin-top: -17%;
    }
    .Common-Banner .portfolio-content .back-btn-main {
        display: flex;
        width: 100%;
        justify-content: center;
    }
    p.whish {
        font-size: 22px;
    }
    .Common-Banner .breadcrumb-item a {
        font-size: 13px;
    }
 
}
@media (min-width: 486px) and (max-width: 576px) {
    .Common-Banner {
        top: -69px;
    }
    .Common-Banner .portfolio-content {
        padding: 7% 0% 2% 0%;
    }
    section.Portfolio-Content {
        margin-top: -17%;
    }
    .Common-Banner .portfolio-content .back-btn-main {
        display: flex;
        width: 100%;
        justify-content: center;
    }
    p.whish {
        font-size: 22px;
    }
    .Common-Banner .breadcrumb-item a {
        font-size: 13px;
    }
   
}
@media (min-width: 577px) and (max-width: 767px) {
    .Common-Banner .portfolio-content {
        padding: 20% 2% 2% 4%;
    }
    .Common-Banner .portfolio-content .back-btn-main {
        width: 31%;
    }
    p.whish {
        font-size: 22px;
    }
    .Common-Banner .breadcrumb-item a {
        font-size: 13px;
    }
    .Common-Banner .portfolio-content .back-btn-main {
        display: flex;
        width: 100%;
        justify-content: center;
    }
 
}
@media (min-width: 768px) and (max-width: 991px) {
    .Common-Banner .portfolio-content {
        padding: 20% 2% 2% 4%;
    }
    .Common-Banner .portfolio-content .back-btn-main {
        width: 31%;
    }
    p.whish {
        font-size: 22px;
    }
    .Common-Banner .breadcrumb-item a {
        font-size: 13px;
    }
  
}
@media (min-width: 992px) and (max-width: 1199px) {
    .Common-Banner .portfolio-content {
        padding: 16% 3% 2% 4%;
    }
    .Common-Banner .portfolio-content .back-btn-main {
        width: 29%;
    }
    p.whish {
        font-size: 24px;
    }
}
