.SellerLogin .StepsixeditIcon {
  position: absolute;
  top: 10px;
  right: 8px;
  font-size: 14px;
}

.SellerLogin .loginHead a p {
  text-align: center;
  margin-top: 5%;
  color: #000;
}

.SellerLogin .personal-details-form {
  margin-top: 5%;
  padding: 11%;
}

.SellerLogin .loginHead h3 {
  text-align: center;
  font-size: 24px;
  margin-top: 5%;
}

.SellerLogin .loginHead .lessThanSymbol {
  font-size: 13px;
  margin-right: 5px;
}

.SellerLogin .login_now_btn {
  background: #f88d28 0% 0% no-repeat padding-box;
  border-radius: 24px;
  border: 0;
  padding: 8px 50px;
}

.SellerLogin .personal-details-form .form-control:first-of-type {
  font-size: 14px !important;
  border-radius: 5px;
  margin-bottom: 30px;
}

.SellerLogin .personal-details-form .Passwordfield {
  margin-bottom: 5px !important;
}

.SellerLogin .personal-details-form .get-otp-text p {
  text-align: end;
  font-size: 12px;
  color: #1c42a9;
  font-weight: 500;
  cursor: pointer;
}

.SellerLogin .personal-details-form .OpenGmailsec .opengmailbtn {
  background: #f88d28 0% 0% no-repeat padding-box;
  border-radius: 24px;
  border: 0;
  width: 409px;
  margin-bottom: 20px;
}

.SellerLogin .personal-details-form .OpenGmailsec p span:last-of-type {
  font-size: 16px;
  letter-spacing: 0px;
  color: #ff7d00;
}

.SellerLogin .personal-details-form .OpenGmailsec p span:first-of-type {
  font-size: 16px;
  letter-spacing: 0px;
  color: #000;
}

.SellerLogin .loginHead .headbelowtxt {
  text-align: center;
  font-size: 14px;
}

.SellerLogin .passwordreset {
  text-align: center;
  margin: 5%;
}

.SellerLogin .passwordreset .backlogin{
  color: #f88d28;
}
