.add-money-modal .modal-content {
    background-color: transparent;
    border: none;
}

.add-money-modal .modal-header {
    background-color: transparent;
    border-bottom: none;
}

.add-money-modal .modal-body {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 17px #00000012;
    border-radius: 10px;
}

.add-money-modal .modal-body p {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
}

.add-money-modal .modal-footer {
    border-top: none;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 17px #00000012;
    border-radius: 10px;
}

.add-money-modal .modal-body .form-control {
    height: 48px;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border-radius: 10px;
    color: #8A8A8A;
    font-size: 12px;
}

.add-money-modal .btn-continue {
    background: #F88D28 0% 0% no-repeat padding-box;
    border-radius: 24px;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    border: none;
   padding: 7px 40px;
}


.add-money-modal .btn-continue:focus {
    background: #F88D28 0% 0% no-repeat padding-box;
    border-radius: 24px;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    border: none;
    padding: 7px 40px;
}