/* .Desktop-View Css Started */

.CompareAll .sub-title-medium {
    height: 51px;
    text-align: center;
    color: black;
    font-size: 16px;
    font-family: "Roboto" !important;
    margin-bottom: 7px;
}

.CompareAll .Book {
    text-align: center;
}

.CompareAll h1.plan {
    text-align: center;
    font-size: 28px;
    color: black;
}

/* .CompareAll .Desktop-View .white-bg-subscribe:hover .header {
    color: #000;
    text-align: center;
    background: linear-gradient(46.21deg, #6297ff 47.49%, #29d7f4 92.35%);
    font-size: 14px;
    font-weight: 700;
    padding: 5px;
    display: block;
}

.CompareAll .Desktop-View .white-bg-subscribe:hover {
    width: 224px;
    border-right: 2px solid #007bff;
    border-left: 2px solid #007bff;
    border-bottom: 2px solid #007bff;
} */

.CompareAll .Desktop-View .white-bg-subscribe {
    position: absolute;
    height: 100%;
    z-index: 99;
    width: 224px;
}

/* .CompareAll .subscription-class .Desktop-View .white-bg-subscribe:hover .most-pop {
    color: #000;
    text-align: center;
    background: linear-gradient(46.21deg, #6297ff 47.49%, #29d7f4 92.35%);
    font-size: 14px;
    font-weight: 700;
    padding: 5px;
    display: block;
} */

.CompareAll .header {
    color: white;
    text-align: center;
    background: transparent;
    font-size: 14px;
    font-weight: 700;
    padding: 5px;
    display: block;
}

.CompareAll .Book button.btn.btn-primary {
    background-color: transparent;
    padding: 4px 38px;
    color: black;
}

.CompareAll .accordion {
    border: none;
}

.CompareAll .accordion-item {
    border: none;
}

.CompareAll .accordion-button:focus {
    z-index: 3;
    outline: 0;
    box-shadow: none;
}

.CompareAll button.accordion-button {
    font-weight: 600;
    font-size: 15px;
    background-color: aliceblue;
}

.CompareAll .accordion-button::before {
    border-radius: 100px;
    flex-shrink: 0;
    width: 25px;
    height: 25px;
    background-size: 16px;
    content: "";
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23212529' stroke-linecap='round' stroke-linejoin='round'><path d='M2 5L8 11L14 5'/></svg>") !important;
    background-repeat: no-repeat;

    transition: var(--bs-accordion-btn-icon-transition);
    /* background-color: black; */
    color: white;
}

.CompareAll .accordion-button::after {
    display: none;
}

section.CompareAll {
    position: relative;
    margin-top: 4%;
}

.CompareAll .Main-accordion {
    margin-top: 12%;
}

.CompareAll td.main-width {
    font-weight: 500;
    padding: 13px 14px !important;
    width: 24% !important;
    border-bottom: 0px;
}

.CompareAll .table> :not(caption)>*>* {
    font-size: 14px;
    width: 18%;
    padding: 13px 12px;
    border-bottom: 0px;
}

.CompareAll form {
    margin-top: 31%;
    font-size: 19px;
    display: flex;
    align-items: center;
}

.CompareAll .Desktop-View {
    display: block;
}

.CompareAll .Mobile-View {
    display: none;
}

.CompareAll .Desktop-View table td {
    width: 20% !important;
}

/* .Desktop-View Css End */

/* Mobile View Css Started */
.CompareAll .Mobile-View .white-bg-subscribe:hover .header {
    color: #000;
    text-align: center;
    background: linear-gradient(46.21deg, #6297ff 47.49%, #29d7f4 92.35%);
    font-size: 14px;
    font-weight: 700;
    padding: 5px;
    display: block;
}

.CompareAll .Mobile-View .white-bg-subscribe:hover {
    width: 224px;
    border-right: 2px solid #007bff;
    border-left: 2px solid #007bff;
    border-bottom: 2px solid #007bff;
}

.CompareAll .Mobile-View .white-bg-subscribe {
    position: absolute;
    height: 100%;
    z-index: 99;
    width: 224px;
}

.CompareAll .subscription-class .Mobile-View .white-bg-subscribe:hover .most-pop {
    color: #000;
    text-align: center;
    background: linear-gradient(46.21deg, #6297ff 47.49%, #29d7f4 92.35%);
    font-size: 14px;
    font-weight: 700;
    padding: 5px;
    display: block;
}

.CompareAll .Mobile-View .Main-accordion p.maintenance-text {
    font-weight: bold;
    text-wrap: nowrap;
    color: black;
    margin-top: 10px;
    padding: 0px -6px;
}

.CompareAll table {
    width: 100%;
    table-layout: fixed;
}

.CompareAll .Toggle-Btn {
    display: flex;
}

.CompareAll .on {
    color: black;
    font-size: 16px;
    font-weight: 500;
}

.CompareAll .off {
    color: grey;
    font-size: 16px;
}

.CompareAll .form-switch {
    margin: 0px 11px;
    padding-left: 2.5em;

}

.CompareAll .form-check-input {
    width: 42px;
    height: 21px;
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
    background-color: #007bff !important;
    background-image: var(--bs-form-switch-bg),
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e") !important;

}

.CompareAll .form-check-input:checked {
    background-image: var(--bs-form-switch-bg),
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e") !important;
    border: 1px solid gray !important;
    background-color: #007bff !important;
}

.CompareAll .Desktop-View {
    margin-bottom: 10%;
  }
/* Mobile View Css End */
@media (min-width: 280px) and (max-width: 484px) {
    .CompareAll .sub-title-medium {
        font-size: 13px;
        height: 66px;
    }

    .CompareAll .Main-accordion {
        margin-top: 6%;
    }

    .maintenance-title {
        font-size: 14px;
    }

    .CompareAll .nav-link.active {
        width: 18%;
        height: auto;
    }

    .CompareAll .Book button.btn.btn-primary {
        font-size: 12px;
        padding: 4px 1px;
    }

    .CompareAll .Book {
        text-align: center;
        /* display: none; */
    }

    .CompareAll .NavPills .nav-link.active .header {
        line-height: 15px;
    }

    .CompareAll td.main-width {
        font-size: 12px;
    }

    .CompareAll table {
        width: 100%;
        table-layout: fixed;
    }

    .CompareAll td {
        text-align: center;
        width: auto;
        font-size: 11px;
    }
    .CompareAll .table > :not(caption) > * > * {
        font-size: 11px !important;
       
    }
    .CompareAll button.accordion-button {
        font-size: 14px;
    }

    .CompareAll .Mobile-View {
        display: block;
    }

    .CompareAll .Mobile-View .white-bg-subscribe {
        position: unset;
        width: 100%;
    }

    .CompareAll .Mobile-View .white-bg-subscribe:hover {
        width: 100%;
    }

    .CompareAll .Desktop-View {
        display: none;
    }

    .CompareAll form {
        justify-content: center;
        margin-top: 0%;
    }

    .CompareAll h1.plan {
        font-size: 22px;
    }

    .CompareAll form {
        font-size: 14px;
    }
}

@media (min-width: 485px) and (max-width: 575px) {
    .CompareAll .sub-title-medium {
        font-size: 13px;
        height: 50px;
    }

    .CompareAll .Main-accordion {
        margin-top: 5%;
    }

    .maintenance-title {
        font-size: 17px;
    }

    .CompareAll .nav-link.active {
        width: 18%;
        height: auto;
    }

    .CompareAll .Book button.btn.btn-primary {
        font-size: 13px;
        padding: 4px 9px;
    }

    .CompareAll .Book {
        text-align: center;

    }

    .CompareAll td.main-width {
        font-size: 12px;
    }

    .CompareAll .table> :not(caption)>*>* {
        font-size: 12px;
    }

    .CompareAll button.accordion-button {
        font-size: 14px;
    }

    .CompareAll .Mobile-View {
        display: block;
    }

    .CompareAll .Mobile-View .white-bg-subscribe {
        position: unset;
        width: 100%;
    }

    .CompareAll .Mobile-View .white-bg-subscribe:hover {
        width: 100%;
    }

    .CompareAll .Desktop-View {
        display: none;
    }

    .CompareAll table {
        width: 100%;
        table-layout: fixed;
    }

    .CompareAll form {
        font-size: 14px;
    }

    .CompareAll td {
        text-align: center;
        width: auto;
    }

    .CompareAll form {
        justify-content: center;
        margin-top: 0%;
    }

    .CompareAll h1.plan {
        font-size: 22px;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .CompareAll .sub-title-medium {
        font-size: 13px;
        height: 36px;
    }

    .CompareAll .Main-accordion {
        margin-top: 5%;
    }

    .maintenance-title {
        font-size: 14px;
    }

    .CompareAll .nav-link.active {
        width: auto;
        height: 100%;
    }

    .CompareAll .Book button.btn.btn-primary {
        padding: 4px 5px;
        font-size: 13px;
    }

    .CompareAll td.main-width {
        font-size: 13px;
    }

    .CompareAll .table> :not(caption)>*>* {
        font-size: 13px;
    }

    .CompareAll .Desktop-View .white-bg-subscribe {
        position: unset;
        width: 100%;
    }

    .CompareAll .Desktop-View .white-bg-subscribe:hover {
        width: 79px;
    }

    .CompareAll .Desktop-View .white-bg-subscribe {
        width: 79px;
    }

    .CompareAll .Mobile-View {
        display: block;
    }

    .CompareAll .Mobile-View .white-bg-subscribe {
        position: unset;
        width: 100%;
    }

    .CompareAll .Mobile-View .white-bg-subscribe:hover {
        width: 100%;
    }

    .CompareAll .Desktop-View {
        display: none;
    }

    .CompareAll table {
        width: 100%;
        table-layout: fixed;
    }

    .CompareAll form {
        font-size: 16px;
    }

    .CompareAll td {
        text-align: center;
        width: auto;
    }

    .CompareAll form {
        justify-content: center;
        margin-top: 0%;
    }

    .CompareAll h1.plan {
        font-size: 24px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .CompareAll .sub-title-medium {
        font-size: 14px;
        height: 37px;
    }

    .CompareAll .Desktop-View .white-bg-subscribe:hover {
        width: 109px;
    }

    .CompareAll .Desktop-View .white-bg-subscribe {
        width: 109px;
    }

    .maintenance-title {
        font-size: 16px;
    }

    .CompareAll .nav-link.active {
        width: auto;
        height: 100%;
    }

    .CompareAll .Book button.btn.btn-primary {
        padding: 4px 17px;
        font-size: 14px;
    }

    .CompareAll td.main-width {
        font-size: 14px;
    }

    .CompareAll .table> :not(caption)>*>* {
        font-size: 14px;
    }

    .CompareAll .Mobile-View {
        display: block;
    }

    .CompareAll .Mobile-View .white-bg-subscribe {
        position: unset;
        width: 100%;
    }

    .CompareAll .Mobile-View .white-bg-subscribe:hover {
        width: 100%;
    }

    .CompareAll .Desktop-View {
        display: none;
    }

    .CompareAll table {
        width: 100%;
        table-layout: fixed;
    }

    .CompareAll td {
        text-align: center;
        width: auto;
    }

    .CompareAll form {
        justify-content: center;
        margin-top: 0%;
    }

    .CompareAll .Main-accordion {
        margin-top: 6%;
    }

    .CompareAll h1.plan {
        font-size: 26px;
    }

    .CompareAll form {
        font-size: 18px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .maintenance-title {
        font-size: 18px;
    }

    .CompareAll .sub-title-medium {
        font-size: 16px;
    }

    .CompareAll .sub-title-medium {
        font-size: 14px;
        height: 37px;
    }

    .maintenance-title {
        font-size: 19px;
    }

    .CompareAll .nav-link.active {
        width: auto;
        height: 100%;
    }

    .CompareAll .Book button.btn.btn-primary {
        padding: 4px 33px;
        font-size: 14px;
    }

    .CompareAll td.main-width {
        font-size: 14px;
    }

    .CompareAll .table> :not(caption)>*>* {
        font-size: 14px;
    }

    .CompareAll .Desktop-View .white-bg-subscribe:hover {
        width: 156px;
    }

    .CompareAll .Desktop-View .white-bg-subscribe {
        width: 156px;
    }

    .CompareAll .Mobile-View {
        display: none;
    }

    .CompareAll .Main-accordion {
        margin-top: 14%;
    }
    .CompareAll .Desktop-View {
        margin-bottom: 9%;
      }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .CompareAll .Desktop-View .white-bg-subscribe:hover {
        width: 188px;
    }

    .CompareAll .Desktop-View .white-bg-subscribe {
        width: 188px;
    }

    .CompareAll .Mobile-View {
        display: none;
    }

    /* .CompareAll .Main-accordion {
        margin-top: 20%;
    } */
}

@media (min-width: 1900px) and (max-width: 2685px) {
    .CompareAll .nav-link.active {
        width: 10%;
    }

    .CompareAll .Mobile-View {
        display: none;
    }
}