.blog-card .theme-description {
    border-left: 1px solid #454545;
    color: #777;
    font-style: italic;
    margin-bottom: 20px;
    padding-left: 20px;
    margin-top: 5%;
    font-size: 15px;

}

.blog-card .theme-name {
    color: #f88d28;
    font-weight: bold;
}

.blog-card .second-section {
    border: 1px solid #d3cece;
    border-radius: 3px;
    padding: 15px;
}

.blog-card .second-section .themmm {
    font-size: 20px;
    color: #454545;
}

.blog-card .second-section .price {
    font-size: 18px;
    color: #f88d28;
    font-weight: 600;
}

.blog-card .check-texttt {
    font-size: 12px;
}

.blog-card .add-to-cart {
    background: #f88d28;
    border: 1px solid #f88d28;
    border-radius: 10px;
    color: #FFF;
    padding: 8px 20px;
    width: 100%;
}

.blog-card .third-section {
    background-color: #f7f7f7;
    margin : 20px 0px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #d3cece;
    padding: 15px;
}

.blog-card .third-section .feature-head {
    font-size: 14px;
    font-weight: 600;
}

.blog-card .third-section .feature-head-sub {
    font-size: 14px;
}

.blog-card .blog-main-img .theme-card-img {
    position: relative;
    background: no-repeat;
    width: 100%;
    display: block;
    transition: 1.4s;
    height: 2000px
    /* border-radius: 5px 5px 0px 0px; */

}