/* .affilate-dash-top-main{
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
} */
.affilate-dash-top-main .dash-main-cont-sec {
  /* padding: 20px; */
  /* background: #F7F4F1; */
  background: #eef6ff;
  height: 100%;
  overflow-y: scroll;
}

.dash-t-ttde {
  font-weight: 600;
  font-size: 18px;
  color: #303030;
  margin-bottom: 0px;
}

.dasvvv-sub-p {
  font-weight: normal;
  font-size: 12px;
  color: #303030;
  margin-bottom: 0px;
}

.month-wise-droppp .form-select {
  font-size: 13px;
  color: #000;
  width: 100px;
  margin-left: auto;
}

.dash-t-ttde {
  font-weight: 600;
  font-size: 18px;
  color: #303030;
  margin-bottom: 0px;
}

.dasvvv-sub-p {
  font-weight: normal;
  font-size: 12px;
  color: #303030;
  margin-bottom: 0px;
}

.month-wise-droppp .form-select {
  font-size: 13px;
  color: #000;
  width: 100px;
  margin-left: auto;
  border: 0px;
}

.dashboard-part {
  height: 100vh;
  background: #f8f8f84a;
  transition: all 0.3s ease-in-out;
}

.affilate-dash-top-main .sidebar-flex {
  width: 300px;
  height: 100vh;
}

.affilate-dash-top-main .content-flex {
  width: 100%;
  overflow-y: auto;
}

.affilate-dash-top-main .acceptbtn {
  background: none;
  border: 0;
  color: #179100;
  font-size: 12px;
  margin-right: 5px;
}

.affilate-dash-top-main .acceptbtn:active {
  background: none;
  color: #179100;
}

.affilate-dash-top-main .rejectbtn {
  background: none;
  border: 0;
  color: #d10606;
  border-radius: 15px;
  font-size: 12px;
}

.affilate-dash-top-main .rejectbtn:active {
  background: none;
  color: #d10606;
}

.affilate-dash-top-main .progressbtn {
  background: none;
  border: 0;
  color: #f88c27;
  border-radius: 15px;
  font-size: 12px;
}

.affilate-dash-top-main .progressbtn:active {
  background: none;
  color: #f88c27;
}

.affilate-dash-top-main .OrderTabs .nav-link {
  color: #000;
  font-size: 14px;
}

.affilate-dash-top-main .OrderTabs .iconEyetextimg p {
  font-size: 14px;
}

.affilate-dash-top-main .OrderTabs .iconEyetextimg {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.affilate-dash-top-main .OrderTabs .eyeIcon {
  color: #f88d28;
}

.affilate-dash-top-main .OrderTabs .nav-link.active {
  color: #1c42a9;
  font-weight: bold;
}

@media (min-width: 577px) and (max-width: 768px) {
  .dash-t-ttde {
    font-size: 15px;
  }

  .dasvvv-sub-p {
    font-size: 11px;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
}

@media (min-width: 992px) and (max-width: 1200px) {
}
