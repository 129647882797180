.NewArrivals .NewArrivals-main-img-holder {
  border-radius: 10px;
  margin-bottom: 5%;
}

.NewArrivals .NewArrivals-main-img-holder .NewArrivals-main-img {
  width: 100%;
  border-radius: 10px;
}

.NewArrivals .NewArrivals-img-holder .NewArrivals-img {
  width: 100%;
  border-radius: 10px;
  /* height: 180px; */
  height: auto;
  object-fit: cover;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.NewArrivals .NewArrivals_Swiper {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding: 20px 20px 50px 20px;
  height: 450px;
}

.NewArrivals .NewArrivals_Swiper .swiper-pagination-bullet-active {
  background: #ababab;
}

.NewArrivals {
  position: relative;
}

.NewArrivals .swiper-button-prev:after {
  display: none;
}

.NewArrivals .swiper-button-next:after {
  display: none;
}

.NewArrivals .navigation-arrow-holder .navigation-arrow {
  width: 14px;
  height: 30px;
  color: #000;
  background: #ccc;
  padding: 0px 8px;
  border-radius: 50px;
}

.NewArrivals .navigation-arrow-holder .swiper-button-prev {
  position: absolute;
  top: -28px;
  left: 93%;
}

.NewArrivals .navigation-arrow-holder .swiper-button-next {
  position: absolute;
  top: -28px;
  right: 0%;
}

.NewArrivals .slider-started {
  position: relative;
}

.NewArrivals .navigation-arrow-holder .swiper-button-prev,
.NewArrivals .navigation-arrow-holder .swiper-button-next {
  cursor: pointer;
}

@media (min-width: 0px) and (max-width: 320px) {
  .NewArrivals .navigation-arrow-holder .navigation-arrow {
    width: 8px;
    height: 18px;
    padding: 0px 5px;
  }

  .NewArrivals .navigation-arrow-holder .swiper-button-prev {
    top: -20px;
    left: 82%;
  }

  .NewArrivals .navigation-arrow-holder .swiper-button-next {
    top: -20px;
    right: 0%;
  }

  .NewArrivals .NewArrivals-img-holder .NewArrivals-img {
    height: 100px;
    object-fit: contain;
  }

  .NewArrivals .NewArrivals_Swiper {
    height: 300px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .NewArrivals .navigation-arrow-holder .navigation-arrow {
    width: 8px;
    height: 18px;
    padding: 0px 5px;
  }

  .NewArrivals .navigation-arrow-holder .swiper-button-prev {
    top: -20px;
    left: 85%;
  }

  .NewArrivals .navigation-arrow-holder .swiper-button-next {
    top: -20px;
    right: 0%;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .NewArrivals .navigation-arrow-holder .swiper-button-prev {
    top: -20px;
    left: 88%;
  }

  .NewArrivals .navigation-arrow-holder .swiper-button-next {
    top: -20px;
    right: 0%;
  }
}

@media (min-width: 576px) and (max-width: 675px) {
  .NewArrivals .navigation-arrow-holder .swiper-button-prev {
    top: -20px;
    left: 88%;
  }

  .NewArrivals .navigation-arrow-holder .swiper-button-next {
    top: -20px;
    right: 0%;
  }
}

@media (min-width: 676px) and (max-width: 767px) {
  .NewArrivals .navigation-arrow-holder .swiper-button-prev {
    top: -20px;
    left: 88%;
  }

  .NewArrivals .navigation-arrow-holder .swiper-button-next {
    top: -20px;
    right: 0%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .NewArrivals .navigation-arrow-holder .swiper-button-prev {
    left: 85%;
  }
}
@media (min-width: 992px) and (max-width: 1023px) {
  .NewArrivals .navigation-arrow-holder .swiper-button-prev {
    position: absolute;
    top: -28px;
    left: 90%;
  }
}

@media (min-width: 1024px) and (max-width: 1299px) {
  .NewArrivals .navigation-arrow-holder .swiper-button-prev {
    left: 90%;
  }
}
