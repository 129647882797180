.B2bContact .contactHead h3 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 17px;
}

.B2bContact {
    padding: 3%;
}

.B2bContact .b2bContactMain .form-label {
    margin-bottom: .5rem;
    font-size: 14px;
}

.B2bContact .b2bContactMain .form-control {
    font-size: 13px;
}

.B2bContact .b2bContactMain .Contactbanner img {
    width: 100%;
    margin-bottom: 22px;
}

.B2bContact .b2bContactMain .Contactsubmitbtn button {
    background: #FF7C00 0% 0% no-repeat padding-box;
    border-radius: 24px;
    border: 0;
    font-size: 14px;
    padding: 5px 36px;
}

.B2bContact .b2bContactMain .teradata-div .form-control {
    background-color: #F1F1F1;
    border: none;
}

.B2bContact .b2bContactMain .form-control {
    width: 100%;
}