.comment-section {
  margin: 40px 0px;
}

.comment-section .top-com {
  margin-bottom: 50px;
}

.comment-section .top-com p {
  font-size: 24px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0.24px;
  color: #000000;
  opacity: 1;
}

.comment-section .comment-img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  opacity: 1;
}

.comment-section .lorem-div {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  padding: 25px;
}
.comment-section .lorem-div p {
  font-size: 16px;
  margin: 0;
  font-weight: 300;
  line-height: 27px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 5;
  line-clamp: 5;
}

.comment-section .replydate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0px 60px 0px;
}
.comment-section .reply-button {
  background: #dfdfdf 0% 0% no-repeat padding-box;
  border-radius: 19px;
  border: none;
  font-size: 15px;
  font-weight: 300;
  line-height: 16px;
  width: 86px;
  height: 38px;
  opacity: 1;
}

.comment-section .reply-button:hover {
  background: #979797 0% 0% no-repeat padding-box;
}
.comment-section .replydate p {
  font-size: 16px;
  margin: 0;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.comment-section .commented .form-sec .backgrd {
  background: #f9fbff 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 40px;
}
.comment-section .commented .form-sec .form-sec-main {
  justify-content: center;
}
.comment-section .commented .leave-comment p {
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.comment-section .commented .labelname {
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.comment-section .commented .form-control {
  padding: 13px;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: 0px;
  color: #a7a7a7;
  opacity: 1;
}

.comment-section .commented .send-div {
  margin-top: 37px;
}
.comment-section .commented .send-div .send-buton {
  width: 172px;
  height: 38px;
  background: #f88d28 0% 0% no-repeat padding-box;
  border-radius: 24px;
  opacity: 1;
  font-size: 15px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  border: none;
}

.comment-section .commented .send-div .send-buton:hover {
  background: #e87000 0% 0% no-repeat padding-box;
}

@media (min-width: 0px) and (max-width: 485px) {
  .comment-section {
    margin: 19px 0px;
  }
  .comment-section .top-com {
    margin-bottom: 14px;
  }
  .comment-section .top-com p {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
  .comment-section .comment-img {
    width: 50px;
    height: 50px;
  }
  .comment-section .lorem-div {
    padding: 20px;
  }
  .comment-section .lorem-div p {
    font-size: 11px;
    line-height: 17px;
  }

  .comment-section .replydate p {
    font-size: 11px;
    line-height: 10px;
  }
  .comment-section .replydate {
    margin: 8px 0px 20px 0px;
  }
  .comment-section .reply-button {
    font-size: 11px;
    line-height: 14px;
    width: 85px;
    height: 27px;
  }

  .comment-section .commented .form-sec .backgrd {
    padding: 20px;
  }
  .comment-section .commented .leave-comment p {
    font-size: 15px;
    line-height: 12px;
  }
  .comment-section .commented .labelname {
    font-size: 11px;
    font-weight: 300;
    line-height: 13px;
  }
  .comment-section .commented .form-control {
    padding: 10px;
    font-size: 11px;
    line-height: 10px;
  }

  .comment-section .commented .send-div {
    margin-top: 25px;
  }

  .comment-section .commented .send-div .send-buton {
    width: 149px;
    height: 35px;
    font-size: 12px;
    line-height: 12px;
  }
}

@media (min-width: 486px) and (max-width: 767px) {
  .comment-section {
    margin: 22px 0px;
  }
  .comment-section .top-com {
    margin-bottom: 16px;
  }
  .comment-section .top-com p {
    font-size: 17px;
    font-weight: 500;
    line-height: 22px;
  }
  .comment-section .comment-img {
    width: 50px;
    height: 50px;
  }
  .comment-section .lorem-div {
    padding: 22px;
  }
  .comment-section .lorem-div p {
    font-size: 12px;
    line-height: 18px;
  }

  .comment-section .replydate p {
    font-size: 12px;
    line-height: 11px;
  }
  .comment-section .replydate {
    margin: 10px 0px 24px 0px;
  }
  .comment-section .reply-button {
    font-size: 12px;
    line-height: 15px;
    width: 85px;
    height: 27px;
  }

  .comment-section .commented .form-sec .backgrd {
    padding: 22px;
  }
  .comment-section .commented .leave-comment p {
    font-size: 16px;
    line-height: 13px;
  }
  .comment-section .commented .labelname {
    font-size: 12px;
    line-height: 14px;
  }
  .comment-section .commented .form-control {
    padding: 11px;
    font-size: 12px;
    line-height: 11px;
  }

  .comment-section .commented .send-div {
    margin-top: 27px;
  }

  .comment-section .commented .send-div .send-buton {
    width: 149px;
    height: 35px;
    font-size: 12px;
    line-height: 12px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .comment-section {
    margin: 30px 0px;
  }
  .comment-section .top-com {
    margin-bottom: 20px;
  }
  .comment-section .top-com p {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
  .comment-section .comment-img {
    width: 50px;
    height: 50px;
  }
  .comment-section .lorem-div {
    padding: 22px;
  }
  .comment-section .lorem-div p {
    font-size: 13px;
    line-height: 18px;
  }

  .comment-section .replydate p {
    font-size: 13px;
    line-height: 11px;
  }
  .comment-section .replydate {
    margin: 10px 0px 30px 0px;
  }
  .comment-section .reply-button {
    font-size: 13px;
    line-height: 15px;
    width: 85px;
    height: 27px;
  }

  .comment-section .commented .form-sec .backgrd {
    padding: 22px;
  }
  .comment-section .commented .leave-comment p {
    font-size: 17px;
    line-height: 15px;
  }
  .comment-section .commented .labelname {
    font-size: 13px;
    line-height: 15px;
  }
  .comment-section .commented .form-control {
    padding: 11px;
    font-size: 13px;
    line-height: 13px;
  }

  .comment-section .commented .send-div {
    margin-top: 27px;
  }

  .comment-section .commented .send-div .send-buton {
    width: 149px;
    height: 35px;
    font-size: 13px;
    line-height: 13px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .comment-section .top-com p {
    font-size: 23px;
  }
  .comment-section .lorem-div p {
    font-size: 15px;
    line-height: 23px;
  }
  .comment-section .replydate p {
    font-size: 14px;
  }
}
