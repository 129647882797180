.Resources .card-main {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    position: relative;
    width: 100%;
    height: auto;
}

.Resources .card-main .main-image img.card-img-top {
    height: 226px;
    transition: transform 0.3s ease-in-out;
}
.Resources .card-main .main-image:hover img.card-img-top {
    transform: scale(1.1);
}
.Resources .card-main .main-image .overlay {
    background: transparent linear-gradient(118deg, #000000cc 0%, #f88d2875 100%) 0% 0% no-repeat padding-box;
    border-radius: 14px;
    width: 100%;
    top: 100%;
    position: absolute;
    height: 0px;
    border-radius: 14px;
    transition: 0.2s ease-in-out;
}
.Resources .card-main .main-image:hover .overlay {
    top: 0%;
    height: 100%;
}
.Resources .card-main .main-image {
    margin: 4px 5px;
    position: relative;
    height: 226px;
    overflow: hidden;
}
.Resources .card-main .content-img {
    position: absolute;
    top: 146px;
    color: white;
    display: none;
}
.Resources .card-main .main-image:hover .content-img {
    padding: 0px 10px;
    transition: bottom 0.6s ease-in-out;
    display: block;
    animation: slide-top 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.Resources .card {
    border: none;
    margin-bottom: 7%;
}
.Resources .card-main .content-img .maintenance-text {
    color: white;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    margin-top: 35px;
}
.Resources .card-main .card-body .maintenance-text {
    color: black;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 6;
}
.Resources {
    margin-bottom: -5%;
    top: -135px;
    position: relative;
 
}
@-webkit-keyframes slide-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }
}

@media (min-width:0px) and (max-width:575px) {
    .Resources {
        top: -51px;
}
.Resources .card-main .main-image img.card-img-top {
    height: auto;
}
.Resources .card-main .main-image {
  height: auto;
}
}
