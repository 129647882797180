.Business_Cards .Business_Cards_Slider .Business_Cards_content {
  border-radius: 10px;
  height: 180px;
  padding: 30px 30px;
}

.Business_Cards
  .Business_Cards_Slider
  .Business_Cards_content
  .Business_Cards_img_holder {
  margin-bottom: 5%;
}

.Business_Cards
  .Business_Cards_Slider
  .Business_Cards_content
  .Business_Cards_img_holder
  .Business_Cards_img {
  height: 30px;
}

.Business_Cards .Business_Cards_Slider .Business_Cards_content .text_holder h4 {
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
}

.Business_Cards .Business_Cards_Slider .Business_Cards_content .text_holder p {
  text-align: left;
  font-size: 14px;
  color: #ffffff;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
}

.Business_Cards .Business_Cards_Slider {
  padding-bottom: 7%;
}

.Business_Cards .Business_Cards_Slider .swiper-pagination-bullet {
  height: 1px;
  width: 15px;
  margin: 1px;
}

.Business_Cards .Business_Cards_Slider .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #000;
  width: 10px;
  height: 1px;
}

@media (min-width: 0px) and (max-width: 320px) {
  .Business_Cards
    .Business_Cards_Slider
    .Business_Cards_content
    .text_holder
    h4 {
    font-size: 16px;
  }

  .Business_Cards
    .Business_Cards_Slider
    .Business_Cards_content
    .text_holder
    p {
    font-size: 12px;
  }

  .Business_Cards .Business_Cards_Slider .Business_Cards_content {
    margin-bottom: 5%;
  }

  .Business_Cards
    .Business_Cards_Slider
    .Business_Cards_content
    .Business_Cards_img_holder
    .Business_Cards_img {
    height: 20px;
  }

  .Business_Cards .Business_Cards_Slider .Business_Cards_content {
    padding: 25px 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Business_Cards
    .Business_Cards_Slider
    .Business_Cards_content
    .Business_Cards_img_holder
    .Business_Cards_img {
    height: 20px;
  }

  .Business_Cards
    .Business_Cards_Slider
    .Business_Cards_content
    .text_holder
    p {
    font-size: 12px;
  }
}
