.settingCard {
    height: 100vh;
    margin: 5% 0% 0% 8%;
    border: 0 !important;
    overflow-y: auto;
    background: #fff;
    box-shadow: 0 0 4px #ccc;
}

.settingCard .nav-pills .nav-link {
    color: black;
}

.settingCard .nav-pills .nav-link.active {
    color: #ffff;
    background: #f88d28;
}

.settingCard .settingInputGrp {
    position: sticky;
    top: 0;
    background: #fff;
}

.tabadminmain {
    margin-right: 3%;
}