.selldash-commonmodal .modal-title {
    font-weight: 500;
    font-size: 18px;
}

.selldash-commonmodal .modal-content {
    border: 0px;
    box-shadow: 0px 0px 5px 0px #ccc;
}

.selldash-commonmodal .form-label {
    font-weight: normal;
    font-size: 14px;
    color: #000;
}

.selldash-commonmodal .form-label .labelsbtext {
    font-size: 10px;
}

.selldash-commonmodal .form-control::placeholder {
    font-size: 13px;
}

.selldash-commonmodal .css-1jqq78o-placeholder {
    font-size: 13px;
}

.selldash-commonmodal .ullistt ul li {
    font-size: 13px;
}

.selldash-commonmodal .ullistt ul {
    padding-left: 15px;
    margin-bottom: 0px;
}

.selldash-commonmodal .bjvbhbvh-img {
    width: 100%;
}

.selldash-commonmodal .modal-body .form-sec {
    border-right: 1px solid #ccc;
    padding-right: 20px;
}

.selldash-commonmodal .ullistt.bg {
    background-color: #F3F3F3;
    padding: 7px;
    border-radius: 4px;
}

.selldash-commonmodal .closebtn {
    background-color: #E2E2E2;
    padding: 5px 20px;
    color: #000;
    border-radius: 25px;
    font-size: 14px;
    margin: 0px 10px;
}

.selldash-commonmodal .sbmitbtn {
    background-color: #F88D28;
    padding: 5px 20px;
    color: #fff;
    border-radius: 25px;
    font-size: 14px;
}

.selldash-commonmodal .savebtn {
    background: #F88D28 0% 0% no-repeat padding-box;
    border-radius: 24px;
    border: 0;
}

.selldash-commonmodal .closebtn {
    background: #E2E2E2 0% 0% no-repeat padding-box;
    border-radius: 24px;
    border: 0;
}

.selldash-commonmodal .settingHeading h3 {
    font-size: 16px;
}

.selldash-commonmodal .settingHeading {
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
}

.selldash-commonmodal .settingHeading .radiosMain {
    display: flex;
    justify-content: space-around;
    padding-bottom: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.selldash-commonmodal .form-check-label {
    font-size: 14px;
}

.selldash-commonmodal .radiosMain .form-check {
    margin-right: auto;
}

.selldash-commonmodal .adddefaulttxt,
.selldash-commonmodal .form-label {
    font-size: 13px;
}

/* .selldash-commonmodal .settingHeading .radiosMain:last-child {
    border-bottom: 0;
} */