.return-order-stepform {
    margin: 1% 0% 10% 0%;
}

.return-order-stepform h4.stepform-heading {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
}

.return-order-stepform .step-container.active .circle {
    color: #ffffff;
    border: 1px solid #ee9723;
    background-color: #ee9723;
}

.return-order-stepform .progress-bar-container .step-row {
    display: flex;
    align-items: center;

    width: 100%;
}

.return-order-stepform .progress-bar-container .step-container {
    position: relative;
    display: flex;
}

.return-order-stepform .progress-bar-container .line-right {
    margin: 0px 0px 0px 17px;
    margin-bottom: 13px;
    width: 61px;
    border-bottom: 1px solid #B2B2B2;
}


.return-order-stepform .line-right.completed {
    border-bottom: 1px solid #B2B2B2;
}



.return-order-stepform .progress-bar-container .step-text {
    font-size: 14px;
    color: #B2B2B2;
    font-weight: 400;
    margin: 0px 0px 0px 15px;
}

.return-order-stepform .progress-bar-container .step-container.active .step-text,
.return-order-stepform .progress-bar-container .step-container.completed .step-text {
    font-weight: 400;
    font-size: 14px;
    color: #000;
    font-weight: 400;
}

.return-order-stepform .signup-form {
    margin: 10px 60px 40px 15px;
}

.return-order-stepform .progress-bar-container .circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    color: #fff;
    font-size: 20px;
    border: 1px solid #B2B2B2;
    font-size: 14px;
    background-color: #B2B2B2;
}

.return-order-stepform .progress-bar-container .circle.completed {
    color: #ffffff;
    border: 1px solid #ee9723;
    background-color: #ee9723;
}

.return-order-stepform .progress-bar-container .circle-center {
    justify-content: center;
    text-align: center;
    display: flex;
}

.return-order-stepform .main-steps {
    background: #F3F3F3 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    padding: 15px 25px;
}

/* progress bar css end */


.return-order-stepform .main-steps .main-btn-div {
    display: flex;
    justify-content: end;
}

.return-order-stepform .main-steps .main-btn-div .continue-btn {
    background: #F88D28 0% 0% no-repeat padding-box;
    border-radius: 24px;
    opacity: 1;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #F88D28;
    padding: 3px 20px;
    margin: 5px;
}

.return-order-stepform .main-steps .main-btn-div .cancel-btn {
    background: transparent;
    border-radius: 24px;
    opacity: 1;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid black;
    padding: 3px 20px;
    margin: 5px;
}

.return-order-stepform .main-steps .content h6 {
    font-size: 18px;
    font-weight: 500;
}

.return-order-stepform .main-steps .content label {
    font-size: 14px;
    font-weight: 400;
}

.return-order-stepform .main-steps .content .form-control {
    font-size: 12px;
}

.return-order-stepform .main-steps .content .left-div p {
    font-size: 12px;
    font-family: 400;
    color: #000;
    margin: 0px;
}

.return-order-stepform .main-steps .content .left-div h6 {
    font-size: 15px;
    font-family: 500;
    color: #000;
}

.return-order-stepform .main-steps .content .left-div .uplod {
    background: #DBDBDB 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    border: none;
    color: #606060;
    font-size: 12px;
    padding: 5px 19px;
    margin: 5px 0px;
}

.return-order-stepform .main-steps .content .img-div {
    position: relative;
}

.return-order-stepform .main-steps .content .img-div .product {
    width: 100%;
    height: 100%;
}

.return-order-stepform .main-steps .content .img-div .wrong-icon {
    position: absolute;
    top: 0px;
    right: -7px;
    font-size: 22px;
}






@media screen and (min-width: 0px) and (max-width: 480px) {
    .return-order-stepform .progress-bar-container .line-right {

        border: none;
        width: 0px;
    }

    .return-order-stepform .progress-bar-container .step-row {
        display: inline;
    }
    .return-order-stepform .progress-bar-container .step-container {
        margin-bottom: 10px;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .return-order-stepform .progress-bar-container .line-right {

        border: none;
        width: 0px;
    }
}