.NetpurtiSeller {
  background-image: url("../../../../../public/assest/images/Seller_panel/seller-image/banner/Sellerbanner.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 3%;
  position: relative;
  height: 575px;
  margin-bottom: 16%;
}

.NetpurtiSeller .NetpurtiSeller-title h2 {
  font-size: 30px;
  letter-spacing: 0.3px;
  color: #ffffff;
  font-weight: 600;
  text-align: center;
}

.NetpurtiSeller .NetpurtiSeller-title p {
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 40px;
}

.NetpurtiSeller .videotabRow {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.NetpurtiSeller .videotabRow .nav-link {
  color: #000;
  background: #f2f2f2 0% 0% no-repeat padding-box;
  border-radius: 19px;
}

.NetpurtiSeller .videotabRow .nav-link.active {
  background: #1c42a9 0% 0% no-repeat padding-box;
  border-radius: 19px;
  color: #fff;
}

.NetpurtiSeller .videotabRow .nav-item {
  margin-left: 15px;
}

.NetpurtiSeller .videoTabmain {
  position: relative;
}

.NetpurtiSeller .videoTabmain .tabVideo {
  width: 900px;
  /* border: 86px; */
  border-radius: 36px;
}

.NetpurtiSeller .videoTabmain .videoMainsec {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}

@media screen and (min-width: 0px) and (max-width: 485px) {
  .NetpurtiSeller .videoTabmain .tabVideo {
    width: 100% !important;
    border-radius: 5px;
  }

  .NetpurtiSeller .videoTabmain .videoMainsec {
    position: unset;
    transform: none;
  }

  .NetpurtiSeller .NetpurtiSeller-title h2 {
    font-size: 20px;
  }

  .NetpurtiSeller .NetpurtiSeller-title p {
    font-size: 13px;
  }

  .NetpurtiSeller {
    height: 393px;
  }

  .NetpurtiSeller .videotabRow {
    align-content: flex-start;
    flex-wrap: unset;
    overflow-x: auto;
    width: 100%;
    justify-content: flex-start;
  }

  .NetpurtiSeller .videotabRow .nav-link {
    width: max-content;
    margin-bottom: 14px;
  }
}

@media screen and (min-width: 486px) and (max-width: 767.98px) {
  .NetpurtiSeller .videoTabmain .tabVideo {
    width: 100%;
    /* border: 86px; */
    border-radius: 36px;
  }

  .NetpurtiSeller .videoTabmain .videoMainsec {
    position: unset;
    transform: none;
  }

  .NetpurtiSeller .videotabRow {
    align-content: flex-start;
    flex-wrap: unset;
    overflow-x: auto;
    width: 100%;
    justify-content: flex-start;
  }

  .NetpurtiSeller .videotabRow .nav-link {
    width: max-content;
    margin-bottom: 14px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .NetpurtiSeller .videoTabmain .tabVideo {
    width: 100%;
    /* border: 86px; */
    border-radius: 36px;
  }

  .NetpurtiSeller .videoTabmain .videoMainsec {
    position: unset;
    transform: none;
  }

  .NetpurtiSeller .videotabRow {
    align-content: flex-start;
    flex-wrap: unset;
    overflow-x: auto;
    width: 100%;
    justify-content: flex-start;
  }

  .NetpurtiSeller .videotabRow .nav-link {
    width: max-content;
    margin-bottom: 14px;
  }
}